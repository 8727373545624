import type { ProfilingSetting, User, UserFeatureOverrides, UserId, UserInformation } from '@client/models/user-models';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';
import URI from 'urijs';
import { getPreloadedFetchOrFallback } from '../modules/preloaded-resources';
import type { UserMessage } from '../modules/subscription/types';

const VIEWED_NEW_FEATURES = 'ViewedNewFeatures';
const VIEWED_CHANGE_PAYMENT_DRAWER = 'ViewedChangePaymentDrawer';
const VIEWED_CHANGE_PLAN_SERVICE_INTRODUCTION = 'ViewedChangePlanServiceIntroduction';
const VIEWED_CANCEL_PRODUCT_ONBOARDING = 'ViewedCancelProductOnboarding';
const VIEWED_BANK_SYNC_WORK_IN_PROGRESS = 'ViewedBankSyncWorkInProgress';
const VIEWED_SEARCH_PAGE = 'ViewedSearchPage';

export async function getUser(): Promise<User> {
  return getPreloadedFetchOrFallback('user', async () =>
    axios.get('/api/user').then((response: AxiosResponse<User>) => response.data)
  );
}

export async function deleteUser(reason: any) {
  const params = {};
  if (reason) {
    set(params, 'reason', reason);
  }

  const deleteUserUrl = URI('/api/user').search(params).toString();

  return axios.delete(deleteUserUrl);
}

export async function sessionKeepAlive(): Promise<AxiosResponse<void>> {
  return axios.get('/api/v1/user-session/keep-alive');
}

export async function saveUserInformation(userInfo: UserInformation): Promise<User> {
  return axios.put('/api/user', userInfo).then((response) => response.data);
}

export async function saveUserViewedNewFeatures(): Promise<User> {
  return axios.post('/api/user/visited-page', { type: VIEWED_NEW_FEATURES }).then((response) => response.data);
}

export async function saveUserViewedChangePaymentDrawer(): Promise<User> {
  return axios.post('/api/user/visited-page', { type: VIEWED_CHANGE_PAYMENT_DRAWER }).then((response) => response.data);
}

export async function saveUserViewedChangePlanDrawer(): Promise<User> {
  return axios
    .post('/api/user/visited-page', { type: VIEWED_CHANGE_PLAN_SERVICE_INTRODUCTION })
    .then((response) => response.data);
}

export async function saveUserViewedOnboardCancelProduct(): Promise<User> {
  return axios
    .post('/api/user/visited-page', { type: VIEWED_CANCEL_PRODUCT_ONBOARDING })
    .then((response) => response.data);
}

export async function saveUserViewedBankSyncWorkInProgress(): Promise<User> {
  return axios
    .post('/api/user/visited-page', { type: VIEWED_BANK_SYNC_WORK_IN_PROGRESS })
    .then((response) => response.data);
}

export async function saveUserViewedSearchPage(): Promise<User> {
  return axios.post('/api/user/visited-page', { type: VIEWED_SEARCH_PAGE }).then((response) => response.data);
}

export async function logoutUser(): Promise<void> {
  return axios.post('/api/user/logout');
}

export async function changeUserEmail(email: string): Promise<User> {
  return axios.post('/api/user/change-email', { email }).then((response) => response.data);
}

// eslint-disable-next-line
export async function acceptPrivacyPolicyAndTermsOfUse(): Promise<User> {
  return axios.post('/api/user/accept-privacy-policy-and-terms-of-use').then((response) => response.data);
}

export async function setUserMessageAsClicked(userMessage: UserMessage) {
  const userMessageId = get(userMessage, 'id');

  return axios.get(`/api/user/user-message/${userMessageId}/mark-as-clicked`).then((response) => response.data);
}

/**
 * Returns url for notifications settings depending on the argument
 *
 * @param emailUserId Optional, only needed for users that are not logged in
 * @returns Url for notification settings
 */
function notificationSettingsUrl(emailUserId: string) {
  const path = '/api/user/notification-settings';

  return emailUserId ? `${path}?emailUserId=${encodeURIComponent(emailUserId)}` : path;
}

/**
 * Returns the notifications settings for a user
 *
 * @param emailUserId Optional, only needed for users that are not logged in
 * @returns Notifications settings for a user
 */
export async function getUserNotificationSettings(emailUserId: UserId) {
  return axios.get(notificationSettingsUrl(emailUserId)).then((response) => response.data);
}

/**
 * Updates the notification settings for a user
 *
 * @param notificationSettings The notification settings to be patched
 * @param emailUserId Optional, only needed for users that are not logged in
 * @returns The updated notifications settings for a user
 */
export async function patchUserNotificationSettings(notificationSettings: any, emailUserId: string) {
  return axios.patch(notificationSettingsUrl(emailUserId), notificationSettings).then((response) => response.data);
}

/**
 * Returns the profiling settings for a user
 */
export async function getUserProfilingSettings(): Promise<ProfilingSetting[]> {
  const path = '/api/user/profiling-settings';

  return axios.get(path).then((response) => response.data);
}

export async function updateUserProfilingSetting(profilingSetting: ProfilingSetting): Promise<ProfilingSetting[]> {
  const path = '/api/user/profiling-settings';

  return axios.patch(path, profilingSetting).then((response) => response.data);
}

export async function getGdprData(): Promise<any> {
  const path = '/api/user/export-data/json';

  return axios.get(path);
}

export async function updateFeatureOverrides(data: UserFeatureOverrides): Promise<User> {
  return axios.post(`/api/user/feature-overrides`, data, { params: {} }).then((response) => response.data);
}
