import { Sentry } from '@client/assets/js/sentry';
import type { UserId } from '@client/models/user-models';
import { CancellationOutcome } from '@client/modules/cancellation/models';
import type { OnboardingInsightUpdateIncoming } from '@client/modules/InsightsPage/insightCardModels';
import type { ContractId, SupplierId } from '@client/modules/subscription/types';
import axios from 'axios';

export type InsightId = string;

export interface Insight {
  acknowledgedAt?: string;
  id: InsightId;
  createdAt: string;
  content: InsightContent;
  userId: UserId;
  webUiPath: string;
}

export interface InsightContent {
  type: InsightContentType;
}

export enum InsightContentType {
  UnresolvedSubscription = 'UnresolvedSubscription',
  NewSubscriptionFound = 'NewSubscriptionFound',
  SubscriptionCostChanged = 'SubscriptionCostChanged',
  OnboardingInsight = 'Onboarding',
  BindingPeriodEnding = 'BindingPeriodEnding',
  DiscoverOngoing = 'DiscoverOngoing',
  DiscoverOutcome = 'DiscoverOutcome',
  PersonalMobileRecommendation = 'PersonalMobileRecommendation',
  SaveDeskOngoing = 'SaveDeskOngoing',
  SaveDeskOutcome = 'SaveDeskOutcome',
  AddonRecommendation = 'AddonRecommendation',
  CancellationOutcome = 'CancellationOutcome',
  CancellationOngoing = 'CancellationOngoing',
  SubscriptionActionOutcome = 'SubscriptionActionOutcome',
}

export interface InsightContract {
  id: ContractId;
  service: string;
  possibleActions: SupportedInsightContractAction[];
  bindingPeriod?: BindingPeriod;
}

export interface BindingPeriod {
  endsAt: string;
  startedAt: string;
}

export enum SupportedInsightContractAction {
  Cancel = 'Cancel',
}

export interface InsightServiceProvider {
  id: SupplierId;
  name: string;
  logotypeUrl?: string;
}

/**
 * @deprecated should use SubscriptionActionType instead
 */
export enum ActionType {
  Cancel = 'Cancel',
  IndependentOrder = 'IndependentOrder',
  SaveDesk = 'SaveDesk',
}

export interface CategoryName {
  name: string;
}

/**
 * This model is representing the same data as CancellationOutcome in src/client/modules/cancellation/models.ts
 * but this model is using the new vocabulary. Insights have to use the new vocabulary since they are exposed to
 * a customer.
 * In the future all code will use this model.
 */
export enum InsightCancellationOutcome {
  Cancelled = 'Cancelled',
  AlreadyCancelled = 'AlreadyCancelled',
  ConfirmedByUser = 'ConfirmedByUser',
  CancelledWithUpcomingPayments = 'CancelledWithUpcomingPayments',
  NoSuchAccount = 'NoSuchAccount',
  HasBindingPeriod = 'HasBindingPeriod',
  NoResponseFromServiceProvider = 'NoResponseFromServiceProvider',
  NoAnswerFromUser = 'NoAnswerFromUser',
  NotACancellableSubscription = 'NotACancellableSubscription',
  PayingThroughThirdParty = 'PayingThroughThirdParty',
  ManuallyClosed = 'ManuallyClosed',
  UserDeleted = 'UserDeleted',
  WithdrawalSentToServiceProvider = 'WithdrawalSentToServiceProvider',
  UserWithdrawn = 'UserWithdrawn',
  UserNotTheOwner = 'UserNotTheOwner',
  WrongUserInput = 'WrongUserInput',
  ServiceProviderContactedUser = 'ServiceProviderContactedUser',
}

export function toInsightCancellationOutcome(cancellationOutcome: CancellationOutcome): InsightCancellationOutcome {
  switch (cancellationOutcome) {
    case CancellationOutcome.Terminated:
      return InsightCancellationOutcome.Cancelled;
    case CancellationOutcome.AlreadyTerminated:
      return InsightCancellationOutcome.AlreadyCancelled;
    case CancellationOutcome.ConfirmedByUser:
      return InsightCancellationOutcome.ConfirmedByUser;
    case CancellationOutcome.NoSuchAccount:
      return InsightCancellationOutcome.NoSuchAccount;
    case CancellationOutcome.HasBindingPeriod:
      return InsightCancellationOutcome.HasBindingPeriod;
    case CancellationOutcome.NoAnswerFromSupplier:
      return InsightCancellationOutcome.NoResponseFromServiceProvider;
    case CancellationOutcome.NoAnswerFromUser:
      return InsightCancellationOutcome.NoAnswerFromUser;
    case CancellationOutcome.NotACancellableSubscription:
      return InsightCancellationOutcome.NotACancellableSubscription;
    case CancellationOutcome.PayingThroughThirdParty:
      return InsightCancellationOutcome.PayingThroughThirdParty;
    case CancellationOutcome.ManuallyClosed:
      return InsightCancellationOutcome.ManuallyClosed;
    case CancellationOutcome.Aborted:
      return InsightCancellationOutcome.UserDeleted;
    case CancellationOutcome.CancellationSent:
      return InsightCancellationOutcome.WithdrawalSentToServiceProvider;
    case CancellationOutcome.Cancelled:
      return InsightCancellationOutcome.UserWithdrawn;
    case CancellationOutcome.CancelledWithUpcomingPayments:
      return InsightCancellationOutcome.CancelledWithUpcomingPayments;
    case CancellationOutcome.UserNotTheOwner:
      return InsightCancellationOutcome.UserNotTheOwner;
    case CancellationOutcome.ServiceProviderContactedUser:
      return InsightCancellationOutcome.ServiceProviderContactedUser;
    case CancellationOutcome.WrongUserInput:
      return InsightCancellationOutcome.WrongUserInput;
    default:
      Sentry.captureMessage(
        `Don´t know how to handle outcome ${cancellationOutcome} when converting to InsightCancellationOutcome`
      );

      return InsightCancellationOutcome.Cancelled;
  }
}

export type InsightUpdateIncoming = OnboardingInsightUpdateIncoming;

export async function fetchInsightCards(): Promise<Insight[]> {
  return axios.get('/api/v1/user/insights').then((response) => response.data);
}

export async function resolveInsightCard(insightId: InsightId): Promise<Insight> {
  return axios.post(`/api/v1/user/insights/${encodeURIComponent(insightId)}/resolve`).then((response) => response.data);
}

export async function updateInsight(insightId: InsightId, payload: InsightUpdateIncoming): Promise<void> {
  return axios.post(`/api/v1/user/insights/${encodeURIComponent(insightId)}`, payload).then(({ data }) => data);
}
