import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { CancellationMethodType } from '@client/models/service';
import { SubscriptionActionType } from '@client/models/subscription';
import { isCancellationEnabled, isChangePlanEnabled, isPauseEnabled } from '@client/modules/app-context';
import { selectorAppContext, selectorFeatures } from '@client/modules/app-context/duck';
import { ActionType } from '@client/modules/InsightsPage/models';
import { BindingTimeNotice } from '@client/modules/overview/components/BindingTimeNotice';
import { RectangularActionButton } from '@client/modules/overview/ContractAction/components/RectangularActionButton';
import { isBindingPeriodEndingSoon } from '@client/modules/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/utils';
import { SubscriptionSettingsButton } from '@client/modules/subscription/components/SubscriptionSettingsButton';
import { ContractCancellationOutcome } from '@client/modules/subscription/ContractDetailsPage/components/ContractCancellationOutcome';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import { ContractDetailsPriceInformation } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsPriceInformation';
import { OngoingCancellation } from '@client/modules/subscription/ContractDetailsPage/components/OngoingCancellation';
import { SubscriptionReminder } from '@client/modules/subscription/ContractDetailsPage/components/SubscriptionReminder/component';
import { PriceHistory } from '@client/modules/subscription/PriceHistoryPage/components/PriceHistory/component';
import type {
  BindingTimeContract,
  Contract,
  ContractBindingPeriod,
  Subscription,
} from '@client/modules/subscription/types';
import { ContractState, hasTerminationInstructions, SubscriptionSource } from '@client/modules/subscription/types';
import { overviewPage, subscriptionPage } from '@client/routes';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PauseOutcomeSuccessful } from './components/PauseOutcomeSuccessful';

const useStyles = makeStyles({
  badgeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '100%',
    paddingTop: '16px',
  },

  content: {
    margin: '0 0 32px 0',
    display: 'grid',
    gridTemplateColumns: 'auto',
  },

  bindingTimeNoticeContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  bindingPeriodSpacing: {
    marginBottom: '32px',
  },
});

export interface Props {
  contract?: Contract;
  subscription: Subscription;

  onSaveContractBinding(contractBindingPeriod: ContractBindingPeriod): void;

  onCancelClick?(): void;

  onPauseClick?(): void;

  onResumeClick?(): void;

  onChangePlanClick?(): void;
}

const Component = ({
  contract,
  subscription,
  onSaveContractBinding,
  onCancelClick,
  onPauseClick,
  onResumeClick,
  onChangePlanClick,
}: Props): JSX.Element | null => {
  const classes = useStyles();
  const { colors } = useTheme();

  const history = useHistory();

  const features = useSelector(selectorFeatures);
  const appContext = useSelector(selectorAppContext);

  const category = contract?.service?.category.text;
  const cancelAvailable =
    isCancellationEnabled(features.actionFeatures) &&
    (contract?.terminable || hasTerminationInstructions(contract)) &&
    contract?.cancellationMethod.type !== CancellationMethodType.BlockPayment;
  const pauseAvailable = isPauseEnabled(features.actionFeatures);
  const changePlanAvailable = isChangePlanEnabled(features.actionFeatures);

  const contractHasActions = Boolean(
    (cancelAvailable && onCancelClick) ||
      (pauseAvailable && onPauseClick) ||
      (pauseAvailable && onResumeClick) ||
      (changePlanAvailable && onChangePlanClick)
  );
  const multipleServicesSubscription = subscription?.contracts?.length > 1;
  const multipleServicesSupplier = !subscription?.supplier.singleServiceSupplier;
  const bindingTimeOver40Days = contract && !isBindingPeriodEndingSoon(contract);
  const serviceProvider = subscription.supplier.name;
  const logoUrl = subscription.supplier.logoUrl;
  const hasTerminatingContracts = (subscription?.contracts ?? []).some((tempContract: Contract) => {
    return tempContract.state == ContractState.Terminating;
  });
  const showCancellationOutcome =
    contract?.state === ContractState.Terminated ||
    (contract?.state === ContractState.Active && contract?.lastCompletedAction === ActionType.Cancel);

  // TODO this needs to be handled in the child. Show a message if there are no settings.
  const settingsButtonEnabled =
    (!multipleServicesSubscription && multipleServicesSupplier) ||
    (!multipleServicesSubscription &&
      !hasTerminatingContracts &&
      (subscription.source === 'manual' ||
        features.subscriptionFeatures.deletingSubscriptions ||
        !(subscription.supplier.singleServiceSupplier ?? true)));

  const pauseOutcomeSuccessfulCard = pauseAvailable &&
    contract?.pausedAt &&
    contract?.pauseInfo?.pausedUntil &&
    contract?.pauseInfo && (
      <PauseOutcomeSuccessful
        pausedFrom={contract.pauseInfo.pausedFrom}
        pausedUntil={contract.pauseInfo.pausedUntil}
        pausedAt={contract.pausedAt}
        pauseOutcome={contract.pauseInfo.actionOutcome}
      />
    );

  const cancelButton = cancelAvailable && onCancelClick && (
    <RectangularActionButton
      actionType={SubscriptionActionType.Cancel}
      onClick={onCancelClick}
      data-test="button-cancel"
    />
  );

  const pauseButton = pauseAvailable && contract && onPauseClick && (
    <RectangularActionButton
      actionType={SubscriptionActionType.Pause}
      onClick={onPauseClick}
      data-test="button-pause"
    />
  );

  const resumeButton = pauseAvailable && contract && onResumeClick && (
    <RectangularActionButton
      actionType={SubscriptionActionType.Resume}
      onClick={onResumeClick}
      data-test="button-resume"
    />
  );

  const changePlanButton = changePlanAvailable && contract && onChangePlanClick && (
    <RectangularActionButton
      actionType={SubscriptionActionType.ChangePlan}
      onClick={onChangePlanClick}
      data-test="button-change-plan"
    />
  );

  const actionButtons = (
    <div className={classes.badgeContainer}>
      <Body style={{ margin: '0 0 8px 16px' }} color={colors.textOn.surfaceSubdued}>
        <LocalizedMessage id="weCanHelpYou" />
      </Body>
      {changePlanButton}
      {pauseButton}
      {resumeButton}
      {cancelButton}
    </div>
  );

  function showPriceHistory() {
    if (subscription.source === SubscriptionSource.Converted) {
      return false;
    }
    return (subscription?.contracts.length ?? 0) === 1 && subscription.bankEvents?.length;
  }

  function bindingTimeAndCostVisualizationContainers() {
    const contractIsActive = contract?.state === ContractState.Active;
    const reminderEnabled = appContext.features.subscriptionFeatures.reminderEnabled;
    const showSubscriptionReminder = contract && contractIsActive && reminderEnabled;

    return showSubscriptionReminder || showPriceHistory() ? (
      <>
        <Body style={{ margin: '32px 0 16px 16px' }} color={colors.textOn.surfaceSubdued}>
          <LocalizedMessage id="contractInformationV2" />
        </Body>
        {showSubscriptionReminder && (
          <div className={classes.bindingPeriodSpacing}>
            <SubscriptionReminder
              contract={contract}
              onSaveContractBinding={onSaveContractBinding}
              subscription={subscription}
            />
          </div>
        )}
        {showPriceHistory() && <PriceHistory contractId={contract?.id} showViewTransactionsButton />}
      </>
    ) : null;
  }

  function getNextPaymentDate() {
    return subscription.source === SubscriptionSource.Converted ? undefined : subscription.nextPaymentDate;
  }

  const headerContent = (
    <ContractDetailsPriceInformation
      paymentInterval={subscription.paymentInterval}
      estimatedPaymentDate={getNextPaymentDate()}
      cost={subscription.cost}
    />
  );

  const header = (
    <ContractHeaderDeprecated
      providerLogo={logoUrl}
      content={headerContent}
      title={category ?? ''}
      providerName={serviceProvider}
      subHeading={serviceProvider}
    />
  );

  return subscription && contract ? (
    <MultiDeviceLayout
      fullWidthContent={header}
      footer={
        <ContractDetailsFloatingButtons
          backButton={!!features.identify?.overview || multipleServicesSubscription}
          customGoBack={() => {
            history.push(multipleServicesSubscription ? subscriptionPage(subscription.id) : overviewPage);
          }}
        />
      }
      hasTopNavigation={false}
    >
      {pauseOutcomeSuccessfulCard}
      {!bindingTimeOver40Days && contractHasActions && !hasTerminatingContracts ? actionButtons : null}
      {bindingTimeOver40Days && contractHasActions ? (
        <div className={classes.bindingTimeNoticeContainer}>
          <BindingTimeNotice
            bindingPeriodEndDate={(contract as BindingTimeContract).contractBindingPeriod.endsAt}
            contractHasActions={contractHasActions}
          />
        </div>
      ) : null}

      <div className={classes.content}>
        {showCancellationOutcome && contract.cancellationInfo?.actionOutcome && (
          <ContractCancellationOutcome
            cancellationInfo={contract.cancellationInfo}
            serviceProviderName={subscription.supplier.name}
            serviceProviderId={subscription.supplier.id}
            categoryName={contract.service.category.name}
            contractId={contract.id}
            subscriptionId={subscription.id}
            outcome={contract.cancellationInfo.actionOutcome}
            market={appContext.market}
            serviceName={contract.service.name}
          />
        )}
        {contract?.state === ContractState.Terminating && contract.cancellationInfo && (
          <OngoingCancellation
            cancellationInfo={contract.cancellationInfo}
            serviceProviderName={serviceProvider}
            categoryName={contract.service.category.name}
            localizedCategoryName={contract.service.category.text}
          />
        )}
        {settingsButtonEnabled && <SubscriptionSettingsButton subscription={subscription} />}
        {bindingTimeAndCostVisualizationContainers()}
      </div>
    </MultiDeviceLayout>
  ) : null;
};

export const ContractDetailsPageComponent = withLocalization('contract/ContractDetails')(Component);
