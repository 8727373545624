import aboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/sv-SE.json';
import bankAboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/BankAboutUsPage/sv-SE.json';
import insightPage from '@client/internationalization/localization-messages/overview/InsightsPage/sv-SE.json';
import insightPage_insightsCards_addon from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/AddonRecommendation/sv-SE.json';
import insightPage_bindingTimeEnding from '@client/internationalization/localization-messages/overview/InsightsPage/BindingTimeEnding/sv-SE.json';
import insightPage_insightsCards_discoverOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOngoing/sv-SE.json';
import insightPage_insightsCards_discoverOutcomeInsight from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOutcome/sv-SE.json';
import insightPage_insightsCards_newSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewSubscriptionFound/sv-SE.json';
import insightPage_insightsCards_personalRecommend from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/sv-SE.json';
import insightPage_insightsCards_saveDeskOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOngoingCard/sv-SE.json';
import insightPage_insightsCards_saveDeskOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOutcome/sv-SE.json';
import insightPage_subscriptionCostChanged from '@client/internationalization/localization-messages/overview/InsightsPage/SubscriptionCostChanged/sv-SE.json';
import insightPage_unresolvedSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnresolvedSubscription/sv-SE.json';
import overviewPage from '@client/internationalization/localization-messages/overview/OverviewPage/sv-SE.json';
import overviewAddManualSubscription from '@client/internationalization/localization-messages/overview/addManualSubscription/sv-SE.json';
import overviewBindingTimeBar from '@client/internationalization/localization-messages/overview/BindingTimeBar/sv-SE.json';
import overviewPage_didYouCancel from '@client/internationalization/localization-messages/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/sv-SE.json';
import badgeCtas from '@client/internationalization/localization-messages/BadgeCtas/sv-SE.json';
import bindingTimeNotice from '@client/internationalization/localization-messages/overview/BindingTimeNotice/sv-SE.json';
import onboardingDrawer from '@client/internationalization/localization-messages/overview/OnboardingDrawer/sv-SE.json';
import overviewPage_quickActionsV2 from '@client/internationalization/localization-messages/overview/QuickActionsV2/sv-SE.json';
import addonMobileRecommendationsPage from '@client/internationalization/localization-messages/addon/mobile/RecommendationsPage/sv-SE.json';
import addonMobileResultPage from '@client/internationalization/localization-messages/addon/mobile/ResultPage/sv-SE.json';
import authAuthStatusPage from '@client/internationalization/localization-messages/auth/AuthStatusPage/sv-SE.json';
import authBasicLoginPage from '@client/internationalization/localization-messages/auth/BasicLoginPage/sv-SE.json';
import authCreateAccountPage from '@client/internationalization/localization-messages/auth/CreateAccountPage/sv-SE.json';
import authOnboardingChooseBankPage from '@client/internationalization/localization-messages/auth/OnboardingChooseBankPage/sv-SE.json';
import authOnboardingEmailPage from '@client/internationalization/localization-messages/auth/OnboardingEmailPage/sv-SE.json';
import authPreLoginPage from '@client/internationalization/localization-messages/auth/PreLoginPage/sv-SE.json';
import authResetPasswordPage from '@client/internationalization/localization-messages/auth/ResetPasswordPage/sv-SE.json';
import authSyncSourcesError from '@client/internationalization/localization-messages/auth/SyncSourcesError/sv-SE.json';
import authSyncSourcesFetching from '@client/internationalization/localization-messages/auth/SyncSourcesFetching/sv-SE.json';
import badgeABTestCTAS from '@client/internationalization/localization-messages/BadgeABTestCtas/sv-SE.json';
import bankSyncCancelProduct from '@client/internationalization/localization-messages/bank-sync/CancelProductOnboardPages/sv-SE.json';
import bankSyncDrawerPages from '@client/internationalization/localization-messages/bank-sync/DrawerPages/sv-SE.json';
import bankSyncLoadingScreen from '@client/internationalization/localization-messages/bank-sync/LoadingScreen/sv-SE.json';
import bankSyncOnboardBankSync from '@client/internationalization/localization-messages/bank-sync/OnBoardBankSync/sv-SE.json';
import campaignIdSelect from '@client/internationalization/localization-messages/CampaignIdSelect/sv-SE.json';
import cancelCancellationOutcome from '@client/internationalization/localization-messages/cancel/CancellationOutcome/sv-SE.json';
import cancellationDetailsPage from '@client/internationalization/localization-messages/cancellation/cancellationDetailsPage/sv-SE.json';
import cancellationFormCancellationInstruction from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationInstructionsSection/sv-SE.json';
import cancellationFormCancellationTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationTerms/sv-SE.json';
import cancellationFormConfirmSection from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/ConfirmSection/sv-SE.json';
import cancellationFormLightUserTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/LightUserTerms/sv-SE.json';
import cancellationModalsContainer from '@client/internationalization/localization-messages/cancellation/CancellationModalsContainer/sv-SE.json';
import cancellationQuestionsFlow from '@client/internationalization/localization-messages/cancellation/CancellationQuestionFlow/sv-SE.json';
import cancellationReasonsDialog from '@client/internationalization/localization-messages/cancellation/CancellationReasonsDialog/sv-SE.json';
import cancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/sv-SE.json';
import cancellationStepper from '@client/internationalization/localization-messages/cancellation/CancellationStepper/sv-SE.json';
import cancellationDoYouWantToSaveDialog from '@client/internationalization/localization-messages/cancellation/DoYouWantToSaveDialog/sv-SE.json';
import cancellationIntelligentGuidePage from '@client/internationalization/localization-messages/cancellation/IntelligentGuidePage/sv-SE.json';
import cancellationNetflixCancellationInstructionsPage from '@client/internationalization/localization-messages/cancellation/NetflixCancellationInstructionsPage/sv-SE.json';
import cancellationSigningNemIdErrorPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdErrorPage/sv-SE.json';
import cancellationSigningNemIdSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdSigningPage/sv-SE.json';
import cancellationNoSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningPage/sv-SE.json';
import cancellationNoSigningServicePage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningServicePage/sv-SE.json';
import cancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/sv-SE.json';
import cancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/sv-SE.json';
import categories from '@client/internationalization/localization-messages/categories/sv-SE.json';
import categoryNames from '@client/internationalization/localization-messages/CategoryNames/CategoryNames/sv-SE.json';
import changeDataPlan from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplan/sv-SE.json';
import changeDataPlanCompletedPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanCompletedPage/sv-SE.json';
import changeDataPlanOrderFormPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanOrderFormPage/sv-SE.json';
import changeDataPlanPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanPage/sv-SE.json';
import changePaymentChangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-payment/ChangePlanServiceIntroductionDrawer/sv-SE.json';
import changePaymentIntelligentGuidePage from '@client/internationalization/localization-messages/change-payment/IntelligentGuidePage/sv-SE.json';
import changePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-plan/ChangePlanServiceIntroductionDrawer/sv-SE.json';
import changePlanIntelligentGuideExperimentPage from '@client/internationalization/localization-messages/change-plan/IntelligentGuideExperimentPage/sv-SE.json';
import changePlanIntelligentGuidePage from '@client/internationalization/localization-messages/change-plan/IntelligentGuidePage/sv-SE.json';
import changePlan from '@client/internationalization/localization-messages/ChangePlan/sv-SE.json';
import charityDonation from '@client/internationalization/localization-messages/charity-donation/sv-SE.json';
import complexContractGuideIntroPage from '@client/internationalization/localization-messages/complex-contract-guide/IntroPage/sv-SE.json';
import complexContractGuideLoanServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanServiceQuestionPage/sv-SE.json';
import complexContractGuideLoanTypeQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanTypeQuestionPage/sv-SE.json';
import complexContractGuideServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/ServiceQuestionPage/sv-SE.json';
import complexContractGuideShared from '@client/internationalization/localization-messages/complex-contract-guide/shared/sv-SE.json';
import complexContractGuideSuccessPage from '@client/internationalization/localization-messages/complex-contract-guide/SuccessPage/sv-SE.json';
import conditionsAndPoliciesMenu from '@client/internationalization/localization-messages/conditions-and-policies/ConditionsAndPoliciesMenu/sv-SE.json';
import conditionsAndPoliciesCookiesPage from '@client/internationalization/localization-messages/conditions-and-policies/CookiesPage/sv-SE.json';
import conditionsAndPoliciesSecurityPage from '@client/internationalization/localization-messages/conditions-and-policies/SecurityPage/sv-SE.json';
import conditionsAndPolicies from '@client/internationalization/localization-messages/conditions-and-policies/sv-SE.json';
import contractBindingPeriodDialog from '@client/internationalization/localization-messages/contract/bindingPeriodDialog/sv-SE.json';
import contractCancellationHistory from '@client/internationalization/localization-messages/contract/CancellationHistory/sv-SE.json';
import contractCancelledContract from '@client/internationalization/localization-messages/contract/CancelledContract/sv-SE.json';
import contractCancellationOutcome from '@client/internationalization/localization-messages/contract/ContractCancellationOutcome/sv-SE.json';
import contractCardMessage from '@client/internationalization/localization-messages/contract/ContractCardMessage/sv-SE.json';
import contractDetails from '@client/internationalization/localization-messages/contract/ContractDetails/sv-SE.json';
import contractDetailsPage from '@client/internationalization/localization-messages/contract/ContractDetailsPage/sv-SE.json';
import contractDoMoreComponent from '@client/internationalization/localization-messages/contract/DoMoreComponent/sv-SE.json';
import contractHistoryToolTip from '@client/internationalization/localization-messages/contract/HistoryTooltip/sv-SE.json';
import contractPriceHistory from '@client/internationalization/localization-messages/contract/PriceHistoryLolipop/sv-SE.json';
import contractSelectServicePage from '@client/internationalization/localization-messages/contract/SelectServicePageLolipop/sv-SE.json';
import contractSelectServicesList from '@client/internationalization/localization-messages/contract/SelectServicesListLolipop/sv-SE.json';
import contractSettingsButton from '@client/internationalization/localization-messages/contract/SettingsButtonLolipop/sv-SE.json';
import contractSettingsPage from '@client/internationalization/localization-messages/contract/SettingsPageLolipop/sv-SE.json';
import contractSubscriptionAlarm from '@client/internationalization/localization-messages/contract/SubscriptionAlarmLolipop/sv-SE.json';
import contractSubscriptionDetails from '@client/internationalization/localization-messages/contract/SubscriptionDetailsLolipop/sv-SE.json';
import contractSubscriptionReminder from '@client/internationalization/localization-messages/contract/SubscriptionReminder/sv-SE.json';
import contractSubscriptionReminderDialog from '@client/internationalization/localization-messages/contract/subscriptionReminderDialog/sv-SE.json';
import contractTransactionHistory from '@client/internationalization/localization-messages/contract/TransactionHistoryLolipop/sv-SE.json';
import contractUpdateCost from '@client/internationalization/localization-messages/contract/UpdateCostLolipop/sv-SE.json';
import discoverCategoryPage from '@client/internationalization/localization-messages/discover/CategoryPage/sv-SE.json';
import discoverCategoryProviderPage from '@client/internationalization/localization-messages/discover/CategoryProviderPage/sv-SE.json';
import discoverComponentsProductInfo from '@client/internationalization/localization-messages/discover/components/ProductInformationHeader/sv-SE.json';
import discoverDiscoverWidget from '@client/internationalization/localization-messages/discover/DiscoverWidget/sv-SE.json';
import discoverFakeCategoryPage from '@client/internationalization/localization-messages/discover/FakeCategoryPage/sv-SE.json';
import discoverFakeCategorySubscriptionPage from '@client/internationalization/localization-messages/discover/FakeCategorySubscriptionsPage/sv-SE.json';
import discoverFitnessCategoryFeedbackPage from '@client/internationalization/localization-messages/discover/FitnessCategoryFeedbackPage/sv-SE.json';
import discoverMarketplacePage from '@client/internationalization/localization-messages/discover/MarketplacePage/sv-SE.json';
import discoverMobileOrderSentPage from '@client/internationalization/localization-messages/discover/MobileOrderSentPage/sv-SE.json';
import discoverOrderProductPage from '@client/internationalization/localization-messages/discover/OrderProductPage/sv-SE.json';
import discoverProductRecommendations from '@client/internationalization/localization-messages/discover/ProductRecommendations/sv-SE.json';
import discoverSearchSubscriptionAllCategories from '@client/internationalization/localization-messages/discover/SearchSubscriptionAllCategoriesPage/sv-SE.json';
import discoverSearchSubscriptionPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionPage/sv-SE.json';
import discoverSearchSubscriptionResultPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionResultPage/sv-SE.json';
import dummy from '@client/internationalization/localization-messages/DUMMY/sv-SE.json';
import eSigning from '@client/internationalization/localization-messages/e-signing/nem-id/NemIdSigningPage/sv-SE.json';
import editProduct from '@client/internationalization/localization-messages/EditProduct/sv-SE.json';
import energyGuide from '@client/internationalization/localization-messages/EnergyGuide/OrderForm/sv-SE.json';
import errorHandling from '@client/internationalization/localization-messages/error-handling/LoggedOutPage/sv-SE.json';
import errorPageErrorPage from '@client/internationalization/localization-messages/errorPage/ErrorPage/sv-SE.json';
import errorScreen from '@client/internationalization/localization-messages/ErrorScreen/sv-SE.json';
import explore from '@client/internationalization/localization-messages/Explore/sv-SE.json';
import feedbackModal from '@client/internationalization/localization-messages/feedback-modal/sv-SE.json';
import fields from '@client/internationalization/localization-messages/fields/sv-SE.json';
import financialProductsLoansShow from '@client/internationalization/localization-messages/financial-products/loans/ShowNewFeatureLoanDialog/sv-SE.json';
import financialProductsSavingsInvestment from '@client/internationalization/localization-messages/financial-products/savings/InvestmentInfoCard/sv-SE.json';
import financialProductOurPartnersPage from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPage/sv-SE.json';
import financialProductOurPartnersPageNew from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPageNew/sv-SE.json';
import financialProductsSavingsOverview from '@client/internationalization/localization-messages/financial-products/savings/Overview/sv-SE.json';
import financialProductSavingsAfterCancellation from '@client/internationalization/localization-messages/financial-products/savings/SavingsAfterCancellation/sv-SE.json';
import financialProductsShowNewFeatureLoan from '@client/internationalization/localization-messages/financial-products/ShowNewFeatureLoanDialog/sv-SE.json';
import financialProductsSwedBankConsolidate from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/HowItWorksPage/sv-SE.json';
import financialSwedBankConsolidateIntro from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/IntroPage/sv-SE.json';
import financialProductLoanApplication from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/LoanApplicationStatus/sv-SE.json';
import financialProductsOfferPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OfferPage/sv-SE.json';
import financialProductOrderCompleted from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderCompletedPage/sv-SE.json';
import financialProductOrderPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderPage/sv-SE.json';
import financialProductSelectContract from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectContractsPage/sv-SE.json';
import financialProductsSelectPrincipal from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectPrincipalPage/sv-SE.json';
import financialProductRepaymentPeriod from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/sv-SE.json';
import generalIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/sv-SE.json';
import generalSelectServices from '@client/internationalization/localization-messages/general/SelectServicesPage/sv-SE.json';
import genericActionsPage from '@client/internationalization/localization-messages/genericActions/GenericActionsPage/sv-SE.json';
import genericActionsSelectServices from '@client/internationalization/localization-messages/genericActions/SelectServicesPage/sv-SE.json';
import genericActionsSubscriptionCancel from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/Cancel/sv-SE.json';
import genericActionsSubscriptionsChangePayment from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/ChangePayment/sv-SE.json';
import guidesContractDetailsAlternative from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/AlternativesSection/sv-SE.json';
import guidesDealBanner from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/DealBanner/sv-SE.json';
import guidesPaymentMethod from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/PaymentMethodsCard/sv-SE.json';
import hack7CancelPage from '@client/internationalization/localization-messages/Hack7CancelPage/sv-SE.json';
import header from '@client/internationalization/localization-messages/Header/sv-SE.json';
import howToCancelListPage from '@client/internationalization/localization-messages/HowToCancelListPage/sv-SE.json';
import howToCancelPage from '@client/internationalization/localization-messages/HowToCancelPage/sv-SE.json';
import improveOrderPage from '@client/internationalization/localization-messages/improve/flowgen/OrderPage/sv-SE.json';
import improveOrderSentPage from '@client/internationalization/localization-messages/improve/flowgen/OrderSentPage/sv-SE.json';
import improveOtherAlternativePage from '@client/internationalization/localization-messages/improve/flowgen/OtherAlternativesPage/sv-SE.json';
import improvePostalCodeInput from '@client/internationalization/localization-messages/improve/flowgen/postalCodeInput/sv-SE.json';
import improveResultPage from '@client/internationalization/localization-messages/improve/flowgen/ResultPage/sv-SE.json';
import improveFlowgen from '@client/internationalization/localization-messages/improve/flowgen/sv-SE.json';
import insightPageInsightsCardsCancelOutcome from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationOutcome/sv-SE.json';
import insightPageInsightsCardsCancelUpdate from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationUpdate/sv-SE.json';
import insightPageOnboardingInsightCard from '@client/internationalization/localization-messages/InsightsPage/OnboardingInsightCard/sv-SE.json';
import intervalToString from '@client/internationalization/localization-messages/interval-to-string/sv-SE.json';
import landingPage from '@client/internationalization/localization-messages/LandingPage/sv-SE.json';
import loadingScreen from '@client/internationalization/localization-messages/LoadingScreen/sv-SE.json';
import loanApplicationsDetailsPage from '@client/internationalization/localization-messages/loan-applications/DetailsPage/sv-SE.json';
import loanApplicationsLoadingError from '@client/internationalization/localization-messages/loan-applications/LoadingError/sv-SE.json';
import loanApplicationsMenuDrawer from '@client/internationalization/localization-messages/loan-applications/MenuDrawer/sv-SE.json';
import loanApplicationsOverview from '@client/internationalization/localization-messages/loan-applications/Overview/sv-SE.json';
import loginPage from '@client/internationalization/localization-messages/LoginPage/sv-SE.json';
import manageOrder from '@client/internationalization/localization-messages/ManageOrder/sv-SE.json';
import manualAddContractPage from '@client/internationalization/localization-messages/ManualAddContractPage/sv-SE.json';
import manualAddContractPricingPage from '@client/internationalization/localization-messages/ManualContractPricingPage/sv-SE.json';
import menu from '@client/internationalization/localization-messages/Menu/sv-SE.json';
import menuPage from '@client/internationalization/localization-messages/MenuPage/sv-SE.json';
import movingBundledCancellationForm from '@client/internationalization/localization-messages/moving/BundledCancellationFromPage/sv-SE.json';
import movingBundledCancellationPage from '@client/internationalization/localization-messages/moving/BundledCancellationPage/sv-SE.json';
import movingBundledSwitchOrderForm from '@client/internationalization/localization-messages/moving/BundledSwitchOrderFormPage/sv-SE.json';
import movingBundledSwitchPage from '@client/internationalization/localization-messages/moving/BundledSwitchSwitchPage/sv-SE.json';
import thanksSwitchPage from '@client/internationalization/localization-messages/moving/ThanksSwitchPage/sv-SE.json';
import movingGuide from '@client/internationalization/localization-messages/movingGuide/sv-SE.json';
import movingGuideMovingDetails from '@client/internationalization/localization-messages/movingGuide/MovingDetailsPage/sv-SE.json';
import movingGuideOneLastThingPage from '@client/internationalization/localization-messages/movingGuide/OneLastThingPage/sv-SE.json';
import negotiateContractCompletePage from '@client/internationalization/localization-messages/negotiate/NegotiateContractCompletePage/sv-SE.json';
import negotiateGuide from '@client/internationalization/localization-messages/negotiate/NegotiateGuide/sv-SE.json';
import negotiationOngoingCard from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationCard/sv-SE.json';
import negotiationOngoingStepper from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationStepper/sv-SE.json';
import notificationOptInPage from '@client/internationalization/localization-messages/NotificationOptInPage/sv-SE.json';
import onboardingPSD2Consent from '@client/internationalization/localization-messages/onboarding/PSD2ConsentPage/sv-SE.json';
import onboardingPSD2Primer from '@client/internationalization/localization-messages/onboarding/PSD2PrimerPage/sv-SE.json';
import onboardingBDSyncConnecting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryConnecting/sv-SE.json';
import onboardingBDSyncError from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryErrorPage/sv-SE.json';
import onboardingBDSyncFetch from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryFetchPage/sv-SE.json';
import onboardingBDSyncSuccess from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/sv-SE.json';
import onboardingBDSyncWaiting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/sv-SE.json';
import onboardingCancelProduct from '@client/internationalization/localization-messages/onboarding-cancel-product/OnboardingCancelProductPages/sv-SE.json';
import onboardingPsd2WelcomePage from '@client/internationalization/localization-messages/onboarding-psd2/WelcomePage/sv-SE.json';
import onboardingPsd2Cards from '@client/internationalization/localization-messages/onboarding-psd2/Cards/sv-SE.json';
import onboardingPsd2ConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/sv-SE.json';
import onboardingPsd2OpBankConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPage/sv-SE.json';
import onboardingPsd2OpBankConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPageV2/sv-SE.json';
import onboardingPsd2ConsentPageDanskeBank from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/DanskeBankConsentPage/sv-SE.json';
import onboardingPsd2RenewConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPage/sv-SE.json';
import onboardingPsd2RenewConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPageV2/sv-SE.json';
import orderedSubscriptionOngoing from '@client/internationalization/localization-messages/orderedSubscription/OngoingOrderCard/sv-SE.json';
import orderedSubscriptionOrderedEnergy from '@client/internationalization/localization-messages/orderedSubscription/OrderedEnergyProductInfo/sv-SE.json';
import orderedSubscriptionOrderedMobile from '@client/internationalization/localization-messages/orderedSubscription/OrderedMobileProductInfo/sv-SE.json';
import orderedSubscriptionDetails from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetails/sv-SE.json';
import orderedSubscriptionDetailsPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/sv-SE.json';
import orderedSubscriptionInfoCard from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/sv-SE.json';
import orderedSubscriptionPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionPage/sv-SE.json';
import orderedSubscriptionOrderStepper from '@client/internationalization/localization-messages/orderedSubscription/OrderStepper/sv-SE.json';
import orderedSubscServiceProviderInfo from '@client/internationalization/localization-messages/orderedSubscription/ServiceProviderInfoCard/sv-SE.json';
import orderingSubscriptionTerms from '@client/internationalization/localization-messages/ordering-subscription/common/TermsAndConditionsPage/sv-SE.json';
import orderingSubscriptionCompleted from '@client/internationalization/localization-messages/ordering-subscription/common/OrderCompletedPage/sv-SE.json';
import orderingBroadbandAddressPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAddressPage/sv-SE.json';
import orderingBroadbandAllAlternatives from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/sv-SE.json';
import orderingBroadbandContract from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideContractCostPage/sv-SE.json';
import orderingBroadbandIntroPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideIntroPage/sv-SE.json';
import orderingBroadbandNoResultPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideNoResultPage/sv-SE.json';
import orderingBroadbandSpeedPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideSpeedPage/sv-SE.json';
import orderingBroadbandOptimizeOrder from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandOrderFormPage/sv-SE.json';
import orderingBroadbandOptimizeProduct from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/sv-SE.json';
import orderingBroadbandOptimizeProductCosts from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/sv-SE.json';
import orderingElectricityOrder from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPage/sv-SE.json';
import orderingElectricityOrderVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/sv-SE.json';
import orderingElectricityGuidePrice from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/sv-SE.json';
import orderingElectricityGuideAlternatives from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/sv-SE.json';
import orderingElectricityGuideBinding from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/sv-SE.json';
import orderingElectricityGuideBindingTime from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/sv-SE.json';
import orderingElectricityGuideEnergy from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/sv-SE.json';
import orderingElectricityGuideExplanation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/sv-SE.json';
import orderingElectricityGuideFlow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/sv-SE.json';
import orderingElectricityGuideHow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/sv-SE.json';
import orderingElectricityGuideInformation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/sv-SE.json';
import orderingElectricityGuideIntro from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/sv-SE.json';
import orderingElectricityGuideLivingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/sv-SE.json';
import orderingElectricityGuidePostalCodePage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/sv-SE.json';
import orderingElectricityGuidePreferences from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/sv-SE.json';
import orderingElectricityGuidePricingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/sv-SE.json';
import orderingElectricityGuideProductPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/sv-SE.json';
import orderingElectricityGuideSummaryPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/sv-SE.json';
import orderingElectricityGuideTrading from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/sv-SE.json';
import orderingElectricityGuideUsage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/sv-SE.json';
import orderingElectricityGuideUsageResult from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/sv-SE.json';
import orderingElectricityGuideUsageResultVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/sv-SE.json';
import orderingElectricityGuidePostalCode from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/sv-SE.json';
import orderingSubscriptionIndependentOrder from '@client/internationalization/localization-messages/ordering-subscription/independent/OrderCompletedPage/sv-SE.json';
import ordersOverview from '@client/internationalization/localization-messages/OrdersOverview/sv-SE.json';
import overviewChangePlanService from '@client/internationalization/localization-messages/overview/ChangePlanServiceIntroductionDrawer/sv-SE.json';
import overviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/sv-SE.json';
import overviewDeprecatedInsights from '@client/internationalization/localization-messages/overview/DeprecatedInsightsPage/OnboardingInsightCard/sv-SE.json';
import overviewDiscoverPage from '@client/internationalization/localization-messages/overview/DiscoverPage/sv-SE.json';
import overviewGenericAction from '@client/internationalization/localization-messages/overview/GenericActions/sv-SE.json';
import overviewImprovementPage from '@client/internationalization/localization-messages/overview/ImprovementPage/sv-SE.json';
import overviewImproveV2 from '@client/internationalization/localization-messages/overview/ImproveV2/sv-SE.json';
import overviewInsightsCardsActionRating from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ActionRating/sv-SE.json';
import overviewInsightsCardsCancellationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/CancellationUpdate/sv-SE.json';
import overviewInsightsCardsChangeData from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ChangeDataplanUpdate/sv-SE.json';
import overviewInsightsCardsIndependent from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/IndependentOrderUpdate/sv-SE.json';
import overviewInsightsCardConfirmCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/sv-SE.json';
import overviewContactUsButton from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ContactUsButton/sv-SE.json';
import overviewInsightsCardsMonthlyCheck from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/MonthlyChecklist/sv-SE.json';
import overviewInsightsCardsNegotiationOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationOutcome/sv-SE.json';
import overviewInsightsCardsNegotiationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationUpdate/sv-SE.json';
import overviewInsightsCardsProvider from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewProviderOffersCard/sv-SE.json';
import overviewInsightsCardsSaveDeskFeedback from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/sv-SE.json';
import overviewInsightCardsSaveDeskRequested from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOrderRequested/sv-SE.json';
import overviewInsightsCardsSaveDeskPredefined from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/sv-SE.json';
import overviewInsightsCardsSaveDeskResponse from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskResponseCard/sv-SE.json';
import overviewInsightsCardsSwitchOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchOutcome/sv-SE.json';
import overviewInsightsCardsSwitchUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchUpdate/sv-SE.json';
import overviewInsightsCardsThankYou from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ThankYouCard/sv-SE.json';
import overviewInsightsCardsTryCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/TryCancellationAgainButton/sv-SE.json';
import overviewInsightsCardsUnreviewed from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnreviewedAction/sv-SE.json';
import overviewInsightsCardsOnboarding from '@client/internationalization/localization-messages/overview/InsightsPage/OnboardingInsightCard/sv-SE.json';
import overviewInsightsPrimerCardV2 from '@client/internationalization/localization-messages/overview/InsightsPage/PrimerCardV2/sv-SE.json';
import overviewLoadingWithCogs from '@client/internationalization/localization-messages/overview/LoadingWithCogs/sv-SE.json';
import overviewManageContract from '@client/internationalization/localization-messages/overview/ManageContractSection/SwitchProgressWithoutCancelStepper/sv-SE.json';
import overviewMonthlyChecklist from '@client/internationalization/localization-messages/overview/MonthlyChecklist/sv-SE.json';
import overviewMoreInfoNeeded from '@client/internationalization/localization-messages/overview/more-information-needed/sv-SE.json';
import overviewOnboardingCards from '@client/internationalization/localization-messages/overview/OnboardingCards/sv-SE.json';
import overviewQuickActionsCancel from '@client/internationalization/localization-messages/overview/QuickActionsCancel/sv-SE.json';
import overviewQuickActionsCancelV2 from '@client/internationalization/localization-messages/overview/QuickActionsCancelV2/sv-SE.json';
import overviewQuickActionsImprove from '@client/internationalization/localization-messages/overview/QuickActionsImprove/sv-SE.json';
import overviewQuickActionsImproveV2 from '@client/internationalization/localization-messages/overview/QuickActionsImproveV2/sv-SE.json';
import overviewSubscriptionFeedback from '@client/internationalization/localization-messages/overview/SubscriptionFeedback/sv-SE.json';
import overviewSubscriptionSearch from '@client/internationalization/localization-messages/overview/SubscriptionSearch/sv-SE.json';
import overviewSubscriptionSearchComponent from '@client/internationalization/localization-messages/overview/SubscriptionSearchOverviewComponent/sv-SE.json';
import waysToSave from '@client/internationalization/localization-messages/overview/WaysToSave/sv-SE.json';
import overview from '@client/internationalization/localization-messages/overview/sv-SE.json';
import overviewBottomNavigation from '@client/internationalization/localization-messages/overviewBottomNavigation/sv-SE.json';
import pauseIntelligentGuide from '@client/internationalization/localization-messages/pause/IntelligentGuidePage/sv-SE.json';
import pauseSubscriptionPause from '@client/internationalization/localization-messages/pause/SubscriptionPausePage/sv-SE.json';
import provideFeedbackPage from '@client/internationalization/localization-messages/ProvideFeedbackPage/sv-SE.json';
import providerPortalDatepicker from '@client/internationalization/localization-messages/provider-portal-datepicker/DatePicker/sv-SE.json';
import providerPortalSnackback from '@client/internationalization/localization-messages/provider-portal-snackback/Snackbar/sv-SE.json';
import registrationPage from '@client/internationalization/localization-messages/RegistrationPage/sv-SE.json';
import retentionOfferCard from '@client/internationalization/localization-messages/retention-offer/SuccessfulRetentionOfferCard/sv-SE.json';
import saveDesk from '@client/internationalization/localization-messages/save-desk/sv-SE.json';
import saveDeskOngoingCard from '@client/internationalization/localization-messages/save-desk/OngoingSaveDeskCard/sv-SE.json';
import saveDeskOrderSent from '@client/internationalization/localization-messages/save-desk/OrderSentPage/sv-SE.json';
import savingsDoYouWantToSave from '@client/internationalization/localization-messages/savings/DoYouWantToSaveDialog/sv-SE.json';
import savingsAfterCancellation from '@client/internationalization/localization-messages/savings/SavingsAfterCancellation/sv-SE.json';
import selectProductPage from '@client/internationalization/localization-messages/SelectProductPage/sv-SE.json';
import settingsNotificationSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/sv-SE.json';
import settingsNotificationPushSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/PushNotificationInstructions/sv-SE.json';
import settingsPersonalDetails from '@client/internationalization/localization-messages/settings/PersonalDetailsPage/sv-SE.json';
import settingsProfile from '@client/internationalization/localization-messages/settings/ProfilingSettingsPage/sv-SE.json';
import settingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/sv-SE.json';
import settingsUser from '@client/internationalization/localization-messages/settings/UserSettingsPage/sv-SE.json';
import settingsViewGdprData from '@client/internationalization/localization-messages/settings/ViewGdprDataPage/sv-SE.json';
import settingsSwedbank from '@client/internationalization/localization-messages/settings-swedbank/SwedbankNotificationSettingsPage/sv-SE.json';
import sharedComponentsEmoji from '@client/internationalization/localization-messages/shared-components/EmojiRating/sv-SE.json';
import sharedComponentsUpdateBankApp from '@client/internationalization/localization-messages/shared-components/UpdateBankApp/sv-SE.json';
import sharedComponentsYouSavedComponent from '@client/internationalization/localization-messages/shared-components/YouSavedComponent/sv-SE.json';
import showOnboardingFeatures from '@client/internationalization/localization-messages/ShowOnboardingFeaturesComponent/sv-SE.json';
import signOutSuccessPage from '@client/internationalization/localization-messages/SignoutSuccessPage/sv-SE.json';
import standardComponentsCurrency from '@client/internationalization/localization-messages/standard-components/CurrencyTextField/sv-SE.json';
import standardComponentsDate from '@client/internationalization/localization-messages/standard-components/DatePicker/sv-SE.json';
import standardComponentsEmail from '@client/internationalization/localization-messages/standard-components/EmailField/sv-SE.json';
import standardComponentsGuide from '@client/internationalization/localization-messages/standard-components/GuidePage/sv-SE.json';
import standardComponentsMobile from '@client/internationalization/localization-messages/standard-components/MobilePhoneNumberField/sv-SE.json';
import standardComponentsPassword from '@client/internationalization/localization-messages/standard-components/PasswordField/sv-SE.json';
import standardComponentsPersonal from '@client/internationalization/localization-messages/standard-components/PersonalNumberField/sv-SE.json';
import standardComponentsPostalCode from '@client/internationalization/localization-messages/standard-components/PostalCodeField/sv-SE.json';
import standardComponentsSnackbar from '@client/internationalization/localization-messages/standard-components/PostalCodeField/sv-SE.json';
import subscriptionCancellationMeasure from '@client/internationalization/localization-messages/subscription/CancellationMeasurement/sv-SE.json';
import subscriptionComponentsSelectable from '@client/internationalization/localization-messages/subscription/components/SelectableServiceList/sv-SE.json';
import subscriptionRemoveSubscription from '@client/internationalization/localization-messages/subscription/RemoveSubscriptionDialog/sv-SE.json';
import subscriptionSelectLoanService from '@client/internationalization/localization-messages/subscription/SelectLoanServicesPage/sv-SE.json';
import subscriptionSelectLoanTransaction from '@client/internationalization/localization-messages/subscription/SelectLoanTransactionTypePage/sv-SE.json';
import subscriptionSelectServices from '@client/internationalization/localization-messages/subscription/SelectServicesPage/sv-SE.json';
import subscriptionPage from '@client/internationalization/localization-messages/subscription/SubscriptionPage/sv-SE.json';
import subscriptionSettingsPage from '@client/internationalization/localization-messages/subscription/SubscriptionSettingsPage/sv-SE.json';
import subscriptionUpdateCostPage from '@client/internationalization/localization-messages/subscription/SubscriptionUpdateCostPage/sv-SE.json';
import subscriptionSwitchMeasurement from '@client/internationalization/localization-messages/subscription/SwitchMeasurement/sv-SE.json';
import subscriptionUnresolvedGuide from '@client/internationalization/localization-messages/subscription/UnresolvedGuide/sv-SE.json';
import subscriptionWhatsWrong from '@client/internationalization/localization-messages/subscription/WhatsWrongDialog/sv-SE.json';
import subscriptionAlarmMore from '@client/internationalization/localization-messages/subscription-alarm/MorePage/sv-SE.json';
import subscriptionAlarmPage from '@client/internationalization/localization-messages/subscription-alarm/SubscriptionAlarmPage/sv-SE.json';
import subscriptionHubActions from '@client/internationalization/localization-messages/subscription-hub/PastActions/sv-SE.json';
import subscriptionHub from '@client/internationalization/localization-messages/subscriptionHub/subscriptionHub/sv-SE.json';
import supplierPortalChange from '@client/internationalization/localization-messages/supplier-portal-change/sv-SE.json';
import supplierPortalSaveDesk from '@client/internationalization/localization-messages/supplier-portal-save-desk/sv-SE.json';
import swedbankOverviewSync from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/sv-SE.json';
import swedenPhoneCallsLimited from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/sv-SE.json';
import syncSourcesAddSync from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcePage/sv-SE.json';
import syncSourcesAddSyncSpeed from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcesSpeedDial/sv-SE.json';
import syncSourcesDeleteSync from '@client/internationalization/localization-messages/sync-sources/DeleteSyncSourcesPage/sv-SE.json';
import syncSourcesError from '@client/internationalization/localization-messages/sync-sources/Error/sv-SE.json';
import syncSourcesNoSync from '@client/internationalization/localization-messages/sync-sources/NoSyncSources/sv-SE.json';
import syncSourcesPopularSync from '@client/internationalization/localization-messages/sync-sources/PopularSyncSourcesList/sv-SE.json';
import syncSourcesFetching from '@client/internationalization/localization-messages/sync-sources/SyncSourcesFetching/sv-SE.json';
import syncSourcesList from '@client/internationalization/localization-messages/sync-sources/SyncSourcesList/sv-SE.json';
import syncSourcesReminder from '@client/internationalization/localization-messages/sync-sources/SyncSourcesReminder/sv-SE.json';
import syncSourcesTinkSync from '@client/internationalization/localization-messages/sync-sources/TinkSyncPage/sv-SE.json';
import syncSourcesUpdateBank from '@client/internationalization/localization-messages/sync-sources/UpdateBankAuthsPage/sv-SE.json';
import syncSourcesUpdateSync from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSources/sv-SE.json';
import syncSourcesUpdateSyncButton from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSourcesButton/sv-SE.json';
import terminationFormPage from '@client/internationalization/localization-messages/termination/TerminationFormPage/sv-SE.json';
import terminationFormPCancelReason from '@client/internationalization/localization-messages/termination/TerminationFormPage/CancelReasonSection/sv-SE.json';
import terminationInstructions from '@client/internationalization/localization-messages/termination/TerminationInstructionsPage/sv-SE.json';
import termsAndCondition from '@client/internationalization/localization-messages/TermsAndConditionsUpload/sv-SE.json';
import typeFormButton from '@client/internationalization/localization-messages/TypeformButton/sv-SE.json';
import userFormPreview from '@client/internationalization/localization-messages/user-form-preview/sv-SE.json';
import userFeedback from '@client/internationalization/localization-messages/userFeedback/sv-SE.json';
import userDeleteSettings from '@client/internationalization/localization-messages/userSettings/UserDeletePage/sv-SE.json';
import userDeleteVerifiedPage from '@client/internationalization/localization-messages/userSettings/UserDeleteVerifiedPage/sv-SE.json';
import zendesk from '@client/internationalization/localization-messages/zendesk/sv-SE.json';
import swedbankaboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/Swedbank/sv-SE.json';
import swedbankbankAboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/BankAboutUsPage/Swedbank/sv-SE.json';
import swedbankinsightPage from '@client/internationalization/localization-messages/overview/InsightsPage/Swedbank/sv-SE.json';
import swedbankinsightPage_insightsCards_addon from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/AddonRecommendation/Swedbank/sv-SE.json';
import swedbankinsightPage_bindingTimeEnding from '@client/internationalization/localization-messages/overview/InsightsPage/BindingTimeEnding/Swedbank/sv-SE.json';
import swedbankinsightPage_insightsCards_discoverOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOngoing/Swedbank/sv-SE.json';
import swedbankinsightPage_insightsCards_discoverOutcomeInsight from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOutcome/Swedbank/sv-SE.json';
import swedbankinsightPage_insightsCards_newSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewSubscriptionFound/Swedbank/sv-SE.json';
import swedbankinsightPage_insightsCards_personalRecommend from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/Swedbank/sv-SE.json';
import swedbankinsightPage_insightsCards_saveDeskOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOngoingCard/Swedbank/sv-SE.json';
import swedbankinsightPage_insightsCards_saveDeskOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOutcome/Swedbank/sv-SE.json';
import swedbankinsightPage_subscriptionCostChanged from '@client/internationalization/localization-messages/overview/InsightsPage/SubscriptionCostChanged/Swedbank/sv-SE.json';
import swedbankinsightPage_unresolvedSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnresolvedSubscription/Swedbank/sv-SE.json';
import swedbankoverviewPage from '@client/internationalization/localization-messages/overview/OverviewPage/Swedbank/sv-SE.json';
import swedbankoverviewAddManualSubscription from '@client/internationalization/localization-messages/overview/addManualSubscription/Swedbank/sv-SE.json';
import swedbankoverviewBindingTimeBar from '@client/internationalization/localization-messages/overview/BindingTimeBar/Swedbank/sv-SE.json';
import swedbankoverviewPage_didYouCancel from '@client/internationalization/localization-messages/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/Swedbank/sv-SE.json';
import swedbankbadgeCtas from '@client/internationalization/localization-messages/BadgeCtas/Swedbank/sv-SE.json';
import swedbankbindingTimeNotice from '@client/internationalization/localization-messages/overview/BindingTimeNotice/Swedbank/sv-SE.json';
import swedbankonboardingDrawer from '@client/internationalization/localization-messages/overview/OnboardingDrawer/Swedbank/sv-SE.json';
import swedbankoverviewPage_quickActionsV2 from '@client/internationalization/localization-messages/overview/QuickActionsV2/Swedbank/sv-SE.json';
import swedbankaddonMobileRecommendationsPage from '@client/internationalization/localization-messages/addon/mobile/RecommendationsPage/Swedbank/sv-SE.json';
import swedbankaddonMobileResultPage from '@client/internationalization/localization-messages/addon/mobile/ResultPage/Swedbank/sv-SE.json';
import swedbankauthAuthStatusPage from '@client/internationalization/localization-messages/auth/AuthStatusPage/Swedbank/sv-SE.json';
import swedbankauthBasicLoginPage from '@client/internationalization/localization-messages/auth/BasicLoginPage/Swedbank/sv-SE.json';
import swedbankauthCreateAccountPage from '@client/internationalization/localization-messages/auth/CreateAccountPage/Swedbank/sv-SE.json';
import swedbankauthOnboardingChooseBankPage from '@client/internationalization/localization-messages/auth/OnboardingChooseBankPage/Swedbank/sv-SE.json';
import swedbankauthOnboardingEmailPage from '@client/internationalization/localization-messages/auth/OnboardingEmailPage/Swedbank/sv-SE.json';
import swedbankauthPreLoginPage from '@client/internationalization/localization-messages/auth/PreLoginPage/Swedbank/sv-SE.json';
import swedbankauthResetPasswordPage from '@client/internationalization/localization-messages/auth/ResetPasswordPage/Swedbank/sv-SE.json';
import swedbankauthSyncSourcesError from '@client/internationalization/localization-messages/auth/SyncSourcesError/Swedbank/sv-SE.json';
import swedbankauthSyncSourcesFetching from '@client/internationalization/localization-messages/auth/SyncSourcesFetching/Swedbank/sv-SE.json';
import swedbankbadgeABTestCTAS from '@client/internationalization/localization-messages/BadgeABTestCtas/Swedbank/sv-SE.json';
import swedbankbankSyncCancelProduct from '@client/internationalization/localization-messages/bank-sync/CancelProductOnboardPages/Swedbank/sv-SE.json';
import swedbankbankSyncDrawerPages from '@client/internationalization/localization-messages/bank-sync/DrawerPages/Swedbank/sv-SE.json';
import swedbankbankSyncLoadingScreen from '@client/internationalization/localization-messages/bank-sync/LoadingScreen/Swedbank/sv-SE.json';
import swedbankbankSyncOnboardBankSync from '@client/internationalization/localization-messages/bank-sync/OnBoardBankSync/Swedbank/sv-SE.json';
import swedbankcampaignIdSelect from '@client/internationalization/localization-messages/CampaignIdSelect/Swedbank/sv-SE.json';
import swedbankcancelCancellationOutcome from '@client/internationalization/localization-messages/cancel/CancellationOutcome/Swedbank/sv-SE.json';
import swedbankcancellationDetailsPage from '@client/internationalization/localization-messages/cancellation/cancellationDetailsPage/Swedbank/sv-SE.json';
import swedbankcancellationFormCancellationInstruction from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationInstructionsSection/Swedbank/sv-SE.json';
import swedbankcancellationFormCancellationTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationTerms/Swedbank/sv-SE.json';
import swedbankcancellationFormConfirmSection from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/ConfirmSection/Swedbank/sv-SE.json';
import swedbankcancellationFormLightUserTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/LightUserTerms/Swedbank/sv-SE.json';
import swedbankcancellationModalsContainer from '@client/internationalization/localization-messages/cancellation/CancellationModalsContainer/Swedbank/sv-SE.json';
import swedbankcancellationQuestionsFlow from '@client/internationalization/localization-messages/cancellation/CancellationQuestionFlow/Swedbank/sv-SE.json';
import swedbankcancellationReasonsDialog from '@client/internationalization/localization-messages/cancellation/CancellationReasonsDialog/Swedbank/sv-SE.json';
import swedbankcancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/Swedbank/sv-SE.json';
import swedbankcancellationStepper from '@client/internationalization/localization-messages/cancellation/CancellationStepper/Swedbank/sv-SE.json';
import swedbankcancellationDoYouWantToSaveDialog from '@client/internationalization/localization-messages/cancellation/DoYouWantToSaveDialog/Swedbank/sv-SE.json';
import swedbankcancellationIntelligentGuidePage from '@client/internationalization/localization-messages/cancellation/IntelligentGuidePage/Swedbank/sv-SE.json';
import swedbankcancellationNetflixCancellationInstructionsPage from '@client/internationalization/localization-messages/cancellation/NetflixCancellationInstructionsPage/Swedbank/sv-SE.json';
import swedbankcancellationSigningNemIdErrorPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdErrorPage/Swedbank/sv-SE.json';
import swedbankcancellationSigningNemIdSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdSigningPage/Swedbank/sv-SE.json';
import swedbankcancellationNoSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningPage/Swedbank/sv-SE.json';
import swedbankcancellationNoSigningServicePage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningServicePage/Swedbank/sv-SE.json';
import swedbankcancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/Swedbank/sv-SE.json';
import swedbankcancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/Swedbank/sv-SE.json';
import swedbankcategories from '@client/internationalization/localization-messages/categories/Swedbank/sv-SE.json';
import swedbankcategoryNames from '@client/internationalization/localization-messages/CategoryNames/CategoryNames/Swedbank/sv-SE.json';
import swedbankchangeDataPlan from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplan/Swedbank/sv-SE.json';
import swedbankchangeDataPlanCompletedPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanCompletedPage/Swedbank/sv-SE.json';
import swedbankchangeDataPlanOrderFormPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanOrderFormPage/Swedbank/sv-SE.json';
import swedbankchangeDataPlanPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanPage/Swedbank/sv-SE.json';
import swedbankchangePaymentChangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-payment/ChangePlanServiceIntroductionDrawer/Swedbank/sv-SE.json';
import swedbankchangePaymentIntelligentGuidePage from '@client/internationalization/localization-messages/change-payment/IntelligentGuidePage/Swedbank/sv-SE.json';
import swedbankchangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-plan/ChangePlanServiceIntroductionDrawer/Swedbank/sv-SE.json';
import swedbankchangePlanIntelligentGuideExperimentPage from '@client/internationalization/localization-messages/change-plan/IntelligentGuideExperimentPage/Swedbank/sv-SE.json';
import swedbankchangePlanIntelligentGuidePage from '@client/internationalization/localization-messages/change-plan/IntelligentGuidePage/Swedbank/sv-SE.json';
import swedbankchangePlan from '@client/internationalization/localization-messages/ChangePlan/Swedbank/sv-SE.json';
import swedbankcharityDonation from '@client/internationalization/localization-messages/charity-donation/Swedbank/sv-SE.json';
import swedbankcomplexContractGuideIntroPage from '@client/internationalization/localization-messages/complex-contract-guide/IntroPage/Swedbank/sv-SE.json';
import swedbankcomplexContractGuideLoanServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanServiceQuestionPage/Swedbank/sv-SE.json';
import swedbankcomplexContractGuideLoanTypeQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanTypeQuestionPage/Swedbank/sv-SE.json';
import swedbankcomplexContractGuideServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/ServiceQuestionPage/Swedbank/sv-SE.json';
import swedbankcomplexContractGuideShared from '@client/internationalization/localization-messages/complex-contract-guide/shared/Swedbank/sv-SE.json';
import swedbankcomplexContractGuideSuccessPage from '@client/internationalization/localization-messages/complex-contract-guide/SuccessPage/Swedbank/sv-SE.json';
import swedbankconditionsAndPoliciesMenu from '@client/internationalization/localization-messages/conditions-and-policies/ConditionsAndPoliciesMenu/Swedbank/sv-SE.json';
import swedbankconditionsAndPoliciesCookiesPage from '@client/internationalization/localization-messages/conditions-and-policies/CookiesPage/Swedbank/sv-SE.json';
import swedbankconditionsAndPoliciesSecurityPage from '@client/internationalization/localization-messages/conditions-and-policies/SecurityPage/Swedbank/sv-SE.json';
import swedbankconditionsAndPolicies from '@client/internationalization/localization-messages/conditions-and-policies/Swedbank/sv-SE.json';
import swedbankcontractBindingPeriodDialog from '@client/internationalization/localization-messages/contract/bindingPeriodDialog/Swedbank/sv-SE.json';
import swedbankcontractCancellationHistory from '@client/internationalization/localization-messages/contract/CancellationHistory/Swedbank/sv-SE.json';
import swedbankcontractCancelledContract from '@client/internationalization/localization-messages/contract/CancelledContract/Swedbank/sv-SE.json';
import swedbankcontractCancellationOutcome from '@client/internationalization/localization-messages/contract/ContractCancellationOutcome/Swedbank/sv-SE.json';
import swedbankcontractCardMessage from '@client/internationalization/localization-messages/contract/ContractCardMessage/Swedbank/sv-SE.json';
import swedbankcontractDetails from '@client/internationalization/localization-messages/contract/ContractDetails/Swedbank/sv-SE.json';
import swedbankcontractDetailsPage from '@client/internationalization/localization-messages/contract/ContractDetailsPage/Swedbank/sv-SE.json';
import swedbankcontractDoMoreComponent from '@client/internationalization/localization-messages/contract/DoMoreComponent/Swedbank/sv-SE.json';
import swedbankcontractHistoryToolTip from '@client/internationalization/localization-messages/contract/HistoryTooltip/Swedbank/sv-SE.json';
import swedbankcontractPriceHistory from '@client/internationalization/localization-messages/contract/PriceHistoryLolipop/Swedbank/sv-SE.json';
import swedbankcontractSelectServicePage from '@client/internationalization/localization-messages/contract/SelectServicePageLolipop/Swedbank/sv-SE.json';
import swedbankcontractSelectServicesList from '@client/internationalization/localization-messages/contract/SelectServicesListLolipop/Swedbank/sv-SE.json';
import swedbankcontractSettingsButton from '@client/internationalization/localization-messages/contract/SettingsButtonLolipop/Swedbank/sv-SE.json';
import swedbankcontractSettingsPage from '@client/internationalization/localization-messages/contract/SettingsPageLolipop/Swedbank/sv-SE.json';
import swedbankcontractSubscriptionAlarm from '@client/internationalization/localization-messages/contract/SubscriptionAlarmLolipop/Swedbank/sv-SE.json';
import swedbankcontractSubscriptionDetails from '@client/internationalization/localization-messages/contract/SubscriptionDetailsLolipop/Swedbank/sv-SE.json';
import swedbankcontractSubscriptionReminder from '@client/internationalization/localization-messages/contract/SubscriptionReminder/Swedbank/sv-SE.json';
import swedbankcontractSubscriptionReminderDialog from '@client/internationalization/localization-messages/contract/subscriptionReminderDialog/Swedbank/sv-SE.json';
import swedbankcontractTransactionHistory from '@client/internationalization/localization-messages/contract/TransactionHistoryLolipop/Swedbank/sv-SE.json';
import swedbankcontractUpdateCost from '@client/internationalization/localization-messages/contract/UpdateCostLolipop/Swedbank/sv-SE.json';
import swedbankdiscoverCategoryPage from '@client/internationalization/localization-messages/discover/CategoryPage/Swedbank/sv-SE.json';
import swedbankdiscoverCategoryProviderPage from '@client/internationalization/localization-messages/discover/CategoryProviderPage/Swedbank/sv-SE.json';
import swedbankdiscoverComponentsProductInfo from '@client/internationalization/localization-messages/discover/components/ProductInformationHeader/Swedbank/sv-SE.json';
import swedbankdiscoverDiscoverWidget from '@client/internationalization/localization-messages/discover/DiscoverWidget/Swedbank/sv-SE.json';
import swedbankdiscoverFakeCategoryPage from '@client/internationalization/localization-messages/discover/FakeCategoryPage/Swedbank/sv-SE.json';
import swedbankdiscoverFakeCategorySubscriptionPage from '@client/internationalization/localization-messages/discover/FakeCategorySubscriptionsPage/Swedbank/sv-SE.json';
import swedbankdiscoverFitnessCategoryFeedbackPage from '@client/internationalization/localization-messages/discover/FitnessCategoryFeedbackPage/Swedbank/sv-SE.json';
import swedbankdiscoverMarketplacePage from '@client/internationalization/localization-messages/discover/MarketplacePage/Swedbank/sv-SE.json';
import swedbankdiscoverMobileOrderSentPage from '@client/internationalization/localization-messages/discover/MobileOrderSentPage/Swedbank/sv-SE.json';
import swedbankdiscoverOrderProductPage from '@client/internationalization/localization-messages/discover/OrderProductPage/Swedbank/sv-SE.json';
import swedbankdiscoverProductRecommendations from '@client/internationalization/localization-messages/discover/ProductRecommendations/Swedbank/sv-SE.json';
import swedbankdiscoverSearchSubscriptionAllCategories from '@client/internationalization/localization-messages/discover/SearchSubscriptionAllCategoriesPage/Swedbank/sv-SE.json';
import swedbankdiscoverSearchSubscriptionPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionPage/Swedbank/sv-SE.json';
import swedbankdiscoverSearchSubscriptionResultPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionResultPage/Swedbank/sv-SE.json';
import swedbankdummy from '@client/internationalization/localization-messages/DUMMY/Swedbank/sv-SE.json';
import swedbankeSigning from '@client/internationalization/localization-messages/e-signing/nem-id/NemIdSigningPage/Swedbank/sv-SE.json';
import swedbankeditProduct from '@client/internationalization/localization-messages/EditProduct/Swedbank/sv-SE.json';
import swedbankenergyGuide from '@client/internationalization/localization-messages/EnergyGuide/OrderForm/Swedbank/sv-SE.json';
import swedbankerrorHandling from '@client/internationalization/localization-messages/error-handling/LoggedOutPage/Swedbank/sv-SE.json';
import swedbankerrorPageErrorPage from '@client/internationalization/localization-messages/errorPage/ErrorPage/Swedbank/sv-SE.json';
import swedbankerrorScreen from '@client/internationalization/localization-messages/ErrorScreen/Swedbank/sv-SE.json';
import swedbankexplore from '@client/internationalization/localization-messages/Explore/Swedbank/sv-SE.json';
import swedbankfeedbackModal from '@client/internationalization/localization-messages/feedback-modal/Swedbank/sv-SE.json';
import swedbankfields from '@client/internationalization/localization-messages/fields/Swedbank/sv-SE.json';
import swedbankfinancialProductsLoansShow from '@client/internationalization/localization-messages/financial-products/loans/ShowNewFeatureLoanDialog/Swedbank/sv-SE.json';
import swedbankfinancialProductsSavingsInvestment from '@client/internationalization/localization-messages/financial-products/savings/InvestmentInfoCard/Swedbank/sv-SE.json';
import swedbankfinancialProductOurPartnersPage from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPage/Swedbank/sv-SE.json';
import swedbankfinancialProductOurPartnersPageNew from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPageNew/Swedbank/sv-SE.json';
import swedbankfinancialProductsSavingsOverview from '@client/internationalization/localization-messages/financial-products/savings/Overview/Swedbank/sv-SE.json';
import swedbankfinancialProductSavingsAfterCancellation from '@client/internationalization/localization-messages/financial-products/savings/SavingsAfterCancellation/Swedbank/sv-SE.json';
import swedbankfinancialProductsShowNewFeatureLoan from '@client/internationalization/localization-messages/financial-products/ShowNewFeatureLoanDialog/Swedbank/sv-SE.json';
import swedbankfinancialProductsSwedBankConsolidate from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/HowItWorksPage/Swedbank/sv-SE.json';
import swedbankfinancialSwedBankConsolidateIntro from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/IntroPage/Swedbank/sv-SE.json';
import swedbankfinancialProductLoanApplication from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/LoanApplicationStatus/Swedbank/sv-SE.json';
import swedbankfinancialProductsOfferPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OfferPage/Swedbank/sv-SE.json';
import swedbankfinancialProductOrderCompleted from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderCompletedPage/Swedbank/sv-SE.json';
import swedbankfinancialProductOrderPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderPage/Swedbank/sv-SE.json';
import swedbankfinancialProductSelectContract from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectContractsPage/Swedbank/sv-SE.json';
import swedbankfinancialProductsSelectPrincipal from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectPrincipalPage/Swedbank/sv-SE.json';
import swedbankfinancialProductRepaymentPeriod from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/Swedbank/sv-SE.json';
import swedbankgeneralIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/Swedbank/sv-SE.json';
import swedbankgeneralSelectServices from '@client/internationalization/localization-messages/general/SelectServicesPage/Swedbank/sv-SE.json';
import swedbankgenericActionsPage from '@client/internationalization/localization-messages/genericActions/GenericActionsPage/Swedbank/sv-SE.json';
import swedbankgenericActionsSelectServices from '@client/internationalization/localization-messages/genericActions/SelectServicesPage/Swedbank/sv-SE.json';
import swedbankgenericActionsSubscriptionCancel from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/Cancel/Swedbank/sv-SE.json';
import swedbankgenericActionsSubscriptionsChangePayment from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/ChangePayment/Swedbank/sv-SE.json';
import swedbankguidesContractDetailsAlternative from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/AlternativesSection/Swedbank/sv-SE.json';
import swedbankguidesDealBanner from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/DealBanner/Swedbank/sv-SE.json';
import swedbankguidesPaymentMethod from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/PaymentMethodsCard/Swedbank/sv-SE.json';
import swedbankhack7CancelPage from '@client/internationalization/localization-messages/Hack7CancelPage/Swedbank/sv-SE.json';
import swedbankheader from '@client/internationalization/localization-messages/Header/Swedbank/sv-SE.json';
import swedbankhowToCancelListPage from '@client/internationalization/localization-messages/HowToCancelListPage/Swedbank/sv-SE.json';
import swedbankhowToCancelPage from '@client/internationalization/localization-messages/HowToCancelPage/Swedbank/sv-SE.json';
import swedbankimproveOrderPage from '@client/internationalization/localization-messages/improve/flowgen/OrderPage/Swedbank/sv-SE.json';
import swedbankimproveOrderSentPage from '@client/internationalization/localization-messages/improve/flowgen/OrderSentPage/Swedbank/sv-SE.json';
import swedbankimproveOtherAlternativePage from '@client/internationalization/localization-messages/improve/flowgen/OtherAlternativesPage/Swedbank/sv-SE.json';
import swedbankimprovePostalCodeInput from '@client/internationalization/localization-messages/improve/flowgen/postalCodeInput/Swedbank/sv-SE.json';
import swedbankimproveResultPage from '@client/internationalization/localization-messages/improve/flowgen/ResultPage/Swedbank/sv-SE.json';
import swedbankimproveFlowgen from '@client/internationalization/localization-messages/improve/flowgen/Swedbank/sv-SE.json';
import swedbankinsightPageInsightsCardsCancelOutcome from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationOutcome/Swedbank/sv-SE.json';
import swedbankinsightPageInsightsCardsCancelUpdate from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationUpdate/Swedbank/sv-SE.json';
import swedbankinsightPageOnboardingInsightCard from '@client/internationalization/localization-messages/InsightsPage/OnboardingInsightCard/Swedbank/sv-SE.json';
import swedbankintervalToString from '@client/internationalization/localization-messages/interval-to-string/Swedbank/sv-SE.json';
import swedbanklandingPage from '@client/internationalization/localization-messages/LandingPage/Swedbank/sv-SE.json';
import swedbankloadingScreen from '@client/internationalization/localization-messages/LoadingScreen/Swedbank/sv-SE.json';
import swedbankloanApplicationsDetailsPage from '@client/internationalization/localization-messages/loan-applications/DetailsPage/Swedbank/sv-SE.json';
import swedbankloanApplicationsLoadingError from '@client/internationalization/localization-messages/loan-applications/LoadingError/Swedbank/sv-SE.json';
import swedbankloanApplicationsMenuDrawer from '@client/internationalization/localization-messages/loan-applications/MenuDrawer/Swedbank/sv-SE.json';
import swedbankloanApplicationsOverview from '@client/internationalization/localization-messages/loan-applications/Overview/Swedbank/sv-SE.json';
import swedbankloginPage from '@client/internationalization/localization-messages/LoginPage/Swedbank/sv-SE.json';
import swedbankmanageOrder from '@client/internationalization/localization-messages/ManageOrder/Swedbank/sv-SE.json';
import swedbankmanualAddContractPage from '@client/internationalization/localization-messages/ManualAddContractPage/Swedbank/sv-SE.json';
import swedbankmanualAddContractPricingPage from '@client/internationalization/localization-messages/ManualContractPricingPage/Swedbank/sv-SE.json';
import swedbankmenu from '@client/internationalization/localization-messages/Menu/Swedbank/sv-SE.json';
import swedbankmenuPage from '@client/internationalization/localization-messages/MenuPage/Swedbank/sv-SE.json';
import swedbankmovingBundledCancellationForm from '@client/internationalization/localization-messages/moving/BundledCancellationFromPage/Swedbank/sv-SE.json';
import swedbankmovingBundledCancellationPage from '@client/internationalization/localization-messages/moving/BundledCancellationPage/Swedbank/sv-SE.json';
import swedbankmovingBundledSwitchOrderForm from '@client/internationalization/localization-messages/moving/BundledSwitchOrderFormPage/Swedbank/sv-SE.json';
import swedbankmovingBundledSwitchPage from '@client/internationalization/localization-messages/moving/BundledSwitchSwitchPage/Swedbank/sv-SE.json';
import swedbankthanksSwitchPage from '@client/internationalization/localization-messages/moving/ThanksSwitchPage/Swedbank/sv-SE.json';
import swedbankmovingGuide from '@client/internationalization/localization-messages/movingGuide/Swedbank/sv-SE.json';
import swedbankmovingGuideMovingDetails from '@client/internationalization/localization-messages/movingGuide/MovingDetailsPage/Swedbank/sv-SE.json';
import swedbankmovingGuideOneLastThingPage from '@client/internationalization/localization-messages/movingGuide/OneLastThingPage/Swedbank/sv-SE.json';
import swedbanknegotiateContractCompletePage from '@client/internationalization/localization-messages/negotiate/NegotiateContractCompletePage/Swedbank/sv-SE.json';
import swedbanknegotiateGuide from '@client/internationalization/localization-messages/negotiate/NegotiateGuide/Swedbank/sv-SE.json';
import swedbanknegotiationOngoingCard from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationCard/Swedbank/sv-SE.json';
import swedbanknegotiationOngoingStepper from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationStepper/Swedbank/sv-SE.json';
import swedbanknotificationOptInPage from '@client/internationalization/localization-messages/NotificationOptInPage/Swedbank/sv-SE.json';
import swedbankonboardingPSD2Consent from '@client/internationalization/localization-messages/onboarding/PSD2ConsentPage/Swedbank/sv-SE.json';
import swedbankonboardingPSD2Primer from '@client/internationalization/localization-messages/onboarding/PSD2PrimerPage/Swedbank/sv-SE.json';
import swedbankonboardingBDSyncConnecting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryConnecting/Swedbank/sv-SE.json';
import swedbankonboardingBDSyncError from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryErrorPage/Swedbank/sv-SE.json';
import swedbankonboardingBDSyncFetch from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryFetchPage/Swedbank/sv-SE.json';
import swedbankonboardingBDSyncSuccess from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/Swedbank/sv-SE.json';
import swedbankonboardingBDSyncWaiting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/Swedbank/sv-SE.json';
import swedbankonboardingCancelProduct from '@client/internationalization/localization-messages/onboarding-cancel-product/OnboardingCancelProductPages/Swedbank/sv-SE.json';
import swedbankonboardingPsd2WelcomePage from '@client/internationalization/localization-messages/onboarding-psd2/WelcomePage/Swedbank/sv-SE.json';
import swedbankonboardingPsd2Cards from '@client/internationalization/localization-messages/onboarding-psd2/Cards/Swedbank/sv-SE.json';
import swedbankonboardingPsd2ConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/Swedbank/sv-SE.json';
import swedbankonboardingPsd2OpBankConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPage/Swedbank/sv-SE.json';
import swedbankonboardingPsd2OpBankConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPageV2/Swedbank/sv-SE.json';
import swedbankonboardingPsd2ConsentPageDanskeBank from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/DanskeBankConsentPage/Swedbank/sv-SE.json';
import swedbankonboardingPsd2RenewConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPage/Swedbank/sv-SE.json';
import swedbankonboardingPsd2RenewConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPageV2/Swedbank/sv-SE.json';
import swedbankorderedSubscriptionOngoing from '@client/internationalization/localization-messages/orderedSubscription/OngoingOrderCard/Swedbank/sv-SE.json';
import swedbankorderedSubscriptionOrderedEnergy from '@client/internationalization/localization-messages/orderedSubscription/OrderedEnergyProductInfo/Swedbank/sv-SE.json';
import swedbankorderedSubscriptionOrderedMobile from '@client/internationalization/localization-messages/orderedSubscription/OrderedMobileProductInfo/Swedbank/sv-SE.json';
import swedbankorderedSubscriptionDetails from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetails/Swedbank/sv-SE.json';
import swedbankorderedSubscriptionDetailsPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/Swedbank/sv-SE.json';
import swedbankorderedSubscriptionInfoCard from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/Swedbank/sv-SE.json';
import swedbankorderedSubscriptionPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionPage/Swedbank/sv-SE.json';
import swedbankorderedSubscriptionOrderStepper from '@client/internationalization/localization-messages/orderedSubscription/OrderStepper/Swedbank/sv-SE.json';
import swedbankorderedSubscServiceProviderInfo from '@client/internationalization/localization-messages/orderedSubscription/ServiceProviderInfoCard/Swedbank/sv-SE.json';
import swedbankorderingSubscriptionTerms from '@client/internationalization/localization-messages/ordering-subscription/common/TermsAndConditionsPage/Swedbank/sv-SE.json';
import swedbankorderingSubscriptionCompleted from '@client/internationalization/localization-messages/ordering-subscription/common/OrderCompletedPage/Swedbank/sv-SE.json';
import swedbankorderingBroadbandAddressPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAddressPage/Swedbank/sv-SE.json';
import swedbankorderingBroadbandAllAlternatives from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/Swedbank/sv-SE.json';
import swedbankorderingBroadbandContract from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideContractCostPage/Swedbank/sv-SE.json';
import swedbankorderingBroadbandIntroPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideIntroPage/Swedbank/sv-SE.json';
import swedbankorderingBroadbandNoResultPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideNoResultPage/Swedbank/sv-SE.json';
import swedbankorderingBroadbandSpeedPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideSpeedPage/Swedbank/sv-SE.json';
import swedbankorderingBroadbandOptimizeOrder from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandOrderFormPage/Swedbank/sv-SE.json';
import swedbankorderingBroadbandOptimizeProduct from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/Swedbank/sv-SE.json';
import swedbankorderingBroadbandOptimizeProductCosts from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/Swedbank/sv-SE.json';
import swedbankorderingElectricityOrder from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityOrderVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuidePrice from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideAlternatives from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideBinding from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideBindingTime from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideEnergy from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideExplanation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideFlow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideHow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideInformation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideIntro from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideLivingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuidePostalCodePage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuidePreferences from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuidePricingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideProductPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideSummaryPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideTrading from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideUsage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideUsageResult from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuideUsageResultVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/Swedbank/sv-SE.json';
import swedbankorderingElectricityGuidePostalCode from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/Swedbank/sv-SE.json';
import swedbankorderingSubscriptionIndependentOrder from '@client/internationalization/localization-messages/ordering-subscription/independent/OrderCompletedPage/Swedbank/sv-SE.json';
import swedbankordersOverview from '@client/internationalization/localization-messages/OrdersOverview/Swedbank/sv-SE.json';
import swedbankoverviewChangePlanService from '@client/internationalization/localization-messages/overview/ChangePlanServiceIntroductionDrawer/Swedbank/sv-SE.json';
import swedbankoverviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/Swedbank/sv-SE.json';
import swedbankoverviewDeprecatedInsights from '@client/internationalization/localization-messages/overview/DeprecatedInsightsPage/OnboardingInsightCard/Swedbank/sv-SE.json';
import swedbankoverviewDiscoverPage from '@client/internationalization/localization-messages/overview/DiscoverPage/Swedbank/sv-SE.json';
import swedbankoverviewGenericAction from '@client/internationalization/localization-messages/overview/GenericActions/Swedbank/sv-SE.json';
import swedbankoverviewImprovementPage from '@client/internationalization/localization-messages/overview/ImprovementPage/Swedbank/sv-SE.json';
import swedbankoverviewImproveV2 from '@client/internationalization/localization-messages/overview/ImproveV2/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsActionRating from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ActionRating/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsCancellationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/CancellationUpdate/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsChangeData from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ChangeDataplanUpdate/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsIndependent from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/IndependentOrderUpdate/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardConfirmCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/Swedbank/sv-SE.json';
import swedbankoverviewContactUsButton from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ContactUsButton/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsMonthlyCheck from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/MonthlyChecklist/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsNegotiationOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationOutcome/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsNegotiationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationUpdate/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsProvider from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewProviderOffersCard/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsSaveDeskFeedback from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/Swedbank/sv-SE.json';
import swedbankoverviewInsightCardsSaveDeskRequested from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOrderRequested/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsSaveDeskPredefined from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsSaveDeskResponse from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskResponseCard/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsSwitchOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchOutcome/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsSwitchUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchUpdate/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsThankYou from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ThankYouCard/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsTryCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/TryCancellationAgainButton/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsUnreviewed from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnreviewedAction/Swedbank/sv-SE.json';
import swedbankoverviewInsightsCardsOnboarding from '@client/internationalization/localization-messages/overview/InsightsPage/OnboardingInsightCard/Swedbank/sv-SE.json';
import swedbankoverviewInsightsPrimerCardV2 from '@client/internationalization/localization-messages/overview/InsightsPage/PrimerCardV2/Swedbank/sv-SE.json';
import swedbankoverviewLoadingWithCogs from '@client/internationalization/localization-messages/overview/LoadingWithCogs/Swedbank/sv-SE.json';
import swedbankoverviewManageContract from '@client/internationalization/localization-messages/overview/ManageContractSection/SwitchProgressWithoutCancelStepper/Swedbank/sv-SE.json';
import swedbankoverviewMonthlyChecklist from '@client/internationalization/localization-messages/overview/MonthlyChecklist/Swedbank/sv-SE.json';
import swedbankoverviewMoreInfoNeeded from '@client/internationalization/localization-messages/overview/more-information-needed/Swedbank/sv-SE.json';
import swedbankoverviewOnboardingCards from '@client/internationalization/localization-messages/overview/OnboardingCards/Swedbank/sv-SE.json';
import swedbankoverviewQuickActionsCancel from '@client/internationalization/localization-messages/overview/QuickActionsCancel/Swedbank/sv-SE.json';
import swedbankoverviewQuickActionsCancelV2 from '@client/internationalization/localization-messages/overview/QuickActionsCancelV2/Swedbank/sv-SE.json';
import swedbankoverviewQuickActionsImprove from '@client/internationalization/localization-messages/overview/QuickActionsImprove/Swedbank/sv-SE.json';
import swedbankoverviewQuickActionsImproveV2 from '@client/internationalization/localization-messages/overview/QuickActionsImproveV2/Swedbank/sv-SE.json';
import swedbankoverviewSubscriptionFeedback from '@client/internationalization/localization-messages/overview/SubscriptionFeedback/Swedbank/sv-SE.json';
import swedbankoverviewSubscriptionSearch from '@client/internationalization/localization-messages/overview/SubscriptionSearch/Swedbank/sv-SE.json';
import swedbankoverviewSubscriptionSearchComponent from '@client/internationalization/localization-messages/overview/SubscriptionSearchOverviewComponent/Swedbank/sv-SE.json';
import swedbankwaysToSave from '@client/internationalization/localization-messages/overview/WaysToSave/Swedbank/sv-SE.json';
import swedbankoverview from '@client/internationalization/localization-messages/overview/Swedbank/sv-SE.json';
import swedbankoverviewBottomNavigation from '@client/internationalization/localization-messages/overviewBottomNavigation/Swedbank/sv-SE.json';
import swedbankpauseIntelligentGuide from '@client/internationalization/localization-messages/pause/IntelligentGuidePage/Swedbank/sv-SE.json';
import swedbankpauseSubscriptionPause from '@client/internationalization/localization-messages/pause/SubscriptionPausePage/Swedbank/sv-SE.json';
import swedbankprovideFeedbackPage from '@client/internationalization/localization-messages/ProvideFeedbackPage/Swedbank/sv-SE.json';
import swedbankproviderPortalDatepicker from '@client/internationalization/localization-messages/provider-portal-datepicker/DatePicker/Swedbank/sv-SE.json';
import swedbankproviderPortalSnackback from '@client/internationalization/localization-messages/provider-portal-snackback/Snackbar/Swedbank/sv-SE.json';
import swedbankregistrationPage from '@client/internationalization/localization-messages/RegistrationPage/Swedbank/sv-SE.json';
import swedbankretentionOfferCard from '@client/internationalization/localization-messages/retention-offer/SuccessfulRetentionOfferCard/Swedbank/sv-SE.json';
import swedbanksaveDesk from '@client/internationalization/localization-messages/save-desk/Swedbank/sv-SE.json';
import swedbanksaveDeskOngoingCard from '@client/internationalization/localization-messages/save-desk/OngoingSaveDeskCard/Swedbank/sv-SE.json';
import swedbanksaveDeskOrderSent from '@client/internationalization/localization-messages/save-desk/OrderSentPage/Swedbank/sv-SE.json';
import swedbanksavingsDoYouWantToSave from '@client/internationalization/localization-messages/savings/DoYouWantToSaveDialog/Swedbank/sv-SE.json';
import swedbanksavingsAfterCancellation from '@client/internationalization/localization-messages/savings/SavingsAfterCancellation/Swedbank/sv-SE.json';
import swedbankselectProductPage from '@client/internationalization/localization-messages/SelectProductPage/Swedbank/sv-SE.json';
import swedbanksettingsNotificationSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/Swedbank/sv-SE.json';
import swedbanksettingsNotificationPushSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/PushNotificationInstructions/Swedbank/sv-SE.json';
import swedbanksettingsPersonalDetails from '@client/internationalization/localization-messages/settings/PersonalDetailsPage/Swedbank/sv-SE.json';
import swedbanksettingsProfile from '@client/internationalization/localization-messages/settings/ProfilingSettingsPage/Swedbank/sv-SE.json';
import swedbanksettingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/Swedbank/sv-SE.json';
import swedbanksettingsUser from '@client/internationalization/localization-messages/settings/UserSettingsPage/Swedbank/sv-SE.json';
import swedbanksettingsViewGdprData from '@client/internationalization/localization-messages/settings/ViewGdprDataPage/Swedbank/sv-SE.json';
import swedbanksettingsSwedbank from '@client/internationalization/localization-messages/settings-swedbank/SwedbankNotificationSettingsPage/Swedbank/sv-SE.json';
import swedbanksharedComponentsEmoji from '@client/internationalization/localization-messages/shared-components/EmojiRating/Swedbank/sv-SE.json';
import swedbanksharedComponentsUpdateBankApp from '@client/internationalization/localization-messages/shared-components/UpdateBankApp/Swedbank/sv-SE.json';
import swedbanksharedComponentsYouSavedComponent from '@client/internationalization/localization-messages/shared-components/YouSavedComponent/Swedbank/sv-SE.json';
import swedbankshowOnboardingFeatures from '@client/internationalization/localization-messages/ShowOnboardingFeaturesComponent/Swedbank/sv-SE.json';
import swedbanksignOutSuccessPage from '@client/internationalization/localization-messages/SignoutSuccessPage/Swedbank/sv-SE.json';
import swedbankstandardComponentsCurrency from '@client/internationalization/localization-messages/standard-components/CurrencyTextField/Swedbank/sv-SE.json';
import swedbankstandardComponentsDate from '@client/internationalization/localization-messages/standard-components/DatePicker/Swedbank/sv-SE.json';
import swedbankstandardComponentsEmail from '@client/internationalization/localization-messages/standard-components/EmailField/Swedbank/sv-SE.json';
import swedbankstandardComponentsGuide from '@client/internationalization/localization-messages/standard-components/GuidePage/Swedbank/sv-SE.json';
import swedbankstandardComponentsMobile from '@client/internationalization/localization-messages/standard-components/MobilePhoneNumberField/Swedbank/sv-SE.json';
import swedbankstandardComponentsPassword from '@client/internationalization/localization-messages/standard-components/PasswordField/Swedbank/sv-SE.json';
import swedbankstandardComponentsPersonal from '@client/internationalization/localization-messages/standard-components/PersonalNumberField/Swedbank/sv-SE.json';
import swedbankstandardComponentsPostalCode from '@client/internationalization/localization-messages/standard-components/PostalCodeField/Swedbank/sv-SE.json';
import swedbankstandardComponentsSnackbar from '@client/internationalization/localization-messages/standard-components/PostalCodeField/Swedbank/sv-SE.json';
import swedbanksubscriptionCancellationMeasure from '@client/internationalization/localization-messages/subscription/CancellationMeasurement/Swedbank/sv-SE.json';
import swedbanksubscriptionComponentsSelectable from '@client/internationalization/localization-messages/subscription/components/SelectableServiceList/Swedbank/sv-SE.json';
import swedbanksubscriptionRemoveSubscription from '@client/internationalization/localization-messages/subscription/RemoveSubscriptionDialog/Swedbank/sv-SE.json';
import swedbanksubscriptionSelectLoanService from '@client/internationalization/localization-messages/subscription/SelectLoanServicesPage/Swedbank/sv-SE.json';
import swedbanksubscriptionSelectLoanTransaction from '@client/internationalization/localization-messages/subscription/SelectLoanTransactionTypePage/Swedbank/sv-SE.json';
import swedbanksubscriptionSelectServices from '@client/internationalization/localization-messages/subscription/SelectServicesPage/Swedbank/sv-SE.json';
import swedbanksubscriptionPage from '@client/internationalization/localization-messages/subscription/SubscriptionPage/Swedbank/sv-SE.json';
import swedbanksubscriptionSettingsPage from '@client/internationalization/localization-messages/subscription/SubscriptionSettingsPage/Swedbank/sv-SE.json';
import swedbanksubscriptionUpdateCostPage from '@client/internationalization/localization-messages/subscription/SubscriptionUpdateCostPage/Swedbank/sv-SE.json';
import swedbanksubscriptionSwitchMeasurement from '@client/internationalization/localization-messages/subscription/SwitchMeasurement/Swedbank/sv-SE.json';
import swedbanksubscriptionUnresolvedGuide from '@client/internationalization/localization-messages/subscription/UnresolvedGuide/Swedbank/sv-SE.json';
import swedbanksubscriptionWhatsWrong from '@client/internationalization/localization-messages/subscription/WhatsWrongDialog/Swedbank/sv-SE.json';
import swedbanksubscriptionAlarmMore from '@client/internationalization/localization-messages/subscription-alarm/MorePage/Swedbank/sv-SE.json';
import swedbanksubscriptionAlarmPage from '@client/internationalization/localization-messages/subscription-alarm/SubscriptionAlarmPage/Swedbank/sv-SE.json';
import swedbanksubscriptionHubActions from '@client/internationalization/localization-messages/subscription-hub/PastActions/Swedbank/sv-SE.json';
import swedbanksubscriptionHub from '@client/internationalization/localization-messages/subscriptionHub/subscriptionHub/Swedbank/sv-SE.json';
import swedbanksupplierPortalChange from '@client/internationalization/localization-messages/supplier-portal-change/Swedbank/sv-SE.json';
import swedbanksupplierPortalSaveDesk from '@client/internationalization/localization-messages/supplier-portal-save-desk/Swedbank/sv-SE.json';
import swedbankswedbankOverviewSync from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/Swedbank/sv-SE.json';
import swedbankswedenPhoneCallsLimited from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/Swedbank/sv-SE.json';
import swedbanksyncSourcesAddSync from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcePage/Swedbank/sv-SE.json';
import swedbanksyncSourcesAddSyncSpeed from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcesSpeedDial/Swedbank/sv-SE.json';
import swedbanksyncSourcesDeleteSync from '@client/internationalization/localization-messages/sync-sources/DeleteSyncSourcesPage/Swedbank/sv-SE.json';
import swedbanksyncSourcesError from '@client/internationalization/localization-messages/sync-sources/Error/Swedbank/sv-SE.json';
import swedbanksyncSourcesNoSync from '@client/internationalization/localization-messages/sync-sources/NoSyncSources/Swedbank/sv-SE.json';
import swedbanksyncSourcesPopularSync from '@client/internationalization/localization-messages/sync-sources/PopularSyncSourcesList/Swedbank/sv-SE.json';
import swedbanksyncSourcesFetching from '@client/internationalization/localization-messages/sync-sources/SyncSourcesFetching/Swedbank/sv-SE.json';
import swedbanksyncSourcesList from '@client/internationalization/localization-messages/sync-sources/SyncSourcesList/Swedbank/sv-SE.json';
import swedbanksyncSourcesReminder from '@client/internationalization/localization-messages/sync-sources/SyncSourcesReminder/Swedbank/sv-SE.json';
import swedbanksyncSourcesTinkSync from '@client/internationalization/localization-messages/sync-sources/TinkSyncPage/Swedbank/sv-SE.json';
import swedbanksyncSourcesUpdateBank from '@client/internationalization/localization-messages/sync-sources/UpdateBankAuthsPage/Swedbank/sv-SE.json';
import swedbanksyncSourcesUpdateSync from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSources/Swedbank/sv-SE.json';
import swedbanksyncSourcesUpdateSyncButton from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSourcesButton/Swedbank/sv-SE.json';
import swedbankterminationFormPage from '@client/internationalization/localization-messages/termination/TerminationFormPage/Swedbank/sv-SE.json';
import swedbankterminationFormPCancelReason from '@client/internationalization/localization-messages/termination/TerminationFormPage/CancelReasonSection/Swedbank/sv-SE.json';
import swedbankterminationInstructions from '@client/internationalization/localization-messages/termination/TerminationInstructionsPage/Swedbank/sv-SE.json';
import swedbanktermsAndCondition from '@client/internationalization/localization-messages/TermsAndConditionsUpload/Swedbank/sv-SE.json';
import swedbanktypeFormButton from '@client/internationalization/localization-messages/TypeformButton/Swedbank/sv-SE.json';
import swedbankuserFormPreview from '@client/internationalization/localization-messages/user-form-preview/Swedbank/sv-SE.json';
import swedbankuserFeedback from '@client/internationalization/localization-messages/userFeedback/Swedbank/sv-SE.json';
import swedbankuserDeleteSettings from '@client/internationalization/localization-messages/userSettings/UserDeletePage/Swedbank/sv-SE.json';
import swedbankuserDeleteVerifiedPage from '@client/internationalization/localization-messages/userSettings/UserDeleteVerifiedPage/Swedbank/sv-SE.json';
import swedbankzendesk from '@client/internationalization/localization-messages/zendesk/Swedbank/sv-SE.json';

export const svSETranslations = {
  aboutUsPage: aboutUsPage,
  'AboutUsPage/BankAboutUsPage': bankAboutUsPage,
  'addon/mobile/RecommendationsPage': addonMobileRecommendationsPage,
  'addon/mobile/ResultPage': addonMobileResultPage,
  'auth/AuthStatusPage': authAuthStatusPage,
  'auth/BasicLoginPage': authBasicLoginPage,
  'auth/CreateAccountPage': authCreateAccountPage,
  'auth/LoginPage': authCreateAccountPage,
  'auth/OnboardingChooseBankPage': authOnboardingChooseBankPage,
  'auth/OnboardingEmailPage': authOnboardingEmailPage,
  'auth/PreLoginPage': authPreLoginPage,
  'auth/ResetPasswordPage': authResetPasswordPage,
  'auth/SyncSourcesError': authSyncSourcesError,
  'auth/SyncSourcesFetching': authSyncSourcesFetching,
  BadgeABTestCtas: badgeABTestCTAS,
  BadgeCtas: badgeCtas,
  'bank-sync/CancelProductOnboardPages': bankSyncCancelProduct,
  'bank-sync/DrawerPages': bankSyncDrawerPages,
  'bank-sync/LoadingScreen': bankSyncLoadingScreen,
  'bank-sync/OnBoardBankSync': bankSyncOnboardBankSync,
  CampaignIdSelect: campaignIdSelect,
  'cancel/CancellationOutcome': cancelCancellationOutcome,
  'cancellation/cancellationDetailsPage': cancellationDetailsPage,
  'cancellation/CancellationFormPage/CancellationInstructionsSection': cancellationFormCancellationInstruction,
  'cancellation/CancellationFormPage/CancellationTerms': cancellationFormCancellationTerms,
  'cancellation/CancellationFormPage/ConfirmSection': cancellationFormConfirmSection,
  'cancellation/CancellationFormPage/LightUserTerms': cancellationFormLightUserTerms,
  'cancellation/CancellationModalsContainer': cancellationModalsContainer,
  'cancellation/CancellationQuestionFlow': cancellationQuestionsFlow,
  'cancellation/CancellationReasonsDialog': cancellationReasonsDialog,
  'cancellation/CancellationSentPage': cancellationSentPage,
  'cancellation/CancellationStepper': cancellationStepper,
  'cancellation/DoYouWantToSaveDialog': cancellationDoYouWantToSaveDialog,
  'cancellation/IntelligentGuidePage': cancellationIntelligentGuidePage,
  'cancellation/NetflixCancellationInstructionsPage': cancellationNetflixCancellationInstructionsPage,
  'cancellation/signing/NemIdErrorPage': cancellationSigningNemIdErrorPage,
  'cancellation/signing/NemIdSigningPage': cancellationSigningNemIdSigningPage,
  'cancellation/signing/NoSigningPage': cancellationNoSigningPage,
  'cancellation/signing/NoSigningServicePage': cancellationNoSigningServicePage,
  CancellationPortal: cancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement': cancellationsCancellationPortalAgreement,
  categories: categories,
  'CategoryNames/CategoryNames': categoryNames,
  'change-dataplan/ChangeDataplan': changeDataPlan,
  'change-dataplan/ChangeDataplanCompletedPage': changeDataPlanCompletedPage,
  'change-dataplan/ChangeDataplanOrderFormPage': changeDataPlanOrderFormPage,
  'change-dataplan/ChangeDataplanPage': changeDataPlanPage,
  'change-payment/ChangePlanServiceIntroductionDrawer': changePaymentChangePlanServiceIntroductionDrawer,
  'change-payment/IntelligentGuidePage': changePaymentIntelligentGuidePage,
  'change-plan/ChangePlanServiceIntroductionDrawer': changePlanServiceIntroductionDrawer,
  'change-plan/IntelligentGuideExperimentPage': changePlanIntelligentGuideExperimentPage,
  'change-plan/IntelligentGuidePage': changePlanIntelligentGuidePage,
  ChangePlan: changePlan,
  'charity-donation': charityDonation,
  'complex-contract-guide/IntroPage': complexContractGuideIntroPage,
  'complex-contract-guide/LoanServiceQuestionPage': complexContractGuideLoanServiceQuestionPage,
  'complex-contract-guide/LoanTypeQuestionPage': complexContractGuideLoanTypeQuestionPage,
  'complex-contract-guide/ServiceQuestionPage': complexContractGuideServiceQuestionPage,
  'complex-contract-guide/Shared': complexContractGuideShared,
  'complex-contract-guide/SuccessPage': complexContractGuideSuccessPage,
  'conditions-and-policies/ConditionsAndPoliciesMenu': conditionsAndPoliciesMenu,
  'conditions-and-policies/CookiesPage': conditionsAndPoliciesCookiesPage,
  'conditions-and-policies/SecurityPage': conditionsAndPoliciesSecurityPage,
  'conditions-and-policies': conditionsAndPolicies,
  'contract/bindingPeriodDialog': contractBindingPeriodDialog,
  'contract/CancellationHistory': contractCancellationHistory,
  'contract/CancelledContract': contractCancelledContract,
  'contract/ContractCancellationOutcome': contractCancellationOutcome,
  'contract/ContractCardMessage': contractCardMessage,
  'contract/ContractDetails': contractDetails,
  'contract/ContractDetailsPage': contractDetailsPage,
  'contract/DoMoreComponent': contractDoMoreComponent,
  'contract/HistoryToolTip': contractHistoryToolTip,
  'contract/PriceHistoryLolipop': contractPriceHistory,
  'contract/SelectServicePageLolipop': contractSelectServicePage,
  'contract/SelectServicesListLolipop': contractSelectServicesList,
  'contract/SettingsButtonLolipop': contractSettingsButton,
  'contract/SettingsPageLolipop': contractSettingsPage,
  'contract/SubscriptionAlarmLolipop': contractSubscriptionAlarm,
  'contract/SubscriptionDetailsLolipop': contractSubscriptionDetails,
  'contract/SubscriptionReminder': contractSubscriptionReminder,
  'contract/subscriptionReminderDialog': contractSubscriptionReminderDialog,
  'contract/TransactionHistoryLolipop': contractTransactionHistory,
  'contract/UpdateCostLolipop': contractUpdateCost,
  'discover/CategoryPage': discoverCategoryPage,
  'discover/CategoryProviderPage': discoverCategoryProviderPage,
  'discover/components/ProductInformationHeader': discoverComponentsProductInfo,
  'discover/DiscoverWidget': discoverDiscoverWidget,
  'discover/FakeCategoryPage': discoverFakeCategoryPage,
  'discover/FakeCategorySubscriptionsPage': discoverFakeCategorySubscriptionPage,
  'discover/FitnessCategoryFeedbackPage': discoverFitnessCategoryFeedbackPage,
  'discover/MarketplacePage': discoverMarketplacePage,
  'discover/MobileOrderSentPage': discoverMobileOrderSentPage,
  'discover/OrderProductPage': discoverOrderProductPage,
  'discover/ProductRecommendations': discoverProductRecommendations,
  'discover/SearchSubscriptionAllCategoriesPage': discoverSearchSubscriptionAllCategories,
  'discover/SearchSubscriptionPage': discoverSearchSubscriptionPage,
  'discover/SearchSubscriptionResultPage': discoverSearchSubscriptionResultPage,
  DUMMY: dummy,
  'e-signing/nem-id/NemIdSigningPage': eSigning,
  EditProduct: editProduct,
  'EnergyGuide/OrderForm': energyGuide,
  'error-handling/LoggedOutPage': errorHandling,
  'errorPage/ErrorPage': errorPageErrorPage,
  ErrorScreen: errorScreen,
  Explore: explore,
  'feedback-modal': feedbackModal,
  fields: fields,
  'financial-products/loans/ShowNewFeatureLoanDialog': financialProductsLoansShow,
  'financial-products/savings/InvestmentInfoCard': financialProductsSavingsInvestment,
  'financial-products/savings/OurPartnersPage': financialProductOurPartnersPage,
  'financial-products/savings/OurPartnersPageNew': financialProductOurPartnersPageNew,
  'financial-products/savings/Overview': financialProductsSavingsOverview,
  'financial-products/savings/SavingsAfterCancellation': financialProductSavingsAfterCancellation,
  'financial-products/ShowNewFeatureLoanDialog': financialProductsShowNewFeatureLoan,
  'financial-products/swedbank-consolidate-loans/HowItWorksPage': financialProductsSwedBankConsolidate,
  'financial-products/swedbank-consolidate-loans/IntroPage': financialSwedBankConsolidateIntro,
  'financial-products/swedbank-consolidate-loans/LoanApplicationStatus': financialProductLoanApplication,
  'financial-products/swedbank-consolidate-loans/OfferPage': financialProductsOfferPage,
  'financial-products/swedbank-consolidate-loans/OrderCompletedPage': financialProductOrderCompleted,
  'financial-products/swedbank-consolidate-loans/OrderPage': financialProductOrderPage,
  'financial-products/swedbank-consolidate-loans/SelectContractsPage': financialProductSelectContract,
  'financial-products/swedbank-consolidate-loans/SelectPrincipalPage': financialProductsSelectPrincipal,
  'financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage': financialProductRepaymentPeriod,
  'general/IntelligentGuide': generalIntelligentGuide,
  'general/SelectServicesPage': generalSelectServices,
  'genericActions/GenericActionsPage': genericActionsPage,
  'genericActions/SelectServicesPage': genericActionsSelectServices,
  'genericActions/SubscriptionSearchPage/Cancel': genericActionsSubscriptionCancel,
  'genericActions/SubscriptionSearchPage/ChangePayment': genericActionsSubscriptionsChangePayment,
  'guides/GuideContractDetailsPage/AlternativesSection': guidesContractDetailsAlternative,
  'guides/GuideContractDetailsPage/DealBanner': guidesDealBanner,
  'guides/GuideContractDetailsPage/PaymentMethodsCard': guidesPaymentMethod,
  Hack7CancelPage: hack7CancelPage,
  Header: header,
  HowToCancelListPage: howToCancelListPage,
  HowToCancelPage: howToCancelPage,
  'improve/flowgen': improveFlowgen,
  'improve/flowgen/OrderPage': improveOrderPage,
  'improve/flowgen/OrderSentPage': improveOrderSentPage,
  'improve/flowgen/OtherAlternativesPage': improveOtherAlternativePage,
  'improve/flowgen/PostalCodeInput': improvePostalCodeInput,
  'improve/flowgen/ResultPage': improveResultPage,
  'InsightsPage/InsightCards/CancellationOutcome': insightPageInsightsCardsCancelOutcome,
  'InsightsPage/InsightCards/CancellationUpdate': insightPageInsightsCardsCancelUpdate,
  'InsightsPage/OnboardingInsightCard': insightPageOnboardingInsightCard,
  'interval-to-string': intervalToString,
  LandingPage: landingPage,
  LoadingScreen: loadingScreen,
  'loan-applications/DetailsPage': loanApplicationsDetailsPage,
  'loan-applications/LoadingError': loanApplicationsLoadingError,
  'loan-applications/MenuDrawer': loanApplicationsMenuDrawer,
  'loan-applications/Overview': loanApplicationsOverview,
  LoginPage: loginPage,
  ManageOrder: manageOrder,
  ManualAddContractPage: manualAddContractPage,
  ManualContractPricingPage: manualAddContractPricingPage,
  Menu: menu,
  MenuPage: menuPage,
  'moving/BundledCancellationFromPage': movingBundledCancellationForm,
  'moving/BundledCancellationPage': movingBundledCancellationPage,
  'moving/BundledSwitchOrderFormPage': movingBundledSwitchOrderForm,
  'moving/BundledSwitchSwitchPage': movingBundledSwitchPage,
  'moving/ThanksSwitchPage': thanksSwitchPage,
  movingGuide: movingGuide,
  'movingGuide/MovingDetailsPage': movingGuideMovingDetails,
  'movingGuide/OneLastThingPage': movingGuideOneLastThingPage,
  'negotiate/NegotiateContractCompletePage': negotiateContractCompletePage,
  'negotiate/NegotiateGuide': negotiateGuide,
  'negotiation/OngoingNegotiationCard': negotiationOngoingCard,
  'negotiation/OngoingNegotiationStepper': negotiationOngoingStepper,
  NotificationOptInPage: notificationOptInPage,
  'onboarding/PSD2ConsentPage': onboardingPSD2Consent,
  'onboarding/PSD2PrimerPage': onboardingPSD2Primer,
  'onboarding-bank-discovery-sync/BankDiscoveryConnecting': onboardingBDSyncConnecting,
  'onboarding-bank-discovery-sync/BankDiscoveryErrorPage': onboardingBDSyncError,
  'onboarding-bank-discovery-sync/BankDiscoveryFetchPage': onboardingBDSyncFetch,
  'onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage': onboardingBDSyncSuccess,
  'onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage': onboardingBDSyncWaiting,
  'onboarding-cancel-product/OnboardingCancelProductPages': onboardingCancelProduct,
  'onboarding-psd2/WelcomePage': onboardingPsd2WelcomePage,
  'onboarding-psd2/ConsentPage': onboardingPsd2ConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPage': onboardingPsd2OpBankConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPageV2': onboardingPsd2OpBankConsentPageV2,
  'onboarding-psd2/ConsentPage/DanskeBankConsentPage': onboardingPsd2ConsentPageDanskeBank,
  'onboarding-psd2/RenewConsentPage': onboardingPsd2RenewConsentPage,
  'onboarding-psd2/RenewConsentPageV2': onboardingPsd2RenewConsentPageV2,
  'onboarding/Cards': onboardingPsd2Cards,
  'orderedSubscription/OngoingOrderCard': orderedSubscriptionOngoing,
  'orderedSubscription/OrderedEnergyProductInfo': orderedSubscriptionOrderedEnergy,
  'orderedSubscription/OrderedMobileProductInfo': orderedSubscriptionOrderedMobile,
  'orderedSubscription/OrderedSubscriptionDetails': orderedSubscriptionDetails,
  'orderedSubscription/OrderedSubscriptionDetailsPage': orderedSubscriptionDetailsPage,
  'orderedSubscription/OrderedSubscriptionInfoCard': orderedSubscriptionInfoCard,
  'orderedSubscription/OrderedSubscriptionPage': orderedSubscriptionPage,
  'orderedSubscription/OrderStepper': orderedSubscriptionOrderStepper,
  'orderedSubscription/ServiceProviderInfoCard': orderedSubscServiceProviderInfo,
  'ordering-subscription/common/TermsAndConditionsPage': orderingSubscriptionTerms,
  'ordering-subscription/common/OrderCompletedPage': orderingSubscriptionCompleted,
  'ordering-subscription/broadband/common/BroadbandGuideAddressPage': orderingBroadbandAddressPage,
  'ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage': orderingBroadbandAllAlternatives,
  'ordering-subscription/broadband/common/BroadbandGuideContractCostPage': orderingBroadbandContract,
  'ordering-subscription/broadband/common/BroadbandGuideIntroPage': orderingBroadbandIntroPage,
  'ordering-subscription/broadband/common/BroadbandGuideNoResultPage': orderingBroadbandNoResultPage,
  'ordering-subscription/broadband/common/BroadbandGuideSpeedPage': orderingBroadbandSpeedPage,
  'ordering-subscription/broadband/optimize/BroadbandOrderFormPage': orderingBroadbandOptimizeOrder,
  'ordering-subscription/broadband/optimize/BroadbandProductPage': orderingBroadbandOptimizeProduct,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog':
    orderingBroadbandOptimizeProductCosts,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPage': orderingElectricityOrder,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant': orderingElectricityOrderVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog': orderingElectricityGuidePrice,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage':
    orderingElectricityGuideAlternatives,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage':
    orderingElectricityGuideBinding,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage':
    orderingElectricityGuideBindingTime,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage': orderingElectricityGuideEnergy,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage':
    orderingElectricityGuideExplanation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage': orderingElectricityGuideFlow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage': orderingElectricityGuideHow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage':
    orderingElectricityGuideInformation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage': orderingElectricityGuideIntro,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage': orderingElectricityGuideLivingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage':
    orderingElectricityGuidePostalCodePage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences': orderingElectricityGuidePreferences,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage':
    orderingElectricityGuidePricingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage': orderingElectricityGuideProductPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage': orderingElectricityGuideSummaryPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage': orderingElectricityGuideTrading,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage': orderingElectricityGuideUsage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage':
    orderingElectricityGuideUsageResult,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant':
    orderingElectricityGuideUsageResultVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityPostalCode': orderingElectricityGuidePostalCode,
  'ordering-subscription/independent/OrderCompletedPage': orderingSubscriptionIndependentOrder,
  OrdersOverview: ordersOverview,
  'overview/addManualSubscription': overviewAddManualSubscription,
  'overview/BindingTimeBar': overviewBindingTimeBar,
  'overview/BindingTimeNotice': bindingTimeNotice,
  'overview/ChangePlanServiceIntroductionDrawer': overviewChangePlanService,
  'overview/ContractAction': overviewContractAction,
  'overview/DeprecatedInsightsPage/OnboardingInsightCard': overviewDeprecatedInsights,
  'overview/DiscoverPage': overviewDiscoverPage,
  'overview/GenericActions': overviewGenericAction,
  'overview/ImprovementPage': overviewImprovementPage,
  'overview/ImproveV2': overviewImproveV2,
  'overview/InsightsPage': insightPage,
  'overview/InsightsPage/InsightCards/ActionRating': overviewInsightsCardsActionRating,
  'overview/InsightsPage/InsightCards/CancellationUpdate': overviewInsightsCardsCancellationUpdate,
  'overview/InsightsPage/InsightCards/ChangeDataplanUpdate': overviewInsightsCardsChangeData,
  'overview/InsightsPage/InsightCards/IndependentOrderUpdate': overviewInsightsCardsIndependent,
  'overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton': overviewInsightsCardConfirmCancellation,
  'overview/InsightsPage/InsightCards/ContactUsButton': overviewContactUsButton,
  'overview/InsightsPage/InsightCards/MonthlyChecklist': overviewInsightsCardsMonthlyCheck,
  'overview/InsightsPage/InsightCards/NegotiationOutcome': overviewInsightsCardsNegotiationOutcome,
  'overview/InsightsPage/InsightCards/NegotiationUpdate': overviewInsightsCardsNegotiationUpdate,
  'overview/InsightsPage/InsightCards/NewProviderOffersCard': overviewInsightsCardsProvider,
  'overview/InsightsPage/InsightCards/NewSubscriptionFound': insightPage_insightsCards_newSubscription,
  'overview/InsightsPage/InsightCards/SaveDeskFeedbackCard': overviewInsightsCardsSaveDeskFeedback,
  'overview/InsightsPage/InsightCards/SaveDeskOrderRequested': overviewInsightCardsSaveDeskRequested,
  'overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested': overviewInsightsCardsSaveDeskPredefined,
  'overview/InsightsPage/InsightCards/SaveDeskResponseCard': overviewInsightsCardsSaveDeskResponse,
  'overview/InsightsPage/InsightCards/SwitchOutcome': overviewInsightsCardsSwitchOutcome,
  'overview/InsightsPage/InsightCards/SwitchUpdate': overviewInsightsCardsSwitchUpdate,
  'overview/InsightsPage/InsightCards/ThankYouCard': overviewInsightsCardsThankYou,
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton': overviewInsightsCardsTryCancellation,
  'overview/InsightsPage/InsightCards/UnreviewedAction': overviewInsightsCardsUnreviewed,
  'overview/InsightsPage/InsightCards/AddonRecommendation': insightPage_insightsCards_addon,
  'overview/InsightsPage/BindingTimeEnding': insightPage_bindingTimeEnding,
  'overview/InsightsPage/InsightCards/DiscoverOngoing': insightPage_insightsCards_discoverOngoing,
  'overview/InsightsPage/InsightCards/DiscoverOutcome': insightPage_insightsCards_discoverOutcomeInsight,
  'overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard':
    insightPage_insightsCards_personalRecommend,
  'overview/InsightsPage/InsightCards/SaveDeskOngoingCard': insightPage_insightsCards_saveDeskOngoing,
  'overview/InsightsPage/InsightCards/SaveDeskOutcome': insightPage_insightsCards_saveDeskOutcome,
  'overview/InsightsPage/SubscriptionCostChanged': insightPage_subscriptionCostChanged,
  'overview/InsightsPage/InsightCards/UnresolvedSubscription': insightPage_unresolvedSubscription,
  'overview/InsightsPage/OnboardingInsightCard': overviewInsightsCardsOnboarding,
  'overview/InsightsPage/PrimerCardV2': overviewInsightsPrimerCardV2,
  'overview/LoadingWithCogs': overviewLoadingWithCogs,
  'overview/ManageContractSection/SwitchProgressWithoutCancelStepper': overviewManageContract,
  'overview/MonthlyChecklist': overviewMonthlyChecklist,
  'overview/MoreInfoNeeded': overviewMoreInfoNeeded,
  'overview/OnboardingCards': overviewOnboardingCards,
  'overview/QuickActionsCancel': overviewQuickActionsCancel,
  'overview/QuickActionsCancelV2': overviewQuickActionsCancelV2,
  'overview/QuickActionsImprove': overviewQuickActionsImprove,
  'overview/QuickActionsImproveV2': overviewQuickActionsImproveV2,
  'overview/SubscriptionFeedback': overviewSubscriptionFeedback,
  'overview/SubscriptionSearch': overviewSubscriptionSearch,
  'overview/SubscriptionSearchOverviewComponent': overviewSubscriptionSearchComponent,
  'overview/WaysToSave': waysToSave,
  'overview/OverviewPage': overviewPage,
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage':
    overviewPage_didYouCancel,
  'overview/OnboardingDrawer': onboardingDrawer,
  'overview/QuickActionsV2': overviewPage_quickActionsV2,
  overview: overview,
  overviewBottomNavigation: overviewBottomNavigation,
  'pause/IntelligentGuidePage': pauseIntelligentGuide,
  'pause/SubscriptionPausePage': pauseSubscriptionPause,
  provideFeedbackPage: provideFeedbackPage,
  'provider-portal-datepicker/DatePicker': providerPortalDatepicker,
  'provider-portal-snackback/Snackbar': providerPortalSnackback,
  RegistrationPage: registrationPage,
  'retention-offer/SuccessfulRetentionOfferCard': retentionOfferCard,
  'save-desk': saveDesk,
  'save-desk/OngoingSaveDeskCard': saveDeskOngoingCard,
  'save-desk/OrderSentPage': saveDeskOrderSent,
  'savings/DoYouWantToSaveDialog': savingsDoYouWantToSave,
  'savings/SavingsAfterCancellation': savingsAfterCancellation,
  SelectProductPage: selectProductPage,
  'settings/NotificationSettingsPage': settingsNotificationSettings,
  'settings/NotificationSettingsPage/PushNotificationInstructions': settingsNotificationPushSettings,
  'settings/PersonalDetailsPage': settingsPersonalDetails,
  'settings/ProfilingSettingsPage': settingsProfile,
  'settings/SettingsPage': settingsPage,
  'settings/UserSettingsPage': settingsUser,
  'settings/ViewGdprDataPage': settingsViewGdprData,
  'settings-swedbank/SwedbankNotificationSettingsPage': settingsSwedbank,
  'shared-components/EmojiRating': sharedComponentsEmoji,
  'shared-components/UpdateBankApp': sharedComponentsUpdateBankApp,
  'shared-components/YouSavedComponent': sharedComponentsYouSavedComponent,
  ShowOnboardingFeaturesComponent: showOnboardingFeatures,
  SignoutSuccessPage: signOutSuccessPage,
  'standard-components/CurrencyTextField': standardComponentsCurrency,
  'standard-components/EmailField': standardComponentsEmail,
  'standard-components/DatePicker': standardComponentsDate,
  'standard-components/GuidePage': standardComponentsGuide,
  'standard-components/MobilePhoneNumberField': standardComponentsMobile,
  'standard-components/PasswordField': standardComponentsPassword,
  'standard-components/PersonalNumberField': standardComponentsPersonal,
  'standard-components/PostalCodeField': standardComponentsPostalCode,
  'standard-components/Snackbar': standardComponentsSnackbar,
  'subscription/CancellationMeasurement': subscriptionCancellationMeasure,
  'subscription/components/SelectableServiceList': subscriptionComponentsSelectable,
  'subscription/RemoveSubscriptionDialog': subscriptionRemoveSubscription,
  'subscription/SelectLoanServicesPage': subscriptionSelectLoanService,
  'subscription/SelectLoanTransactionTypePage': subscriptionSelectLoanTransaction,
  'subscription/SelectServicesPage': subscriptionSelectServices,
  'subscription/SubscriptionPage': subscriptionPage,
  'subscription/SubscriptionSettingsPage': subscriptionSettingsPage,
  'subscription/SubscriptionUpdateCostPage': subscriptionUpdateCostPage,
  'subscription/SwitchMeasurement': subscriptionSwitchMeasurement,
  'subscription/UnresolvedGuide': subscriptionUnresolvedGuide,
  'subscription/WhatsWrongDialog': subscriptionWhatsWrong,
  'subscription-alarm/MorePage': subscriptionAlarmMore,
  'subscription-alarm/SubscriptionAlarmPage': subscriptionAlarmPage,
  'subscription-hub/PastActions': subscriptionHubActions,
  'subscriptionHub/subscriptionHub': subscriptionHub,
  'supplier-portal-change': supplierPortalChange,
  'supplier-portal-save-desk': supplierPortalSaveDesk,
  SwedbankOverviewSyncFetch: swedbankOverviewSync,
  SwedenPhoneCallsLimitationInput: swedenPhoneCallsLimited,
  'sync-sources/AddSyncSourcePage': syncSourcesAddSync,
  'sync-sources/AddSyncSourcesSpeedDial': syncSourcesAddSyncSpeed,
  'sync-sources/DeleteSyncSourcesPage': syncSourcesDeleteSync,
  'sync-sources/Error': syncSourcesError,
  'sync-sources/NoSyncSources': syncSourcesNoSync,
  'sync-sources/PopularSyncSourcesList': syncSourcesPopularSync,
  'sync-sources/SyncSourcesFetching': syncSourcesFetching,
  'sync-sources/SyncSourcesList': syncSourcesList,
  'sync-sources/TinkSyncPage': syncSourcesTinkSync,
  'sync-sources/UpdateBankAuthsPage': syncSourcesUpdateBank,
  'sync-sources/UpdateSyncSources': syncSourcesUpdateSync,
  'sync-sources/UpdateSyncSourcesButton': syncSourcesUpdateSyncButton,
  'sync-sources/SyncSourcesReminder': syncSourcesReminder,
  'termination/TerminationFormPage': terminationFormPage,
  'termination/TerminationFormPage/CancelReasonSection': terminationFormPCancelReason,
  'termination/TerminationInstructionsPage': terminationInstructions,
  TermsAndConditionsUpload: termsAndCondition,
  TypeformButton: typeFormButton,
  'user-form-preview': userFormPreview,
  userFeedback: userFeedback,
  'userSettings/UserDeletePage': userDeleteSettings,
  'userSettings/UserDeleteVerifiedPage': userDeleteVerifiedPage,
  zendesk: zendesk,
  'aboutUsPage/Swedbank': swedbankaboutUsPage,
  'AboutUsPage/BankAboutUsPage/Swedbank': swedbankbankAboutUsPage,
  'addon/mobile/RecommendationsPage/Swedbank': swedbankaddonMobileRecommendationsPage,
  'addon/mobile/ResultPage/Swedbank': swedbankaddonMobileResultPage,
  'auth/AuthStatusPage/Swedbank': swedbankauthAuthStatusPage,
  'auth/BasicLoginPage/Swedbank': swedbankauthBasicLoginPage,
  'auth/CreateAccountPage/Swedbank': swedbankauthCreateAccountPage,
  'auth/LoginPage/Swedbank': swedbankauthCreateAccountPage,
  'auth/OnboardingChooseBankPage/Swedbank': swedbankauthOnboardingChooseBankPage,
  'auth/OnboardingEmailPage/Swedbank': swedbankauthOnboardingEmailPage,
  'auth/PreLoginPage/Swedbank': swedbankauthPreLoginPage,
  'auth/ResetPasswordPage/Swedbank': swedbankauthResetPasswordPage,
  'auth/SyncSourcesError/Swedbank': swedbankauthSyncSourcesError,
  'auth/SyncSourcesFetching/Swedbank': swedbankauthSyncSourcesFetching,
  'BadgeABTestCtas/Swedbank': swedbankbadgeABTestCTAS,
  'BadgeCtas/Swedbank': swedbankbadgeCtas,
  'bank-sync/CancelProductOnboardPages/Swedbank': swedbankbankSyncCancelProduct,
  'bank-sync/DrawerPages/Swedbank': swedbankbankSyncDrawerPages,
  'bank-sync/LoadingScreen/Swedbank': swedbankbankSyncLoadingScreen,
  'bank-sync/OnBoardBankSync/Swedbank': swedbankbankSyncOnboardBankSync,
  'CampaignIdSelect/Swedbank': swedbankcampaignIdSelect,
  'cancel/CancellationOutcome/Swedbank': swedbankcancelCancellationOutcome,
  'cancellation/cancellationDetailsPage/Swedbank': swedbankcancellationDetailsPage,
  'cancellation/CancellationFormPage/CancellationInstructionsSection/Swedbank':
    swedbankcancellationFormCancellationInstruction,
  'cancellation/CancellationFormPage/CancellationTerms/Swedbank': swedbankcancellationFormCancellationTerms,
  'cancellation/CancellationFormPage/ConfirmSection/Swedbank': swedbankcancellationFormConfirmSection,
  'cancellation/CancellationFormPage/LightUserTerms/Swedbank': swedbankcancellationFormLightUserTerms,
  'cancellation/CancellationModalsContainer/Swedbank': swedbankcancellationModalsContainer,
  'cancellation/CancellationQuestionFlow/Swedbank': swedbankcancellationQuestionsFlow,
  'cancellation/CancellationReasonsDialog/Swedbank': swedbankcancellationReasonsDialog,
  'cancellation/CancellationSentPage/Swedbank': swedbankcancellationSentPage,
  'cancellation/CancellationStepper/Swedbank': swedbankcancellationStepper,
  'cancellation/DoYouWantToSaveDialog/Swedbank': swedbankcancellationDoYouWantToSaveDialog,
  'cancellation/IntelligentGuidePage/Swedbank': swedbankcancellationIntelligentGuidePage,
  'cancellation/NetflixCancellationInstructionsPage/Swedbank': swedbankcancellationNetflixCancellationInstructionsPage,
  'cancellation/signing/NemIdErrorPage/Swedbank': swedbankcancellationSigningNemIdErrorPage,
  'cancellation/signing/NemIdSigningPage/Swedbank': swedbankcancellationSigningNemIdSigningPage,
  'cancellation/signing/NoSigningPage/Swedbank': swedbankcancellationNoSigningPage,
  'cancellation/signing/NoSigningServicePage/Swedbank': swedbankcancellationNoSigningServicePage,
  'CancellationPortal/Swedbank': swedbankcancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement/Swedbank':
    swedbankcancellationsCancellationPortalAgreement,
  'categories/Swedbank': swedbankcategories,
  'CategoryNames/CategoryNames/Swedbank': swedbankcategoryNames,
  'change-dataplan/ChangeDataplan/Swedbank': swedbankchangeDataPlan,
  'change-dataplan/ChangeDataplanCompletedPage/Swedbank': swedbankchangeDataPlanCompletedPage,
  'change-dataplan/ChangeDataplanOrderFormPage/Swedbank': swedbankchangeDataPlanOrderFormPage,
  'change-dataplan/ChangeDataplanPage/Swedbank': swedbankchangeDataPlanPage,
  'change-payment/ChangePlanServiceIntroductionDrawer/Swedbank':
    swedbankchangePaymentChangePlanServiceIntroductionDrawer,
  'change-payment/IntelligentGuidePage/Swedbank': swedbankchangePaymentIntelligentGuidePage,
  'change-plan/ChangePlanServiceIntroductionDrawer/Swedbank': swedbankchangePlanServiceIntroductionDrawer,
  'change-plan/IntelligentGuideExperimentPage/Swedbank': swedbankchangePlanIntelligentGuideExperimentPage,
  'change-plan/IntelligentGuidePage/Swedbank': swedbankchangePlanIntelligentGuidePage,
  'ChangePlan/Swedbank': swedbankchangePlan,
  'charity-donation/Swedbank': swedbankcharityDonation,
  'complex-contract-guide/IntroPage/Swedbank': swedbankcomplexContractGuideIntroPage,
  'complex-contract-guide/LoanServiceQuestionPage/Swedbank': swedbankcomplexContractGuideLoanServiceQuestionPage,
  'complex-contract-guide/LoanTypeQuestionPage/Swedbank': swedbankcomplexContractGuideLoanTypeQuestionPage,
  'complex-contract-guide/ServiceQuestionPage/Swedbank': swedbankcomplexContractGuideServiceQuestionPage,
  'complex-contract-guide/Shared/Swedbank': swedbankcomplexContractGuideShared,
  'complex-contract-guide/SuccessPage/Swedbank': swedbankcomplexContractGuideSuccessPage,
  'conditions-and-policies/ConditionsAndPoliciesMenu/Swedbank': swedbankconditionsAndPoliciesMenu,
  'conditions-and-policies/CookiesPage/Swedbank': swedbankconditionsAndPoliciesCookiesPage,
  'conditions-and-policies/SecurityPage/Swedbank': swedbankconditionsAndPoliciesSecurityPage,
  'conditions-and-policies/Swedbank': swedbankconditionsAndPolicies,
  'contract/bindingPeriodDialog/Swedbank': swedbankcontractBindingPeriodDialog,
  'contract/CancellationHistory/Swedbank': swedbankcontractCancellationHistory,
  'contract/CancelledContract/Swedbank': swedbankcontractCancelledContract,
  'contract/ContractCancellationOutcome/Swedbank': swedbankcontractCancellationOutcome,
  'contract/ContractCardMessage/Swedbank': swedbankcontractCardMessage,
  'contract/ContractDetails/Swedbank': swedbankcontractDetails,
  'contract/ContractDetailsPage/Swedbank': swedbankcontractDetailsPage,
  'contract/DoMoreComponent/Swedbank': swedbankcontractDoMoreComponent,
  'contract/HistoryToolTip/Swedbank': swedbankcontractHistoryToolTip,
  'contract/PriceHistoryLolipop/Swedbank': swedbankcontractPriceHistory,
  'contract/SelectServicePageLolipop/Swedbank': swedbankcontractSelectServicePage,
  'contract/SelectServicesListLolipop/Swedbank': swedbankcontractSelectServicesList,
  'contract/SettingsButtonLolipop/Swedbank': swedbankcontractSettingsButton,
  'contract/SettingsPageLolipop/Swedbank': swedbankcontractSettingsPage,
  'contract/SubscriptionAlarmLolipop/Swedbank': swedbankcontractSubscriptionAlarm,
  'contract/SubscriptionDetailsLolipop/Swedbank': swedbankcontractSubscriptionDetails,
  'contract/SubscriptionReminder/Swedbank': swedbankcontractSubscriptionReminder,
  'contract/subscriptionReminderDialog/Swedbank': swedbankcontractSubscriptionReminderDialog,
  'contract/TransactionHistoryLolipop/Swedbank': swedbankcontractTransactionHistory,
  'contract/UpdateCostLolipop/Swedbank': swedbankcontractUpdateCost,
  'discover/CategoryPage/Swedbank': swedbankdiscoverCategoryPage,
  'discover/CategoryProviderPage/Swedbank': swedbankdiscoverCategoryProviderPage,
  'discover/components/ProductInformationHeader/Swedbank': swedbankdiscoverComponentsProductInfo,
  'discover/DiscoverWidget/Swedbank': swedbankdiscoverDiscoverWidget,
  'discover/FakeCategoryPage/Swedbank': swedbankdiscoverFakeCategoryPage,
  'discover/FakeCategorySubscriptionsPage/Swedbank': swedbankdiscoverFakeCategorySubscriptionPage,
  'discover/FitnessCategoryFeedbackPage/Swedbank': swedbankdiscoverFitnessCategoryFeedbackPage,
  'discover/MarketplacePage/Swedbank': swedbankdiscoverMarketplacePage,
  'discover/MobileOrderSentPage/Swedbank': swedbankdiscoverMobileOrderSentPage,
  'discover/OrderProductPage/Swedbank': swedbankdiscoverOrderProductPage,
  'discover/ProductRecommendations/Swedbank': swedbankdiscoverProductRecommendations,
  'discover/SearchSubscriptionAllCategoriesPage/Swedbank': swedbankdiscoverSearchSubscriptionAllCategories,
  'discover/SearchSubscriptionPage/Swedbank': swedbankdiscoverSearchSubscriptionPage,
  'discover/SearchSubscriptionResultPage/Swedbank': swedbankdiscoverSearchSubscriptionResultPage,
  'DUMMY/Swedbank': swedbankdummy,
  'e-signing/nem-id/NemIdSigningPage/Swedbank': swedbankeSigning,
  'EditProduct/Swedbank': swedbankeditProduct,
  'EnergyGuide/OrderForm/Swedbank': swedbankenergyGuide,
  'error-handling/LoggedOutPage/Swedbank': swedbankerrorHandling,
  'errorPage/ErrorPage/Swedbank': swedbankerrorPageErrorPage,
  'ErrorScreen/Swedbank': swedbankerrorScreen,
  'Explore/Swedbank': swedbankexplore,
  'feedback-modal/Swedbank': swedbankfeedbackModal,
  'fields/Swedbank': swedbankfields,
  'financial-products/loans/ShowNewFeatureLoanDialog/Swedbank': swedbankfinancialProductsLoansShow,
  'financial-products/savings/InvestmentInfoCard/Swedbank': swedbankfinancialProductsSavingsInvestment,
  'financial-products/savings/OurPartnersPage/Swedbank': swedbankfinancialProductOurPartnersPage,
  'financial-products/savings/OurPartnersPageNew/Swedbank': swedbankfinancialProductOurPartnersPageNew,
  'financial-products/savings/Overview/Swedbank': swedbankfinancialProductsSavingsOverview,
  'financial-products/savings/SavingsAfterCancellation/Swedbank': swedbankfinancialProductSavingsAfterCancellation,
  'financial-products/ShowNewFeatureLoanDialog/Swedbank': swedbankfinancialProductsShowNewFeatureLoan,
  'financial-products/swedbank-consolidate-loans/HowItWorksPage/Swedbank': swedbankfinancialProductsSwedBankConsolidate,
  'financial-products/swedbank-consolidate-loans/IntroPage/Swedbank': swedbankfinancialSwedBankConsolidateIntro,
  'financial-products/swedbank-consolidate-loans/LoanApplicationStatus/Swedbank':
    swedbankfinancialProductLoanApplication,
  'financial-products/swedbank-consolidate-loans/OfferPage/Swedbank': swedbankfinancialProductsOfferPage,
  'financial-products/swedbank-consolidate-loans/OrderCompletedPage/Swedbank': swedbankfinancialProductOrderCompleted,
  'financial-products/swedbank-consolidate-loans/OrderPage/Swedbank': swedbankfinancialProductOrderPage,
  'financial-products/swedbank-consolidate-loans/SelectContractsPage/Swedbank': swedbankfinancialProductSelectContract,
  'financial-products/swedbank-consolidate-loans/SelectPrincipalPage/Swedbank':
    swedbankfinancialProductsSelectPrincipal,
  'financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/Swedbank':
    swedbankfinancialProductRepaymentPeriod,
  'general/IntelligentGuide/Swedbank': swedbankgeneralIntelligentGuide,
  'general/SelectServicesPage/Swedbank': swedbankgeneralSelectServices,
  'genericActions/GenericActionsPage/Swedbank': swedbankgenericActionsPage,
  'genericActions/SelectServicesPage/Swedbank': swedbankgenericActionsSelectServices,
  'genericActions/SubscriptionSearchPage/Cancel/Swedbank': swedbankgenericActionsSubscriptionCancel,
  'genericActions/SubscriptionSearchPage/ChangePayment/Swedbank': swedbankgenericActionsSubscriptionsChangePayment,
  'guides/GuideContractDetailsPage/AlternativesSection/Swedbank': swedbankguidesContractDetailsAlternative,
  'guides/GuideContractDetailsPage/DealBanner/Swedbank': swedbankguidesDealBanner,
  'guides/GuideContractDetailsPage/PaymentMethodsCard/Swedbank': swedbankguidesPaymentMethod,
  'Hack7CancelPage/Swedbank': swedbankhack7CancelPage,
  'Header/Swedbank': swedbankheader,
  'HowToCancelListPage/Swedbank': swedbankhowToCancelListPage,
  'HowToCancelPage/Swedbank': swedbankhowToCancelPage,
  'improve/flowgen/Swedbank': swedbankimproveFlowgen,
  'improve/flowgen/OrderPage/Swedbank': swedbankimproveOrderPage,
  'improve/flowgen/OrderSentPage/Swedbank': swedbankimproveOrderSentPage,
  'improve/flowgen/OtherAlternativesPage/Swedbank': swedbankimproveOtherAlternativePage,
  'improve/flowgen/PostalCodeInput/Swedbank': swedbankimprovePostalCodeInput,
  'improve/flowgen/ResultPage/Swedbank': swedbankimproveResultPage,
  'InsightsPage/InsightCards/CancellationOutcome/Swedbank': swedbankinsightPageInsightsCardsCancelOutcome,
  'InsightsPage/InsightCards/CancellationUpdate/Swedbank': swedbankinsightPageInsightsCardsCancelUpdate,
  'InsightsPage/OnboardingInsightCard/Swedbank': swedbankinsightPageOnboardingInsightCard,
  'interval-to-string/Swedbank': swedbankintervalToString,
  'LandingPage/Swedbank': swedbanklandingPage,
  'LoadingScreen/Swedbank': swedbankloadingScreen,
  'loan-applications/DetailsPage/Swedbank': swedbankloanApplicationsDetailsPage,
  'loan-applications/LoadingError/Swedbank': swedbankloanApplicationsLoadingError,
  'loan-applications/MenuDrawer/Swedbank': swedbankloanApplicationsMenuDrawer,
  'loan-applications/Overview/Swedbank': swedbankloanApplicationsOverview,
  'LoginPage/Swedbank': swedbankloginPage,
  'ManageOrder/Swedbank': swedbankmanageOrder,
  'ManualAddContractPage/Swedbank': swedbankmanualAddContractPage,
  'ManualContractPricingPage/Swedbank': swedbankmanualAddContractPricingPage,
  'Menu/Swedbank': swedbankmenu,
  'MenuPage/Swedbank': swedbankmenuPage,
  'moving/BundledCancellationFromPage/Swedbank': swedbankmovingBundledCancellationForm,
  'moving/BundledCancellationPage/Swedbank': swedbankmovingBundledCancellationPage,
  'moving/BundledSwitchOrderFormPage/Swedbank': swedbankmovingBundledSwitchOrderForm,
  'moving/BundledSwitchSwitchPage/Swedbank': swedbankmovingBundledSwitchPage,
  'moving/ThanksSwitchPage/Swedbank': swedbankthanksSwitchPage,
  'movingGuide/Swedbank': swedbankmovingGuide,
  'movingGuide/MovingDetailsPage/Swedbank': swedbankmovingGuideMovingDetails,
  'movingGuide/OneLastThingPage/Swedbank': swedbankmovingGuideOneLastThingPage,
  'negotiate/NegotiateContractCompletePage/Swedbank': swedbanknegotiateContractCompletePage,
  'negotiate/NegotiateGuide/Swedbank': swedbanknegotiateGuide,
  'negotiation/OngoingNegotiationCard/Swedbank': swedbanknegotiationOngoingCard,
  'negotiation/OngoingNegotiationStepper/Swedbank': swedbanknegotiationOngoingStepper,
  'NotificationOptInPage/Swedbank': swedbanknotificationOptInPage,
  'onboarding/PSD2ConsentPage/Swedbank': swedbankonboardingPSD2Consent,
  'onboarding/PSD2PrimerPage/Swedbank': swedbankonboardingPSD2Primer,
  'onboarding-bank-discovery-sync/BankDiscoveryConnecting/Swedbank': swedbankonboardingBDSyncConnecting,
  'onboarding-bank-discovery-sync/BankDiscoveryErrorPage/Swedbank': swedbankonboardingBDSyncError,
  'onboarding-bank-discovery-sync/BankDiscoveryFetchPage/Swedbank': swedbankonboardingBDSyncFetch,
  'onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/Swedbank': swedbankonboardingBDSyncSuccess,
  'onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/Swedbank': swedbankonboardingBDSyncWaiting,
  'onboarding-cancel-product/OnboardingCancelProductPages/Swedbank': swedbankonboardingCancelProduct,
  'onboarding-psd2/WelcomePage/Swedbank': swedbankonboardingPsd2WelcomePage,
  'onboarding-psd2/ConsentPage/Swedbank': swedbankonboardingPsd2ConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPage/Swedbank': swedbankonboardingPsd2OpBankConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPageV2/Swedbank': swedbankonboardingPsd2OpBankConsentPageV2,
  'onboarding-psd2/ConsentPage/DanskeBankConsentPage/Swedbank': swedbankonboardingPsd2ConsentPageDanskeBank,
  'onboarding-psd2/RenewConsentPage/Swedbank': swedbankonboardingPsd2RenewConsentPage,
  'onboarding-psd2/RenewConsentPageV2/Swedbank': swedbankonboardingPsd2RenewConsentPageV2,
  'onboarding/Cards/Swedbank': swedbankonboardingPsd2Cards,
  'orderedSubscription/OngoingOrderCard/Swedbank': swedbankorderedSubscriptionOngoing,
  'orderedSubscription/OrderedEnergyProductInfo/Swedbank': swedbankorderedSubscriptionOrderedEnergy,
  'orderedSubscription/OrderedMobileProductInfo/Swedbank': swedbankorderedSubscriptionOrderedMobile,
  'orderedSubscription/OrderedSubscriptionDetails/Swedbank': swedbankorderedSubscriptionDetails,
  'orderedSubscription/OrderedSubscriptionDetailsPage/Swedbank': swedbankorderedSubscriptionDetailsPage,
  'orderedSubscription/OrderedSubscriptionInfoCard/Swedbank': swedbankorderedSubscriptionInfoCard,
  'orderedSubscription/OrderedSubscriptionPage/Swedbank': swedbankorderedSubscriptionPage,
  'orderedSubscription/OrderStepper/Swedbank': swedbankorderedSubscriptionOrderStepper,
  'orderedSubscription/ServiceProviderInfoCard/Swedbank': swedbankorderedSubscServiceProviderInfo,
  'ordering-subscription/common/TermsAndConditionsPage/Swedbank': swedbankorderingSubscriptionTerms,
  'ordering-subscription/common/OrderCompletedPage/Swedbank': swedbankorderingSubscriptionCompleted,
  'ordering-subscription/broadband/common/BroadbandGuideAddressPage/Swedbank': swedbankorderingBroadbandAddressPage,
  'ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/Swedbank':
    swedbankorderingBroadbandAllAlternatives,
  'ordering-subscription/broadband/common/BroadbandGuideContractCostPage/Swedbank': swedbankorderingBroadbandContract,
  'ordering-subscription/broadband/common/BroadbandGuideIntroPage/Swedbank': swedbankorderingBroadbandIntroPage,
  'ordering-subscription/broadband/common/BroadbandGuideNoResultPage/Swedbank': swedbankorderingBroadbandNoResultPage,
  'ordering-subscription/broadband/common/BroadbandGuideSpeedPage/Swedbank': swedbankorderingBroadbandSpeedPage,
  'ordering-subscription/broadband/optimize/BroadbandOrderFormPage/Swedbank': swedbankorderingBroadbandOptimizeOrder,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/Swedbank': swedbankorderingBroadbandOptimizeProduct,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/Swedbank':
    swedbankorderingBroadbandOptimizeProductCosts,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPage/Swedbank': swedbankorderingElectricityOrder,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/Swedbank':
    swedbankorderingElectricityOrderVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/Swedbank':
    swedbankorderingElectricityGuidePrice,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/Swedbank':
    swedbankorderingElectricityGuideAlternatives,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/Swedbank':
    swedbankorderingElectricityGuideBinding,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/Swedbank':
    swedbankorderingElectricityGuideBindingTime,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/Swedbank':
    swedbankorderingElectricityGuideEnergy,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/Swedbank':
    swedbankorderingElectricityGuideExplanation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/Swedbank':
    swedbankorderingElectricityGuideFlow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/Swedbank':
    swedbankorderingElectricityGuideHow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/Swedbank':
    swedbankorderingElectricityGuideInformation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/Swedbank':
    swedbankorderingElectricityGuideIntro,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/Swedbank':
    swedbankorderingElectricityGuideLivingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/Swedbank':
    swedbankorderingElectricityGuidePostalCodePage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/Swedbank':
    swedbankorderingElectricityGuidePreferences,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/Swedbank':
    swedbankorderingElectricityGuidePricingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/Swedbank':
    swedbankorderingElectricityGuideProductPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/Swedbank':
    swedbankorderingElectricityGuideSummaryPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/Swedbank':
    swedbankorderingElectricityGuideTrading,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/Swedbank':
    swedbankorderingElectricityGuideUsage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/Swedbank':
    swedbankorderingElectricityGuideUsageResult,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/Swedbank':
    swedbankorderingElectricityGuideUsageResultVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/Swedbank':
    swedbankorderingElectricityGuidePostalCode,
  'ordering-subscription/independent/OrderCompletedPage/Swedbank': swedbankorderingSubscriptionIndependentOrder,
  'OrdersOverview/Swedbank': swedbankordersOverview,
  'overview/addManualSubscription/Swedbank': swedbankoverviewAddManualSubscription,
  'overview/BindingTimeBar/Swedbank': swedbankoverviewBindingTimeBar,
  'overview/BindingTimeNotice/Swedbank': swedbankbindingTimeNotice,
  'overview/ChangePlanServiceIntroductionDrawer/Swedbank': swedbankoverviewChangePlanService,
  'overview/ContractAction/Swedbank': swedbankoverviewContractAction,
  'overview/DeprecatedInsightsPage/OnboardingInsightCard/Swedbank': swedbankoverviewDeprecatedInsights,
  'overview/DiscoverPage/Swedbank': swedbankoverviewDiscoverPage,
  'overview/GenericActions/Swedbank': swedbankoverviewGenericAction,
  'overview/ImprovementPage/Swedbank': swedbankoverviewImprovementPage,
  'overview/ImproveV2/Swedbank': swedbankoverviewImproveV2,
  'overview/InsightsPage/Swedbank': swedbankinsightPage,
  'overview/InsightsPage/InsightCards/ActionRating/Swedbank': swedbankoverviewInsightsCardsActionRating,
  'overview/InsightsPage/InsightCards/CancellationUpdate/Swedbank': swedbankoverviewInsightsCardsCancellationUpdate,
  'overview/InsightsPage/InsightCards/ChangeDataplanUpdate/Swedbank': swedbankoverviewInsightsCardsChangeData,
  'overview/InsightsPage/InsightCards/IndependentOrderUpdate/Swedbank': swedbankoverviewInsightsCardsIndependent,
  'overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/Swedbank':
    swedbankoverviewInsightsCardConfirmCancellation,
  'overview/InsightsPage/InsightCards/ContactUsButton/Swedbank': swedbankoverviewContactUsButton,
  'overview/InsightsPage/InsightCards/MonthlyChecklist/Swedbank': swedbankoverviewInsightsCardsMonthlyCheck,
  'overview/InsightsPage/InsightCards/NegotiationOutcome/Swedbank': swedbankoverviewInsightsCardsNegotiationOutcome,
  'overview/InsightsPage/InsightCards/NegotiationUpdate/Swedbank': swedbankoverviewInsightsCardsNegotiationUpdate,
  'overview/InsightsPage/InsightCards/NewProviderOffersCard/Swedbank': swedbankoverviewInsightsCardsProvider,
  'overview/InsightsPage/InsightCards/NewSubscriptionFound/Swedbank': swedbankinsightPage_insightsCards_newSubscription,
  'overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/Swedbank': swedbankoverviewInsightsCardsSaveDeskFeedback,
  'overview/InsightsPage/InsightCards/SaveDeskOrderRequested/Swedbank': swedbankoverviewInsightCardsSaveDeskRequested,
  'overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/Swedbank':
    swedbankoverviewInsightsCardsSaveDeskPredefined,
  'overview/InsightsPage/InsightCards/SaveDeskResponseCard/Swedbank': swedbankoverviewInsightsCardsSaveDeskResponse,
  'overview/InsightsPage/InsightCards/SwitchOutcome/Swedbank': swedbankoverviewInsightsCardsSwitchOutcome,
  'overview/InsightsPage/InsightCards/SwitchUpdate/Swedbank': swedbankoverviewInsightsCardsSwitchUpdate,
  'overview/InsightsPage/InsightCards/ThankYouCard/Swedbank': swedbankoverviewInsightsCardsThankYou,
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton/Swedbank':
    swedbankoverviewInsightsCardsTryCancellation,
  'overview/InsightsPage/InsightCards/UnreviewedAction/Swedbank': swedbankoverviewInsightsCardsUnreviewed,
  'overview/InsightsPage/InsightCards/AddonRecommendation/Swedbank': swedbankinsightPage_insightsCards_addon,
  'overview/InsightsPage/BindingTimeEnding/Swedbank': swedbankinsightPage_bindingTimeEnding,
  'overview/InsightsPage/InsightCards/DiscoverOngoing/Swedbank': swedbankinsightPage_insightsCards_discoverOngoing,
  'overview/InsightsPage/InsightCards/DiscoverOutcome/Swedbank':
    swedbankinsightPage_insightsCards_discoverOutcomeInsight,
  'overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/Swedbank':
    swedbankinsightPage_insightsCards_personalRecommend,
  'overview/InsightsPage/InsightCards/SaveDeskOngoingCard/Swedbank': swedbankinsightPage_insightsCards_saveDeskOngoing,
  'overview/InsightsPage/InsightCards/SaveDeskOutcome/Swedbank': swedbankinsightPage_insightsCards_saveDeskOutcome,
  'overview/InsightsPage/SubscriptionCostChanged/Swedbank': swedbankinsightPage_subscriptionCostChanged,
  'overview/InsightsPage/InsightCards/UnresolvedSubscription/Swedbank': swedbankinsightPage_unresolvedSubscription,
  'overview/InsightsPage/OnboardingInsightCard/Swedbank': swedbankoverviewInsightsCardsOnboarding,
  'overview/InsightsPage/PrimerCardV2/Swedbank': swedbankoverviewInsightsPrimerCardV2,
  'overview/LoadingWithCogs/Swedbank': swedbankoverviewLoadingWithCogs,
  'overview/ManageContractSection/SwitchProgressWithoutCancelStepper/Swedbank': swedbankoverviewManageContract,
  'overview/MonthlyChecklist/Swedbank': swedbankoverviewMonthlyChecklist,
  'overview/MoreInfoNeeded/Swedbank': swedbankoverviewMoreInfoNeeded,
  'overview/OnboardingCards/Swedbank': swedbankoverviewOnboardingCards,
  'overview/QuickActionsCancel/Swedbank': swedbankoverviewQuickActionsCancel,
  'overview/QuickActionsCancelV2/Swedbank': swedbankoverviewQuickActionsCancelV2,
  'overview/QuickActionsImprove/Swedbank': swedbankoverviewQuickActionsImprove,
  'overview/QuickActionsImproveV2/Swedbank': swedbankoverviewQuickActionsImproveV2,
  'overview/SubscriptionFeedback/Swedbank': swedbankoverviewSubscriptionFeedback,
  'overview/SubscriptionSearch/Swedbank': swedbankoverviewSubscriptionSearch,
  'overview/SubscriptionSearchOverviewComponent/Swedbank': swedbankoverviewSubscriptionSearchComponent,
  'overview/WaysToSave/Swedbank': swedbankwaysToSave,
  'overview/OverviewPage/Swedbank': swedbankoverviewPage,
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/Swedbank':
    swedbankoverviewPage_didYouCancel,
  'overview/OnboardingDrawer/Swedbank': swedbankonboardingDrawer,
  'overview/QuickActionsV2/Swedbank': swedbankoverviewPage_quickActionsV2,
  'overview/Swedbank': swedbankoverview,
  'overviewBottomNavigation/Swedbank': swedbankoverviewBottomNavigation,
  'pause/IntelligentGuidePage/Swedbank': swedbankpauseIntelligentGuide,
  'pause/SubscriptionPausePage/Swedbank': swedbankpauseSubscriptionPause,
  'provideFeedbackPage/Swedbank': swedbankprovideFeedbackPage,
  'provider-portal-datepicker/DatePicker/Swedbank': swedbankproviderPortalDatepicker,
  'provider-portal-snackback/Snackbar/Swedbank': swedbankproviderPortalSnackback,
  'RegistrationPage/Swedbank': swedbankregistrationPage,
  'retention-offer/SuccessfulRetentionOfferCard/Swedbank': swedbankretentionOfferCard,
  'save-desk/Swedbank': swedbanksaveDesk,
  'save-desk/OngoingSaveDeskCard/Swedbank': swedbanksaveDeskOngoingCard,
  'save-desk/OrderSentPage/Swedbank': swedbanksaveDeskOrderSent,
  'savings/DoYouWantToSaveDialog/Swedbank': swedbanksavingsDoYouWantToSave,
  'savings/SavingsAfterCancellation/Swedbank': swedbanksavingsAfterCancellation,
  'SelectProductPage/Swedbank': swedbankselectProductPage,
  'settings/NotificationSettingsPage/Swedbank': swedbanksettingsNotificationSettings,
  'settings/NotificationSettingsPage/PushNotificationInstructions/Swedbank': swedbanksettingsNotificationPushSettings,
  'settings/PersonalDetailsPage/Swedbank': swedbanksettingsPersonalDetails,
  'settings/ProfilingSettingsPage/Swedbank': swedbanksettingsProfile,
  'settings/SettingsPage/Swedbank': swedbanksettingsPage,
  'settings/UserSettingsPage/Swedbank': swedbanksettingsUser,
  'settings/ViewGdprDataPage/Swedbank': swedbanksettingsViewGdprData,
  'settings-swedbank/SwedbankNotificationSettingsPage/Swedbank': swedbanksettingsSwedbank,
  'shared-components/EmojiRating/Swedbank': swedbanksharedComponentsEmoji,
  'shared-components/UpdateBankApp/Swedbank': swedbanksharedComponentsUpdateBankApp,
  'shared-components/YouSavedComponent/Swedbank': swedbanksharedComponentsYouSavedComponent,
  'ShowOnboardingFeaturesComponent/Swedbank': swedbankshowOnboardingFeatures,
  'SignoutSuccessPage/Swedbankt': swedbanksignOutSuccessPage,
  'standard-components/CurrencyTextField/Swedbank': swedbankstandardComponentsCurrency,
  'standard-components/EmailField/Swedbank': swedbankstandardComponentsEmail,
  'standard-components/DatePicker/Swedbank': swedbankstandardComponentsDate,
  'standard-components/GuidePage/Swedbank': swedbankstandardComponentsGuide,
  'standard-components/MobilePhoneNumberField/Swedbank': swedbankstandardComponentsMobile,
  'standard-components/PasswordField/Swedbank': swedbankstandardComponentsPassword,
  'standard-components/PersonalNumberField/Swedbank': swedbankstandardComponentsPersonal,
  'standard-components/PostalCodeField/Swedbank': swedbankstandardComponentsPostalCode,
  'standard-components/Snackbar/Swedbank': swedbankstandardComponentsSnackbar,
  'subscription/CancellationMeasurement/Swedbank': swedbanksubscriptionCancellationMeasure,
  'subscription/components/SelectableServiceList/Swedbank': swedbanksubscriptionComponentsSelectable,
  'subscription/RemoveSubscriptionDialog/Swedbank': swedbanksubscriptionRemoveSubscription,
  'subscription/SelectLoanServicesPage/Swedbank': swedbanksubscriptionSelectLoanService,
  'subscription/SelectLoanTransactionTypePage/Swedbank': swedbanksubscriptionSelectLoanTransaction,
  'subscription/SelectServicesPage/Swedbank': swedbanksubscriptionSelectServices,
  'subscription/SubscriptionPage/Swedbank': swedbanksubscriptionPage,
  'subscription/SubscriptionSettingsPage/Swedbank': swedbanksubscriptionSettingsPage,
  'subscription/SubscriptionUpdateCostPage/Swedbank': swedbanksubscriptionUpdateCostPage,
  'subscription/SwitchMeasurement/Swedbank': swedbanksubscriptionSwitchMeasurement,
  'subscription/UnresolvedGuide/Swedbank': swedbanksubscriptionUnresolvedGuide,
  'subscription/WhatsWrongDialog/Swedbank': swedbanksubscriptionWhatsWrong,
  'subscription-alarm/MorePage/Swedbank': swedbanksubscriptionAlarmMore,
  'subscription-alarm/SubscriptionAlarmPage/Swedbank': swedbanksubscriptionAlarmPage,
  'subscription-hub/PastActions/Swedbank': swedbanksubscriptionHubActions,
  'subscriptionHub/subscriptionHub/Swedbank': swedbanksubscriptionHub,
  'supplier-portal-change/Swedbank': swedbanksupplierPortalChange,
  'supplier-portal-save-desk/Swedbank': swedbanksupplierPortalSaveDesk,
  'SwedbankOverviewSyncFetch/Swedbank': swedbankswedbankOverviewSync,
  'SwedenPhoneCallsLimitationInput/Swedbank': swedbankswedenPhoneCallsLimited,
  'sync-sources/AddSyncSourcePage/Swedbank': swedbanksyncSourcesAddSync,
  'sync-sources/AddSyncSourcesSpeedDial/Swedbank': swedbanksyncSourcesAddSyncSpeed,
  'sync-sources/DeleteSyncSourcesPage/Swedbank': swedbanksyncSourcesDeleteSync,
  'sync-sources/Error/Swedbank': swedbanksyncSourcesError,
  'sync-sources/NoSyncSources/Swedbank': swedbanksyncSourcesNoSync,
  'sync-sources/PopularSyncSourcesList/Swedbank': swedbanksyncSourcesPopularSync,
  'sync-sources/SyncSourcesFetching/Swedbank': swedbanksyncSourcesFetching,
  'sync-sources/SyncSourcesList/Swedbank': swedbanksyncSourcesList,
  'sync-sources/TinkSyncPage/Swedbank': swedbanksyncSourcesTinkSync,
  'sync-sources/UpdateBankAuthsPage/Swedbank': swedbanksyncSourcesUpdateBank,
  'sync-sources/UpdateSyncSources/Swedbank': swedbanksyncSourcesUpdateSync,
  'sync-sources/UpdateSyncSourcesButton/Swedbank': swedbanksyncSourcesUpdateSyncButton,
  'sync-sources/SyncSourcesReminder/Swedbank': swedbanksyncSourcesReminder,
  'termination/TerminationFormPage/Swedbank': swedbankterminationFormPage,
  'termination/TerminationFormPage/CancelReasonSection/Swedbank': swedbankterminationFormPCancelReason,
  'termination/TerminationInstructionsPage/Swedbank': swedbankterminationInstructions,
  'TermsAndConditionsUpload/Swedbank': swedbanktermsAndCondition,
  'TypeformButton/Swedbank': swedbanktypeFormButton,
  'user-form-preview/Swedbank': swedbankuserFormPreview,
  'userFeedback/Swedbank': swedbankuserFeedback,
  'userSettings/UserDeletePage/Swedbank': swedbankuserDeleteSettings,
  'userSettings/UserDeleteVerifiedPage/Swedbank': swedbankuserDeleteVerifiedPage,
  'zendesk/Swedbank': swedbankzendesk,
};
