import verboseMinnaLogo from '@client/assets/images/logo.svg';
import minnaTechLogo from '@client/assets/images/minna-tech-logo.svg';
import type { AppContext } from '../modules/app-context';
import { AppType, Locale } from '../modules/app-context/constants';

// multiple-markets
export const getLogo = ({ appType }: Pick<AppContext, 'appType'>): string => {
  const minnaLogo = verboseMinnaLogo;

  switch (appType) {
    case AppType.Standalone:
    case AppType.Swedbank:
      return minnaLogo;
    default:
      return minnaTechLogo;
  }
};

export const getLogoAltText = ({ appType, locale }: Pick<AppContext, 'appType' | 'locale'>): string => {
  const englishName = 'Minna Tech';
  const swedishName = 'Minatjänster.se';

  if (locale === Locale.enGB) {
    return englishName;
  }

  switch (appType) {
    case AppType.Standalone:
    case AppType.Swedbank:
      return swedishName;

    default:
      return englishName;
  }
};
