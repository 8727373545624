import { reducer as intelligentGuide } from '@client/ducks/intelligent-guide';
import { reducer as manualAddSupplier } from '@client/ducks/manual-add-supplier';
import { reducer as searchEntryPoint } from '@client/ducks/search-entrypoint';
import user from '@client/ducks/user';
import { reducer as internationalization } from '@client/internationalization/duck';
import { reducer as appContext } from '@client/modules/app-context/duck';
import { cancellation } from '@client/modules/cancellation';
import { reducer as insights } from '@client/modules/InsightsPage/duck';
import { overviewReducer as overview } from '@client/modules/overview';
import { reducer as saveDesk } from '@client/modules/saveDesk/reducer';
import { settingsReducer } from '@client/modules/settings';
// @ts-ignore js import, remove this when the import is typed
import swedbankNotificationSettings from '@client/modules/settings/NotificationSettingsPage/SwedbankNotificationSettingsPage/duck';
import { subscriptionReducer as subscription } from '@client/modules/subscription';
import deleteUser from '@client/modules/userDeleted/delete-user';
import { reducer as zendesk } from '@client/modules/Zendesk/ducks/zendesk';
import { reducer as zendeskChat } from '@client/modules/Zendesk/ducks/zendesk-chat/zendesk-chat';
import { reducer as zendeskHelpCenter } from '@client/modules/Zendesk/ducks/zendesk-help-center/zendesk-help-center';

/**
 * Reducers that must be available at startup of the application.
 *
 * Prefer to lazy load reducers by using a lazy module (src/lazy-modules).
 * Currently lazy modules are in an experimental state, so all needed reducers are loaded on startup.
 */
export const startupReducers = {
  ...settingsReducer,
  appContext,
  cancellation,
  deleteUser,
  insights,
  intelligentGuide,
  internationalization,
  manualAddSupplier,
  overview,
  saveDesk,
  searchEntryPoint,
  subscription,
  swedbankNotificationSettings,
  user,
  zendesk,
  zendeskChat,
  zendeskHelpCenter,
};
