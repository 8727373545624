import aboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/nl-BE.json';
import bankAboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/BankAboutUsPage/nl-BE.json';
import insightPage from '@client/internationalization/localization-messages/overview/InsightsPage/nl-BE.json';
import insightPage_insightsCards_addon from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/AddonRecommendation/nl-BE.json';
import insightPage_bindingTimeEnding from '@client/internationalization/localization-messages/overview/InsightsPage/BindingTimeEnding/nl-BE.json';
import insightPage_insightsCards_discoverOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOngoing/nl-BE.json';
import insightPage_insightsCards_discoverOutcomeInsight from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOutcome/nl-BE.json';
import insightPage_insightsCards_newSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewSubscriptionFound/nl-BE.json';
import insightPage_insightsCards_personalRecommend from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/nl-BE.json';
import insightPage_insightsCards_saveDeskOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOngoingCard/nl-BE.json';
import insightPage_insightsCards_saveDeskOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOutcome/nl-BE.json';
import insightPage_subscriptionCostChanged from '@client/internationalization/localization-messages/overview/InsightsPage/SubscriptionCostChanged/nl-BE.json';
import insightPage_unresolvedSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnresolvedSubscription/nl-BE.json';
import overviewPage from '@client/internationalization/localization-messages/overview/OverviewPage/nl-BE.json';
import overviewAddManualSubscription from '@client/internationalization/localization-messages/overview/addManualSubscription/nl-BE.json';
import overviewBindingTimeBar from '@client/internationalization/localization-messages/overview/BindingTimeBar/nl-BE.json';
import overviewPage_didYouCancel from '@client/internationalization/localization-messages/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/nl-BE.json';
import badgeCtas from '@client/internationalization/localization-messages/BadgeCtas/nl-BE.json';
import bindingTimeNotice from '@client/internationalization/localization-messages/overview/BindingTimeNotice/nl-BE.json';
import onboardingDrawer from '@client/internationalization/localization-messages/overview/OnboardingDrawer/nl-BE.json';
import overviewPage_quickActionsV2 from '@client/internationalization/localization-messages/overview/QuickActionsV2/nl-BE.json';
import addonMobileRecommendationsPage from '@client/internationalization/localization-messages/addon/mobile/RecommendationsPage/nl-BE.json';
import addonMobileResultPage from '@client/internationalization/localization-messages/addon/mobile/ResultPage/nl-BE.json';
import authAuthStatusPage from '@client/internationalization/localization-messages/auth/AuthStatusPage/nl-BE.json';
import authBasicLoginPage from '@client/internationalization/localization-messages/auth/BasicLoginPage/nl-BE.json';
import authCreateAccountPage from '@client/internationalization/localization-messages/auth/CreateAccountPage/nl-BE.json';
import authOnboardingChooseBankPage from '@client/internationalization/localization-messages/auth/OnboardingChooseBankPage/nl-BE.json';
import authOnboardingEmailPage from '@client/internationalization/localization-messages/auth/OnboardingEmailPage/nl-BE.json';
import authPreLoginPage from '@client/internationalization/localization-messages/auth/PreLoginPage/nl-BE.json';
import authResetPasswordPage from '@client/internationalization/localization-messages/auth/ResetPasswordPage/nl-BE.json';
import authSyncSourcesError from '@client/internationalization/localization-messages/auth/SyncSourcesError/nl-BE.json';
import authSyncSourcesFetching from '@client/internationalization/localization-messages/auth/SyncSourcesFetching/nl-BE.json';
import badgeABTestCTAS from '@client/internationalization/localization-messages/BadgeABTestCtas/nl-BE.json';
import bankSyncCancelProduct from '@client/internationalization/localization-messages/bank-sync/CancelProductOnboardPages/nl-BE.json';
import bankSyncDrawerPages from '@client/internationalization/localization-messages/bank-sync/DrawerPages/nl-BE.json';
import bankSyncLoadingScreen from '@client/internationalization/localization-messages/bank-sync/LoadingScreen/nl-BE.json';
import bankSyncOnboardBankSync from '@client/internationalization/localization-messages/bank-sync/OnBoardBankSync/nl-BE.json';
import campaignIdSelect from '@client/internationalization/localization-messages/CampaignIdSelect/nl-BE.json';
import cancelCancellationOutcome from '@client/internationalization/localization-messages/cancel/CancellationOutcome/nl-BE.json';
import cancellationDetailsPage from '@client/internationalization/localization-messages/cancellation/cancellationDetailsPage/nl-BE.json';
import cancellationFormCancellationInstruction from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationInstructionsSection/nl-BE.json';
import cancellationFormCancellationTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationTerms/nl-BE.json';
import cancellationFormConfirmSection from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/ConfirmSection/nl-BE.json';
import cancellationFormLightUserTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/LightUserTerms/nl-BE.json';
import cancellationModalsContainer from '@client/internationalization/localization-messages/cancellation/CancellationModalsContainer/nl-BE.json';
import cancellationQuestionsFlow from '@client/internationalization/localization-messages/cancellation/CancellationQuestionFlow/nl-BE.json';
import cancellationReasonsDialog from '@client/internationalization/localization-messages/cancellation/CancellationReasonsDialog/nl-BE.json';
import cancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/nl-BE.json';
import cancellationStepper from '@client/internationalization/localization-messages/cancellation/CancellationStepper/nl-BE.json';
import cancellationDoYouWantToSaveDialog from '@client/internationalization/localization-messages/cancellation/DoYouWantToSaveDialog/nl-BE.json';
import cancellationIntelligentGuidePage from '@client/internationalization/localization-messages/cancellation/IntelligentGuidePage/nl-BE.json';
import cancellationNetflixCancellationInstructionsPage from '@client/internationalization/localization-messages/cancellation/NetflixCancellationInstructionsPage/nl-BE.json';
import cancellationSigningNemIdErrorPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdErrorPage/nl-BE.json';
import cancellationSigningNemIdSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdSigningPage/nl-BE.json';
import cancellationNoSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningPage/nl-BE.json';
import cancellationNoSigningServicePage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningServicePage/nl-BE.json';
import cancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/nl-BE.json';
import cancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/nl-BE.json';
import categories from '@client/internationalization/localization-messages/categories/nl-BE.json';
import categoryNames from '@client/internationalization/localization-messages/CategoryNames/CategoryNames/nl-BE.json';
import changeDataPlan from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplan/nl-BE.json';
import changeDataPlanCompletedPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanCompletedPage/nl-BE.json';
import changeDataPlanOrderFormPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanOrderFormPage/nl-BE.json';
import changeDataPlanPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanPage/nl-BE.json';
import changePaymentChangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-payment/ChangePlanServiceIntroductionDrawer/nl-BE.json';
import changePaymentIntelligentGuidePage from '@client/internationalization/localization-messages/change-payment/IntelligentGuidePage/nl-BE.json';
import changePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-plan/ChangePlanServiceIntroductionDrawer/nl-BE.json';
import changePlanIntelligentGuideExperimentPage from '@client/internationalization/localization-messages/change-plan/IntelligentGuideExperimentPage/nl-BE.json';
import changePlanIntelligentGuidePage from '@client/internationalization/localization-messages/change-plan/IntelligentGuidePage/nl-BE.json';
import changePlan from '@client/internationalization/localization-messages/ChangePlan/nl-BE.json';
import charityDonation from '@client/internationalization/localization-messages/charity-donation/nl-BE.json';
import complexContractGuideIntroPage from '@client/internationalization/localization-messages/complex-contract-guide/IntroPage/nl-BE.json';
import complexContractGuideLoanServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanServiceQuestionPage/nl-BE.json';
import complexContractGuideLoanTypeQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanTypeQuestionPage/nl-BE.json';
import complexContractGuideServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/ServiceQuestionPage/nl-BE.json';
import complexContractGuideShared from '@client/internationalization/localization-messages/complex-contract-guide/shared/nl-BE.json';
import complexContractGuideSuccessPage from '@client/internationalization/localization-messages/complex-contract-guide/SuccessPage/nl-BE.json';
import conditionsAndPoliciesMenu from '@client/internationalization/localization-messages/conditions-and-policies/ConditionsAndPoliciesMenu/nl-BE.json';
import conditionsAndPoliciesCookiesPage from '@client/internationalization/localization-messages/conditions-and-policies/CookiesPage/nl-BE.json';
import conditionsAndPoliciesSecurityPage from '@client/internationalization/localization-messages/conditions-and-policies/SecurityPage/nl-BE.json';
import conditionsAndPolicies from '@client/internationalization/localization-messages/conditions-and-policies/nl-BE.json';
import contractBindingPeriodDialog from '@client/internationalization/localization-messages/contract/bindingPeriodDialog/nl-BE.json';
import contractCancellationHistory from '@client/internationalization/localization-messages/contract/CancellationHistory/nl-BE.json';
import contractCancelledContract from '@client/internationalization/localization-messages/contract/CancelledContract/nl-BE.json';
import contractCancellationOutcome from '@client/internationalization/localization-messages/contract/ContractCancellationOutcome/nl-BE.json';
import contractCardMessage from '@client/internationalization/localization-messages/contract/ContractCardMessage/nl-BE.json';
import contractDetails from '@client/internationalization/localization-messages/contract/ContractDetails/nl-BE.json';
import contractDetailsPage from '@client/internationalization/localization-messages/contract/ContractDetailsPage/nl-BE.json';
import contractDoMoreComponent from '@client/internationalization/localization-messages/contract/DoMoreComponent/nl-BE.json';
import contractHistoryToolTip from '@client/internationalization/localization-messages/contract/HistoryTooltip/nl-BE.json';
import contractPriceHistory from '@client/internationalization/localization-messages/contract/PriceHistoryLolipop/nl-BE.json';
import contractSelectServicePage from '@client/internationalization/localization-messages/contract/SelectServicePageLolipop/nl-BE.json';
import contractSelectServicesList from '@client/internationalization/localization-messages/contract/SelectServicesListLolipop/nl-BE.json';
import contractSettingsButton from '@client/internationalization/localization-messages/contract/SettingsButtonLolipop/nl-BE.json';
import contractSettingsPage from '@client/internationalization/localization-messages/contract/SettingsPageLolipop/nl-BE.json';
import contractSubscriptionAlarm from '@client/internationalization/localization-messages/contract/SubscriptionAlarmLolipop/nl-BE.json';
import contractSubscriptionDetails from '@client/internationalization/localization-messages/contract/SubscriptionDetailsLolipop/nl-BE.json';
import contractSubscriptionReminder from '@client/internationalization/localization-messages/contract/SubscriptionReminder/nl-BE.json';
import contractSubscriptionReminderDialog from '@client/internationalization/localization-messages/contract/subscriptionReminderDialog/nl-BE.json';
import contractTransactionHistory from '@client/internationalization/localization-messages/contract/TransactionHistoryLolipop/nl-BE.json';
import contractUpdateCost from '@client/internationalization/localization-messages/contract/UpdateCostLolipop/nl-BE.json';
import discoverCategoryPage from '@client/internationalization/localization-messages/discover/CategoryPage/nl-BE.json';
import discoverCategoryProviderPage from '@client/internationalization/localization-messages/discover/CategoryProviderPage/nl-BE.json';
import discoverComponentsProductInfo from '@client/internationalization/localization-messages/discover/components/ProductInformationHeader/nl-BE.json';
import discoverDiscoverWidget from '@client/internationalization/localization-messages/discover/DiscoverWidget/nl-BE.json';
import discoverFakeCategoryPage from '@client/internationalization/localization-messages/discover/FakeCategoryPage/nl-BE.json';
import discoverFakeCategorySubscriptionPage from '@client/internationalization/localization-messages/discover/FakeCategorySubscriptionsPage/nl-BE.json';
import discoverFitnessCategoryFeedbackPage from '@client/internationalization/localization-messages/discover/FitnessCategoryFeedbackPage/nl-BE.json';
import discoverMarketplacePage from '@client/internationalization/localization-messages/discover/MarketplacePage/nl-BE.json';
import discoverMobileOrderSentPage from '@client/internationalization/localization-messages/discover/MobileOrderSentPage/nl-BE.json';
import discoverOrderProductPage from '@client/internationalization/localization-messages/discover/OrderProductPage/nl-BE.json';
import discoverProductRecommendations from '@client/internationalization/localization-messages/discover/ProductRecommendations/nl-BE.json';
import discoverSearchSubscriptionAllCategories from '@client/internationalization/localization-messages/discover/SearchSubscriptionAllCategoriesPage/nl-BE.json';
import discoverSearchSubscriptionPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionPage/nl-BE.json';
import discoverSearchSubscriptionResultPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionResultPage/nl-BE.json';
import dummy from '@client/internationalization/localization-messages/DUMMY/nl-BE.json';
import eSigning from '@client/internationalization/localization-messages/e-signing/nem-id/NemIdSigningPage/nl-BE.json';
import editProduct from '@client/internationalization/localization-messages/EditProduct/nl-BE.json';
import energyGuide from '@client/internationalization/localization-messages/EnergyGuide/OrderForm/nl-BE.json';
import errorHandling from '@client/internationalization/localization-messages/error-handling/LoggedOutPage/nl-BE.json';
import errorPageErrorPage from '@client/internationalization/localization-messages/errorPage/ErrorPage/nl-BE.json';
import errorScreen from '@client/internationalization/localization-messages/ErrorScreen/nl-BE.json';
import explore from '@client/internationalization/localization-messages/Explore/nl-BE.json';
import feedbackModal from '@client/internationalization/localization-messages/feedback-modal/nl-BE.json';
import fields from '@client/internationalization/localization-messages/fields/nl-BE.json';
import financialProductsLoansShow from '@client/internationalization/localization-messages/financial-products/loans/ShowNewFeatureLoanDialog/nl-BE.json';
import financialProductsSavingsInvestment from '@client/internationalization/localization-messages/financial-products/savings/InvestmentInfoCard/nl-BE.json';
import financialProductOurPartnersPage from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPage/nl-BE.json';
import financialProductOurPartnersPageNew from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPageNew/nl-BE.json';
import financialProductsSavingsOverview from '@client/internationalization/localization-messages/financial-products/savings/Overview/nl-BE.json';
import financialProductSavingsAfterCancellation from '@client/internationalization/localization-messages/financial-products/savings/SavingsAfterCancellation/nl-BE.json';
import financialProductsShowNewFeatureLoan from '@client/internationalization/localization-messages/financial-products/ShowNewFeatureLoanDialog/nl-BE.json';
import financialProductsSwedBankConsolidate from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/HowItWorksPage/nl-BE.json';
import financialSwedBankConsolidateIntro from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/IntroPage/nl-BE.json';
import financialProductLoanApplication from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/LoanApplicationStatus/nl-BE.json';
import financialProductsOfferPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OfferPage/nl-BE.json';
import financialProductOrderCompleted from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderCompletedPage/nl-BE.json';
import financialProductOrderPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderPage/nl-BE.json';
import financialProductSelectContract from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectContractsPage/nl-BE.json';
import financialProductsSelectPrincipal from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectPrincipalPage/nl-BE.json';
import financialProductRepaymentPeriod from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/nl-BE.json';
import generalIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/nl-BE.json';
import generalSelectServices from '@client/internationalization/localization-messages/general/SelectServicesPage/nl-BE.json';
import genericActionsPage from '@client/internationalization/localization-messages/genericActions/GenericActionsPage/nl-BE.json';
import genericActionsSelectServices from '@client/internationalization/localization-messages/genericActions/SelectServicesPage/nl-BE.json';
import genericActionsSubscriptionCancel from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/Cancel/nl-BE.json';
import genericActionsSubscriptionsChangePayment from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/ChangePayment/nl-BE.json';
import guidesContractDetailsAlternative from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/AlternativesSection/nl-BE.json';
import guidesDealBanner from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/DealBanner/nl-BE.json';
import guidesPaymentMethod from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/PaymentMethodsCard/nl-BE.json';
import hack7CancelPage from '@client/internationalization/localization-messages/Hack7CancelPage/nl-BE.json';
import header from '@client/internationalization/localization-messages/Header/nl-BE.json';
import howToCancelListPage from '@client/internationalization/localization-messages/HowToCancelListPage/nl-BE.json';
import howToCancelPage from '@client/internationalization/localization-messages/HowToCancelPage/nl-BE.json';
import improveOrderPage from '@client/internationalization/localization-messages/improve/flowgen/OrderPage/nl-BE.json';
import improveOrderSentPage from '@client/internationalization/localization-messages/improve/flowgen/OrderSentPage/nl-BE.json';
import improveOtherAlternativePage from '@client/internationalization/localization-messages/improve/flowgen/OtherAlternativesPage/nl-BE.json';
import improvePostalCodeInput from '@client/internationalization/localization-messages/improve/flowgen/postalCodeInput/nl-BE.json';
import improveResultPage from '@client/internationalization/localization-messages/improve/flowgen/ResultPage/nl-BE.json';
import improveFlowgen from '@client/internationalization/localization-messages/improve/flowgen/nl-BE.json';
import insightPageInsightsCardsCancelOutcome from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationOutcome/nl-BE.json';
import insightPageInsightsCardsCancelUpdate from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationUpdate/nl-BE.json';
import insightPageOnboardingInsightCard from '@client/internationalization/localization-messages/InsightsPage/OnboardingInsightCard/nl-BE.json';
import intervalToString from '@client/internationalization/localization-messages/interval-to-string/nl-BE.json';
import landingPage from '@client/internationalization/localization-messages/LandingPage/nl-BE.json';
import loadingScreen from '@client/internationalization/localization-messages/LoadingScreen/nl-BE.json';
import loanApplicationsDetailsPage from '@client/internationalization/localization-messages/loan-applications/DetailsPage/nl-BE.json';
import loanApplicationsLoadingError from '@client/internationalization/localization-messages/loan-applications/LoadingError/nl-BE.json';
import loanApplicationsMenuDrawer from '@client/internationalization/localization-messages/loan-applications/MenuDrawer/nl-BE.json';
import loanApplicationsOverview from '@client/internationalization/localization-messages/loan-applications/Overview/nl-BE.json';
import loginPage from '@client/internationalization/localization-messages/LoginPage/nl-BE.json';
import manageOrder from '@client/internationalization/localization-messages/ManageOrder/nl-BE.json';
import manualAddContractPage from '@client/internationalization/localization-messages/ManualAddContractPage/nl-BE.json';
import manualAddContractPricingPage from '@client/internationalization/localization-messages/ManualContractPricingPage/nl-BE.json';
import menu from '@client/internationalization/localization-messages/Menu/nl-BE.json';
import menuPage from '@client/internationalization/localization-messages/MenuPage/nl-BE.json';
import movingBundledCancellationForm from '@client/internationalization/localization-messages/moving/BundledCancellationFromPage/nl-BE.json';
import movingBundledCancellationPage from '@client/internationalization/localization-messages/moving/BundledCancellationPage/nl-BE.json';
import movingBundledSwitchOrderForm from '@client/internationalization/localization-messages/moving/BundledSwitchOrderFormPage/nl-BE.json';
import movingBundledSwitchPage from '@client/internationalization/localization-messages/moving/BundledSwitchSwitchPage/nl-BE.json';
import thanksSwitchPage from '@client/internationalization/localization-messages/moving/ThanksSwitchPage/nl-BE.json';
import movingGuide from '@client/internationalization/localization-messages/movingGuide/nl-BE.json';
import movingGuideMovingDetails from '@client/internationalization/localization-messages/movingGuide/MovingDetailsPage/nl-BE.json';
import movingGuideOneLastThingPage from '@client/internationalization/localization-messages/movingGuide/OneLastThingPage/nl-BE.json';
import negotiateContractCompletePage from '@client/internationalization/localization-messages/negotiate/NegotiateContractCompletePage/nl-BE.json';
import negotiateGuide from '@client/internationalization/localization-messages/negotiate/NegotiateGuide/nl-BE.json';
import negotiationOngoingCard from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationCard/nl-BE.json';
import negotiationOngoingStepper from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationStepper/nl-BE.json';
import notificationOptInPage from '@client/internationalization/localization-messages/NotificationOptInPage/nl-BE.json';
import onboardingPSD2Consent from '@client/internationalization/localization-messages/onboarding/PSD2ConsentPage/nl-BE.json';
import onboardingPSD2Primer from '@client/internationalization/localization-messages/onboarding/PSD2PrimerPage/nl-BE.json';
import onboardingBDSyncConnecting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryConnecting/nl-BE.json';
import onboardingBDSyncError from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryErrorPage/nl-BE.json';
import onboardingBDSyncFetch from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryFetchPage/nl-BE.json';
import onboardingBDSyncSuccess from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/nl-BE.json';
import onboardingBDSyncWaiting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/nl-BE.json';
import onboardingCancelProduct from '@client/internationalization/localization-messages/onboarding-cancel-product/OnboardingCancelProductPages/nl-BE.json';
import onboardingPsd2WelcomePage from '@client/internationalization/localization-messages/onboarding-psd2/WelcomePage/nl-BE.json';
import onboardingPsd2Cards from '@client/internationalization/localization-messages/onboarding-psd2/Cards/nl-BE.json';
import onboardingPsd2ConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/nl-BE.json';
import onboardingPsd2OpBankConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPage/nl-BE.json';
import onboardingPsd2OpBankConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPageV2/nl-BE.json';
import onboardingPsd2ConsentPageDanskeBank from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/DanskeBankConsentPage/nl-BE.json';
import onboardingPsd2RenewConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPage/nl-BE.json';
import onboardingPsd2RenewConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPageV2/nl-BE.json';
import orderedSubscriptionOngoing from '@client/internationalization/localization-messages/orderedSubscription/OngoingOrderCard/nl-BE.json';
import orderedSubscriptionOrderedEnergy from '@client/internationalization/localization-messages/orderedSubscription/OrderedEnergyProductInfo/nl-BE.json';
import orderedSubscriptionOrderedMobile from '@client/internationalization/localization-messages/orderedSubscription/OrderedMobileProductInfo/nl-BE.json';
import orderedSubscriptionDetails from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetails/nl-BE.json';
import orderedSubscriptionDetailsPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/nl-BE.json';
import orderedSubscriptionInfoCard from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/nl-BE.json';
import orderedSubscriptionPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionPage/nl-BE.json';
import orderedSubscriptionOrderStepper from '@client/internationalization/localization-messages/orderedSubscription/OrderStepper/nl-BE.json';
import orderedSubscServiceProviderInfo from '@client/internationalization/localization-messages/orderedSubscription/ServiceProviderInfoCard/nl-BE.json';
import orderingSubscriptionTerms from '@client/internationalization/localization-messages/ordering-subscription/common/TermsAndConditionsPage/nl-BE.json';
import orderingSubscriptionCompleted from '@client/internationalization/localization-messages/ordering-subscription/common/OrderCompletedPage/nl-BE.json';
import orderingBroadbandAddressPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAddressPage/nl-BE.json';
import orderingBroadbandAllAlternatives from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/nl-BE.json';
import orderingBroadbandContract from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideContractCostPage/nl-BE.json';
import orderingBroadbandIntroPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideIntroPage/nl-BE.json';
import orderingBroadbandNoResultPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideNoResultPage/nl-BE.json';
import orderingBroadbandSpeedPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideSpeedPage/nl-BE.json';
import orderingBroadbandOptimizeOrder from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandOrderFormPage/nl-BE.json';
import orderingBroadbandOptimizeProduct from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/nl-BE.json';
import orderingBroadbandOptimizeProductCosts from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/nl-BE.json';
import orderingElectricityOrder from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPage/nl-BE.json';
import orderingElectricityOrderVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/nl-BE.json';
import orderingElectricityGuidePrice from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/nl-BE.json';
import orderingElectricityGuideAlternatives from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/nl-BE.json';
import orderingElectricityGuideBinding from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/nl-BE.json';
import orderingElectricityGuideBindingTime from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/nl-BE.json';
import orderingElectricityGuideEnergy from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/nl-BE.json';
import orderingElectricityGuideExplanation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/nl-BE.json';
import orderingElectricityGuideFlow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/nl-BE.json';
import orderingElectricityGuideHow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/nl-BE.json';
import orderingElectricityGuideInformation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/nl-BE.json';
import orderingElectricityGuideIntro from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/nl-BE.json';
import orderingElectricityGuideLivingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/nl-BE.json';
import orderingElectricityGuidePostalCodePage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/nl-BE.json';
import orderingElectricityGuidePreferences from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/nl-BE.json';
import orderingElectricityGuidePricingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/nl-BE.json';
import orderingElectricityGuideProductPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/nl-BE.json';
import orderingElectricityGuideSummaryPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/nl-BE.json';
import orderingElectricityGuideTrading from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/nl-BE.json';
import orderingElectricityGuideUsage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/nl-BE.json';
import orderingElectricityGuideUsageResult from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/nl-BE.json';
import orderingElectricityGuideUsageResultVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/nl-BE.json';
import orderingElectricityGuidePostalCode from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/nl-BE.json';
import orderingSubscriptionIndependentOrder from '@client/internationalization/localization-messages/ordering-subscription/independent/OrderCompletedPage/nl-BE.json';
import ordersOverview from '@client/internationalization/localization-messages/OrdersOverview/nl-BE.json';
import overviewChangePlanService from '@client/internationalization/localization-messages/overview/ChangePlanServiceIntroductionDrawer/nl-BE.json';
import overviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/nl-BE.json';
import overviewDeprecatedInsights from '@client/internationalization/localization-messages/overview/DeprecatedInsightsPage/OnboardingInsightCard/nl-BE.json';
import overviewDiscoverPage from '@client/internationalization/localization-messages/overview/DiscoverPage/nl-BE.json';
import overviewGenericAction from '@client/internationalization/localization-messages/overview/GenericActions/nl-BE.json';
import overviewImprovementPage from '@client/internationalization/localization-messages/overview/ImprovementPage/nl-BE.json';
import overviewImproveV2 from '@client/internationalization/localization-messages/overview/ImproveV2/nl-BE.json';
import overviewInsightsCardsActionRating from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ActionRating/nl-BE.json';
import overviewInsightsCardsCancellationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/CancellationUpdate/nl-BE.json';
import overviewInsightsCardsChangeData from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ChangeDataplanUpdate/nl-BE.json';
import overviewInsightsCardsIndependent from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/IndependentOrderUpdate/nl-BE.json';
import overviewInsightsCardConfirmCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/nl-BE.json';
import overviewContactUsButton from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ContactUsButton/nl-BE.json';
import overviewInsightsCardsMonthlyCheck from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/MonthlyChecklist/nl-BE.json';
import overviewInsightsCardsNegotiationOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationOutcome/nl-BE.json';
import overviewInsightsCardsNegotiationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationUpdate/nl-BE.json';
import overviewInsightsCardsProvider from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewProviderOffersCard/nl-BE.json';
import overviewInsightsCardsSaveDeskFeedback from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/nl-BE.json';
import overviewInsightCardsSaveDeskRequested from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOrderRequested/nl-BE.json';
import overviewInsightsCardsSaveDeskPredefined from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/nl-BE.json';
import overviewInsightsCardsSaveDeskResponse from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskResponseCard/nl-BE.json';
import overviewInsightsCardsSwitchOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchOutcome/nl-BE.json';
import overviewInsightsCardsSwitchUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchUpdate/nl-BE.json';
import overviewInsightsCardsThankYou from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ThankYouCard/nl-BE.json';
import overviewInsightsCardsTryCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/TryCancellationAgainButton/nl-BE.json';
import overviewInsightsCardsUnreviewed from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnreviewedAction/nl-BE.json';
import overviewInsightsCardsOnboarding from '@client/internationalization/localization-messages/overview/InsightsPage/OnboardingInsightCard/nl-BE.json';
import overviewInsightsPrimerCardV2 from '@client/internationalization/localization-messages/overview/InsightsPage/PrimerCardV2/nl-BE.json';
import overviewLoadingWithCogs from '@client/internationalization/localization-messages/overview/LoadingWithCogs/nl-BE.json';
import overviewManageContract from '@client/internationalization/localization-messages/overview/ManageContractSection/SwitchProgressWithoutCancelStepper/nl-BE.json';
import overviewMonthlyChecklist from '@client/internationalization/localization-messages/overview/MonthlyChecklist/nl-BE.json';
import overviewMoreInfoNeeded from '@client/internationalization/localization-messages/overview/more-information-needed/nl-BE.json';
import overviewOnboardingCards from '@client/internationalization/localization-messages/overview/OnboardingCards/nl-BE.json';
import overviewQuickActionsCancel from '@client/internationalization/localization-messages/overview/QuickActionsCancel/nl-BE.json';
import overviewQuickActionsCancelV2 from '@client/internationalization/localization-messages/overview/QuickActionsCancelV2/nl-BE.json';
import overviewQuickActionsImprove from '@client/internationalization/localization-messages/overview/QuickActionsImprove/nl-BE.json';
import overviewQuickActionsImproveV2 from '@client/internationalization/localization-messages/overview/QuickActionsImproveV2/nl-BE.json';
import overviewSubscriptionFeedback from '@client/internationalization/localization-messages/overview/SubscriptionFeedback/nl-BE.json';
import overviewSubscriptionSearch from '@client/internationalization/localization-messages/overview/SubscriptionSearch/nl-BE.json';
import overviewSubscriptionSearchComponent from '@client/internationalization/localization-messages/overview/SubscriptionSearchOverviewComponent/nl-BE.json';
import waysToSave from '@client/internationalization/localization-messages/overview/WaysToSave/nl-BE.json';
import overview from '@client/internationalization/localization-messages/overview/nl-BE.json';
import overviewBottomNavigation from '@client/internationalization/localization-messages/overviewBottomNavigation/nl-BE.json';
import pauseIntelligentGuide from '@client/internationalization/localization-messages/pause/IntelligentGuidePage/nl-BE.json';
import pauseSubscriptionPause from '@client/internationalization/localization-messages/pause/SubscriptionPausePage/nl-BE.json';
import provideFeedbackPage from '@client/internationalization/localization-messages/ProvideFeedbackPage/nl-BE.json';
import providerPortalDatepicker from '@client/internationalization/localization-messages/provider-portal-datepicker/DatePicker/nl-BE.json';
import providerPortalSnackback from '@client/internationalization/localization-messages/provider-portal-snackback/Snackbar/nl-BE.json';
import registrationPage from '@client/internationalization/localization-messages/RegistrationPage/nl-BE.json';
import retentionOfferCard from '@client/internationalization/localization-messages/retention-offer/SuccessfulRetentionOfferCard/nl-BE.json';
import saveDesk from '@client/internationalization/localization-messages/save-desk/nl-BE.json';
import saveDeskOngoingCard from '@client/internationalization/localization-messages/save-desk/OngoingSaveDeskCard/nl-BE.json';
import saveDeskOrderSent from '@client/internationalization/localization-messages/save-desk/OrderSentPage/nl-BE.json';
import savingsDoYouWantToSave from '@client/internationalization/localization-messages/savings/DoYouWantToSaveDialog/nl-BE.json';
import savingsAfterCancellation from '@client/internationalization/localization-messages/savings/SavingsAfterCancellation/nl-BE.json';
import selectProductPage from '@client/internationalization/localization-messages/SelectProductPage/nl-BE.json';
import settingsNotificationSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/nl-BE.json';
import settingsNotificationPushSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/PushNotificationInstructions/nl-BE.json';
import settingsPersonalDetails from '@client/internationalization/localization-messages/settings/PersonalDetailsPage/nl-BE.json';
import settingsProfile from '@client/internationalization/localization-messages/settings/ProfilingSettingsPage/nl-BE.json';
import settingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/nl-BE.json';
import settingsUser from '@client/internationalization/localization-messages/settings/UserSettingsPage/nl-BE.json';
import settingsViewGdprData from '@client/internationalization/localization-messages/settings/ViewGdprDataPage/nl-BE.json';
import settingsSwedbank from '@client/internationalization/localization-messages/settings-swedbank/SwedbankNotificationSettingsPage/nl-BE.json';
import sharedComponentsEmoji from '@client/internationalization/localization-messages/shared-components/EmojiRating/nl-BE.json';
import sharedComponentsUpdateBankApp from '@client/internationalization/localization-messages/shared-components/UpdateBankApp/nl-BE.json';
import sharedComponentsYouSavedComponent from '@client/internationalization/localization-messages/shared-components/YouSavedComponent/nl-BE.json';
import showOnboardingFeatures from '@client/internationalization/localization-messages/ShowOnboardingFeaturesComponent/nl-BE.json';
import signOutSuccessPage from '@client/internationalization/localization-messages/SignoutSuccessPage/nl-BE.json';
import standardComponentsCurrency from '@client/internationalization/localization-messages/standard-components/CurrencyTextField/nl-BE.json';
import standardComponentsDate from '@client/internationalization/localization-messages/standard-components/DatePicker/nl-BE.json';
import standardComponentsEmail from '@client/internationalization/localization-messages/standard-components/EmailField/nl-BE.json';
import standardComponentsGuide from '@client/internationalization/localization-messages/standard-components/GuidePage/nl-BE.json';
import standardComponentsMobile from '@client/internationalization/localization-messages/standard-components/MobilePhoneNumberField/nl-BE.json';
import standardComponentsPassword from '@client/internationalization/localization-messages/standard-components/PasswordField/nl-BE.json';
import standardComponentsPersonal from '@client/internationalization/localization-messages/standard-components/PersonalNumberField/nl-BE.json';
import standardComponentsPostalCode from '@client/internationalization/localization-messages/standard-components/PostalCodeField/nl-BE.json';
import standardComponentsSnackbar from '@client/internationalization/localization-messages/standard-components/PostalCodeField/nl-BE.json';
import subscriptionCancellationMeasure from '@client/internationalization/localization-messages/subscription/CancellationMeasurement/nl-BE.json';
import subscriptionComponentsSelectable from '@client/internationalization/localization-messages/subscription/components/SelectableServiceList/nl-BE.json';
import subscriptionRemoveSubscription from '@client/internationalization/localization-messages/subscription/RemoveSubscriptionDialog/nl-BE.json';
import subscriptionSelectLoanService from '@client/internationalization/localization-messages/subscription/SelectLoanServicesPage/nl-BE.json';
import subscriptionSelectLoanTransaction from '@client/internationalization/localization-messages/subscription/SelectLoanTransactionTypePage/nl-BE.json';
import subscriptionSelectServices from '@client/internationalization/localization-messages/subscription/SelectServicesPage/nl-BE.json';
import subscriptionPage from '@client/internationalization/localization-messages/subscription/SubscriptionPage/nl-BE.json';
import subscriptionSettingsPage from '@client/internationalization/localization-messages/subscription/SubscriptionSettingsPage/nl-BE.json';
import subscriptionUpdateCostPage from '@client/internationalization/localization-messages/subscription/SubscriptionUpdateCostPage/nl-BE.json';
import subscriptionSwitchMeasurement from '@client/internationalization/localization-messages/subscription/SwitchMeasurement/nl-BE.json';
import subscriptionUnresolvedGuide from '@client/internationalization/localization-messages/subscription/UnresolvedGuide/nl-BE.json';
import subscriptionWhatsWrong from '@client/internationalization/localization-messages/subscription/WhatsWrongDialog/nl-BE.json';
import subscriptionAlarmMore from '@client/internationalization/localization-messages/subscription-alarm/MorePage/nl-BE.json';
import subscriptionAlarmPage from '@client/internationalization/localization-messages/subscription-alarm/SubscriptionAlarmPage/nl-BE.json';
import subscriptionHubActions from '@client/internationalization/localization-messages/subscription-hub/PastActions/nl-BE.json';
import subscriptionHub from '@client/internationalization/localization-messages/subscriptionHub/subscriptionHub/nl-BE.json';
import supplierPortalChange from '@client/internationalization/localization-messages/supplier-portal-change/nl-BE.json';
import supplierPortalSaveDesk from '@client/internationalization/localization-messages/supplier-portal-save-desk/nl-BE.json';
import swedbankOverviewSync from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/nl-BE.json';
import swedenPhoneCallsLimited from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/nl-BE.json';
import syncSourcesAddSync from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcePage/nl-BE.json';
import syncSourcesAddSyncSpeed from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcesSpeedDial/nl-BE.json';
import syncSourcesDeleteSync from '@client/internationalization/localization-messages/sync-sources/DeleteSyncSourcesPage/nl-BE.json';
import syncSourcesError from '@client/internationalization/localization-messages/sync-sources/Error/nl-BE.json';
import syncSourcesNoSync from '@client/internationalization/localization-messages/sync-sources/NoSyncSources/nl-BE.json';
import syncSourcesPopularSync from '@client/internationalization/localization-messages/sync-sources/PopularSyncSourcesList/nl-BE.json';
import syncSourcesFetching from '@client/internationalization/localization-messages/sync-sources/SyncSourcesFetching/nl-BE.json';
import syncSourcesList from '@client/internationalization/localization-messages/sync-sources/SyncSourcesList/nl-BE.json';
import syncSourcesReminder from '@client/internationalization/localization-messages/sync-sources/SyncSourcesReminder/nl-BE.json';
import syncSourcesTinkSync from '@client/internationalization/localization-messages/sync-sources/TinkSyncPage/nl-BE.json';
import syncSourcesUpdateBank from '@client/internationalization/localization-messages/sync-sources/UpdateBankAuthsPage/nl-BE.json';
import syncSourcesUpdateSync from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSources/nl-BE.json';
import syncSourcesUpdateSyncButton from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSourcesButton/nl-BE.json';
import terminationFormPage from '@client/internationalization/localization-messages/termination/TerminationFormPage/nl-BE.json';
import terminationFormPCancelReason from '@client/internationalization/localization-messages/termination/TerminationFormPage/CancelReasonSection/nl-BE.json';
import terminationInstructions from '@client/internationalization/localization-messages/termination/TerminationInstructionsPage/nl-BE.json';
import termsAndCondition from '@client/internationalization/localization-messages/TermsAndConditionsUpload/nl-BE.json';
import typeFormButton from '@client/internationalization/localization-messages/TypeformButton/nl-BE.json';
import userFormPreview from '@client/internationalization/localization-messages/user-form-preview/nl-BE.json';
import userFeedback from '@client/internationalization/localization-messages/userFeedback/nl-BE.json';
import userDeleteSettings from '@client/internationalization/localization-messages/userSettings/UserDeletePage/nl-BE.json';
import userDeleteVerifiedPage from '@client/internationalization/localization-messages/userSettings/UserDeleteVerifiedPage/nl-BE.json';
import zendesk from '@client/internationalization/localization-messages/zendesk/nl-BE.json';

export const nlBETranslations = {
  aboutUsPage: aboutUsPage,
  'AboutUsPage/BankAboutUsPage': bankAboutUsPage,
  'addon/mobile/RecommendationsPage': addonMobileRecommendationsPage,
  'addon/mobile/ResultPage': addonMobileResultPage,
  'auth/AuthStatusPage': authAuthStatusPage,
  'auth/BasicLoginPage': authBasicLoginPage,
  'auth/CreateAccountPage': authCreateAccountPage,
  'auth/LoginPage': authCreateAccountPage,
  'auth/OnboardingChooseBankPage': authOnboardingChooseBankPage,
  'auth/OnboardingEmailPage': authOnboardingEmailPage,
  'auth/PreLoginPage': authPreLoginPage,
  'auth/ResetPasswordPage': authResetPasswordPage,
  'auth/SyncSourcesError': authSyncSourcesError,
  'auth/SyncSourcesFetching': authSyncSourcesFetching,
  BadgeABTestCtas: badgeABTestCTAS,
  BadgeCtas: badgeCtas,
  'bank-sync/CancelProductOnboardPages': bankSyncCancelProduct,
  'bank-sync/DrawerPages': bankSyncDrawerPages,
  'bank-sync/LoadingScreen': bankSyncLoadingScreen,
  'bank-sync/OnBoardBankSync': bankSyncOnboardBankSync,
  CampaignIdSelect: campaignIdSelect,
  'cancel/CancellationOutcome': cancelCancellationOutcome,
  'cancellation/cancellationDetailsPage': cancellationDetailsPage,
  'cancellation/CancellationFormPage/CancellationInstructionsSection': cancellationFormCancellationInstruction,
  'cancellation/CancellationFormPage/CancellationTerms': cancellationFormCancellationTerms,
  'cancellation/CancellationFormPage/ConfirmSection': cancellationFormConfirmSection,
  'cancellation/CancellationFormPage/LightUserTerms': cancellationFormLightUserTerms,
  'cancellation/CancellationModalsContainer': cancellationModalsContainer,
  'cancellation/CancellationQuestionFlow': cancellationQuestionsFlow,
  'cancellation/CancellationReasonsDialog': cancellationReasonsDialog,
  'cancellation/CancellationSentPage': cancellationSentPage,
  'cancellation/CancellationStepper': cancellationStepper,
  'cancellation/DoYouWantToSaveDialog': cancellationDoYouWantToSaveDialog,
  'cancellation/IntelligentGuidePage': cancellationIntelligentGuidePage,
  'cancellation/NetflixCancellationInstructionsPage': cancellationNetflixCancellationInstructionsPage,
  'cancellation/signing/NemIdErrorPage': cancellationSigningNemIdErrorPage,
  'cancellation/signing/NemIdSigningPage': cancellationSigningNemIdSigningPage,
  'cancellation/signing/NoSigningPage': cancellationNoSigningPage,
  'cancellation/signing/NoSigningServicePage': cancellationNoSigningServicePage,
  CancellationPortal: cancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement': cancellationsCancellationPortalAgreement,
  categories: categories,
  'CategoryNames/CategoryNames': categoryNames,
  'change-dataplan/ChangeDataplan': changeDataPlan,
  'change-dataplan/ChangeDataplanCompletedPage': changeDataPlanCompletedPage,
  'change-dataplan/ChangeDataplanOrderFormPage': changeDataPlanOrderFormPage,
  'change-dataplan/ChangeDataplanPage': changeDataPlanPage,
  'change-payment/ChangePlanServiceIntroductionDrawer': changePaymentChangePlanServiceIntroductionDrawer,
  'change-payment/IntelligentGuidePage': changePaymentIntelligentGuidePage,
  'change-plan/ChangePlanServiceIntroductionDrawer': changePlanServiceIntroductionDrawer,
  'change-plan/IntelligentGuideExperimentPage': changePlanIntelligentGuideExperimentPage,
  'change-plan/IntelligentGuidePage': changePlanIntelligentGuidePage,
  ChangePlan: changePlan,
  'charity-donation': charityDonation,
  'complex-contract-guide/IntroPage': complexContractGuideIntroPage,
  'complex-contract-guide/LoanServiceQuestionPage': complexContractGuideLoanServiceQuestionPage,
  'complex-contract-guide/LoanTypeQuestionPage': complexContractGuideLoanTypeQuestionPage,
  'complex-contract-guide/ServiceQuestionPage': complexContractGuideServiceQuestionPage,
  'complex-contract-guide/Shared': complexContractGuideShared,
  'complex-contract-guide/SuccessPage': complexContractGuideSuccessPage,
  'conditions-and-policies/ConditionsAndPoliciesMenu': conditionsAndPoliciesMenu,
  'conditions-and-policies/CookiesPage': conditionsAndPoliciesCookiesPage,
  'conditions-and-policies/SecurityPage': conditionsAndPoliciesSecurityPage,
  'conditions-and-policies': conditionsAndPolicies,
  'contract/bindingPeriodDialog': contractBindingPeriodDialog,
  'contract/CancellationHistory': contractCancellationHistory,
  'contract/CancelledContract': contractCancelledContract,
  'contract/ContractCancellationOutcome': contractCancellationOutcome,
  'contract/ContractCardMessage': contractCardMessage,
  'contract/ContractDetails': contractDetails,
  'contract/ContractDetailsPage': contractDetailsPage,
  'contract/DoMoreComponent': contractDoMoreComponent,
  'contract/HistoryToolTip': contractHistoryToolTip,
  'contract/PriceHistoryLolipop': contractPriceHistory,
  'contract/SelectServicePageLolipop': contractSelectServicePage,
  'contract/SelectServicesListLolipop': contractSelectServicesList,
  'contract/SettingsButtonLolipop': contractSettingsButton,
  'contract/SettingsPageLolipop': contractSettingsPage,
  'contract/SubscriptionAlarmLolipop': contractSubscriptionAlarm,
  'contract/SubscriptionDetailsLolipop': contractSubscriptionDetails,
  'contract/SubscriptionReminder': contractSubscriptionReminder,
  'contract/subscriptionReminderDialog': contractSubscriptionReminderDialog,
  'contract/TransactionHistoryLolipop': contractTransactionHistory,
  'contract/UpdateCostLolipop': contractUpdateCost,
  'discover/CategoryPage': discoverCategoryPage,
  'discover/CategoryProviderPage': discoverCategoryProviderPage,
  'discover/components/ProductInformationHeader': discoverComponentsProductInfo,
  'discover/DiscoverWidget': discoverDiscoverWidget,
  'discover/FakeCategoryPage': discoverFakeCategoryPage,
  'discover/FakeCategorySubscriptionsPage': discoverFakeCategorySubscriptionPage,
  'discover/FitnessCategoryFeedbackPage': discoverFitnessCategoryFeedbackPage,
  'discover/MarketplacePage': discoverMarketplacePage,
  'discover/MobileOrderSentPage': discoverMobileOrderSentPage,
  'discover/OrderProductPage': discoverOrderProductPage,
  'discover/ProductRecommendations': discoverProductRecommendations,
  'discover/SearchSubscriptionAllCategoriesPage': discoverSearchSubscriptionAllCategories,
  'discover/SearchSubscriptionPage': discoverSearchSubscriptionPage,
  'discover/SearchSubscriptionResultPage': discoverSearchSubscriptionResultPage,
  DUMMY: dummy,
  'e-signing/nem-id/NemIdSigningPage': eSigning,
  EditProduct: editProduct,
  'EnergyGuide/OrderForm': energyGuide,
  'error-handling/LoggedOutPage': errorHandling,
  'errorPage/ErrorPage': errorPageErrorPage,
  ErrorScreen: errorScreen,
  Explore: explore,
  'feedback-modal': feedbackModal,
  fields: fields,
  'financial-products/loans/ShowNewFeatureLoanDialog': financialProductsLoansShow,
  'financial-products/savings/InvestmentInfoCard': financialProductsSavingsInvestment,
  'financial-products/savings/OurPartnersPage': financialProductOurPartnersPage,
  'financial-products/savings/OurPartnersPageNew': financialProductOurPartnersPageNew,
  'financial-products/savings/Overview': financialProductsSavingsOverview,
  'financial-products/savings/SavingsAfterCancellation': financialProductSavingsAfterCancellation,
  'financial-products/ShowNewFeatureLoanDialog': financialProductsShowNewFeatureLoan,
  'financial-products/swedbank-consolidate-loans/HowItWorksPage': financialProductsSwedBankConsolidate,
  'financial-products/swedbank-consolidate-loans/IntroPage': financialSwedBankConsolidateIntro,
  'financial-products/swedbank-consolidate-loans/LoanApplicationStatus': financialProductLoanApplication,
  'financial-products/swedbank-consolidate-loans/OfferPage': financialProductsOfferPage,
  'financial-products/swedbank-consolidate-loans/OrderCompletedPage': financialProductOrderCompleted,
  'financial-products/swedbank-consolidate-loans/OrderPage': financialProductOrderPage,
  'financial-products/swedbank-consolidate-loans/SelectContractsPage': financialProductSelectContract,
  'financial-products/swedbank-consolidate-loans/SelectPrincipalPage': financialProductsSelectPrincipal,
  'financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage': financialProductRepaymentPeriod,
  'general/IntelligentGuide': generalIntelligentGuide,
  'general/SelectServicesPage': generalSelectServices,
  'genericActions/GenericActionsPage': genericActionsPage,
  'genericActions/SelectServicesPage': genericActionsSelectServices,
  'genericActions/SubscriptionSearchPage/Cancel': genericActionsSubscriptionCancel,
  'genericActions/SubscriptionSearchPage/ChangePayment': genericActionsSubscriptionsChangePayment,
  'guides/GuideContractDetailsPage/AlternativesSection': guidesContractDetailsAlternative,
  'guides/GuideContractDetailsPage/DealBanner': guidesDealBanner,
  'guides/GuideContractDetailsPage/PaymentMethodsCard': guidesPaymentMethod,
  Hack7CancelPage: hack7CancelPage,
  Header: header,
  HowToCancelListPage: howToCancelListPage,
  HowToCancelPage: howToCancelPage,
  'improve/flowgen': improveFlowgen,
  'improve/flowgen/OrderPage': improveOrderPage,
  'improve/flowgen/OrderSentPage': improveOrderSentPage,
  'improve/flowgen/OtherAlternativesPage': improveOtherAlternativePage,
  'improve/flowgen/PostalCodeInput': improvePostalCodeInput,
  'improve/flowgen/ResultPage': improveResultPage,
  'InsightsPage/InsightCards/CancellationOutcome': insightPageInsightsCardsCancelOutcome,
  'InsightsPage/InsightCards/CancellationUpdate': insightPageInsightsCardsCancelUpdate,
  'InsightsPage/OnboardingInsightCard': insightPageOnboardingInsightCard,
  'interval-to-string': intervalToString,
  LandingPage: landingPage,
  LoadingScreen: loadingScreen,
  'loan-applications/DetailsPage': loanApplicationsDetailsPage,
  'loan-applications/LoadingError': loanApplicationsLoadingError,
  'loan-applications/MenuDrawer': loanApplicationsMenuDrawer,
  'loan-applications/Overview': loanApplicationsOverview,
  LoginPage: loginPage,
  ManageOrder: manageOrder,
  ManualAddContractPage: manualAddContractPage,
  ManualContractPricingPage: manualAddContractPricingPage,
  Menu: menu,
  MenuPage: menuPage,
  'moving/BundledCancellationFromPage': movingBundledCancellationForm,
  'moving/BundledCancellationPage': movingBundledCancellationPage,
  'moving/BundledSwitchOrderFormPage': movingBundledSwitchOrderForm,
  'moving/BundledSwitchSwitchPage': movingBundledSwitchPage,
  'moving/ThanksSwitchPage': thanksSwitchPage,
  movingGuide: movingGuide,
  'movingGuide/MovingDetailsPage': movingGuideMovingDetails,
  'movingGuide/OneLastThingPage': movingGuideOneLastThingPage,
  'negotiate/NegotiateContractCompletePage': negotiateContractCompletePage,
  'negotiate/NegotiateGuide': negotiateGuide,
  'negotiation/OngoingNegotiationCard': negotiationOngoingCard,
  'negotiation/OngoingNegotiationStepper': negotiationOngoingStepper,
  NotificationOptInPage: notificationOptInPage,
  'onboarding/PSD2ConsentPage': onboardingPSD2Consent,
  'onboarding/PSD2PrimerPage': onboardingPSD2Primer,
  'onboarding-bank-discovery-sync/BankDiscoveryConnecting': onboardingBDSyncConnecting,
  'onboarding-bank-discovery-sync/BankDiscoveryErrorPage': onboardingBDSyncError,
  'onboarding-bank-discovery-sync/BankDiscoveryFetchPage': onboardingBDSyncFetch,
  'onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage': onboardingBDSyncSuccess,
  'onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage': onboardingBDSyncWaiting,
  'onboarding-cancel-product/OnboardingCancelProductPages': onboardingCancelProduct,
  'onboarding-psd2/WelcomePage': onboardingPsd2WelcomePage,
  'onboarding-psd2/ConsentPage': onboardingPsd2ConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPage': onboardingPsd2OpBankConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPageV2': onboardingPsd2OpBankConsentPageV2,
  'onboarding-psd2/ConsentPage/DanskeBankConsentPage': onboardingPsd2ConsentPageDanskeBank,
  'onboarding-psd2/RenewConsentPage': onboardingPsd2RenewConsentPage,
  'onboarding-psd2/RenewConsentPageV2': onboardingPsd2RenewConsentPageV2,
  'onboarding/Cards': onboardingPsd2Cards,
  'orderedSubscription/OngoingOrderCard': orderedSubscriptionOngoing,
  'orderedSubscription/OrderedEnergyProductInfo': orderedSubscriptionOrderedEnergy,
  'orderedSubscription/OrderedMobileProductInfo': orderedSubscriptionOrderedMobile,
  'orderedSubscription/OrderedSubscriptionDetails': orderedSubscriptionDetails,
  'orderedSubscription/OrderedSubscriptionDetailsPage': orderedSubscriptionDetailsPage,
  'orderedSubscription/OrderedSubscriptionInfoCard': orderedSubscriptionInfoCard,
  'orderedSubscription/OrderedSubscriptionPage': orderedSubscriptionPage,
  'orderedSubscription/OrderStepper': orderedSubscriptionOrderStepper,
  'orderedSubscription/ServiceProviderInfoCard': orderedSubscServiceProviderInfo,
  'ordering-subscription/common/TermsAndConditionsPage': orderingSubscriptionTerms,
  'ordering-subscription/common/OrderCompletedPage': orderingSubscriptionCompleted,
  'ordering-subscription/broadband/common/BroadbandGuideAddressPage': orderingBroadbandAddressPage,
  'ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage': orderingBroadbandAllAlternatives,
  'ordering-subscription/broadband/common/BroadbandGuideContractCostPage': orderingBroadbandContract,
  'ordering-subscription/broadband/common/BroadbandGuideIntroPage': orderingBroadbandIntroPage,
  'ordering-subscription/broadband/common/BroadbandGuideNoResultPage': orderingBroadbandNoResultPage,
  'ordering-subscription/broadband/common/BroadbandGuideSpeedPage': orderingBroadbandSpeedPage,
  'ordering-subscription/broadband/optimize/BroadbandOrderFormPage': orderingBroadbandOptimizeOrder,
  'ordering-subscription/broadband/optimize/BroadbandProductPage': orderingBroadbandOptimizeProduct,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog':
    orderingBroadbandOptimizeProductCosts,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPage': orderingElectricityOrder,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant': orderingElectricityOrderVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog': orderingElectricityGuidePrice,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage':
    orderingElectricityGuideAlternatives,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage':
    orderingElectricityGuideBinding,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage':
    orderingElectricityGuideBindingTime,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage': orderingElectricityGuideEnergy,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage':
    orderingElectricityGuideExplanation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage': orderingElectricityGuideFlow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage': orderingElectricityGuideHow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage':
    orderingElectricityGuideInformation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage': orderingElectricityGuideIntro,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage': orderingElectricityGuideLivingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage':
    orderingElectricityGuidePostalCodePage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences': orderingElectricityGuidePreferences,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage':
    orderingElectricityGuidePricingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage': orderingElectricityGuideProductPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage': orderingElectricityGuideSummaryPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage': orderingElectricityGuideTrading,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage': orderingElectricityGuideUsage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage':
    orderingElectricityGuideUsageResult,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant':
    orderingElectricityGuideUsageResultVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityPostalCode': orderingElectricityGuidePostalCode,
  'ordering-subscription/independent/OrderCompletedPage': orderingSubscriptionIndependentOrder,
  OrdersOverview: ordersOverview,
  'overview/addManualSubscription': overviewAddManualSubscription,
  'overview/BindingTimeBar': overviewBindingTimeBar,
  'overview/BindingTimeNotice': bindingTimeNotice,
  'overview/ChangePlanServiceIntroductionDrawer': overviewChangePlanService,
  'overview/ContractAction': overviewContractAction,
  'overview/DeprecatedInsightsPage/OnboardingInsightCard': overviewDeprecatedInsights,
  'overview/DiscoverPage': overviewDiscoverPage,
  'overview/GenericActions': overviewGenericAction,
  'overview/ImprovementPage': overviewImprovementPage,
  'overview/ImproveV2': overviewImproveV2,
  'overview/InsightsPage': insightPage,
  'overview/InsightsPage/InsightCards/ActionRating': overviewInsightsCardsActionRating,
  'overview/InsightsPage/InsightCards/CancellationUpdate': overviewInsightsCardsCancellationUpdate,
  'overview/InsightsPage/InsightCards/ChangeDataplanUpdate': overviewInsightsCardsChangeData,
  'overview/InsightsPage/InsightCards/IndependentOrderUpdate': overviewInsightsCardsIndependent,
  'overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton': overviewInsightsCardConfirmCancellation,
  'overview/InsightsPage/InsightCards/ContactUsButton': overviewContactUsButton,
  'overview/InsightsPage/InsightCards/MonthlyChecklist': overviewInsightsCardsMonthlyCheck,
  'overview/InsightsPage/InsightCards/NegotiationOutcome': overviewInsightsCardsNegotiationOutcome,
  'overview/InsightsPage/InsightCards/NegotiationUpdate': overviewInsightsCardsNegotiationUpdate,
  'overview/InsightsPage/InsightCards/NewProviderOffersCard': overviewInsightsCardsProvider,
  'overview/InsightsPage/InsightCards/NewSubscriptionFound': insightPage_insightsCards_newSubscription,
  'overview/InsightsPage/InsightCards/SaveDeskFeedbackCard': overviewInsightsCardsSaveDeskFeedback,
  'overview/InsightsPage/InsightCards/SaveDeskOrderRequested': overviewInsightCardsSaveDeskRequested,
  'overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested': overviewInsightsCardsSaveDeskPredefined,
  'overview/InsightsPage/InsightCards/SaveDeskResponseCard': overviewInsightsCardsSaveDeskResponse,
  'overview/InsightsPage/InsightCards/SwitchOutcome': overviewInsightsCardsSwitchOutcome,
  'overview/InsightsPage/InsightCards/SwitchUpdate': overviewInsightsCardsSwitchUpdate,
  'overview/InsightsPage/InsightCards/ThankYouCard': overviewInsightsCardsThankYou,
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton': overviewInsightsCardsTryCancellation,
  'overview/InsightsPage/InsightCards/UnreviewedAction': overviewInsightsCardsUnreviewed,
  'overview/InsightsPage/InsightCards/AddonRecommendation': insightPage_insightsCards_addon,
  'overview/InsightsPage/BindingTimeEnding': insightPage_bindingTimeEnding,
  'overview/InsightsPage/InsightCards/DiscoverOngoing': insightPage_insightsCards_discoverOngoing,
  'overview/InsightsPage/InsightCards/DiscoverOutcome': insightPage_insightsCards_discoverOutcomeInsight,
  'overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard':
    insightPage_insightsCards_personalRecommend,
  'overview/InsightsPage/InsightCards/SaveDeskOngoingCard': insightPage_insightsCards_saveDeskOngoing,
  'overview/InsightsPage/InsightCards/SaveDeskOutcome': insightPage_insightsCards_saveDeskOutcome,
  'overview/InsightsPage/SubscriptionCostChanged': insightPage_subscriptionCostChanged,
  'overview/InsightsPage/InsightCards/UnresolvedSubscription': insightPage_unresolvedSubscription,
  'overview/InsightsPage/OnboardingInsightCard': overviewInsightsCardsOnboarding,
  'overview/InsightsPage/PrimerCardV2': overviewInsightsPrimerCardV2,
  'overview/LoadingWithCogs': overviewLoadingWithCogs,
  'overview/ManageContractSection/SwitchProgressWithoutCancelStepper': overviewManageContract,
  'overview/MonthlyChecklist': overviewMonthlyChecklist,
  'overview/MoreInfoNeeded': overviewMoreInfoNeeded,
  'overview/OnboardingCards': overviewOnboardingCards,
  'overview/QuickActionsCancel': overviewQuickActionsCancel,
  'overview/QuickActionsCancelV2': overviewQuickActionsCancelV2,
  'overview/QuickActionsImprove': overviewQuickActionsImprove,
  'overview/QuickActionsImproveV2': overviewQuickActionsImproveV2,
  'overview/SubscriptionFeedback': overviewSubscriptionFeedback,
  'overview/SubscriptionSearch': overviewSubscriptionSearch,
  'overview/SubscriptionSearchOverviewComponent': overviewSubscriptionSearchComponent,
  'overview/WaysToSave': waysToSave,
  'overview/OverviewPage': overviewPage,
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage':
    overviewPage_didYouCancel,
  'overview/OnboardingDrawer': onboardingDrawer,
  'overview/QuickActionsV2': overviewPage_quickActionsV2,
  overview: overview,
  overviewBottomNavigation: overviewBottomNavigation,
  'pause/IntelligentGuidePage': pauseIntelligentGuide,
  'pause/SubscriptionPausePage': pauseSubscriptionPause,
  provideFeedbackPage: provideFeedbackPage,
  'provider-portal-datepicker/DatePicker': providerPortalDatepicker,
  'provider-portal-snackback/Snackbar': providerPortalSnackback,
  RegistrationPage: registrationPage,
  'retention-offer/SuccessfulRetentionOfferCard': retentionOfferCard,
  'save-desk': saveDesk,
  'save-desk/OngoingSaveDeskCard': saveDeskOngoingCard,
  'save-desk/OrderSentPage': saveDeskOrderSent,
  'savings/DoYouWantToSaveDialog': savingsDoYouWantToSave,
  'savings/SavingsAfterCancellation': savingsAfterCancellation,
  SelectProductPage: selectProductPage,
  'settings/NotificationSettingsPage': settingsNotificationSettings,
  'settings/NotificationSettingsPage/PushNotificationInstructions': settingsNotificationPushSettings,
  'settings/PersonalDetailsPage': settingsPersonalDetails,
  'settings/ProfilingSettingsPage': settingsProfile,
  'settings/SettingsPage': settingsPage,
  'settings/UserSettingsPage': settingsUser,
  'settings/ViewGdprDataPage': settingsViewGdprData,
  'settings-swedbank/SwedbankNotificationSettingsPage': settingsSwedbank,
  'shared-components/EmojiRating': sharedComponentsEmoji,
  'shared-components/UpdateBankApp': sharedComponentsUpdateBankApp,
  'shared-components/YouSavedComponent': sharedComponentsYouSavedComponent,
  ShowOnboardingFeaturesComponent: showOnboardingFeatures,
  SignoutSuccessPage: signOutSuccessPage,
  'standard-components/CurrencyTextField': standardComponentsCurrency,
  'standard-components/EmailField': standardComponentsEmail,
  'standard-components/DatePicker': standardComponentsDate,
  'standard-components/GuidePage': standardComponentsGuide,
  'standard-components/MobilePhoneNumberField': standardComponentsMobile,
  'standard-components/PasswordField': standardComponentsPassword,
  'standard-components/PersonalNumberField': standardComponentsPersonal,
  'standard-components/PostalCodeField': standardComponentsPostalCode,
  'standard-components/Snackbar': standardComponentsSnackbar,
  'subscription/CancellationMeasurement': subscriptionCancellationMeasure,
  'subscription/components/SelectableServiceList': subscriptionComponentsSelectable,
  'subscription/RemoveSubscriptionDialog': subscriptionRemoveSubscription,
  'subscription/SelectLoanServicesPage': subscriptionSelectLoanService,
  'subscription/SelectLoanTransactionTypePage': subscriptionSelectLoanTransaction,
  'subscription/SelectServicesPage': subscriptionSelectServices,
  'subscription/SubscriptionPage': subscriptionPage,
  'subscription/SubscriptionSettingsPage': subscriptionSettingsPage,
  'subscription/SubscriptionUpdateCostPage': subscriptionUpdateCostPage,
  'subscription/SwitchMeasurement': subscriptionSwitchMeasurement,
  'subscription/UnresolvedGuide': subscriptionUnresolvedGuide,
  'subscription/WhatsWrongDialog': subscriptionWhatsWrong,
  'subscription-alarm/MorePage': subscriptionAlarmMore,
  'subscription-alarm/SubscriptionAlarmPage': subscriptionAlarmPage,
  'subscription-hub/PastActions': subscriptionHubActions,
  'subscriptionHub/subscriptionHub': subscriptionHub,
  'supplier-portal-change': supplierPortalChange,
  'supplier-portal-save-desk': supplierPortalSaveDesk,
  SwedbankOverviewSyncFetch: swedbankOverviewSync,
  SwedenPhoneCallsLimitationInput: swedenPhoneCallsLimited,
  'sync-sources/AddSyncSourcePage': syncSourcesAddSync,
  'sync-sources/AddSyncSourcesSpeedDial': syncSourcesAddSyncSpeed,
  'sync-sources/DeleteSyncSourcesPage': syncSourcesDeleteSync,
  'sync-sources/Error': syncSourcesError,
  'sync-sources/NoSyncSources': syncSourcesNoSync,
  'sync-sources/PopularSyncSourcesList': syncSourcesPopularSync,
  'sync-sources/SyncSourcesFetching': syncSourcesFetching,
  'sync-sources/SyncSourcesList': syncSourcesList,
  'sync-sources/TinkSyncPage': syncSourcesTinkSync,
  'sync-sources/UpdateBankAuthsPage': syncSourcesUpdateBank,
  'sync-sources/UpdateSyncSources': syncSourcesUpdateSync,
  'sync-sources/UpdateSyncSourcesButton': syncSourcesUpdateSyncButton,
  'sync-sources/SyncSourcesReminder': syncSourcesReminder,
  'termination/TerminationFormPage': terminationFormPage,
  'termination/TerminationFormPage/CancelReasonSection': terminationFormPCancelReason,
  'termination/TerminationInstructionsPage': terminationInstructions,
  TermsAndConditionsUpload: termsAndCondition,
  TypeformButton: typeFormButton,
  'user-form-preview': userFormPreview,
  userFeedback: userFeedback,
  'userSettings/UserDeletePage': userDeleteSettings,
  'userSettings/UserDeleteVerifiedPage': userDeleteVerifiedPage,
  zendesk: zendesk,
};
