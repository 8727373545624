import { ThemeType } from '@client/models/ThemeType';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { connect } from 'react-redux';
import type { AppFeatureToggles } from './modules/app-context';
import {
  NavigationType,
  NotificationType,
  OnboardingType,
  PersonalInformationType,
  SigningFeature,
} from './modules/app-context';

export const defaultFeatures: AppFeatureToggles = {
  logout: {
    enabled: true,
    logoutConfirmationFlow: false,
    loginFromSignoutPage: false,
  },
  bankDiscovery: true,
  helpCenter: {
    enabled: true,
    chatEnabled: true,
    callUsEnabled: true,
    callBackEnabled: false,
    emailUsEnabled: true,
    zendeskBaseUrl: 'https://minatjanster.zendesk.com',
    supportEmail: 'kontakt@minatjanster.se',
  },
  insights: true,
  identify: {
    overview: {
      manuallyAddedSubscriptions: true,
      bankIdLogo: true,
      sslCertifiedIcon: true,
      complexContractGuide: true,
    },
    identify: true,
    identifyOptIn: false,
  },
  settings: {
    personalInformation: {
      personalInformationType: PersonalInformationType.STANDALONE,
      enabledAddressForm: false,
      enabledPersonalNumber: true,
      enabledNameChange: true,
      enabledExtendedAddress: false,
    },
    profiling: true,
    notification: {
      notificationType: NotificationType.STANDALONE,
      pushEnabled: true,
    },
    changeEmailCard: true,
    deleteAccount: true,
    connectedBankAccounts: false,
  },
  navigation: {
    navigationType: NavigationType.FULL,
    feedback: true,
    pageNavigationLogo: false,
  },
  signing: SigningFeature.BANKID,
  onboarding: OnboardingType.DISABLED,
  useUnresolvedSubscriptionGuide: false,
  theme: ThemeType.MINNA,
  subscriptionFeatures: {
    cancelAndSave: {
      enabled: false,
    },
    cancellationEnabledWithTerms: false,
    deletingSubscriptions: true,
    saveDeskEnabled: false,
    editBdSubscriptions: true,
    reminderEnabled: true,
    merchantGuide: {
      phoneLinkEnabled: false,
    },
  },
  actionFeatures: {
    cancel: {
      enabled: true,
      merchantOutreachEnabled: true,
      merchantGuideEnabled: true,
      merchantDirectEnabled: true,
    },
    resume: {
      enabled: false,
      merchantOutreachEnabled: false,
      merchantGuideEnabled: false,
      merchantDirectEnabled: false,
    },
    changePlan: {
      enabled: false,
      merchantOutreachEnabled: false,
      merchantGuideEnabled: false,
      merchantDirectEnabled: false,
    },
    block: {
      enabled: false,
      merchantOutreachEnabled: false,
      merchantGuideEnabled: false,
      merchantDirectEnabled: false,
    },
    retentionOffers: {
      enabled: false,
      merchantOutreachEnabled: false,
      merchantGuideEnabled: false,
      merchantDirectEnabled: false,
    },
    unblock: {
      enabled: false,
      merchantOutreachEnabled: false,
      merchantGuideEnabled: false,
      merchantDirectEnabled: false,
    },
    resubscribe: {
      enabled: false,
      merchantOutreachEnabled: false,
      merchantGuideEnabled: false,
      merchantDirectEnabled: false,
    },
    pause: {
      enabled: false,
      merchantOutreachEnabled: false,
      merchantGuideEnabled: false,
      merchantDirectEnabled: false,
    },
    changePayment: {
      enabled: false,
      merchantOutreachEnabled: false,
      merchantGuideEnabled: false,
      merchantDirectEnabled: false,
    },
  },
  beta: false,
  subscriptionHub: {},
  searchPageFeatures: { showIntroScreen: true },
};

const mapStateToProps = (state: any) => ({ features: selectorFeatures(state) });
export const withFeatures = connect(mapStateToProps);
