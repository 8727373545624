import insightPage from '@client/internationalization/localization-messages/overview/InsightsPage/en-US.json';
import insightPage_bindingTimeEnding from '@client/internationalization/localization-messages/overview/InsightsPage/BindingTimeEnding/en-US.json';
import insightPage_insightsCards_discoverOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOngoing/en-US.json';
import insightPage_insightsCards_discoverOutcomeInsight from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOutcome/en-US.json';
import insightPage_insightsCards_newSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewSubscriptionFound/en-US.json';
import insightPage_insightsCards_personalRecommend from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/en-US.json';
import insightPage_insightsCards_saveDeskOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOutcome/en-US.json';
import insightPage_subscriptionCostChanged from '@client/internationalization/localization-messages/overview/InsightsPage/SubscriptionCostChanged/en-US.json';
import insightPage_unresolvedSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnresolvedSubscription/en-US.json';
import overviewPage from '@client/internationalization/localization-messages/overview/OverviewPage/en-US.json';
import overviewAddManualSubscription from '@client/internationalization/localization-messages/overview/addManualSubscription/en-US.json';
import overviewPage_didYouCancel from '@client/internationalization/localization-messages/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/en-US.json';
import badgeCtas from '@client/internationalization/localization-messages/BadgeCtas/en-US.json';
import bindingTimeNotice from '@client/internationalization/localization-messages/overview/BindingTimeNotice/en-US.json';
import onboardingDrawer from '@client/internationalization/localization-messages/overview/OnboardingDrawer/en-US.json';
import overviewPage_quickActionsV2 from '@client/internationalization/localization-messages/overview/QuickActionsV2/en-US.json';
import cancelCancellationOutcome from '@client/internationalization/localization-messages/cancel/CancellationOutcome/en-US.json';
import cancellationFormCancellationInstruction from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationInstructionsSection/en-US.json';
import cancellationFormCancellationTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationTerms/en-US.json';
import cancellationFormConfirmSection from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/ConfirmSection/en-US.json';
import cancellationModalsContainer from '@client/internationalization/localization-messages/cancellation/CancellationModalsContainer/en-US.json';
import cancellationReasonsDialog from '@client/internationalization/localization-messages/cancellation/CancellationReasonsDialog/en-US.json';
import cancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/en-US.json';
import cancellationStepper from '@client/internationalization/localization-messages/cancellation/CancellationStepper/en-US.json';
import cancellationIntelligentGuidePage from '@client/internationalization/localization-messages/cancellation/IntelligentGuidePage/en-US.json';
import cancellationNoSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningPage/en-US.json';
import cancellationNoSigningServicePage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningServicePage/en-US.json';
import cancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/en-US.json';
import cancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/en-US.json';
import categoryNames from '@client/internationalization/localization-messages/CategoryNames/CategoryNames/en-US.json';
import complexContractGuideIntroPage from '@client/internationalization/localization-messages/complex-contract-guide/IntroPage/en-US.json';
import conditionsAndPoliciesMenu from '@client/internationalization/localization-messages/conditions-and-policies/ConditionsAndPoliciesMenu/en-US.json';
import conditionsAndPoliciesCookiesPage from '@client/internationalization/localization-messages/conditions-and-policies/CookiesPage/en-US.json';
import conditionsAndPoliciesSecurityPage from '@client/internationalization/localization-messages/conditions-and-policies/SecurityPage/en-US.json';
import conditionsAndPolicies from '@client/internationalization/localization-messages/conditions-and-policies/en-US.json';
import contractCancellationOutcome from '@client/internationalization/localization-messages/contract/ContractCancellationOutcome/en-US.json';
import contractCardMessage from '@client/internationalization/localization-messages/contract/ContractCardMessage/en-US.json';
import contractDetails from '@client/internationalization/localization-messages/contract/ContractDetails/en-US.json';
import contractHistoryToolTip from '@client/internationalization/localization-messages/contract/HistoryTooltip/en-US.json';
import contractPriceHistory from '@client/internationalization/localization-messages/contract/PriceHistoryLolipop/en-US.json';
import contractSelectServicePage from '@client/internationalization/localization-messages/contract/SelectServicePageLolipop/en-US.json';
import contractSelectServicesList from '@client/internationalization/localization-messages/contract/SelectServicesListLolipop/en-US.json';
import contractSettingsButton from '@client/internationalization/localization-messages/contract/SettingsButtonLolipop/en-US.json';
import contractSettingsPage from '@client/internationalization/localization-messages/contract/SettingsPageLolipop/en-US.json';
import contractSubscriptionDetails from '@client/internationalization/localization-messages/contract/SubscriptionDetailsLolipop/en-US.json';
import contractSubscriptionReminder from '@client/internationalization/localization-messages/contract/SubscriptionReminder/en-US.json';
import contractSubscriptionReminderDialog from '@client/internationalization/localization-messages/contract/subscriptionReminderDialog/en-US.json';
import contractTransactionHistory from '@client/internationalization/localization-messages/contract/TransactionHistoryLolipop/en-US.json';
import contractUpdateCost from '@client/internationalization/localization-messages/contract/UpdateCostLolipop/en-US.json';
import errorHandling from '@client/internationalization/localization-messages/error-handling/LoggedOutPage/en-US.json';
import errorPageErrorPage from '@client/internationalization/localization-messages/errorPage/ErrorPage/en-US.json';
import errorScreen from '@client/internationalization/localization-messages/ErrorScreen/en-US.json';
import feedbackModal from '@client/internationalization/localization-messages/feedback-modal/en-US.json';
import fields from '@client/internationalization/localization-messages/fields/en-US.json';
import generalIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/en-US.json';
import genericActionsSelectServices from '@client/internationalization/localization-messages/genericActions/SelectServicesPage/en-US.json';
import genericActionsSubscriptionCancel from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/Cancel/en-US.json';
import genericActionsSubscriptionsChangePayment from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/ChangePayment/en-US.json';
import header from '@client/internationalization/localization-messages/Header/en-US.json';
import insightPageInsightsCardsCancelOutcome from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationOutcome/en-US.json';
import insightPageInsightsCardsCancelUpdate from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationUpdate/en-US.json';
import intervalToString from '@client/internationalization/localization-messages/interval-to-string/en-US.json';
import manualAddContractPage from '@client/internationalization/localization-messages/ManualAddContractPage/en-US.json';
import manualAddContractPricingPage from '@client/internationalization/localization-messages/ManualContractPricingPage/en-US.json';
import menuPage from '@client/internationalization/localization-messages/MenuPage/en-US.json';
import overviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/en-US.json';
import overviewContactUsButton from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ContactUsButton/en-US.json';
import overviewInsightsCardsThankYou from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ThankYouCard/en-US.json';
import overviewInsightsCardsTryCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/TryCancellationAgainButton/en-US.json';
import overviewInsightsCardsOnboarding from '@client/internationalization/localization-messages/overview/InsightsPage/OnboardingInsightCard/en-US.json';
import overviewLoadingWithCogs from '@client/internationalization/localization-messages/overview/LoadingWithCogs/en-US.json';
import overviewOnboardingCards from '@client/internationalization/localization-messages/overview/OnboardingCards/en-US.json';
import overviewQuickActionsCancelV2 from '@client/internationalization/localization-messages/overview/QuickActionsCancelV2/en-US.json';
import overview from '@client/internationalization/localization-messages/overview/en-US.json';
import overviewBottomNavigation from '@client/internationalization/localization-messages/overviewBottomNavigation/en-US.json';
import settingsNotificationSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/en-US.json';
import settingsNotificationPushSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/PushNotificationInstructions/en-US.json';
import settingsProfile from '@client/internationalization/localization-messages/settings/ProfilingSettingsPage/en-US.json';
import settingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/en-US.json';
import settingsUser from '@client/internationalization/localization-messages/settings/UserSettingsPage/en-US.json';
import settingsViewGdprData from '@client/internationalization/localization-messages/settings/ViewGdprDataPage/en-US.json';
import signOutSuccessPage from '@client/internationalization/localization-messages/SignoutSuccessPage/en-US.json';
import standardComponentsCurrency from '@client/internationalization/localization-messages/standard-components/CurrencyTextField/en-US.json';
import standardComponentsDate from '@client/internationalization/localization-messages/standard-components/DatePicker/en-US.json';
import standardComponentsEmail from '@client/internationalization/localization-messages/standard-components/EmailField/en-US.json';
import standardComponentsMobile from '@client/internationalization/localization-messages/standard-components/MobilePhoneNumberField/en-US.json';
import standardComponentsPersonal from '@client/internationalization/localization-messages/standard-components/PersonalNumberField/en-US.json';
import standardComponentsPostalCode from '@client/internationalization/localization-messages/standard-components/PostalCodeField/en-US.json';
import standardComponentsSnackbar from '@client/internationalization/localization-messages/standard-components/PostalCodeField/en-US.json';
import subscriptionRemoveSubscription from '@client/internationalization/localization-messages/subscription/RemoveSubscriptionDialog/en-US.json';
import subscriptionUnresolvedGuide from '@client/internationalization/localization-messages/subscription/UnresolvedGuide/en-US.json';
import subscriptionWhatsWrong from '@client/internationalization/localization-messages/subscription/WhatsWrongDialog/en-US.json';
import terminationFormPage from '@client/internationalization/localization-messages/termination/TerminationFormPage/en-US.json';
import terminationInstructions from '@client/internationalization/localization-messages/termination/TerminationInstructionsPage/en-US.json';
import typeFormButton from '@client/internationalization/localization-messages/TypeformButton/en-US.json';
import userDeleteSettings from '@client/internationalization/localization-messages/userSettings/UserDeletePage/en-US.json';
import userDeleteVerifiedPage from '@client/internationalization/localization-messages/userSettings/UserDeleteVerifiedPage/en-US.json';
import zendesk from '@client/internationalization/localization-messages/zendesk/en-US.json';

export const enUSTranslations = {
  BadgeCtas: badgeCtas,
  'cancel/CancellationOutcome': cancelCancellationOutcome,
  'cancellation/CancellationFormPage/CancellationInstructionsSection': cancellationFormCancellationInstruction,
  'cancellation/CancellationFormPage/CancellationTerms': cancellationFormCancellationTerms,
  'cancellation/CancellationFormPage/ConfirmSection': cancellationFormConfirmSection,
  'cancellation/CancellationModalsContainer': cancellationModalsContainer,
  'cancellation/CancellationReasonsDialog': cancellationReasonsDialog,
  'cancellation/CancellationSentPage': cancellationSentPage,
  'cancellation/CancellationStepper': cancellationStepper,
  'cancellation/IntelligentGuidePage': cancellationIntelligentGuidePage,
  'cancellation/signing/NoSigningPage': cancellationNoSigningPage,
  'cancellation/signing/NoSigningServicePage': cancellationNoSigningServicePage,
  CancellationPortal: cancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement': cancellationsCancellationPortalAgreement,
  'CategoryNames/CategoryNames': categoryNames,
  'complex-contract-guide/IntroPage': complexContractGuideIntroPage,
  'conditions-and-policies/ConditionsAndPoliciesMenu': conditionsAndPoliciesMenu,
  'conditions-and-policies/CookiesPage': conditionsAndPoliciesCookiesPage,
  'conditions-and-policies/SecurityPage': conditionsAndPoliciesSecurityPage,
  'conditions-and-policies': conditionsAndPolicies,
  'contract/ContractCancellationOutcome': contractCancellationOutcome,
  'contract/ContractCardMessage': contractCardMessage,
  'contract/ContractDetails': contractDetails,
  'contract/HistoryToolTip': contractHistoryToolTip,
  'contract/PriceHistoryLolipop': contractPriceHistory,
  'contract/SelectServicePageLolipop': contractSelectServicePage,
  'contract/SelectServicesListLolipop': contractSelectServicesList,
  'contract/SettingsButtonLolipop': contractSettingsButton,
  'contract/SettingsPageLolipop': contractSettingsPage,
  'contract/SubscriptionDetailsLolipop': contractSubscriptionDetails,
  'contract/SubscriptionReminder': contractSubscriptionReminder,
  'contract/subscriptionReminderDialog': contractSubscriptionReminderDialog,
  'contract/TransactionHistoryLolipop': contractTransactionHistory,
  'contract/UpdateCostLolipop': contractUpdateCost,
  'error-handling/LoggedOutPage': errorHandling,
  'errorPage/ErrorPage': errorPageErrorPage,
  ErrorScreen: errorScreen,
  'feedback-modal': feedbackModal,
  fields: fields,
  'general/IntelligentGuide': generalIntelligentGuide,
  'genericActions/SelectServicesPage': genericActionsSelectServices,
  'genericActions/SubscriptionSearchPage/Cancel': genericActionsSubscriptionCancel,
  'genericActions/SubscriptionSearchPage/ChangePayment': genericActionsSubscriptionsChangePayment,
  Header: header,
  'InsightsPage/InsightCards/CancellationOutcome': insightPageInsightsCardsCancelOutcome,
  'InsightsPage/InsightCards/CancellationUpdate': insightPageInsightsCardsCancelUpdate,
  'interval-to-string': intervalToString,
  ManualAddContractPage: manualAddContractPage,
  ManualContractPricingPage: manualAddContractPricingPage,
  MenuPage: menuPage,
  'overview/addManualSubscription': overviewAddManualSubscription,
  'overview/BindingTimeNotice': bindingTimeNotice,
  'overview/ContractAction': overviewContractAction,
  'overview/InsightsPage': insightPage,
  'overview/InsightsPage/InsightCards/ContactUsButton': overviewContactUsButton,
  'overview/InsightsPage/InsightCards/NewSubscriptionFound': insightPage_insightsCards_newSubscription,
  'overview/InsightsPage/InsightCards/ThankYouCard': overviewInsightsCardsThankYou,
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton': overviewInsightsCardsTryCancellation,
  'overview/InsightsPage/BindingTimeEnding': insightPage_bindingTimeEnding,
  'overview/InsightsPage/InsightCards/DiscoverOngoing': insightPage_insightsCards_discoverOngoing,
  'overview/InsightsPage/InsightCards/DiscoverOutcome': insightPage_insightsCards_discoverOutcomeInsight,
  'overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard':
    insightPage_insightsCards_personalRecommend,
  'overview/InsightsPage/InsightCards/SaveDeskOutcome': insightPage_insightsCards_saveDeskOutcome,
  'overview/InsightsPage/SubscriptionCostChanged': insightPage_subscriptionCostChanged,
  'overview/InsightsPage/InsightCards/UnresolvedSubscription': insightPage_unresolvedSubscription,
  'overview/InsightsPage/OnboardingInsightCard': overviewInsightsCardsOnboarding,
  'overview/LoadingWithCogs': overviewLoadingWithCogs,
  'overview/OnboardingCards': overviewOnboardingCards,
  'overview/QuickActionsCancelV2': overviewQuickActionsCancelV2,
  'overview/OverviewPage': overviewPage,
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage':
    overviewPage_didYouCancel,
  'overview/OnboardingDrawer': onboardingDrawer,
  'overview/QuickActionsV2': overviewPage_quickActionsV2,
  overview: overview,
  overviewBottomNavigation: overviewBottomNavigation,
  'settings/NotificationSettingsPage': settingsNotificationSettings,
  'settings/NotificationSettingsPage/PushNotificationInstructions': settingsNotificationPushSettings,
  'settings/ProfilingSettingsPage': settingsProfile,
  'settings/SettingsPage': settingsPage,
  'settings/UserSettingsPage': settingsUser,
  'settings/ViewGdprDataPage': settingsViewGdprData,
  SignoutSuccessPage: signOutSuccessPage,
  'standard-components/CurrencyTextField': standardComponentsCurrency,
  'standard-components/EmailField': standardComponentsEmail,
  'standard-components/DatePicker': standardComponentsDate,
  'standard-components/MobilePhoneNumberField': standardComponentsMobile,
  'standard-components/PersonalNumberField': standardComponentsPersonal,
  'standard-components/PostalCodeField': standardComponentsPostalCode,
  'standard-components/Snackbar': standardComponentsSnackbar,
  'subscription/RemoveSubscriptionDialog': subscriptionRemoveSubscription,
  'subscription/UnresolvedGuide': subscriptionUnresolvedGuide,
  'subscription/WhatsWrongDialog': subscriptionWhatsWrong,
  'termination/TerminationFormPage': terminationFormPage,
  'termination/TerminationInstructionsPage': terminationInstructions,
  TypeformButton: typeFormButton,
  'userSettings/UserDeletePage': userDeleteSettings,
  'userSettings/UserDeleteVerifiedPage': userDeleteVerifiedPage,
  zendesk: zendesk,
};
