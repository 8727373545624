import aboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/en-GB.json';
import bankAboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/BankAboutUsPage/en-GB.json';
import insightPage from '@client/internationalization/localization-messages/overview/InsightsPage/en-GB.json';
import insightPage_insightsCards_addon from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/AddonRecommendation/en-GB.json';
import insightPage_bindingTimeEnding from '@client/internationalization/localization-messages/overview/InsightsPage/BindingTimeEnding/en-GB.json';
import insightPage_insightsCards_discoverOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOngoing/en-GB.json';
import insightPage_insightsCards_discoverOutcomeInsight from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOutcome/en-GB.json';
import insightPage_insightsCards_newSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewSubscriptionFound/en-GB.json';
import insightPage_insightsCards_personalRecommend from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/en-GB.json';
import insightPage_insightsCards_saveDeskOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOngoingCard/en-GB.json';
import insightPage_insightsCards_saveDeskOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOutcome/en-GB.json';
import insightPage_subscriptionCostChanged from '@client/internationalization/localization-messages/overview/InsightsPage/SubscriptionCostChanged/en-GB.json';
import insightPage_unresolvedSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnresolvedSubscription/en-GB.json';
import overviewPage from '@client/internationalization/localization-messages/overview/OverviewPage/en-GB.json';
import overviewAddManualSubscription from '@client/internationalization/localization-messages/overview/addManualSubscription/en-GB.json';
import overviewBindingTimeBar from '@client/internationalization/localization-messages/overview/BindingTimeBar/en-GB.json';
import overviewPage_didYouCancel from '@client/internationalization/localization-messages/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/en-GB.json';
import badgeCtas from '@client/internationalization/localization-messages/BadgeCtas/en-GB.json';
import bindingTimeNotice from '@client/internationalization/localization-messages/overview/BindingTimeNotice/en-GB.json';
import onboardingDrawer from '@client/internationalization/localization-messages/overview/OnboardingDrawer/en-GB.json';
import overviewPage_quickActionsV2 from '@client/internationalization/localization-messages/overview/QuickActionsV2/en-GB.json';
import addonMobileRecommendationsPage from '@client/internationalization/localization-messages/addon/mobile/RecommendationsPage/en-GB.json';
import addonMobileResultPage from '@client/internationalization/localization-messages/addon/mobile/ResultPage/en-GB.json';
import authAuthStatusPage from '@client/internationalization/localization-messages/auth/AuthStatusPage/en-GB.json';
import authBasicLoginPage from '@client/internationalization/localization-messages/auth/BasicLoginPage/en-GB.json';
import authCreateAccountPage from '@client/internationalization/localization-messages/auth/CreateAccountPage/en-GB.json';
import authOnboardingChooseBankPage from '@client/internationalization/localization-messages/auth/OnboardingChooseBankPage/en-GB.json';
import authOnboardingEmailPage from '@client/internationalization/localization-messages/auth/OnboardingEmailPage/en-GB.json';
import authPreLoginPage from '@client/internationalization/localization-messages/auth/PreLoginPage/en-GB.json';
import authResetPasswordPage from '@client/internationalization/localization-messages/auth/ResetPasswordPage/en-GB.json';
import authSyncSourcesError from '@client/internationalization/localization-messages/auth/SyncSourcesError/en-GB.json';
import authSyncSourcesFetching from '@client/internationalization/localization-messages/auth/SyncSourcesFetching/en-GB.json';
import badgeABTestCTAS from '@client/internationalization/localization-messages/BadgeABTestCtas/en-GB.json';
import bankSyncCancelProduct from '@client/internationalization/localization-messages/bank-sync/CancelProductOnboardPages/en-GB.json';
import bankSyncDrawerPages from '@client/internationalization/localization-messages/bank-sync/DrawerPages/en-GB.json';
import bankSyncLoadingScreen from '@client/internationalization/localization-messages/bank-sync/LoadingScreen/en-GB.json';
import bankSyncOnboardBankSync from '@client/internationalization/localization-messages/bank-sync/OnBoardBankSync/en-GB.json';
import campaignIdSelect from '@client/internationalization/localization-messages/CampaignIdSelect/en-GB.json';
import cancelCancellationOutcome from '@client/internationalization/localization-messages/cancel/CancellationOutcome/en-GB.json';
import cancellationDetailsPage from '@client/internationalization/localization-messages/cancellation/cancellationDetailsPage/en-GB.json';
import cancellationFormCancellationInstruction from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationInstructionsSection/en-GB.json';
import cancellationFormCancellationTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationTerms/en-GB.json';
import cancellationFormConfirmSection from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/ConfirmSection/en-GB.json';
import cancellationFormLightUserTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/LightUserTerms/en-GB.json';
import cancellationModalsContainer from '@client/internationalization/localization-messages/cancellation/CancellationModalsContainer/en-GB.json';
import cancellationQuestionsFlow from '@client/internationalization/localization-messages/cancellation/CancellationQuestionFlow/en-GB.json';
import cancellationReasonsDialog from '@client/internationalization/localization-messages/cancellation/CancellationReasonsDialog/en-GB.json';
import cancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/en-GB.json';
import cancellationStepper from '@client/internationalization/localization-messages/cancellation/CancellationStepper/en-GB.json';
import cancellationDoYouWantToSaveDialog from '@client/internationalization/localization-messages/cancellation/DoYouWantToSaveDialog/en-GB.json';
import cancellationIntelligentGuidePage from '@client/internationalization/localization-messages/cancellation/IntelligentGuidePage/en-GB.json';
import cancellationNetflixCancellationInstructionsPage from '@client/internationalization/localization-messages/cancellation/NetflixCancellationInstructionsPage/en-GB.json';
import cancellationSigningNemIdErrorPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdErrorPage/en-GB.json';
import cancellationSigningNemIdSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdSigningPage/en-GB.json';
import cancellationNoSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningPage/en-GB.json';
import cancellationNoSigningServicePage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningServicePage/en-GB.json';
import cancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/en-GB.json';
import cancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/en-GB.json';
import categories from '@client/internationalization/localization-messages/categories/en-GB.json';
import categoryNames from '@client/internationalization/localization-messages/CategoryNames/CategoryNames/en-GB.json';
import changeDataPlan from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplan/en-GB.json';
import changeDataPlanCompletedPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanCompletedPage/en-GB.json';
import changeDataPlanOrderFormPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanOrderFormPage/en-GB.json';
import changeDataPlanPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanPage/en-GB.json';
import changePaymentChangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-payment/ChangePlanServiceIntroductionDrawer/en-GB.json';
import changePaymentIntelligentGuidePage from '@client/internationalization/localization-messages/change-payment/IntelligentGuidePage/en-GB.json';
import changePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-plan/ChangePlanServiceIntroductionDrawer/en-GB.json';
import changePlanIntelligentGuideExperimentPage from '@client/internationalization/localization-messages/change-plan/IntelligentGuideExperimentPage/en-GB.json';
import changePlanIntelligentGuidePage from '@client/internationalization/localization-messages/change-plan/IntelligentGuidePage/en-GB.json';
import changePlan from '@client/internationalization/localization-messages/ChangePlan/en-GB.json';
import charityDonation from '@client/internationalization/localization-messages/charity-donation/en-GB.json';
import complexContractGuideIntroPage from '@client/internationalization/localization-messages/complex-contract-guide/IntroPage/en-GB.json';
import complexContractGuideLoanServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanServiceQuestionPage/en-GB.json';
import complexContractGuideLoanTypeQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanTypeQuestionPage/en-GB.json';
import complexContractGuideServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/ServiceQuestionPage/en-GB.json';
import complexContractGuideShared from '@client/internationalization/localization-messages/complex-contract-guide/shared/en-GB.json';
import complexContractGuideSuccessPage from '@client/internationalization/localization-messages/complex-contract-guide/SuccessPage/en-GB.json';
import conditionsAndPoliciesMenu from '@client/internationalization/localization-messages/conditions-and-policies/ConditionsAndPoliciesMenu/en-GB.json';
import conditionsAndPoliciesCookiesPage from '@client/internationalization/localization-messages/conditions-and-policies/CookiesPage/en-GB.json';
import conditionsAndPoliciesSecurityPage from '@client/internationalization/localization-messages/conditions-and-policies/SecurityPage/en-GB.json';
import conditionsAndPolicies from '@client/internationalization/localization-messages/conditions-and-policies/en-GB.json';
import contractBindingPeriodDialog from '@client/internationalization/localization-messages/contract/bindingPeriodDialog/en-GB.json';
import contractCancellationHistory from '@client/internationalization/localization-messages/contract/CancellationHistory/en-GB.json';
import contractCancelledContract from '@client/internationalization/localization-messages/contract/CancelledContract/en-GB.json';
import contractCancellationOutcome from '@client/internationalization/localization-messages/contract/ContractCancellationOutcome/en-GB.json';
import contractCardMessage from '@client/internationalization/localization-messages/contract/ContractCardMessage/en-GB.json';
import contractDetails from '@client/internationalization/localization-messages/contract/ContractDetails/en-GB.json';
import contractDetailsPage from '@client/internationalization/localization-messages/contract/ContractDetailsPage/en-GB.json';
import contractDoMoreComponent from '@client/internationalization/localization-messages/contract/DoMoreComponent/en-GB.json';
import contractHistoryToolTip from '@client/internationalization/localization-messages/contract/HistoryTooltip/en-GB.json';
import contractPriceHistory from '@client/internationalization/localization-messages/contract/PriceHistoryLolipop/en-GB.json';
import contractSelectServicePage from '@client/internationalization/localization-messages/contract/SelectServicePageLolipop/en-GB.json';
import contractSelectServicesList from '@client/internationalization/localization-messages/contract/SelectServicesListLolipop/en-GB.json';
import contractSettingsButton from '@client/internationalization/localization-messages/contract/SettingsButtonLolipop/en-GB.json';
import contractSettingsPage from '@client/internationalization/localization-messages/contract/SettingsPageLolipop/en-GB.json';
import contractSubscriptionAlarm from '@client/internationalization/localization-messages/contract/SubscriptionAlarmLolipop/en-GB.json';
import contractSubscriptionDetails from '@client/internationalization/localization-messages/contract/SubscriptionDetailsLolipop/en-GB.json';
import contractSubscriptionReminder from '@client/internationalization/localization-messages/contract/SubscriptionReminder/en-GB.json';
import contractSubscriptionReminderDialog from '@client/internationalization/localization-messages/contract/subscriptionReminderDialog/en-GB.json';
import contractTransactionHistory from '@client/internationalization/localization-messages/contract/TransactionHistoryLolipop/en-GB.json';
import contractUpdateCost from '@client/internationalization/localization-messages/contract/UpdateCostLolipop/en-GB.json';
import discoverCategoryPage from '@client/internationalization/localization-messages/discover/CategoryPage/en-GB.json';
import discoverCategoryProviderPage from '@client/internationalization/localization-messages/discover/CategoryProviderPage/en-GB.json';
import discoverComponentsProductInfo from '@client/internationalization/localization-messages/discover/components/ProductInformationHeader/en-GB.json';
import discoverDiscoverWidget from '@client/internationalization/localization-messages/discover/DiscoverWidget/en-GB.json';
import discoverFakeCategoryPage from '@client/internationalization/localization-messages/discover/FakeCategoryPage/en-GB.json';
import discoverFakeCategorySubscriptionPage from '@client/internationalization/localization-messages/discover/FakeCategorySubscriptionsPage/en-GB.json';
import discoverFitnessCategoryFeedbackPage from '@client/internationalization/localization-messages/discover/FitnessCategoryFeedbackPage/en-GB.json';
import discoverMarketplacePage from '@client/internationalization/localization-messages/discover/MarketplacePage/en-GB.json';
import discoverMobileOrderSentPage from '@client/internationalization/localization-messages/discover/MobileOrderSentPage/en-GB.json';
import discoverOrderProductPage from '@client/internationalization/localization-messages/discover/OrderProductPage/en-GB.json';
import discoverProductRecommendations from '@client/internationalization/localization-messages/discover/ProductRecommendations/en-GB.json';
import discoverSearchSubscriptionAllCategories from '@client/internationalization/localization-messages/discover/SearchSubscriptionAllCategoriesPage/en-GB.json';
import discoverSearchSubscriptionPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionPage/en-GB.json';
import discoverSearchSubscriptionResultPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionResultPage/en-GB.json';
import dummy from '@client/internationalization/localization-messages/DUMMY/en-GB.json';
import eSigning from '@client/internationalization/localization-messages/e-signing/nem-id/NemIdSigningPage/en-GB.json';
import editProduct from '@client/internationalization/localization-messages/EditProduct/en-GB.json';
import energyGuide from '@client/internationalization/localization-messages/EnergyGuide/OrderForm/en-GB.json';
import errorHandling from '@client/internationalization/localization-messages/error-handling/LoggedOutPage/en-GB.json';
import errorPageErrorPage from '@client/internationalization/localization-messages/errorPage/ErrorPage/en-GB.json';
import errorScreen from '@client/internationalization/localization-messages/ErrorScreen/en-GB.json';
import explore from '@client/internationalization/localization-messages/Explore/en-GB.json';
import feedbackModal from '@client/internationalization/localization-messages/feedback-modal/en-GB.json';
import fields from '@client/internationalization/localization-messages/fields/en-GB.json';
import financialProductsLoansShow from '@client/internationalization/localization-messages/financial-products/loans/ShowNewFeatureLoanDialog/en-GB.json';
import financialProductsSavingsInvestment from '@client/internationalization/localization-messages/financial-products/savings/InvestmentInfoCard/en-GB.json';
import financialProductOurPartnersPage from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPage/en-GB.json';
import financialProductOurPartnersPageNew from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPageNew/en-GB.json';
import financialProductsSavingsOverview from '@client/internationalization/localization-messages/financial-products/savings/Overview/en-GB.json';
import financialProductSavingsAfterCancellation from '@client/internationalization/localization-messages/financial-products/savings/SavingsAfterCancellation/en-GB.json';
import financialProductsShowNewFeatureLoan from '@client/internationalization/localization-messages/financial-products/ShowNewFeatureLoanDialog/en-GB.json';
import financialProductsSwedBankConsolidate from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/HowItWorksPage/en-GB.json';
import financialSwedBankConsolidateIntro from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/IntroPage/en-GB.json';
import financialProductLoanApplication from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/LoanApplicationStatus/en-GB.json';
import financialProductsOfferPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OfferPage/en-GB.json';
import financialProductOrderCompleted from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderCompletedPage/en-GB.json';
import financialProductOrderPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderPage/en-GB.json';
import financialProductSelectContract from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectContractsPage/en-GB.json';
import financialProductsSelectPrincipal from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectPrincipalPage/en-GB.json';
import financialProductRepaymentPeriod from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/en-GB.json';
import generalIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/en-GB.json';
import generalSelectServices from '@client/internationalization/localization-messages/general/SelectServicesPage/en-GB.json';
import genericActionsPage from '@client/internationalization/localization-messages/genericActions/GenericActionsPage/en-GB.json';
import genericActionsSelectServices from '@client/internationalization/localization-messages/genericActions/SelectServicesPage/en-GB.json';
import genericActionsSubscriptionCancel from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/Cancel/en-GB.json';
import genericActionsSubscriptionsChangePayment from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/ChangePayment/en-GB.json';
import guidesContractDetailsAlternative from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/AlternativesSection/en-GB.json';
import guidesDealBanner from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/DealBanner/en-GB.json';
import guidesPaymentMethod from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/PaymentMethodsCard/en-GB.json';
import hack7CancelPage from '@client/internationalization/localization-messages/Hack7CancelPage/en-GB.json';
import header from '@client/internationalization/localization-messages/Header/en-GB.json';
import howToCancelListPage from '@client/internationalization/localization-messages/HowToCancelListPage/en-GB.json';
import howToCancelPage from '@client/internationalization/localization-messages/HowToCancelPage/en-GB.json';
import improveOrderPage from '@client/internationalization/localization-messages/improve/flowgen/OrderPage/en-GB.json';
import improveOrderSentPage from '@client/internationalization/localization-messages/improve/flowgen/OrderSentPage/en-GB.json';
import improveOtherAlternativePage from '@client/internationalization/localization-messages/improve/flowgen/OtherAlternativesPage/en-GB.json';
import improvePostalCodeInput from '@client/internationalization/localization-messages/improve/flowgen/postalCodeInput/en-GB.json';
import improveResultPage from '@client/internationalization/localization-messages/improve/flowgen/ResultPage/en-GB.json';
import improveFlowgen from '@client/internationalization/localization-messages/improve/flowgen/en-GB.json';
import insightPageInsightsCardsCancelOutcome from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationOutcome/en-GB.json';
import insightPageInsightsCardsCancelUpdate from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationUpdate/en-GB.json';
import insightPageOnboardingInsightCard from '@client/internationalization/localization-messages/InsightsPage/OnboardingInsightCard/en-GB.json';
import intervalToString from '@client/internationalization/localization-messages/interval-to-string/en-GB.json';
import landingPage from '@client/internationalization/localization-messages/LandingPage/en-GB.json';
import loadingScreen from '@client/internationalization/localization-messages/LoadingScreen/en-GB.json';
import loanApplicationsDetailsPage from '@client/internationalization/localization-messages/loan-applications/DetailsPage/en-GB.json';
import loanApplicationsLoadingError from '@client/internationalization/localization-messages/loan-applications/LoadingError/en-GB.json';
import loanApplicationsMenuDrawer from '@client/internationalization/localization-messages/loan-applications/MenuDrawer/en-GB.json';
import loanApplicationsOverview from '@client/internationalization/localization-messages/loan-applications/Overview/en-GB.json';
import loginPage from '@client/internationalization/localization-messages/LoginPage/en-GB.json';
import manageOrder from '@client/internationalization/localization-messages/ManageOrder/en-GB.json';
import manualAddContractPage from '@client/internationalization/localization-messages/ManualAddContractPage/en-GB.json';
import manualAddContractPricingPage from '@client/internationalization/localization-messages/ManualContractPricingPage/en-GB.json';
import menu from '@client/internationalization/localization-messages/Menu/en-GB.json';
import menuPage from '@client/internationalization/localization-messages/MenuPage/en-GB.json';
import movingBundledCancellationForm from '@client/internationalization/localization-messages/moving/BundledCancellationFromPage/en-GB.json';
import movingBundledCancellationPage from '@client/internationalization/localization-messages/moving/BundledCancellationPage/en-GB.json';
import movingBundledSwitchOrderForm from '@client/internationalization/localization-messages/moving/BundledSwitchOrderFormPage/en-GB.json';
import movingBundledSwitchPage from '@client/internationalization/localization-messages/moving/BundledSwitchSwitchPage/en-GB.json';
import thanksSwitchPage from '@client/internationalization/localization-messages/moving/ThanksSwitchPage/en-GB.json';
import movingGuide from '@client/internationalization/localization-messages/movingGuide/en-GB.json';
import movingGuideMovingDetails from '@client/internationalization/localization-messages/movingGuide/MovingDetailsPage/en-GB.json';
import movingGuideOneLastThingPage from '@client/internationalization/localization-messages/movingGuide/OneLastThingPage/en-GB.json';
import negotiateContractCompletePage from '@client/internationalization/localization-messages/negotiate/NegotiateContractCompletePage/en-GB.json';
import negotiateGuide from '@client/internationalization/localization-messages/negotiate/NegotiateGuide/en-GB.json';
import negotiationOngoingCard from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationCard/en-GB.json';
import negotiationOngoingStepper from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationStepper/en-GB.json';
import notificationOptInPage from '@client/internationalization/localization-messages/NotificationOptInPage/en-GB.json';
import onboardingPSD2Consent from '@client/internationalization/localization-messages/onboarding/PSD2ConsentPage/en-GB.json';
import onboardingPSD2Primer from '@client/internationalization/localization-messages/onboarding/PSD2PrimerPage/en-GB.json';
import onboardingBDSyncConnecting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryConnecting/en-GB.json';
import onboardingBDSyncError from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryErrorPage/en-GB.json';
import onboardingBDSyncFetch from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryFetchPage/en-GB.json';
import onboardingBDSyncSuccess from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/en-GB.json';
import onboardingBDSyncWaiting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/en-GB.json';
import onboardingCancelProduct from '@client/internationalization/localization-messages/onboarding-cancel-product/OnboardingCancelProductPages/en-GB.json';
import onboardingPsd2WelcomePage from '@client/internationalization/localization-messages/onboarding-psd2/WelcomePage/en-GB.json';
import onboardingPsd2Cards from '@client/internationalization/localization-messages/onboarding-psd2/Cards/en-GB.json';
import onboardingPsd2ConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/en-GB.json';
import onboardingPsd2OpBankConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPage/en-GB.json';
import onboardingPsd2OpBankConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPageV2/en-GB.json';
import onboardingPsd2ConsentPageDanskeBank from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/DanskeBankConsentPage/en-GB.json';
import onboardingPsd2RenewConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPage/en-GB.json';
import onboardingPsd2RenewConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPageV2/en-GB.json';
import orderedSubscriptionOngoing from '@client/internationalization/localization-messages/orderedSubscription/OngoingOrderCard/en-GB.json';
import orderedSubscriptionOrderedEnergy from '@client/internationalization/localization-messages/orderedSubscription/OrderedEnergyProductInfo/en-GB.json';
import orderedSubscriptionOrderedMobile from '@client/internationalization/localization-messages/orderedSubscription/OrderedMobileProductInfo/en-GB.json';
import orderedSubscriptionDetails from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetails/en-GB.json';
import orderedSubscriptionDetailsPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/en-GB.json';
import orderedSubscriptionInfoCard from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/en-GB.json';
import orderedSubscriptionPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionPage/en-GB.json';
import orderedSubscriptionOrderStepper from '@client/internationalization/localization-messages/orderedSubscription/OrderStepper/en-GB.json';
import orderedSubscServiceProviderInfo from '@client/internationalization/localization-messages/orderedSubscription/ServiceProviderInfoCard/en-GB.json';
import orderingSubscriptionTerms from '@client/internationalization/localization-messages/ordering-subscription/common/TermsAndConditionsPage/en-GB.json';
import orderingSubscriptionCompleted from '@client/internationalization/localization-messages/ordering-subscription/common/OrderCompletedPage/en-GB.json';
import orderingBroadbandAddressPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAddressPage/en-GB.json';
import orderingBroadbandAllAlternatives from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/en-GB.json';
import orderingBroadbandContract from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideContractCostPage/en-GB.json';
import orderingBroadbandIntroPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideIntroPage/en-GB.json';
import orderingBroadbandNoResultPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideNoResultPage/en-GB.json';
import orderingBroadbandSpeedPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideSpeedPage/en-GB.json';
import orderingBroadbandOptimizeOrder from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandOrderFormPage/en-GB.json';
import orderingBroadbandOptimizeProduct from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/en-GB.json';
import orderingBroadbandOptimizeProductCosts from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/en-GB.json';
import orderingElectricityOrder from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPage/en-GB.json';
import orderingElectricityOrderVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/en-GB.json';
import orderingElectricityGuidePrice from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/en-GB.json';
import orderingElectricityGuideAlternatives from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/en-GB.json';
import orderingElectricityGuideBinding from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/en-GB.json';
import orderingElectricityGuideBindingTime from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/en-GB.json';
import orderingElectricityGuideEnergy from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/en-GB.json';
import orderingElectricityGuideExplanation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/en-GB.json';
import orderingElectricityGuideFlow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/en-GB.json';
import orderingElectricityGuideHow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/en-GB.json';
import orderingElectricityGuideInformation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/en-GB.json';
import orderingElectricityGuideIntro from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/en-GB.json';
import orderingElectricityGuideLivingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/en-GB.json';
import orderingElectricityGuidePostalCodePage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/en-GB.json';
import orderingElectricityGuidePreferences from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/en-GB.json';
import orderingElectricityGuidePricingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/en-GB.json';
import orderingElectricityGuideProductPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/en-GB.json';
import orderingElectricityGuideSummaryPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/en-GB.json';
import orderingElectricityGuideTrading from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/en-GB.json';
import orderingElectricityGuideUsage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/en-GB.json';
import orderingElectricityGuideUsageResult from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/en-GB.json';
import orderingElectricityGuideUsageResultVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/en-GB.json';
import orderingElectricityGuidePostalCode from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/en-GB.json';
import orderingSubscriptionIndependentOrder from '@client/internationalization/localization-messages/ordering-subscription/independent/OrderCompletedPage/en-GB.json';
import ordersOverview from '@client/internationalization/localization-messages/OrdersOverview/en-GB.json';
import overviewChangePlanService from '@client/internationalization/localization-messages/overview/ChangePlanServiceIntroductionDrawer/en-GB.json';
import overviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/en-GB.json';
import overviewDeprecatedInsights from '@client/internationalization/localization-messages/overview/DeprecatedInsightsPage/OnboardingInsightCard/en-GB.json';
import overviewDiscoverPage from '@client/internationalization/localization-messages/overview/DiscoverPage/en-GB.json';
import overviewGenericAction from '@client/internationalization/localization-messages/overview/GenericActions/en-GB.json';
import overviewImprovementPage from '@client/internationalization/localization-messages/overview/ImprovementPage/en-GB.json';
import overviewImproveV2 from '@client/internationalization/localization-messages/overview/ImproveV2/en-GB.json';
import overviewInsightsCardsActionRating from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ActionRating/en-GB.json';
import overviewInsightsCardsCancellationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/CancellationUpdate/en-GB.json';
import overviewInsightsCardsChangeData from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ChangeDataplanUpdate/en-GB.json';
import overviewInsightsCardsIndependent from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/IndependentOrderUpdate/en-GB.json';
import overviewInsightsCardConfirmCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/en-GB.json';
import overviewContactUsButton from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ContactUsButton/en-GB.json';
import overviewInsightsCardsMonthlyCheck from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/MonthlyChecklist/en-GB.json';
import overviewInsightsCardsNegotiationOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationOutcome/en-GB.json';
import overviewInsightsCardsNegotiationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationUpdate/en-GB.json';
import overviewInsightsCardsProvider from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewProviderOffersCard/en-GB.json';
import overviewInsightsCardsSaveDeskFeedback from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/en-GB.json';
import overviewInsightCardsSaveDeskRequested from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOrderRequested/en-GB.json';
import overviewInsightsCardsSaveDeskPredefined from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/en-GB.json';
import overviewInsightsCardsSaveDeskResponse from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskResponseCard/en-GB.json';
import overviewInsightsCardsSwitchOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchOutcome/en-GB.json';
import overviewInsightsCardsSwitchUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchUpdate/en-GB.json';
import overviewInsightsCardsThankYou from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ThankYouCard/en-GB.json';
import overviewInsightsCardsTryCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/TryCancellationAgainButton/en-GB.json';
import overviewInsightsCardsUnreviewed from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnreviewedAction/en-GB.json';
import overviewInsightsCardsOnboarding from '@client/internationalization/localization-messages/overview/InsightsPage/OnboardingInsightCard/en-GB.json';
import overviewInsightsPrimerCardV2 from '@client/internationalization/localization-messages/overview/InsightsPage/PrimerCardV2/en-GB.json';
import overviewLoadingWithCogs from '@client/internationalization/localization-messages/overview/LoadingWithCogs/en-GB.json';
import overviewManageContract from '@client/internationalization/localization-messages/overview/ManageContractSection/SwitchProgressWithoutCancelStepper/en-GB.json';
import overviewMonthlyChecklist from '@client/internationalization/localization-messages/overview/MonthlyChecklist/en-GB.json';
import overviewMoreInfoNeeded from '@client/internationalization/localization-messages/overview/more-information-needed/en-GB.json';
import overviewOnboardingCards from '@client/internationalization/localization-messages/overview/OnboardingCards/en-GB.json';
import overviewQuickActionsCancel from '@client/internationalization/localization-messages/overview/QuickActionsCancel/en-GB.json';
import overviewQuickActionsCancelV2 from '@client/internationalization/localization-messages/overview/QuickActionsCancelV2/en-GB.json';
import overviewQuickActionsImprove from '@client/internationalization/localization-messages/overview/QuickActionsImprove/en-GB.json';
import overviewQuickActionsImproveV2 from '@client/internationalization/localization-messages/overview/QuickActionsImproveV2/en-GB.json';
import overviewSubscriptionFeedback from '@client/internationalization/localization-messages/overview/SubscriptionFeedback/en-GB.json';
import overviewSubscriptionSearch from '@client/internationalization/localization-messages/overview/SubscriptionSearch/en-GB.json';
import overviewSubscriptionSearchComponent from '@client/internationalization/localization-messages/overview/SubscriptionSearchOverviewComponent/en-GB.json';
import waysToSave from '@client/internationalization/localization-messages/overview/WaysToSave/en-GB.json';
import overview from '@client/internationalization/localization-messages/overview/en-GB.json';
import overviewBottomNavigation from '@client/internationalization/localization-messages/overviewBottomNavigation/en-GB.json';
import pauseIntelligentGuide from '@client/internationalization/localization-messages/pause/IntelligentGuidePage/en-GB.json';
import pauseSubscriptionPause from '@client/internationalization/localization-messages/pause/SubscriptionPausePage/en-GB.json';
import provideFeedbackPage from '@client/internationalization/localization-messages/ProvideFeedbackPage/en-GB.json';
import providerPortalDatepicker from '@client/internationalization/localization-messages/provider-portal-datepicker/DatePicker/en-GB.json';
import providerPortalSnackback from '@client/internationalization/localization-messages/provider-portal-snackback/Snackbar/en-GB.json';
import registrationPage from '@client/internationalization/localization-messages/RegistrationPage/en-GB.json';
import retentionOfferCard from '@client/internationalization/localization-messages/retention-offer/SuccessfulRetentionOfferCard/en-GB.json';
import saveDesk from '@client/internationalization/localization-messages/save-desk/en-GB.json';
import saveDeskOngoingCard from '@client/internationalization/localization-messages/save-desk/OngoingSaveDeskCard/en-GB.json';
import saveDeskOrderSent from '@client/internationalization/localization-messages/save-desk/OrderSentPage/en-GB.json';
import savingsDoYouWantToSave from '@client/internationalization/localization-messages/savings/DoYouWantToSaveDialog/en-GB.json';
import savingsAfterCancellation from '@client/internationalization/localization-messages/savings/SavingsAfterCancellation/en-GB.json';
import selectProductPage from '@client/internationalization/localization-messages/SelectProductPage/en-GB.json';
import settingsNotificationSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/en-GB.json';
import settingsNotificationPushSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/PushNotificationInstructions/en-GB.json';
import settingsPersonalDetails from '@client/internationalization/localization-messages/settings/PersonalDetailsPage/en-GB.json';
import settingsProfile from '@client/internationalization/localization-messages/settings/ProfilingSettingsPage/en-GB.json';
import settingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/en-GB.json';
import settingsUser from '@client/internationalization/localization-messages/settings/UserSettingsPage/en-GB.json';
import settingsViewGdprData from '@client/internationalization/localization-messages/settings/ViewGdprDataPage/en-GB.json';
import settingsSwedbank from '@client/internationalization/localization-messages/settings-swedbank/SwedbankNotificationSettingsPage/en-GB.json';
import sharedComponentsEmoji from '@client/internationalization/localization-messages/shared-components/EmojiRating/en-GB.json';
import sharedComponentsUpdateBankApp from '@client/internationalization/localization-messages/shared-components/UpdateBankApp/en-GB.json';
import sharedComponentsYouSavedComponent from '@client/internationalization/localization-messages/shared-components/YouSavedComponent/en-GB.json';
import showOnboardingFeatures from '@client/internationalization/localization-messages/ShowOnboardingFeaturesComponent/en-GB.json';
import signOutSuccessPage from '@client/internationalization/localization-messages/SignoutSuccessPage/en-GB.json';
import standardComponentsCurrency from '@client/internationalization/localization-messages/standard-components/CurrencyTextField/en-GB.json';
import standardComponentsDate from '@client/internationalization/localization-messages/standard-components/DatePicker/en-GB.json';
import standardComponentsEmail from '@client/internationalization/localization-messages/standard-components/EmailField/en-GB.json';
import standardComponentsGuide from '@client/internationalization/localization-messages/standard-components/GuidePage/en-GB.json';
import standardComponentsMobile from '@client/internationalization/localization-messages/standard-components/MobilePhoneNumberField/en-GB.json';
import standardComponentsPassword from '@client/internationalization/localization-messages/standard-components/PasswordField/en-GB.json';
import standardComponentsPersonal from '@client/internationalization/localization-messages/standard-components/PersonalNumberField/en-GB.json';
import standardComponentsPostalCode from '@client/internationalization/localization-messages/standard-components/PostalCodeField/en-GB.json';
import standardComponentsSnackbar from '@client/internationalization/localization-messages/standard-components/PostalCodeField/en-GB.json';
import subscriptionCancellationMeasure from '@client/internationalization/localization-messages/subscription/CancellationMeasurement/en-GB.json';
import subscriptionComponentsSelectable from '@client/internationalization/localization-messages/subscription/components/SelectableServiceList/en-GB.json';
import subscriptionRemoveSubscription from '@client/internationalization/localization-messages/subscription/RemoveSubscriptionDialog/en-GB.json';
import subscriptionSelectLoanService from '@client/internationalization/localization-messages/subscription/SelectLoanServicesPage/en-GB.json';
import subscriptionSelectLoanTransaction from '@client/internationalization/localization-messages/subscription/SelectLoanTransactionTypePage/en-GB.json';
import subscriptionSelectServices from '@client/internationalization/localization-messages/subscription/SelectServicesPage/en-GB.json';
import subscriptionPage from '@client/internationalization/localization-messages/subscription/SubscriptionPage/en-GB.json';
import subscriptionSettingsPage from '@client/internationalization/localization-messages/subscription/SubscriptionSettingsPage/en-GB.json';
import subscriptionUpdateCostPage from '@client/internationalization/localization-messages/subscription/SubscriptionUpdateCostPage/en-GB.json';
import subscriptionSwitchMeasurement from '@client/internationalization/localization-messages/subscription/SwitchMeasurement/en-GB.json';
import subscriptionUnresolvedGuide from '@client/internationalization/localization-messages/subscription/UnresolvedGuide/en-GB.json';
import subscriptionWhatsWrong from '@client/internationalization/localization-messages/subscription/WhatsWrongDialog/en-GB.json';
import subscriptionAlarmMore from '@client/internationalization/localization-messages/subscription-alarm/MorePage/en-GB.json';
import subscriptionAlarmPage from '@client/internationalization/localization-messages/subscription-alarm/SubscriptionAlarmPage/en-GB.json';
import subscriptionHubActions from '@client/internationalization/localization-messages/subscription-hub/PastActions/en-GB.json';
import subscriptionHub from '@client/internationalization/localization-messages/subscriptionHub/subscriptionHub/en-GB.json';
import supplierPortalChange from '@client/internationalization/localization-messages/supplier-portal-change/en-GB.json';
import supplierPortalSaveDesk from '@client/internationalization/localization-messages/supplier-portal-save-desk/en-GB.json';
import swedbankOverviewSync from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/en-GB.json';
import swedenPhoneCallsLimited from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/en-GB.json';
import syncSourcesAddSync from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcePage/en-GB.json';
import syncSourcesAddSyncSpeed from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcesSpeedDial/en-GB.json';
import syncSourcesDeleteSync from '@client/internationalization/localization-messages/sync-sources/DeleteSyncSourcesPage/en-GB.json';
import syncSourcesError from '@client/internationalization/localization-messages/sync-sources/Error/en-GB.json';
import syncSourcesNoSync from '@client/internationalization/localization-messages/sync-sources/NoSyncSources/en-GB.json';
import syncSourcesPopularSync from '@client/internationalization/localization-messages/sync-sources/PopularSyncSourcesList/en-GB.json';
import syncSourcesFetching from '@client/internationalization/localization-messages/sync-sources/SyncSourcesFetching/en-GB.json';
import syncSourcesList from '@client/internationalization/localization-messages/sync-sources/SyncSourcesList/en-GB.json';
import syncSourcesReminder from '@client/internationalization/localization-messages/sync-sources/SyncSourcesReminder/en-GB.json';
import syncSourcesTinkSync from '@client/internationalization/localization-messages/sync-sources/TinkSyncPage/en-GB.json';
import syncSourcesUpdateBank from '@client/internationalization/localization-messages/sync-sources/UpdateBankAuthsPage/en-GB.json';
import syncSourcesUpdateSync from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSources/en-GB.json';
import syncSourcesUpdateSyncButton from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSourcesButton/en-GB.json';
import terminationFormPage from '@client/internationalization/localization-messages/termination/TerminationFormPage/en-GB.json';
import terminationFormPCancelReason from '@client/internationalization/localization-messages/termination/TerminationFormPage/CancelReasonSection/en-GB.json';
import terminationInstructions from '@client/internationalization/localization-messages/termination/TerminationInstructionsPage/en-GB.json';
import termsAndCondition from '@client/internationalization/localization-messages/TermsAndConditionsUpload/en-GB.json';
import typeFormButton from '@client/internationalization/localization-messages/TypeformButton/en-GB.json';
import userFormPreview from '@client/internationalization/localization-messages/user-form-preview/en-GB.json';
import userFeedback from '@client/internationalization/localization-messages/userFeedback/en-GB.json';
import userDeleteSettings from '@client/internationalization/localization-messages/userSettings/UserDeletePage/en-GB.json';
import userDeleteVerifiedPage from '@client/internationalization/localization-messages/userSettings/UserDeleteVerifiedPage/en-GB.json';
import zendesk from '@client/internationalization/localization-messages/zendesk/en-GB.json';

export const enGBTranslations = {
  aboutUsPage: aboutUsPage,
  'AboutUsPage/BankAboutUsPage': bankAboutUsPage,
  'addon/mobile/RecommendationsPage': addonMobileRecommendationsPage,
  'addon/mobile/ResultPage': addonMobileResultPage,
  'auth/AuthStatusPage': authAuthStatusPage,
  'auth/BasicLoginPage': authBasicLoginPage,
  'auth/CreateAccountPage': authCreateAccountPage,
  'auth/LoginPage': authCreateAccountPage,
  'auth/OnboardingChooseBankPage': authOnboardingChooseBankPage,
  'auth/OnboardingEmailPage': authOnboardingEmailPage,
  'auth/PreLoginPage': authPreLoginPage,
  'auth/ResetPasswordPage': authResetPasswordPage,
  'auth/SyncSourcesError': authSyncSourcesError,
  'auth/SyncSourcesFetching': authSyncSourcesFetching,
  BadgeABTestCtas: badgeABTestCTAS,
  BadgeCtas: badgeCtas,
  'bank-sync/CancelProductOnboardPages': bankSyncCancelProduct,
  'bank-sync/DrawerPages': bankSyncDrawerPages,
  'bank-sync/LoadingScreen': bankSyncLoadingScreen,
  'bank-sync/OnBoardBankSync': bankSyncOnboardBankSync,
  CampaignIdSelect: campaignIdSelect,
  'cancel/CancellationOutcome': cancelCancellationOutcome,
  'cancellation/cancellationDetailsPage': cancellationDetailsPage,
  'cancellation/CancellationFormPage/CancellationInstructionsSection': cancellationFormCancellationInstruction,
  'cancellation/CancellationFormPage/CancellationTerms': cancellationFormCancellationTerms,
  'cancellation/CancellationFormPage/ConfirmSection': cancellationFormConfirmSection,
  'cancellation/CancellationFormPage/LightUserTerms': cancellationFormLightUserTerms,
  'cancellation/CancellationModalsContainer': cancellationModalsContainer,
  'cancellation/CancellationQuestionFlow': cancellationQuestionsFlow,
  'cancellation/CancellationReasonsDialog': cancellationReasonsDialog,
  'cancellation/CancellationSentPage': cancellationSentPage,
  'cancellation/CancellationStepper': cancellationStepper,
  'cancellation/DoYouWantToSaveDialog': cancellationDoYouWantToSaveDialog,
  'cancellation/IntelligentGuidePage': cancellationIntelligentGuidePage,
  'cancellation/NetflixCancellationInstructionsPage': cancellationNetflixCancellationInstructionsPage,
  'cancellation/signing/NemIdErrorPage': cancellationSigningNemIdErrorPage,
  'cancellation/signing/NemIdSigningPage': cancellationSigningNemIdSigningPage,
  'cancellation/signing/NoSigningPage': cancellationNoSigningPage,
  'cancellation/signing/NoSigningServicePage': cancellationNoSigningServicePage,
  CancellationPortal: cancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement': cancellationsCancellationPortalAgreement,
  categories: categories,
  'CategoryNames/CategoryNames': categoryNames,
  'change-dataplan/ChangeDataplan': changeDataPlan,
  'change-dataplan/ChangeDataplanCompletedPage': changeDataPlanCompletedPage,
  'change-dataplan/ChangeDataplanOrderFormPage': changeDataPlanOrderFormPage,
  'change-dataplan/ChangeDataplanPage': changeDataPlanPage,
  'change-payment/ChangePlanServiceIntroductionDrawer': changePaymentChangePlanServiceIntroductionDrawer,
  'change-payment/IntelligentGuidePage': changePaymentIntelligentGuidePage,
  'change-plan/ChangePlanServiceIntroductionDrawer': changePlanServiceIntroductionDrawer,
  'change-plan/IntelligentGuideExperimentPage': changePlanIntelligentGuideExperimentPage,
  'change-plan/IntelligentGuidePage': changePlanIntelligentGuidePage,
  ChangePlan: changePlan,
  'charity-donation': charityDonation,
  'complex-contract-guide/IntroPage': complexContractGuideIntroPage,
  'complex-contract-guide/LoanServiceQuestionPage': complexContractGuideLoanServiceQuestionPage,
  'complex-contract-guide/LoanTypeQuestionPage': complexContractGuideLoanTypeQuestionPage,
  'complex-contract-guide/ServiceQuestionPage': complexContractGuideServiceQuestionPage,
  'complex-contract-guide/Shared': complexContractGuideShared,
  'complex-contract-guide/SuccessPage': complexContractGuideSuccessPage,
  'conditions-and-policies/ConditionsAndPoliciesMenu': conditionsAndPoliciesMenu,
  'conditions-and-policies/CookiesPage': conditionsAndPoliciesCookiesPage,
  'conditions-and-policies/SecurityPage': conditionsAndPoliciesSecurityPage,
  'conditions-and-policies': conditionsAndPolicies,
  'contract/bindingPeriodDialog': contractBindingPeriodDialog,
  'contract/CancellationHistory': contractCancellationHistory,
  'contract/CancelledContract': contractCancelledContract,
  'contract/ContractCancellationOutcome': contractCancellationOutcome,
  'contract/ContractCardMessage': contractCardMessage,
  'contract/ContractDetails': contractDetails,
  'contract/ContractDetailsPage': contractDetailsPage,
  'contract/DoMoreComponent': contractDoMoreComponent,
  'contract/HistoryToolTip': contractHistoryToolTip,
  'contract/PriceHistoryLolipop': contractPriceHistory,
  'contract/SelectServicePageLolipop': contractSelectServicePage,
  'contract/SelectServicesListLolipop': contractSelectServicesList,
  'contract/SettingsButtonLolipop': contractSettingsButton,
  'contract/SettingsPageLolipop': contractSettingsPage,
  'contract/SubscriptionAlarmLolipop': contractSubscriptionAlarm,
  'contract/SubscriptionDetailsLolipop': contractSubscriptionDetails,
  'contract/SubscriptionReminder': contractSubscriptionReminder,
  'contract/subscriptionReminderDialog': contractSubscriptionReminderDialog,
  'contract/TransactionHistoryLolipop': contractTransactionHistory,
  'contract/UpdateCostLolipop': contractUpdateCost,
  'discover/CategoryPage': discoverCategoryPage,
  'discover/CategoryProviderPage': discoverCategoryProviderPage,
  'discover/components/ProductInformationHeader': discoverComponentsProductInfo,
  'discover/DiscoverWidget': discoverDiscoverWidget,
  'discover/FakeCategoryPage': discoverFakeCategoryPage,
  'discover/FakeCategorySubscriptionsPage': discoverFakeCategorySubscriptionPage,
  'discover/FitnessCategoryFeedbackPage': discoverFitnessCategoryFeedbackPage,
  'discover/MarketplacePage': discoverMarketplacePage,
  'discover/MobileOrderSentPage': discoverMobileOrderSentPage,
  'discover/OrderProductPage': discoverOrderProductPage,
  'discover/ProductRecommendations': discoverProductRecommendations,
  'discover/SearchSubscriptionAllCategoriesPage': discoverSearchSubscriptionAllCategories,
  'discover/SearchSubscriptionPage': discoverSearchSubscriptionPage,
  'discover/SearchSubscriptionResultPage': discoverSearchSubscriptionResultPage,
  DUMMY: dummy,
  'e-signing/nem-id/NemIdSigningPage': eSigning,
  EditProduct: editProduct,
  'EnergyGuide/OrderForm': energyGuide,
  'error-handling/LoggedOutPage': errorHandling,
  'errorPage/ErrorPage': errorPageErrorPage,
  ErrorScreen: errorScreen,
  Explore: explore,
  'feedback-modal': feedbackModal,
  fields: fields,
  'financial-products/loans/ShowNewFeatureLoanDialog': financialProductsLoansShow,
  'financial-products/savings/InvestmentInfoCard': financialProductsSavingsInvestment,
  'financial-products/savings/OurPartnersPage': financialProductOurPartnersPage,
  'financial-products/savings/OurPartnersPageNew': financialProductOurPartnersPageNew,
  'financial-products/savings/Overview': financialProductsSavingsOverview,
  'financial-products/savings/SavingsAfterCancellation': financialProductSavingsAfterCancellation,
  'financial-products/ShowNewFeatureLoanDialog': financialProductsShowNewFeatureLoan,
  'financial-products/swedbank-consolidate-loans/HowItWorksPage': financialProductsSwedBankConsolidate,
  'financial-products/swedbank-consolidate-loans/IntroPage': financialSwedBankConsolidateIntro,
  'financial-products/swedbank-consolidate-loans/LoanApplicationStatus': financialProductLoanApplication,
  'financial-products/swedbank-consolidate-loans/OfferPage': financialProductsOfferPage,
  'financial-products/swedbank-consolidate-loans/OrderCompletedPage': financialProductOrderCompleted,
  'financial-products/swedbank-consolidate-loans/OrderPage': financialProductOrderPage,
  'financial-products/swedbank-consolidate-loans/SelectContractsPage': financialProductSelectContract,
  'financial-products/swedbank-consolidate-loans/SelectPrincipalPage': financialProductsSelectPrincipal,
  'financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage': financialProductRepaymentPeriod,
  'general/IntelligentGuide': generalIntelligentGuide,
  'general/SelectServicesPage': generalSelectServices,
  'genericActions/GenericActionsPage': genericActionsPage,
  'genericActions/SelectServicesPage': genericActionsSelectServices,
  'genericActions/SubscriptionSearchPage/Cancel': genericActionsSubscriptionCancel,
  'genericActions/SubscriptionSearchPage/ChangePayment': genericActionsSubscriptionsChangePayment,
  'guides/GuideContractDetailsPage/AlternativesSection': guidesContractDetailsAlternative,
  'guides/GuideContractDetailsPage/DealBanner': guidesDealBanner,
  'guides/GuideContractDetailsPage/PaymentMethodsCard': guidesPaymentMethod,
  Hack7CancelPage: hack7CancelPage,
  Header: header,
  HowToCancelListPage: howToCancelListPage,
  HowToCancelPage: howToCancelPage,
  'improve/flowgen': improveFlowgen,
  'improve/flowgen/OrderPage': improveOrderPage,
  'improve/flowgen/OrderSentPage': improveOrderSentPage,
  'improve/flowgen/OtherAlternativesPage': improveOtherAlternativePage,
  'improve/flowgen/PostalCodeInput': improvePostalCodeInput,
  'improve/flowgen/ResultPage': improveResultPage,
  'InsightsPage/InsightCards/CancellationOutcome': insightPageInsightsCardsCancelOutcome,
  'InsightsPage/InsightCards/CancellationUpdate': insightPageInsightsCardsCancelUpdate,
  'InsightsPage/OnboardingInsightCard': insightPageOnboardingInsightCard,
  'interval-to-string': intervalToString,
  LandingPage: landingPage,
  LoadingScreen: loadingScreen,
  'loan-applications/DetailsPage': loanApplicationsDetailsPage,
  'loan-applications/LoadingError': loanApplicationsLoadingError,
  'loan-applications/MenuDrawer': loanApplicationsMenuDrawer,
  'loan-applications/Overview': loanApplicationsOverview,
  LoginPage: loginPage,
  ManageOrder: manageOrder,
  ManualAddContractPage: manualAddContractPage,
  ManualContractPricingPage: manualAddContractPricingPage,
  Menu: menu,
  MenuPage: menuPage,
  'moving/BundledCancellationFromPage': movingBundledCancellationForm,
  'moving/BundledCancellationPage': movingBundledCancellationPage,
  'moving/BundledSwitchOrderFormPage': movingBundledSwitchOrderForm,
  'moving/BundledSwitchSwitchPage': movingBundledSwitchPage,
  'moving/ThanksSwitchPage': thanksSwitchPage,
  movingGuide: movingGuide,
  'movingGuide/MovingDetailsPage': movingGuideMovingDetails,
  'movingGuide/OneLastThingPage': movingGuideOneLastThingPage,
  'negotiate/NegotiateContractCompletePage': negotiateContractCompletePage,
  'negotiate/NegotiateGuide': negotiateGuide,
  'negotiation/OngoingNegotiationCard': negotiationOngoingCard,
  'negotiation/OngoingNegotiationStepper': negotiationOngoingStepper,
  NotificationOptInPage: notificationOptInPage,
  'onboarding/PSD2ConsentPage': onboardingPSD2Consent,
  'onboarding/PSD2PrimerPage': onboardingPSD2Primer,
  'onboarding-bank-discovery-sync/BankDiscoveryConnecting': onboardingBDSyncConnecting,
  'onboarding-bank-discovery-sync/BankDiscoveryErrorPage': onboardingBDSyncError,
  'onboarding-bank-discovery-sync/BankDiscoveryFetchPage': onboardingBDSyncFetch,
  'onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage': onboardingBDSyncSuccess,
  'onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage': onboardingBDSyncWaiting,
  'onboarding-cancel-product/OnboardingCancelProductPages': onboardingCancelProduct,
  'onboarding-psd2/WelcomePage': onboardingPsd2WelcomePage,
  'onboarding-psd2/ConsentPage': onboardingPsd2ConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPage': onboardingPsd2OpBankConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPageV2': onboardingPsd2OpBankConsentPageV2,
  'onboarding-psd2/ConsentPage/DanskeBankConsentPage': onboardingPsd2ConsentPageDanskeBank,
  'onboarding-psd2/RenewConsentPage': onboardingPsd2RenewConsentPage,
  'onboarding-psd2/RenewConsentPageV2': onboardingPsd2RenewConsentPageV2,
  'onboarding/Cards': onboardingPsd2Cards,
  'orderedSubscription/OngoingOrderCard': orderedSubscriptionOngoing,
  'orderedSubscription/OrderedEnergyProductInfo': orderedSubscriptionOrderedEnergy,
  'orderedSubscription/OrderedMobileProductInfo': orderedSubscriptionOrderedMobile,
  'orderedSubscription/OrderedSubscriptionDetails': orderedSubscriptionDetails,
  'orderedSubscription/OrderedSubscriptionDetailsPage': orderedSubscriptionDetailsPage,
  'orderedSubscription/OrderedSubscriptionInfoCard': orderedSubscriptionInfoCard,
  'orderedSubscription/OrderedSubscriptionPage': orderedSubscriptionPage,
  'orderedSubscription/OrderStepper': orderedSubscriptionOrderStepper,
  'orderedSubscription/ServiceProviderInfoCard': orderedSubscServiceProviderInfo,
  'ordering-subscription/common/TermsAndConditionsPage': orderingSubscriptionTerms,
  'ordering-subscription/common/OrderCompletedPage': orderingSubscriptionCompleted,
  'ordering-subscription/broadband/common/BroadbandGuideAddressPage': orderingBroadbandAddressPage,
  'ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage': orderingBroadbandAllAlternatives,
  'ordering-subscription/broadband/common/BroadbandGuideContractCostPage': orderingBroadbandContract,
  'ordering-subscription/broadband/common/BroadbandGuideIntroPage': orderingBroadbandIntroPage,
  'ordering-subscription/broadband/common/BroadbandGuideNoResultPage': orderingBroadbandNoResultPage,
  'ordering-subscription/broadband/common/BroadbandGuideSpeedPage': orderingBroadbandSpeedPage,
  'ordering-subscription/broadband/optimize/BroadbandOrderFormPage': orderingBroadbandOptimizeOrder,
  'ordering-subscription/broadband/optimize/BroadbandProductPage': orderingBroadbandOptimizeProduct,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog':
    orderingBroadbandOptimizeProductCosts,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPage': orderingElectricityOrder,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant': orderingElectricityOrderVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog': orderingElectricityGuidePrice,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage':
    orderingElectricityGuideAlternatives,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage':
    orderingElectricityGuideBinding,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage':
    orderingElectricityGuideBindingTime,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage': orderingElectricityGuideEnergy,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage':
    orderingElectricityGuideExplanation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage': orderingElectricityGuideFlow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage': orderingElectricityGuideHow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage':
    orderingElectricityGuideInformation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage': orderingElectricityGuideIntro,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage': orderingElectricityGuideLivingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage':
    orderingElectricityGuidePostalCodePage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences': orderingElectricityGuidePreferences,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage':
    orderingElectricityGuidePricingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage': orderingElectricityGuideProductPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage': orderingElectricityGuideSummaryPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage': orderingElectricityGuideTrading,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage': orderingElectricityGuideUsage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage':
    orderingElectricityGuideUsageResult,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant':
    orderingElectricityGuideUsageResultVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityPostalCode': orderingElectricityGuidePostalCode,
  'ordering-subscription/independent/OrderCompletedPage': orderingSubscriptionIndependentOrder,
  OrdersOverview: ordersOverview,
  'overview/addManualSubscription': overviewAddManualSubscription,
  'overview/BindingTimeBar': overviewBindingTimeBar,
  'overview/BindingTimeNotice': bindingTimeNotice,
  'overview/ChangePlanServiceIntroductionDrawer': overviewChangePlanService,
  'overview/ContractAction': overviewContractAction,
  'overview/DeprecatedInsightsPage/OnboardingInsightCard': overviewDeprecatedInsights,
  'overview/DiscoverPage': overviewDiscoverPage,
  'overview/GenericActions': overviewGenericAction,
  'overview/ImprovementPage': overviewImprovementPage,
  'overview/ImproveV2': overviewImproveV2,
  'overview/InsightsPage': insightPage,
  'overview/InsightsPage/InsightCards/ActionRating': overviewInsightsCardsActionRating,
  'overview/InsightsPage/InsightCards/CancellationUpdate': overviewInsightsCardsCancellationUpdate,
  'overview/InsightsPage/InsightCards/ChangeDataplanUpdate': overviewInsightsCardsChangeData,
  'overview/InsightsPage/InsightCards/IndependentOrderUpdate': overviewInsightsCardsIndependent,
  'overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton': overviewInsightsCardConfirmCancellation,
  'overview/InsightsPage/InsightCards/ContactUsButton': overviewContactUsButton,
  'overview/InsightsPage/InsightCards/MonthlyChecklist': overviewInsightsCardsMonthlyCheck,
  'overview/InsightsPage/InsightCards/NegotiationOutcome': overviewInsightsCardsNegotiationOutcome,
  'overview/InsightsPage/InsightCards/NegotiationUpdate': overviewInsightsCardsNegotiationUpdate,
  'overview/InsightsPage/InsightCards/NewProviderOffersCard': overviewInsightsCardsProvider,
  'overview/InsightsPage/InsightCards/NewSubscriptionFound': insightPage_insightsCards_newSubscription,
  'overview/InsightsPage/InsightCards/SaveDeskFeedbackCard': overviewInsightsCardsSaveDeskFeedback,
  'overview/InsightsPage/InsightCards/SaveDeskOrderRequested': overviewInsightCardsSaveDeskRequested,
  'overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested': overviewInsightsCardsSaveDeskPredefined,
  'overview/InsightsPage/InsightCards/SaveDeskResponseCard': overviewInsightsCardsSaveDeskResponse,
  'overview/InsightsPage/InsightCards/SwitchOutcome': overviewInsightsCardsSwitchOutcome,
  'overview/InsightsPage/InsightCards/SwitchUpdate': overviewInsightsCardsSwitchUpdate,
  'overview/InsightsPage/InsightCards/ThankYouCard': overviewInsightsCardsThankYou,
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton': overviewInsightsCardsTryCancellation,
  'overview/InsightsPage/InsightCards/UnreviewedAction': overviewInsightsCardsUnreviewed,
  'overview/InsightsPage/InsightCards/AddonRecommendation': insightPage_insightsCards_addon,
  'overview/InsightsPage/BindingTimeEnding': insightPage_bindingTimeEnding,
  'overview/InsightsPage/InsightCards/DiscoverOngoing': insightPage_insightsCards_discoverOngoing,
  'overview/InsightsPage/InsightCards/DiscoverOutcome': insightPage_insightsCards_discoverOutcomeInsight,
  'overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard':
    insightPage_insightsCards_personalRecommend,
  'overview/InsightsPage/InsightCards/SaveDeskOngoingCard': insightPage_insightsCards_saveDeskOngoing,
  'overview/InsightsPage/InsightCards/SaveDeskOutcome': insightPage_insightsCards_saveDeskOutcome,
  'overview/InsightsPage/SubscriptionCostChanged': insightPage_subscriptionCostChanged,
  'overview/InsightsPage/InsightCards/UnresolvedSubscription': insightPage_unresolvedSubscription,
  'overview/InsightsPage/OnboardingInsightCard': overviewInsightsCardsOnboarding,
  'overview/InsightsPage/PrimerCardV2': overviewInsightsPrimerCardV2,
  'overview/LoadingWithCogs': overviewLoadingWithCogs,
  'overview/ManageContractSection/SwitchProgressWithoutCancelStepper': overviewManageContract,
  'overview/MonthlyChecklist': overviewMonthlyChecklist,
  'overview/MoreInfoNeeded': overviewMoreInfoNeeded,
  'overview/OnboardingCards': overviewOnboardingCards,
  'overview/QuickActionsCancel': overviewQuickActionsCancel,
  'overview/QuickActionsCancelV2': overviewQuickActionsCancelV2,
  'overview/QuickActionsImprove': overviewQuickActionsImprove,
  'overview/QuickActionsImproveV2': overviewQuickActionsImproveV2,
  'overview/SubscriptionFeedback': overviewSubscriptionFeedback,
  'overview/SubscriptionSearch': overviewSubscriptionSearch,
  'overview/SubscriptionSearchOverviewComponent': overviewSubscriptionSearchComponent,
  'overview/WaysToSave': waysToSave,
  'overview/OverviewPage': overviewPage,
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage':
    overviewPage_didYouCancel,
  'overview/OnboardingDrawer': onboardingDrawer,
  'overview/QuickActionsV2': overviewPage_quickActionsV2,
  overview: overview,
  overviewBottomNavigation: overviewBottomNavigation,
  'pause/IntelligentGuidePage': pauseIntelligentGuide,
  'pause/SubscriptionPausePage': pauseSubscriptionPause,
  provideFeedbackPage: provideFeedbackPage,
  'provider-portal-datepicker/DatePicker': providerPortalDatepicker,
  'provider-portal-snackback/Snackbar': providerPortalSnackback,
  RegistrationPage: registrationPage,
  'retention-offer/SuccessfulRetentionOfferCard': retentionOfferCard,
  'save-desk': saveDesk,
  'save-desk/OngoingSaveDeskCard': saveDeskOngoingCard,
  'save-desk/OrderSentPage': saveDeskOrderSent,
  'savings/DoYouWantToSaveDialog': savingsDoYouWantToSave,
  'savings/SavingsAfterCancellation': savingsAfterCancellation,
  SelectProductPage: selectProductPage,
  'settings/NotificationSettingsPage': settingsNotificationSettings,
  'settings/NotificationSettingsPage/PushNotificationInstructions': settingsNotificationPushSettings,
  'settings/PersonalDetailsPage': settingsPersonalDetails,
  'settings/ProfilingSettingsPage': settingsProfile,
  'settings/SettingsPage': settingsPage,
  'settings/UserSettingsPage': settingsUser,
  'settings/ViewGdprDataPage': settingsViewGdprData,
  'settings-swedbank/SwedbankNotificationSettingsPage': settingsSwedbank,
  'shared-components/EmojiRating': sharedComponentsEmoji,
  'shared-components/UpdateBankApp': sharedComponentsUpdateBankApp,
  'shared-components/YouSavedComponent': sharedComponentsYouSavedComponent,
  ShowOnboardingFeaturesComponent: showOnboardingFeatures,
  SignoutSuccessPage: signOutSuccessPage,
  'standard-components/CurrencyTextField': standardComponentsCurrency,
  'standard-components/EmailField': standardComponentsEmail,
  'standard-components/DatePicker': standardComponentsDate,
  'standard-components/GuidePage': standardComponentsGuide,
  'standard-components/MobilePhoneNumberField': standardComponentsMobile,
  'standard-components/PasswordField': standardComponentsPassword,
  'standard-components/PersonalNumberField': standardComponentsPersonal,
  'standard-components/PostalCodeField': standardComponentsPostalCode,
  'standard-components/Snackbar': standardComponentsSnackbar,
  'subscription/CancellationMeasurement': subscriptionCancellationMeasure,
  'subscription/components/SelectableServiceList': subscriptionComponentsSelectable,
  'subscription/RemoveSubscriptionDialog': subscriptionRemoveSubscription,
  'subscription/SelectLoanServicesPage': subscriptionSelectLoanService,
  'subscription/SelectLoanTransactionTypePage': subscriptionSelectLoanTransaction,
  'subscription/SelectServicesPage': subscriptionSelectServices,
  'subscription/SubscriptionPage': subscriptionPage,
  'subscription/SubscriptionSettingsPage': subscriptionSettingsPage,
  'subscription/SubscriptionUpdateCostPage': subscriptionUpdateCostPage,
  'subscription/SwitchMeasurement': subscriptionSwitchMeasurement,
  'subscription/UnresolvedGuide': subscriptionUnresolvedGuide,
  'subscription/WhatsWrongDialog': subscriptionWhatsWrong,
  'subscription-alarm/MorePage': subscriptionAlarmMore,
  'subscription-alarm/SubscriptionAlarmPage': subscriptionAlarmPage,
  'subscription-hub/PastActions': subscriptionHubActions,
  'subscriptionHub/subscriptionHub': subscriptionHub,
  'supplier-portal-change': supplierPortalChange,
  'supplier-portal-save-desk': supplierPortalSaveDesk,
  SwedbankOverviewSyncFetch: swedbankOverviewSync,
  SwedenPhoneCallsLimitationInput: swedenPhoneCallsLimited,
  'sync-sources/AddSyncSourcePage': syncSourcesAddSync,
  'sync-sources/AddSyncSourcesSpeedDial': syncSourcesAddSyncSpeed,
  'sync-sources/DeleteSyncSourcesPage': syncSourcesDeleteSync,
  'sync-sources/Error': syncSourcesError,
  'sync-sources/NoSyncSources': syncSourcesNoSync,
  'sync-sources/PopularSyncSourcesList': syncSourcesPopularSync,
  'sync-sources/SyncSourcesFetching': syncSourcesFetching,
  'sync-sources/SyncSourcesList': syncSourcesList,
  'sync-sources/TinkSyncPage': syncSourcesTinkSync,
  'sync-sources/UpdateBankAuthsPage': syncSourcesUpdateBank,
  'sync-sources/UpdateSyncSources': syncSourcesUpdateSync,
  'sync-sources/UpdateSyncSourcesButton': syncSourcesUpdateSyncButton,
  'sync-sources/SyncSourcesReminder': syncSourcesReminder,
  'termination/TerminationFormPage': terminationFormPage,
  'termination/TerminationFormPage/CancelReasonSection': terminationFormPCancelReason,
  'termination/TerminationInstructionsPage': terminationInstructions,
  TermsAndConditionsUpload: termsAndCondition,
  TypeformButton: typeFormButton,
  'user-form-preview': userFormPreview,
  userFeedback: userFeedback,
  'userSettings/UserDeletePage': userDeleteSettings,
  'userSettings/UserDeleteVerifiedPage': userDeleteVerifiedPage,
  zendesk: zendesk,
};
