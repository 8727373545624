import cancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/es-US.json';
import cancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/es-US.json';
import cancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/es-US.json';
import dummy from '@client/internationalization/localization-messages/DUMMY/es-US.json';
import generalIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/es-US.json';
import header from '@client/internationalization/localization-messages/Header/es-US.json';
import overviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/es-US.json';
import settingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/es-US.json';
import sharedComponentsUpdateBankApp from '@client/internationalization/localization-messages/shared-components/UpdateBankApp/es-US.json';
import sharedComponentsYouSavedComponent from '@client/internationalization/localization-messages/shared-components/YouSavedComponent/es-US.json';
import zendesk from '@client/internationalization/localization-messages/zendesk/es-US.json';

export const esUSTranslations = {
  'cancellation/CancellationSentPage': cancellationSentPage,
  CancellationPortal: cancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement': cancellationsCancellationPortalAgreement,
  DUMMY: dummy,
  'general/IntelligentGuide': generalIntelligentGuide,
  Header: header,
  'overview/ContractAction': overviewContractAction,
  'settings/SettingsPage': settingsPage,
  'shared-components/UpdateBankApp': sharedComponentsUpdateBankApp,
  'shared-components/YouSavedComponent': sharedComponentsYouSavedComponent,
  zendesk: zendesk,
};
