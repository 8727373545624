import { Currency } from '@client/models/subscription';
import type { ThemeType } from '@client/models/ThemeType';
import * as PropTypes from 'prop-types';
import type { DisplayName, DisplayNameIncoming } from './constants';
import { AppType, Locale, Market } from './constants';

// When updating models below also update a copy in'/integration-tests/cypress/models/index.ts'
export enum CancellationFeature {
  ENABLED = 'Enabled',
  ENABLED_WITH_TERMS = 'EnabledWithTerms',
  DISABLED = 'Disabled',
  PLACEHOLDER = 'Placeholder',
}

export interface CancelAndSaveFeature {
  enabled: boolean;
  urlString?: string;
}

export enum PauseFeature {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export enum ChangePaymentFeature {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export enum ChangePlanFeature {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export enum ResubscribeFeature {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export function isMerchantDirectEnabled(actionFeature: ActionFeatureToggle) {
  return actionFeature.enabled && actionFeature.merchantDirectEnabled;
}

export function isMerchantGuideEnabled(actionFeature: ActionFeatureToggle) {
  return actionFeature.enabled && actionFeature.merchantGuideEnabled;
}

export function isMerchantOutreachEnabled(actionFeature: ActionFeatureToggle) {
  return actionFeature.enabled && actionFeature.merchantOutreachEnabled;
}

/**
 * Returns true if any of the cancellation methods are enabled. If you want to check a specific method, use either of
 * the isMerchantDirectEnabled, isMerchantGuideEnabled or isMerchantOutreachEnabled functions.
 *
 * @param actionFeatures
 */
export function isCancellationEnabled(actionFeatures: ActionFeatures) {
  return (
    actionFeatures.cancel.enabled &&
    (actionFeatures.cancel.merchantDirectEnabled ||
      actionFeatures.cancel.merchantGuideEnabled ||
      actionFeatures.cancel.merchantOutreachEnabled)
  );
}

/**
 * Returns true if any of the action methods are enabled. If you want to check a specific method, use either of
 * the isMerchantDirectEnabled, isMerchantGuideEnabled or isMerchantOutreachEnabled functions.
 *
 * @param actionFeatures
 */
export function isChangePaymentEnabled(actionFeatures: ActionFeatures) {
  return (
    actionFeatures.changePayment.enabled &&
    (actionFeatures.changePayment.merchantDirectEnabled ||
      actionFeatures.changePayment.merchantGuideEnabled ||
      actionFeatures.changePayment.merchantOutreachEnabled)
  );
}

/**
 * Returns true if any of the action methods are enabled. If you want to check a specific method, use either of
 * the isMerchantDirectEnabled, isMerchantGuideEnabled or isMerchantOutreachEnabled functions.
 *
 * @param actionFeatures
 */
export function isChangePlanEnabled(actionFeatures: ActionFeatures) {
  return (
    actionFeatures.changePlan.enabled &&
    (actionFeatures.changePlan.merchantDirectEnabled ||
      actionFeatures.changePlan.merchantGuideEnabled ||
      actionFeatures.changePlan.merchantOutreachEnabled)
  );
}

/**
 * Returns true if any of the action methods are enabled. If you want to check a specific method, use either of
 * the isMerchantDirectEnabled, isMerchantGuideEnabled or isMerchantOutreachEnabled functions.
 *
 * @param actionFeatures
 */
export function isResubscribeEnabled(actionFeatures: ActionFeatures) {
  return (
    actionFeatures.resubscribe.enabled &&
    (actionFeatures.resubscribe.merchantDirectEnabled ||
      actionFeatures.resubscribe.merchantGuideEnabled ||
      actionFeatures.resubscribe.merchantOutreachEnabled)
  );
}

/**
 * Returns true if any of the action methods are enabled. If you want to check a specific method, use either of
 * the isMerchantDirectEnabled, isMerchantGuideEnabled or isMerchantOutreachEnabled functions.
 *
 * @param actionFeatures
 */
export function isPauseEnabled(actionFeatures: ActionFeatures) {
  return (
    actionFeatures.pause.enabled &&
    (actionFeatures.pause.merchantDirectEnabled ||
      actionFeatures.pause.merchantGuideEnabled ||
      actionFeatures.pause.merchantOutreachEnabled)
  );
}

export function isCancellationWithTermsEnabled(state: SubscriptionFeatures) {
  return state.cancellationEnabledWithTerms;
}

export const zendeskOnlyEmailEnabled = (features: AppFeatureToggles) =>
  features.helpCenter.enabled &&
  !features.helpCenter.callBackEnabled &&
  !features.helpCenter.callUsEnabled &&
  !features.helpCenter.chatEnabled;

export enum NavigationType {
  FULL = 'Full', // Full header and bottom-navigation
  MOBILE_ONLY = 'MobileOnly', // Simple header and bottom-navigation (on mobile only)
  PROFILE = 'Profile', // No bottom navigation, "profile" menu instead of normal header
}

export enum OnboardingType {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  CANCELPRODUCT = 'CancelProduct',
}

export interface NavigationFeatures {
  navigationType: NavigationType;
  feedback: boolean;
  userMenuIcon?: boolean;
  pageNavigationLogo: boolean;
}

export enum SigningFeature {
  BANKID = 'Bankid',
  NEMID = 'Nemid',
  CHECKBOX = 'Checkbox',
  CHECKBOX_WITH_PROOF = 'CheckboxWithProof',
}

export enum PersonalInformationType {
  STANDALONE = 'Standalone',
  BANK = 'Bank',
  DISABLED = 'Disabled',
}

export enum NotificationType {
  STANDALONE = 'Standalone',
  SWEDBANK = 'Swedbank',
  DISABLED = 'Disabled',
}

export enum SyncFlowType {
  PSD2 = 'Psd2',
}

export interface SyncFlowFeature {
  syncFlowType: SyncFlowType;
  onboarding: boolean;
  showRetryButton: boolean;
  showWelcomeScreen: boolean;
  enableRemoveAccount: boolean;
}

export interface AppFeatureToggles {
  bankDiscovery: boolean;
  helpCenter: HelpCenter;
  insights: boolean;
  logout?: LogoutFeatures;
  identify?: IdentifyFeatures;
  settings?: Settings;
  conditionsAndPolicies?: ConditionsAndPoliciesFeature;
  navigation?: NavigationFeatures;
  signing: SigningFeature;
  onboarding: OnboardingType;
  syncFlow?: SyncFlowFeature;
  useUnresolvedSubscriptionGuide: boolean;
  subscriptionFeatures: SubscriptionFeatures;
  actionFeatures: ActionFeatures;
  theme: ThemeType;
  typeformAgent?: string;
  beta: boolean;
  subscriptionHub: SubscriptionHubFeatures;
  searchPageFeatures: SearchPageFeatures;
}

export interface ActionFeatures {
  cancel: ActionFeatureToggle;
  resume: ActionFeatureToggle;
  changePlan: ActionFeatureToggle;
  block: ActionFeatureToggle;
  retentionOffers: ActionFeatureToggle;
  unblock: ActionFeatureToggle;
  resubscribe: ActionFeatureToggle;
  pause: ActionFeatureToggle;
  changePayment: ActionFeatureToggle;
}

export interface ActionFeatureToggle {
  enabled: boolean;
  merchantGuideEnabled: boolean;
  merchantDirectEnabled: boolean;
  merchantOutreachEnabled: boolean;
}

export interface SubscriptionHubFeatures {
  search?: SearchWidget;
  overview?: OverviewWidget;
  pastActions?: PastActionWidget;
}
export interface SubscriptionHubWidget {
  sortOrder: number;
}
export interface OverviewWidget extends SubscriptionHubWidget {
  sortOrder: number;
}
export interface SearchWidget extends SubscriptionHubWidget {
  sortOrder: number;
}

export interface PastActionWidget extends SubscriptionHubWidget {
  sortOrder: number;
}

export interface MerchantGuideFeature {
  phoneLinkEnabled: boolean;
}

export interface SubscriptionFeatures {
  cancelAndSave: CancelAndSaveFeature;
  cancellationEnabledWithTerms: boolean;
  deletingSubscriptions: boolean;
  saveDeskEnabled: boolean;
  editBdSubscriptions: boolean;
  reminderEnabled: boolean;
  merchantGuide: MerchantGuideFeature;
}
interface Settings {
  profiling: boolean;
  notification: {
    notificationType: NotificationType;
    pushEnabled: boolean;
  };
  /**
   * Whether to show the "change email" card.
   * Note: Users in bank apps can still change their email through the bank version of the personalInformation settings!
   */
  changeEmailCard: boolean;
  deleteAccount: boolean;
  personalInformation: PersonalInformationSettings;
  connectedBankAccounts: boolean;
}

export interface HelpCenter {
  enabled: boolean;
  chatEnabled: boolean;
  callUsEnabled: boolean;
  callBackEnabled: boolean;
  emailUsEnabled: boolean;
  zendeskBaseUrl: string;
  supportEmail: string;
}

export interface PersonalInformationSettings {
  personalInformationType: PersonalInformationType;
  enabledAddressForm: boolean;
  enabledPersonalNumber: boolean;
  enabledNameChange: boolean;
  enabledExtendedAddress: boolean;
}

interface ConditionsAndPoliciesFeature {
  publicConditionsAndPolicies?: boolean;
  userTermsPage?: boolean;
  privacyPolicyPage?: boolean;
  securityPage?: boolean;
  cookiesPage?: boolean;
  saveUserConsent?: boolean;
}

interface IdentifyFeatures {
  overview?: OverviewFeatures;
  identify: boolean;
  identifyOptIn: boolean;
}

interface OverviewFeatures {
  manuallyAddedSubscriptions: boolean;
  bankIdLogo: boolean;
  sslCertifiedIcon: boolean;
  complexContractGuide: boolean;
}

interface SearchPageFeatures {
  showIntroScreen: boolean;
}

export interface LogoutFeatures {
  enabled: boolean;
  logoutConfirmationFlow?: boolean;
  loginFromSignoutPage: boolean;
}

export interface BaseAppContext {
  appType: AppType;
  displayName: DisplayName;
  features: AppFeatureToggles;
  locale: Locale;
  embeddedView: EmbeddedViewConfig;
}

export interface AppContext extends BaseAppContext {
  market: Market;
  currency: Currency;
}

export interface AppContextIncoming {
  appType: AppType;
  displayName: DisplayNameIncoming;
  market: Market;
  features: AppFeatureToggles;
  locale: Locale;
  currency: Currency;
  embeddedView: EmbeddedViewConfig;
}

export interface EmbeddedViewProducts {
  subscriptions: boolean;
  cancellations: boolean;
}

export interface EmbeddedViewConfig {
  iframe: boolean;
  webView: boolean;
  embeddedViewProducts: EmbeddedViewProducts;
}

export const namePropType = PropTypes.oneOf(Object.values(AppType));
export const marketPropType = PropTypes.oneOf(Object.values(Market));
const localePropType = PropTypes.oneOf(Object.values(Locale));
const currencyPropType = PropTypes.oneOf(Object.values(Currency));
const cancellationType = PropTypes.oneOf(Object.values(CancellationFeature));
export const navigationTypePropType = PropTypes.oneOf(Object.values(NavigationType));
const signingFeature = PropTypes.oneOf(Object.values(SigningFeature));
const personalInformationTypePropType = PropTypes.oneOf(Object.values(PersonalInformationType));
const notificationTypePropType = PropTypes.oneOf(Object.values(NotificationType));
const onboardingType = PropTypes.oneOf(Object.values(OnboardingType));
const syncFlowType = PropTypes.oneOf(Object.values(SyncFlowType));

export const personalInformationSettingsPropType = PropTypes.shape({
  personalInformationType: personalInformationTypePropType.isRequired,
  enabledAddressForm: PropTypes.bool.isRequired,
  enabledPersonalNumber: PropTypes.bool.isRequired,
  enabledNameChange: PropTypes.bool.isRequired,
});

export const featuresPropType = PropTypes.shape({
  bankDiscovery: PropTypes.bool.isRequired,
  insights: PropTypes.bool.isRequired,
  helpCenter: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    chatEnabled: PropTypes.bool.isRequired,
    callUsEnabled: PropTypes.bool.isRequired,
    callBackEnabled: PropTypes.bool.isRequired,
    emailUsEnabled: PropTypes.bool.isRequired,
  }),
  logout: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    logoutConfirmationFlow: PropTypes.bool,
  }),
  overview: PropTypes.shape({
    manuallyAddedSubscriptions: PropTypes.bool.isRequired,
    bankIdLogo: PropTypes.bool.isRequired,
    sslCertifiedIcon: PropTypes.bool.isRequired,
  }),
  settings: PropTypes.shape({
    personalInformation: personalInformationSettingsPropType,
    profiling: PropTypes.bool.isRequired,
    notification: PropTypes.shape({
      notificationType: notificationTypePropType.isRequired,
      pushEnabled: PropTypes.bool.isRequired,
    }),
    /* *
     * Whether to show the "change email" card.
     * Note: Users in bank apps can still change their email through the bank version of the personalInformation settings!
     * */
    changeEmailCard: PropTypes.bool.isRequired,
    deleteAccount: PropTypes.bool.isRequired,
  }),
  conditionsAndPolicies: PropTypes.shape({
    publicConditionsAndPolicies: PropTypes.bool,
    userTermsPage: PropTypes.bool,
    privacyPolicyPage: PropTypes.bool,
    securityPage: PropTypes.bool,
    cookiesPage: PropTypes.bool,
  }),
  navigation: PropTypes.shape({
    navigationType: navigationTypePropType.isRequired,
    feedback: PropTypes.bool.isRequired,
  }),
  signing: signingFeature.isRequired,
  onboarding: onboardingType.isRequired,
  syncFlow: PropTypes.shape({
    syncFlowType: syncFlowType.isRequired,
    onboarding: PropTypes.bool,
  }),
  subscriptionFeatures: PropTypes.shape({
    cancellation: cancellationType.isRequired,
    deletingSubscriptions: PropTypes.bool.isRequired,
    saveDeskEnabled: PropTypes.bool.isRequired,
  }),
});

export const appContextPropType = PropTypes.shape({
  appType: namePropType.isRequired,
  displayName: PropTypes.string.isRequired,
  market: marketPropType.isRequired,
  locale: localePropType.isRequired,
  currency: currencyPropType.isRequired,
  features: featuresPropType.isRequired,
});
