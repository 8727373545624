import type { CancellationId } from '@client/models/cancellation';
import type { AmountWithCurrency } from '@client/models/subscription';
import type { ContractId, PaymentInterval, SubscriptionId } from '../subscription/types';
import type {
  BindingPeriod,
  InsightCancellationOutcome,
  InsightContent,
  InsightContentType,
  InsightContract,
  InsightServiceProvider,
  SupportedInsightContractAction,
} from './models';

export interface BindingPeriodEnding extends InsightContent {
  bindingPeriod: BindingPeriod;
  possibleActions: SupportedInsightContractAction[];
  subscriptionId: SubscriptionId;
  serviceProvider: InsightServiceProvider;
  service: string;
  contractId: ContractId;
  type: InsightContentType.BindingPeriodEnding;
}

export interface NewSubscriptionFound extends InsightContent {
  contracts: InsightContract[];
  paymentInterval: PaymentInterval;
  subscriptionId: SubscriptionId;
  serviceProvider: InsightServiceProvider;
  cost: AmountWithCurrency;
  type: InsightContentType.NewSubscriptionFound;
}

export interface SubscriptionCostChanged extends InsightContent {
  contracts: InsightContract[];
  oldPaymentInterval: PaymentInterval;
  subscriptionId: SubscriptionId;
  serviceProvider: InsightServiceProvider;
  newPaymentInterval: PaymentInterval;
  newCost: AmountWithCurrency;
  oldCost: AmountWithCurrency;
  oldCostWasLastActiveAt: string;
  type: InsightContentType.SubscriptionCostChanged;
}

export enum OnboardingStepStatus {
  Done = 'Done',
  NotDone = 'NotDone',
  NotValid = 'NotValid',
}

export enum OnboardingStepName {
  SignUp = 'SignUp',
  AddBank = 'AddBank',
  ResolveSubscriptions = 'ResolveSubscriptions',
  EnableNotifications = 'EnableNotifications',
  SeeCancellableSubscriptions = 'SeeCancellableSubscriptions',
  Feedback = 'Feedback',
}

export interface UnresolvedSubscriptions {
  type: string;
  subscriptionId: SubscriptionId;
}

export type OnboardingInsightContent = { [step in OnboardingStepName]: OnboardingStepStatus };

export interface OnboardingInsightUpdateIncoming {
  type: string;
  content: OnboardingInsightContent;
}

export interface CancellationOutcome extends InsightContent {
  /**
   * The cost of the cancelled contract per year.
   */
  yearlySavings?: AmountWithCurrency;
  /**
   * Can be either "Failed" or "Successful".
   */
  outcomeType: string;
  /**
   * The id of the subscription.
   */
  subscriptionId?: SubscriptionId;
  /**
   * Name, logotypeUrl, and id of the service provider for which cancellation is requested.
   */
  serviceProvider: InsightServiceProvider;
  /**
   * Can be one of.
   */
  outcomeStatus: InsightCancellationOutcome;
  /**
   * The id of the contract which is being cancelled.
   */
  contractId?: ContractId;
  /**
   * If the contract has a binding period then this is the end date of the binding period.
   */
  bindingTimeEndsAt?: string;
  /**
   * Name of the category service for which cancellation is requested.
   */
  serviceCategoryName: string;
  /**
   * The id of the cancellation.
   */
  cancellationId: CancellationId;
  /**
   * A message to the user.
   */
  messageFromServiceProvider?: string;
  /**
   * Progress of whole cancellation process.
   */
  progress: number;
  /**
   * Date that the cancellation process finished at.
   */
  cancellationFinishedAt?: string;
  /**
   * Date that the cancellation is withdrawn by user.
   */
  userWithdrawnCancellationAt?: string;
  /**
   * Date that the last payment for the contract is due.
   */
  lastPaymentAt?: string;
  type: InsightContentType.CancellationOutcome;
}

export interface CancellationOngoing extends InsightContent {
  /**
   * The last date at which Minna has sent a reminder to the service provider about the cancellation.
   */
  cancellationReminderSentDate?: string;
  /**
   * Name, logotypeUrl, and id of the service provider for which cancellation is requested.
   */
  serviceProvider: InsightServiceProvider;
  /**
   * The date that the cancellation was first requested, can be future dated.
   */
  cancellationRequestedAt: string;
  /**
   * Name of the service for which cancellation is requested.
   */
  serviceCategoryName: string;
  /**
   * The id of the cancelllation.
   */
  cancellationId: CancellationId;
  /**
   * Can be one of "New", "SentToSupplier", "", "", "", "", "", "", "", "", "",
   */
  cancellationStatusName: string;
  /**
   * Progress of whole Cancellation process.
   */
  progress: number;
  type: InsightContentType.CancellationOngoing;
}
