// When updating this model also update the copy in '/integration-tests/cypress/models/index.ts'
export enum AppType {
  Standalone = 'Standalone',
  Swedbank = 'Swedbank',
  OP = 'OpBank',
  ING = 'Ing',
  CancellationPortal = 'CancellationPortal',
  SupplierPortal = 'SupplierPortal',
  Admin = 'Admin',
  SB1 = 'Sb1',
  Tatra = 'Tatra',
  Undefined = 'Undefined', // When we don't know the platform of the user, but still need to fetch e.g localization messages we need to send a platform. Then this can be used.
}

// When updating this model also update the copy in '/integration-tests/cypress/models/index.ts'
export interface DisplayNameIncoming {
  default: string;
  [key: string]: string;
}

export type DisplayName = string;

// When updating this model also update the copy in '/integration-tests/cypress/models/index.ts'
export enum Market {
  Sweden = 'Sweden',
  Finland = 'Finland',
  Denmark = 'Denmark',
  Belgium = 'Belgium',
  UnitedKingdom = 'UnitedKingdom',
  Norway = 'Norway',
  Slovakia = 'Slovakia',
  Spain = 'Spain',
  UnitedStates = 'UnitedStates',
  Australia = 'Australia',
}

export const allMarkets = Object.values(Market);

export function hasPersonalIdentifier(market: Market): boolean {
  switch (market) {
    case Market.Sweden:
      return true;
    default:
      return false;
  }
}

export function hasSigning(market: Market): boolean {
  switch (market) {
    case Market.Sweden:
    case Market.Denmark:
      return true;
    default:
      return false;
  }
}

export function hasAlphanumericPostalCode(market: Market): boolean {
  switch (market) {
    case Market.UnitedKingdom:
      return true;
    default:
      return false;
  }
}

// When updating this model also update the copy in '/integration-tests/cypress/models/index.ts'
export enum Locale {
  enGB = 'en-GB',
  enUS = 'en-US',
  daDK = 'da-DK',
  fiFI = 'fi-FI',
  frFR = 'fr-FR',
  svSE = 'sv-SE',
  nbNO = 'nb-NO',
  skSK = 'sk-SK',
  nlBE = 'nl-BE',
  nlNL = 'nl-NL',
  frBE = 'fr-BE',
  esES = 'es-ES',
}

export const allLocales = Object.values(Locale);

export function languageCodeFromLocale(locale: Locale): string {
  return locale.substr(0, 2);
}
