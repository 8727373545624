import aboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/fi-FI.json';
import bankAboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/BankAboutUsPage/fi-FI.json';
import insightPage from '@client/internationalization/localization-messages/overview/InsightsPage/fi-FI.json';
import insightPage_insightsCards_addon from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/AddonRecommendation/fi-FI.json';
import insightPage_bindingTimeEnding from '@client/internationalization/localization-messages/overview/InsightsPage/BindingTimeEnding/fi-FI.json';
import insightPage_insightsCards_discoverOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOngoing/fi-FI.json';
import insightPage_insightsCards_discoverOutcomeInsight from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOutcome/fi-FI.json';
import insightPage_insightsCards_newSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewSubscriptionFound/fi-FI.json';
import insightPage_insightsCards_personalRecommend from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/fi-FI.json';
import insightPage_insightsCards_saveDeskOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOngoingCard/fi-FI.json';
import insightPage_insightsCards_saveDeskOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOutcome/fi-FI.json';
import insightPage_subscriptionCostChanged from '@client/internationalization/localization-messages/overview/InsightsPage/SubscriptionCostChanged/fi-FI.json';
import insightPage_unresolvedSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnresolvedSubscription/fi-FI.json';
import overviewPage from '@client/internationalization/localization-messages/overview/OverviewPage/fi-FI.json';
import overviewAddManualSubscription from '@client/internationalization/localization-messages/overview/addManualSubscription/fi-FI.json';
import overviewBindingTimeBar from '@client/internationalization/localization-messages/overview/BindingTimeBar/fi-FI.json';
import overviewPage_didYouCancel from '@client/internationalization/localization-messages/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/fi-FI.json';
import badgeCtas from '@client/internationalization/localization-messages/BadgeCtas/fi-FI.json';
import bindingTimeNotice from '@client/internationalization/localization-messages/overview/BindingTimeNotice/fi-FI.json';
import onboardingDrawer from '@client/internationalization/localization-messages/overview/OnboardingDrawer/fi-FI.json';
import overviewPage_quickActionsV2 from '@client/internationalization/localization-messages/overview/QuickActionsV2/fi-FI.json';
import addonMobileRecommendationsPage from '@client/internationalization/localization-messages/addon/mobile/RecommendationsPage/fi-FI.json';
import addonMobileResultPage from '@client/internationalization/localization-messages/addon/mobile/ResultPage/fi-FI.json';
import authAuthStatusPage from '@client/internationalization/localization-messages/auth/AuthStatusPage/fi-FI.json';
import authBasicLoginPage from '@client/internationalization/localization-messages/auth/BasicLoginPage/fi-FI.json';
import authCreateAccountPage from '@client/internationalization/localization-messages/auth/CreateAccountPage/fi-FI.json';
import authOnboardingChooseBankPage from '@client/internationalization/localization-messages/auth/OnboardingChooseBankPage/fi-FI.json';
import authOnboardingEmailPage from '@client/internationalization/localization-messages/auth/OnboardingEmailPage/fi-FI.json';
import authPreLoginPage from '@client/internationalization/localization-messages/auth/PreLoginPage/fi-FI.json';
import authResetPasswordPage from '@client/internationalization/localization-messages/auth/ResetPasswordPage/fi-FI.json';
import authSyncSourcesError from '@client/internationalization/localization-messages/auth/SyncSourcesError/fi-FI.json';
import authSyncSourcesFetching from '@client/internationalization/localization-messages/auth/SyncSourcesFetching/fi-FI.json';
import badgeABTestCTAS from '@client/internationalization/localization-messages/BadgeABTestCtas/fi-FI.json';
import bankSyncCancelProduct from '@client/internationalization/localization-messages/bank-sync/CancelProductOnboardPages/fi-FI.json';
import bankSyncDrawerPages from '@client/internationalization/localization-messages/bank-sync/DrawerPages/fi-FI.json';
import bankSyncLoadingScreen from '@client/internationalization/localization-messages/bank-sync/LoadingScreen/fi-FI.json';
import bankSyncOnboardBankSync from '@client/internationalization/localization-messages/bank-sync/OnBoardBankSync/fi-FI.json';
import campaignIdSelect from '@client/internationalization/localization-messages/CampaignIdSelect/fi-FI.json';
import cancelCancellationOutcome from '@client/internationalization/localization-messages/cancel/CancellationOutcome/fi-FI.json';
import cancellationDetailsPage from '@client/internationalization/localization-messages/cancellation/cancellationDetailsPage/fi-FI.json';
import cancellationFormCancellationInstruction from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationInstructionsSection/fi-FI.json';
import cancellationFormCancellationTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationTerms/fi-FI.json';
import cancellationFormConfirmSection from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/ConfirmSection/fi-FI.json';
import cancellationFormLightUserTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/LightUserTerms/fi-FI.json';
import cancellationModalsContainer from '@client/internationalization/localization-messages/cancellation/CancellationModalsContainer/fi-FI.json';
import cancellationQuestionsFlow from '@client/internationalization/localization-messages/cancellation/CancellationQuestionFlow/fi-FI.json';
import cancellationReasonsDialog from '@client/internationalization/localization-messages/cancellation/CancellationReasonsDialog/fi-FI.json';
import cancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/fi-FI.json';
import cancellationStepper from '@client/internationalization/localization-messages/cancellation/CancellationStepper/fi-FI.json';
import cancellationDoYouWantToSaveDialog from '@client/internationalization/localization-messages/cancellation/DoYouWantToSaveDialog/fi-FI.json';
import cancellationIntelligentGuidePage from '@client/internationalization/localization-messages/cancellation/IntelligentGuidePage/fi-FI.json';
import cancellationNetflixCancellationInstructionsPage from '@client/internationalization/localization-messages/cancellation/NetflixCancellationInstructionsPage/fi-FI.json';
import cancellationSigningNemIdErrorPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdErrorPage/fi-FI.json';
import cancellationSigningNemIdSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdSigningPage/fi-FI.json';
import cancellationNoSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningPage/fi-FI.json';
import cancellationNoSigningServicePage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningServicePage/fi-FI.json';
import cancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/fi-FI.json';
import cancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/fi-FI.json';
import categories from '@client/internationalization/localization-messages/categories/fi-FI.json';
import categoryNames from '@client/internationalization/localization-messages/CategoryNames/CategoryNames/fi-FI.json';
import changeDataPlan from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplan/fi-FI.json';
import changeDataPlanCompletedPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanCompletedPage/fi-FI.json';
import changeDataPlanOrderFormPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanOrderFormPage/fi-FI.json';
import changeDataPlanPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanPage/fi-FI.json';
import changePaymentChangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-payment/ChangePlanServiceIntroductionDrawer/fi-FI.json';
import changePaymentIntelligentGuidePage from '@client/internationalization/localization-messages/change-payment/IntelligentGuidePage/fi-FI.json';
import changePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-plan/ChangePlanServiceIntroductionDrawer/fi-FI.json';
import changePlanIntelligentGuideExperimentPage from '@client/internationalization/localization-messages/change-plan/IntelligentGuideExperimentPage/fi-FI.json';
import changePlanIntelligentGuidePage from '@client/internationalization/localization-messages/change-plan/IntelligentGuidePage/fi-FI.json';
import changePlan from '@client/internationalization/localization-messages/ChangePlan/fi-FI.json';
import charityDonation from '@client/internationalization/localization-messages/charity-donation/fi-FI.json';
import complexContractGuideIntroPage from '@client/internationalization/localization-messages/complex-contract-guide/IntroPage/fi-FI.json';
import complexContractGuideLoanServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanServiceQuestionPage/fi-FI.json';
import complexContractGuideLoanTypeQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanTypeQuestionPage/fi-FI.json';
import complexContractGuideServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/ServiceQuestionPage/fi-FI.json';
import complexContractGuideShared from '@client/internationalization/localization-messages/complex-contract-guide/shared/fi-FI.json';
import complexContractGuideSuccessPage from '@client/internationalization/localization-messages/complex-contract-guide/SuccessPage/fi-FI.json';
import conditionsAndPoliciesMenu from '@client/internationalization/localization-messages/conditions-and-policies/ConditionsAndPoliciesMenu/fi-FI.json';
import conditionsAndPoliciesCookiesPage from '@client/internationalization/localization-messages/conditions-and-policies/CookiesPage/fi-FI.json';
import conditionsAndPoliciesSecurityPage from '@client/internationalization/localization-messages/conditions-and-policies/SecurityPage/fi-FI.json';
import conditionsAndPolicies from '@client/internationalization/localization-messages/conditions-and-policies/fi-FI.json';
import contractBindingPeriodDialog from '@client/internationalization/localization-messages/contract/bindingPeriodDialog/fi-FI.json';
import contractCancellationHistory from '@client/internationalization/localization-messages/contract/CancellationHistory/fi-FI.json';
import contractCancelledContract from '@client/internationalization/localization-messages/contract/CancelledContract/fi-FI.json';
import contractCancellationOutcome from '@client/internationalization/localization-messages/contract/ContractCancellationOutcome/fi-FI.json';
import contractCardMessage from '@client/internationalization/localization-messages/contract/ContractCardMessage/fi-FI.json';
import contractDetails from '@client/internationalization/localization-messages/contract/ContractDetails/fi-FI.json';
import contractDetailsPage from '@client/internationalization/localization-messages/contract/ContractDetailsPage/fi-FI.json';
import contractDoMoreComponent from '@client/internationalization/localization-messages/contract/DoMoreComponent/fi-FI.json';
import contractHistoryToolTip from '@client/internationalization/localization-messages/contract/HistoryTooltip/fi-FI.json';
import contractPriceHistory from '@client/internationalization/localization-messages/contract/PriceHistoryLolipop/fi-FI.json';
import contractSelectServicePage from '@client/internationalization/localization-messages/contract/SelectServicePageLolipop/fi-FI.json';
import contractSelectServicesList from '@client/internationalization/localization-messages/contract/SelectServicesListLolipop/fi-FI.json';
import contractSettingsButton from '@client/internationalization/localization-messages/contract/SettingsButtonLolipop/fi-FI.json';
import contractSettingsPage from '@client/internationalization/localization-messages/contract/SettingsPageLolipop/fi-FI.json';
import contractSubscriptionAlarm from '@client/internationalization/localization-messages/contract/SubscriptionAlarmLolipop/fi-FI.json';
import contractSubscriptionDetails from '@client/internationalization/localization-messages/contract/SubscriptionDetailsLolipop/fi-FI.json';
import contractSubscriptionReminder from '@client/internationalization/localization-messages/contract/SubscriptionReminder/fi-FI.json';
import contractSubscriptionReminderDialog from '@client/internationalization/localization-messages/contract/subscriptionReminderDialog/fi-FI.json';
import contractTransactionHistory from '@client/internationalization/localization-messages/contract/TransactionHistoryLolipop/fi-FI.json';
import contractUpdateCost from '@client/internationalization/localization-messages/contract/UpdateCostLolipop/fi-FI.json';
import discoverCategoryPage from '@client/internationalization/localization-messages/discover/CategoryPage/fi-FI.json';
import discoverCategoryProviderPage from '@client/internationalization/localization-messages/discover/CategoryProviderPage/fi-FI.json';
import discoverComponentsProductInfo from '@client/internationalization/localization-messages/discover/components/ProductInformationHeader/fi-FI.json';
import discoverDiscoverWidget from '@client/internationalization/localization-messages/discover/DiscoverWidget/fi-FI.json';
import discoverFakeCategoryPage from '@client/internationalization/localization-messages/discover/FakeCategoryPage/fi-FI.json';
import discoverFakeCategorySubscriptionPage from '@client/internationalization/localization-messages/discover/FakeCategorySubscriptionsPage/fi-FI.json';
import discoverFitnessCategoryFeedbackPage from '@client/internationalization/localization-messages/discover/FitnessCategoryFeedbackPage/fi-FI.json';
import discoverMarketplacePage from '@client/internationalization/localization-messages/discover/MarketplacePage/fi-FI.json';
import discoverMobileOrderSentPage from '@client/internationalization/localization-messages/discover/MobileOrderSentPage/fi-FI.json';
import discoverOrderProductPage from '@client/internationalization/localization-messages/discover/OrderProductPage/fi-FI.json';
import discoverProductRecommendations from '@client/internationalization/localization-messages/discover/ProductRecommendations/fi-FI.json';
import discoverSearchSubscriptionAllCategories from '@client/internationalization/localization-messages/discover/SearchSubscriptionAllCategoriesPage/fi-FI.json';
import discoverSearchSubscriptionPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionPage/fi-FI.json';
import discoverSearchSubscriptionResultPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionResultPage/fi-FI.json';
import dummy from '@client/internationalization/localization-messages/DUMMY/fi-FI.json';
import eSigning from '@client/internationalization/localization-messages/e-signing/nem-id/NemIdSigningPage/fi-FI.json';
import editProduct from '@client/internationalization/localization-messages/EditProduct/fi-FI.json';
import energyGuide from '@client/internationalization/localization-messages/EnergyGuide/OrderForm/fi-FI.json';
import errorHandling from '@client/internationalization/localization-messages/error-handling/LoggedOutPage/fi-FI.json';
import errorPageErrorPage from '@client/internationalization/localization-messages/errorPage/ErrorPage/fi-FI.json';
import errorScreen from '@client/internationalization/localization-messages/ErrorScreen/fi-FI.json';
import explore from '@client/internationalization/localization-messages/Explore/fi-FI.json';
import feedbackModal from '@client/internationalization/localization-messages/feedback-modal/fi-FI.json';
import fields from '@client/internationalization/localization-messages/fields/fi-FI.json';
import financialProductsLoansShow from '@client/internationalization/localization-messages/financial-products/loans/ShowNewFeatureLoanDialog/fi-FI.json';
import financialProductsSavingsInvestment from '@client/internationalization/localization-messages/financial-products/savings/InvestmentInfoCard/fi-FI.json';
import financialProductOurPartnersPage from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPage/fi-FI.json';
import financialProductOurPartnersPageNew from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPageNew/fi-FI.json';
import financialProductsSavingsOverview from '@client/internationalization/localization-messages/financial-products/savings/Overview/fi-FI.json';
import financialProductSavingsAfterCancellation from '@client/internationalization/localization-messages/financial-products/savings/SavingsAfterCancellation/fi-FI.json';
import financialProductsShowNewFeatureLoan from '@client/internationalization/localization-messages/financial-products/ShowNewFeatureLoanDialog/fi-FI.json';
import financialProductsSwedBankConsolidate from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/HowItWorksPage/fi-FI.json';
import financialSwedBankConsolidateIntro from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/IntroPage/fi-FI.json';
import financialProductLoanApplication from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/LoanApplicationStatus/fi-FI.json';
import financialProductsOfferPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OfferPage/fi-FI.json';
import financialProductOrderCompleted from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderCompletedPage/fi-FI.json';
import financialProductOrderPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderPage/fi-FI.json';
import financialProductSelectContract from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectContractsPage/fi-FI.json';
import financialProductsSelectPrincipal from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectPrincipalPage/fi-FI.json';
import financialProductRepaymentPeriod from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/fi-FI.json';
import generalIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/fi-FI.json';
import generalSelectServices from '@client/internationalization/localization-messages/general/SelectServicesPage/fi-FI.json';
import genericActionsPage from '@client/internationalization/localization-messages/genericActions/GenericActionsPage/fi-FI.json';
import genericActionsSelectServices from '@client/internationalization/localization-messages/genericActions/SelectServicesPage/fi-FI.json';
import genericActionsSubscriptionCancel from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/Cancel/fi-FI.json';
import genericActionsSubscriptionsChangePayment from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/ChangePayment/fi-FI.json';
import guidesContractDetailsAlternative from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/AlternativesSection/fi-FI.json';
import guidesDealBanner from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/DealBanner/fi-FI.json';
import guidesPaymentMethod from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/PaymentMethodsCard/fi-FI.json';
import hack7CancelPage from '@client/internationalization/localization-messages/Hack7CancelPage/fi-FI.json';
import header from '@client/internationalization/localization-messages/Header/fi-FI.json';
import howToCancelListPage from '@client/internationalization/localization-messages/HowToCancelListPage/fi-FI.json';
import howToCancelPage from '@client/internationalization/localization-messages/HowToCancelPage/fi-FI.json';
import improveOrderPage from '@client/internationalization/localization-messages/improve/flowgen/OrderPage/fi-FI.json';
import improveOrderSentPage from '@client/internationalization/localization-messages/improve/flowgen/OrderSentPage/fi-FI.json';
import improveOtherAlternativePage from '@client/internationalization/localization-messages/improve/flowgen/OtherAlternativesPage/fi-FI.json';
import improvePostalCodeInput from '@client/internationalization/localization-messages/improve/flowgen/postalCodeInput/fi-FI.json';
import improveResultPage from '@client/internationalization/localization-messages/improve/flowgen/ResultPage/fi-FI.json';
import improveFlowgen from '@client/internationalization/localization-messages/improve/flowgen/fi-FI.json';
import insightPageInsightsCardsCancelOutcome from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationOutcome/fi-FI.json';
import insightPageInsightsCardsCancelUpdate from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationUpdate/fi-FI.json';
import insightPageOnboardingInsightCard from '@client/internationalization/localization-messages/InsightsPage/OnboardingInsightCard/fi-FI.json';
import intervalToString from '@client/internationalization/localization-messages/interval-to-string/fi-FI.json';
import landingPage from '@client/internationalization/localization-messages/LandingPage/fi-FI.json';
import loadingScreen from '@client/internationalization/localization-messages/LoadingScreen/fi-FI.json';
import loanApplicationsDetailsPage from '@client/internationalization/localization-messages/loan-applications/DetailsPage/fi-FI.json';
import loanApplicationsLoadingError from '@client/internationalization/localization-messages/loan-applications/LoadingError/fi-FI.json';
import loanApplicationsMenuDrawer from '@client/internationalization/localization-messages/loan-applications/MenuDrawer/fi-FI.json';
import loanApplicationsOverview from '@client/internationalization/localization-messages/loan-applications/Overview/fi-FI.json';
import loginPage from '@client/internationalization/localization-messages/LoginPage/fi-FI.json';
import manageOrder from '@client/internationalization/localization-messages/ManageOrder/fi-FI.json';
import manualAddContractPage from '@client/internationalization/localization-messages/ManualAddContractPage/fi-FI.json';
import manualAddContractPricingPage from '@client/internationalization/localization-messages/ManualContractPricingPage/fi-FI.json';
import menu from '@client/internationalization/localization-messages/Menu/fi-FI.json';
import menuPage from '@client/internationalization/localization-messages/MenuPage/fi-FI.json';
import movingBundledCancellationForm from '@client/internationalization/localization-messages/moving/BundledCancellationFromPage/fi-FI.json';
import movingBundledCancellationPage from '@client/internationalization/localization-messages/moving/BundledCancellationPage/fi-FI.json';
import movingBundledSwitchOrderForm from '@client/internationalization/localization-messages/moving/BundledSwitchOrderFormPage/fi-FI.json';
import movingBundledSwitchPage from '@client/internationalization/localization-messages/moving/BundledSwitchSwitchPage/fi-FI.json';
import thanksSwitchPage from '@client/internationalization/localization-messages/moving/ThanksSwitchPage/fi-FI.json';
import movingGuide from '@client/internationalization/localization-messages/movingGuide/fi-FI.json';
import movingGuideMovingDetails from '@client/internationalization/localization-messages/movingGuide/MovingDetailsPage/fi-FI.json';
import movingGuideOneLastThingPage from '@client/internationalization/localization-messages/movingGuide/OneLastThingPage/fi-FI.json';
import negotiateContractCompletePage from '@client/internationalization/localization-messages/negotiate/NegotiateContractCompletePage/fi-FI.json';
import negotiateGuide from '@client/internationalization/localization-messages/negotiate/NegotiateGuide/fi-FI.json';
import negotiationOngoingCard from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationCard/fi-FI.json';
import negotiationOngoingStepper from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationStepper/fi-FI.json';
import notificationOptInPage from '@client/internationalization/localization-messages/NotificationOptInPage/fi-FI.json';
import onboardingPSD2Consent from '@client/internationalization/localization-messages/onboarding/PSD2ConsentPage/fi-FI.json';
import onboardingPSD2Primer from '@client/internationalization/localization-messages/onboarding/PSD2PrimerPage/fi-FI.json';
import onboardingBDSyncConnecting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryConnecting/fi-FI.json';
import onboardingBDSyncError from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryErrorPage/fi-FI.json';
import onboardingBDSyncFetch from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryFetchPage/fi-FI.json';
import onboardingBDSyncSuccess from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/fi-FI.json';
import onboardingBDSyncWaiting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/fi-FI.json';
import onboardingCancelProduct from '@client/internationalization/localization-messages/onboarding-cancel-product/OnboardingCancelProductPages/fi-FI.json';
import onboardingPsd2WelcomePage from '@client/internationalization/localization-messages/onboarding-psd2/WelcomePage/fi-FI.json';
import onboardingPsd2Cards from '@client/internationalization/localization-messages/onboarding-psd2/Cards/fi-FI.json';
import onboardingPsd2ConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/fi-FI.json';
import onboardingPsd2OpBankConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPage/fi-FI.json';
import onboardingPsd2OpBankConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPageV2/fi-FI.json';
import onboardingPsd2ConsentPageDanskeBank from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/DanskeBankConsentPage/fi-FI.json';
import onboardingPsd2RenewConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPage/fi-FI.json';
import onboardingPsd2RenewConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPageV2/fi-FI.json';
import orderedSubscriptionOngoing from '@client/internationalization/localization-messages/orderedSubscription/OngoingOrderCard/fi-FI.json';
import orderedSubscriptionOrderedEnergy from '@client/internationalization/localization-messages/orderedSubscription/OrderedEnergyProductInfo/fi-FI.json';
import orderedSubscriptionOrderedMobile from '@client/internationalization/localization-messages/orderedSubscription/OrderedMobileProductInfo/fi-FI.json';
import orderedSubscriptionDetails from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetails/fi-FI.json';
import orderedSubscriptionDetailsPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/fi-FI.json';
import orderedSubscriptionInfoCard from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/fi-FI.json';
import orderedSubscriptionPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionPage/fi-FI.json';
import orderedSubscriptionOrderStepper from '@client/internationalization/localization-messages/orderedSubscription/OrderStepper/fi-FI.json';
import orderedSubscServiceProviderInfo from '@client/internationalization/localization-messages/orderedSubscription/ServiceProviderInfoCard/fi-FI.json';
import orderingSubscriptionTerms from '@client/internationalization/localization-messages/ordering-subscription/common/TermsAndConditionsPage/fi-FI.json';
import orderingSubscriptionCompleted from '@client/internationalization/localization-messages/ordering-subscription/common/OrderCompletedPage/fi-FI.json';
import orderingBroadbandAddressPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAddressPage/fi-FI.json';
import orderingBroadbandAllAlternatives from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/fi-FI.json';
import orderingBroadbandContract from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideContractCostPage/fi-FI.json';
import orderingBroadbandIntroPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideIntroPage/fi-FI.json';
import orderingBroadbandNoResultPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideNoResultPage/fi-FI.json';
import orderingBroadbandSpeedPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideSpeedPage/fi-FI.json';
import orderingBroadbandOptimizeOrder from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandOrderFormPage/fi-FI.json';
import orderingBroadbandOptimizeProduct from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/fi-FI.json';
import orderingBroadbandOptimizeProductCosts from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/fi-FI.json';
import orderingElectricityOrder from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPage/fi-FI.json';
import orderingElectricityOrderVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/fi-FI.json';
import orderingElectricityGuidePrice from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/fi-FI.json';
import orderingElectricityGuideAlternatives from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/fi-FI.json';
import orderingElectricityGuideBinding from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/fi-FI.json';
import orderingElectricityGuideBindingTime from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/fi-FI.json';
import orderingElectricityGuideEnergy from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/fi-FI.json';
import orderingElectricityGuideExplanation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/fi-FI.json';
import orderingElectricityGuideFlow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/fi-FI.json';
import orderingElectricityGuideHow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/fi-FI.json';
import orderingElectricityGuideInformation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/fi-FI.json';
import orderingElectricityGuideIntro from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/fi-FI.json';
import orderingElectricityGuideLivingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/fi-FI.json';
import orderingElectricityGuidePostalCodePage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/fi-FI.json';
import orderingElectricityGuidePreferences from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/fi-FI.json';
import orderingElectricityGuidePricingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/fi-FI.json';
import orderingElectricityGuideProductPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/fi-FI.json';
import orderingElectricityGuideSummaryPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/fi-FI.json';
import orderingElectricityGuideTrading from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/fi-FI.json';
import orderingElectricityGuideUsage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/fi-FI.json';
import orderingElectricityGuideUsageResult from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/fi-FI.json';
import orderingElectricityGuideUsageResultVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/fi-FI.json';
import orderingElectricityGuidePostalCode from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/fi-FI.json';
import orderingSubscriptionIndependentOrder from '@client/internationalization/localization-messages/ordering-subscription/independent/OrderCompletedPage/fi-FI.json';
import ordersOverview from '@client/internationalization/localization-messages/OrdersOverview/fi-FI.json';
import overviewChangePlanService from '@client/internationalization/localization-messages/overview/ChangePlanServiceIntroductionDrawer/fi-FI.json';
import overviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/fi-FI.json';
import overviewDeprecatedInsights from '@client/internationalization/localization-messages/overview/DeprecatedInsightsPage/OnboardingInsightCard/fi-FI.json';
import overviewDiscoverPage from '@client/internationalization/localization-messages/overview/DiscoverPage/fi-FI.json';
import overviewGenericAction from '@client/internationalization/localization-messages/overview/GenericActions/fi-FI.json';
import overviewImprovementPage from '@client/internationalization/localization-messages/overview/ImprovementPage/fi-FI.json';
import overviewImproveV2 from '@client/internationalization/localization-messages/overview/ImproveV2/fi-FI.json';
import overviewInsightsCardsActionRating from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ActionRating/fi-FI.json';
import overviewInsightsCardsCancellationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/CancellationUpdate/fi-FI.json';
import overviewInsightsCardsChangeData from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ChangeDataplanUpdate/fi-FI.json';
import overviewInsightsCardsIndependent from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/IndependentOrderUpdate/fi-FI.json';
import overviewInsightsCardConfirmCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/fi-FI.json';
import overviewContactUsButton from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ContactUsButton/fi-FI.json';
import overviewInsightsCardsMonthlyCheck from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/MonthlyChecklist/fi-FI.json';
import overviewInsightsCardsNegotiationOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationOutcome/fi-FI.json';
import overviewInsightsCardsNegotiationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationUpdate/fi-FI.json';
import overviewInsightsCardsProvider from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewProviderOffersCard/fi-FI.json';
import overviewInsightsCardsSaveDeskFeedback from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/fi-FI.json';
import overviewInsightCardsSaveDeskRequested from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOrderRequested/fi-FI.json';
import overviewInsightsCardsSaveDeskPredefined from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/fi-FI.json';
import overviewInsightsCardsSaveDeskResponse from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskResponseCard/fi-FI.json';
import overviewInsightsCardsSwitchOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchOutcome/fi-FI.json';
import overviewInsightsCardsSwitchUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchUpdate/fi-FI.json';
import overviewInsightsCardsThankYou from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ThankYouCard/fi-FI.json';
import overviewInsightsCardsTryCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/TryCancellationAgainButton/fi-FI.json';
import overviewInsightsCardsUnreviewed from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnreviewedAction/fi-FI.json';
import overviewInsightsCardsOnboarding from '@client/internationalization/localization-messages/overview/InsightsPage/OnboardingInsightCard/fi-FI.json';
import overviewInsightsPrimerCardV2 from '@client/internationalization/localization-messages/overview/InsightsPage/PrimerCardV2/fi-FI.json';
import overviewLoadingWithCogs from '@client/internationalization/localization-messages/overview/LoadingWithCogs/fi-FI.json';
import overviewManageContract from '@client/internationalization/localization-messages/overview/ManageContractSection/SwitchProgressWithoutCancelStepper/fi-FI.json';
import overviewMonthlyChecklist from '@client/internationalization/localization-messages/overview/MonthlyChecklist/fi-FI.json';
import overviewMoreInfoNeeded from '@client/internationalization/localization-messages/overview/more-information-needed/fi-FI.json';
import overviewOnboardingCards from '@client/internationalization/localization-messages/overview/OnboardingCards/fi-FI.json';
import overviewQuickActionsCancel from '@client/internationalization/localization-messages/overview/QuickActionsCancel/fi-FI.json';
import overviewQuickActionsCancelV2 from '@client/internationalization/localization-messages/overview/QuickActionsCancelV2/fi-FI.json';
import overviewQuickActionsImprove from '@client/internationalization/localization-messages/overview/QuickActionsImprove/fi-FI.json';
import overviewQuickActionsImproveV2 from '@client/internationalization/localization-messages/overview/QuickActionsImproveV2/fi-FI.json';
import overviewSubscriptionFeedback from '@client/internationalization/localization-messages/overview/SubscriptionFeedback/fi-FI.json';
import overviewSubscriptionSearch from '@client/internationalization/localization-messages/overview/SubscriptionSearch/fi-FI.json';
import overviewSubscriptionSearchComponent from '@client/internationalization/localization-messages/overview/SubscriptionSearchOverviewComponent/fi-FI.json';
import waysToSave from '@client/internationalization/localization-messages/overview/WaysToSave/fi-FI.json';
import overview from '@client/internationalization/localization-messages/overview/fi-FI.json';
import overviewBottomNavigation from '@client/internationalization/localization-messages/overviewBottomNavigation/fi-FI.json';
import pauseIntelligentGuide from '@client/internationalization/localization-messages/pause/IntelligentGuidePage/fi-FI.json';
import pauseSubscriptionPause from '@client/internationalization/localization-messages/pause/SubscriptionPausePage/fi-FI.json';
import provideFeedbackPage from '@client/internationalization/localization-messages/ProvideFeedbackPage/fi-FI.json';
import providerPortalDatepicker from '@client/internationalization/localization-messages/provider-portal-datepicker/DatePicker/fi-FI.json';
import providerPortalSnackback from '@client/internationalization/localization-messages/provider-portal-snackback/Snackbar/fi-FI.json';
import registrationPage from '@client/internationalization/localization-messages/RegistrationPage/fi-FI.json';
import retentionOfferCard from '@client/internationalization/localization-messages/retention-offer/SuccessfulRetentionOfferCard/fi-FI.json';
import saveDesk from '@client/internationalization/localization-messages/save-desk/fi-FI.json';
import saveDeskOngoingCard from '@client/internationalization/localization-messages/save-desk/OngoingSaveDeskCard/fi-FI.json';
import saveDeskOrderSent from '@client/internationalization/localization-messages/save-desk/OrderSentPage/fi-FI.json';
import savingsDoYouWantToSave from '@client/internationalization/localization-messages/savings/DoYouWantToSaveDialog/fi-FI.json';
import savingsAfterCancellation from '@client/internationalization/localization-messages/savings/SavingsAfterCancellation/fi-FI.json';
import selectProductPage from '@client/internationalization/localization-messages/SelectProductPage/fi-FI.json';
import settingsNotificationSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/fi-FI.json';
import settingsNotificationPushSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/PushNotificationInstructions/fi-FI.json';
import settingsPersonalDetails from '@client/internationalization/localization-messages/settings/PersonalDetailsPage/fi-FI.json';
import settingsProfile from '@client/internationalization/localization-messages/settings/ProfilingSettingsPage/fi-FI.json';
import settingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/fi-FI.json';
import settingsUser from '@client/internationalization/localization-messages/settings/UserSettingsPage/fi-FI.json';
import settingsViewGdprData from '@client/internationalization/localization-messages/settings/ViewGdprDataPage/fi-FI.json';
import settingsSwedbank from '@client/internationalization/localization-messages/settings-swedbank/SwedbankNotificationSettingsPage/fi-FI.json';
import sharedComponentsEmoji from '@client/internationalization/localization-messages/shared-components/EmojiRating/fi-FI.json';
import sharedComponentsUpdateBankApp from '@client/internationalization/localization-messages/shared-components/UpdateBankApp/fi-FI.json';
import sharedComponentsYouSavedComponent from '@client/internationalization/localization-messages/shared-components/YouSavedComponent/fi-FI.json';
import showOnboardingFeatures from '@client/internationalization/localization-messages/ShowOnboardingFeaturesComponent/fi-FI.json';
import signOutSuccessPage from '@client/internationalization/localization-messages/SignoutSuccessPage/fi-FI.json';
import standardComponentsCurrency from '@client/internationalization/localization-messages/standard-components/CurrencyTextField/fi-FI.json';
import standardComponentsDate from '@client/internationalization/localization-messages/standard-components/DatePicker/fi-FI.json';
import standardComponentsEmail from '@client/internationalization/localization-messages/standard-components/EmailField/fi-FI.json';
import standardComponentsGuide from '@client/internationalization/localization-messages/standard-components/GuidePage/fi-FI.json';
import standardComponentsMobile from '@client/internationalization/localization-messages/standard-components/MobilePhoneNumberField/fi-FI.json';
import standardComponentsPassword from '@client/internationalization/localization-messages/standard-components/PasswordField/fi-FI.json';
import standardComponentsPersonal from '@client/internationalization/localization-messages/standard-components/PersonalNumberField/fi-FI.json';
import standardComponentsPostalCode from '@client/internationalization/localization-messages/standard-components/PostalCodeField/fi-FI.json';
import standardComponentsSnackbar from '@client/internationalization/localization-messages/standard-components/PostalCodeField/fi-FI.json';
import subscriptionCancellationMeasure from '@client/internationalization/localization-messages/subscription/CancellationMeasurement/fi-FI.json';
import subscriptionComponentsSelectable from '@client/internationalization/localization-messages/subscription/components/SelectableServiceList/fi-FI.json';
import subscriptionRemoveSubscription from '@client/internationalization/localization-messages/subscription/RemoveSubscriptionDialog/fi-FI.json';
import subscriptionSelectLoanService from '@client/internationalization/localization-messages/subscription/SelectLoanServicesPage/fi-FI.json';
import subscriptionSelectLoanTransaction from '@client/internationalization/localization-messages/subscription/SelectLoanTransactionTypePage/fi-FI.json';
import subscriptionSelectServices from '@client/internationalization/localization-messages/subscription/SelectServicesPage/fi-FI.json';
import subscriptionPage from '@client/internationalization/localization-messages/subscription/SubscriptionPage/fi-FI.json';
import subscriptionSettingsPage from '@client/internationalization/localization-messages/subscription/SubscriptionSettingsPage/fi-FI.json';
import subscriptionUpdateCostPage from '@client/internationalization/localization-messages/subscription/SubscriptionUpdateCostPage/fi-FI.json';
import subscriptionSwitchMeasurement from '@client/internationalization/localization-messages/subscription/SwitchMeasurement/fi-FI.json';
import subscriptionUnresolvedGuide from '@client/internationalization/localization-messages/subscription/UnresolvedGuide/fi-FI.json';
import subscriptionWhatsWrong from '@client/internationalization/localization-messages/subscription/WhatsWrongDialog/fi-FI.json';
import subscriptionAlarmMore from '@client/internationalization/localization-messages/subscription-alarm/MorePage/fi-FI.json';
import subscriptionAlarmPage from '@client/internationalization/localization-messages/subscription-alarm/SubscriptionAlarmPage/fi-FI.json';
import subscriptionHubActions from '@client/internationalization/localization-messages/subscription-hub/PastActions/fi-FI.json';
import subscriptionHub from '@client/internationalization/localization-messages/subscriptionHub/subscriptionHub/fi-FI.json';
import supplierPortalChange from '@client/internationalization/localization-messages/supplier-portal-change/fi-FI.json';
import supplierPortalSaveDesk from '@client/internationalization/localization-messages/supplier-portal-save-desk/fi-FI.json';
import swedbankOverviewSync from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/fi-FI.json';
import swedenPhoneCallsLimited from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/fi-FI.json';
import syncSourcesAddSync from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcePage/fi-FI.json';
import syncSourcesAddSyncSpeed from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcesSpeedDial/fi-FI.json';
import syncSourcesDeleteSync from '@client/internationalization/localization-messages/sync-sources/DeleteSyncSourcesPage/fi-FI.json';
import syncSourcesError from '@client/internationalization/localization-messages/sync-sources/Error/fi-FI.json';
import syncSourcesNoSync from '@client/internationalization/localization-messages/sync-sources/NoSyncSources/fi-FI.json';
import syncSourcesPopularSync from '@client/internationalization/localization-messages/sync-sources/PopularSyncSourcesList/fi-FI.json';
import syncSourcesFetching from '@client/internationalization/localization-messages/sync-sources/SyncSourcesFetching/fi-FI.json';
import syncSourcesList from '@client/internationalization/localization-messages/sync-sources/SyncSourcesList/fi-FI.json';
import syncSourcesReminder from '@client/internationalization/localization-messages/sync-sources/SyncSourcesReminder/fi-FI.json';
import syncSourcesTinkSync from '@client/internationalization/localization-messages/sync-sources/TinkSyncPage/fi-FI.json';
import syncSourcesUpdateBank from '@client/internationalization/localization-messages/sync-sources/UpdateBankAuthsPage/fi-FI.json';
import syncSourcesUpdateSync from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSources/fi-FI.json';
import syncSourcesUpdateSyncButton from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSourcesButton/fi-FI.json';
import terminationFormPage from '@client/internationalization/localization-messages/termination/TerminationFormPage/fi-FI.json';
import terminationFormPCancelReason from '@client/internationalization/localization-messages/termination/TerminationFormPage/CancelReasonSection/fi-FI.json';
import terminationInstructions from '@client/internationalization/localization-messages/termination/TerminationInstructionsPage/fi-FI.json';
import termsAndCondition from '@client/internationalization/localization-messages/TermsAndConditionsUpload/fi-FI.json';
import typeFormButton from '@client/internationalization/localization-messages/TypeformButton/fi-FI.json';
import userFormPreview from '@client/internationalization/localization-messages/user-form-preview/fi-FI.json';
import userFeedback from '@client/internationalization/localization-messages/userFeedback/fi-FI.json';
import userDeleteSettings from '@client/internationalization/localization-messages/userSettings/UserDeletePage/fi-FI.json';
import userDeleteVerifiedPage from '@client/internationalization/localization-messages/userSettings/UserDeleteVerifiedPage/fi-FI.json';
import zendesk from '@client/internationalization/localization-messages/zendesk/fi-FI.json';
import opBankaboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/OpBank/fi-FI.json';
import opBankbankAboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/BankAboutUsPage/OpBank/fi-FI.json';
import opBankinsightPage from '@client/internationalization/localization-messages/overview/InsightsPage/OpBank/fi-FI.json';
import opBankinsightPage_insightsCards_addon from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/AddonRecommendation/OpBank/fi-FI.json';
import opBankinsightPage_bindingTimeEnding from '@client/internationalization/localization-messages/overview/InsightsPage/BindingTimeEnding/OpBank/fi-FI.json';
import opBankinsightPage_insightsCards_discoverOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOngoing/OpBank/fi-FI.json';
import opBankinsightPage_insightsCards_discoverOutcomeInsight from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOutcome/OpBank/fi-FI.json';
import opBankinsightPage_insightsCards_newSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewSubscriptionFound/OpBank/fi-FI.json';
import opBankinsightPage_insightsCards_personalRecommend from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/OpBank/fi-FI.json';
import opBankinsightPage_insightsCards_saveDeskOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOngoingCard/OpBank/fi-FI.json';
import opBankinsightPage_insightsCards_saveDeskOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOutcome/OpBank/fi-FI.json';
import opBankinsightPage_subscriptionCostChanged from '@client/internationalization/localization-messages/overview/InsightsPage/SubscriptionCostChanged/OpBank/fi-FI.json';
import opBankinsightPage_unresolvedSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnresolvedSubscription/OpBank/fi-FI.json';
import opBankoverviewPage from '@client/internationalization/localization-messages/overview/OverviewPage/OpBank/fi-FI.json';
import opBankoverviewAddManualSubscription from '@client/internationalization/localization-messages/overview/addManualSubscription/OpBank/fi-FI.json';
import opBankoverviewBindingTimeBar from '@client/internationalization/localization-messages/overview/BindingTimeBar/OpBank/fi-FI.json';
import opBankoverviewPage_didYouCancel from '@client/internationalization/localization-messages/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/OpBank/fi-FI.json';
import opBankbadgeCtas from '@client/internationalization/localization-messages/BadgeCtas/OpBank/fi-FI.json';
import opBankbindingTimeNotice from '@client/internationalization/localization-messages/overview/BindingTimeNotice/OpBank/fi-FI.json';
import opBankonboardingDrawer from '@client/internationalization/localization-messages/overview/OnboardingDrawer/OpBank/fi-FI.json';
import opBankoverviewPage_quickActionsV2 from '@client/internationalization/localization-messages/overview/QuickActionsV2/OpBank/fi-FI.json';
import opBankaddonMobileRecommendationsPage from '@client/internationalization/localization-messages/addon/mobile/RecommendationsPage/OpBank/fi-FI.json';
import opBankaddonMobileResultPage from '@client/internationalization/localization-messages/addon/mobile/ResultPage/OpBank/fi-FI.json';
import opBankauthAuthStatusPage from '@client/internationalization/localization-messages/auth/AuthStatusPage/OpBank/fi-FI.json';
import opBankauthBasicLoginPage from '@client/internationalization/localization-messages/auth/BasicLoginPage/OpBank/fi-FI.json';
import opBankauthCreateAccountPage from '@client/internationalization/localization-messages/auth/CreateAccountPage/OpBank/fi-FI.json';
import opBankauthOnboardingChooseBankPage from '@client/internationalization/localization-messages/auth/OnboardingChooseBankPage/OpBank/fi-FI.json';
import opBankauthOnboardingEmailPage from '@client/internationalization/localization-messages/auth/OnboardingEmailPage/OpBank/fi-FI.json';
import opBankauthPreLoginPage from '@client/internationalization/localization-messages/auth/PreLoginPage/OpBank/fi-FI.json';
import opBankauthResetPasswordPage from '@client/internationalization/localization-messages/auth/ResetPasswordPage/OpBank/fi-FI.json';
import opBankauthSyncSourcesError from '@client/internationalization/localization-messages/auth/SyncSourcesError/OpBank/fi-FI.json';
import opBankauthSyncSourcesFetching from '@client/internationalization/localization-messages/auth/SyncSourcesFetching/OpBank/fi-FI.json';
import opBankbadgeABTestCTAS from '@client/internationalization/localization-messages/BadgeABTestCtas/OpBank/fi-FI.json';
import opBankbankSyncCancelProduct from '@client/internationalization/localization-messages/bank-sync/CancelProductOnboardPages/OpBank/fi-FI.json';
import opBankbankSyncDrawerPages from '@client/internationalization/localization-messages/bank-sync/DrawerPages/OpBank/fi-FI.json';
import opBankbankSyncLoadingScreen from '@client/internationalization/localization-messages/bank-sync/LoadingScreen/OpBank/fi-FI.json';
import opBankbankSyncOnboardBankSync from '@client/internationalization/localization-messages/bank-sync/OnBoardBankSync/OpBank/fi-FI.json';
import opBankcampaignIdSelect from '@client/internationalization/localization-messages/CampaignIdSelect/OpBank/fi-FI.json';
import opBankcancelCancellationOutcome from '@client/internationalization/localization-messages/cancel/CancellationOutcome/OpBank/fi-FI.json';
import opBankcancellationDetailsPage from '@client/internationalization/localization-messages/cancellation/cancellationDetailsPage/OpBank/fi-FI.json';
import opBankcancellationFormCancellationInstruction from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationInstructionsSection/OpBank/fi-FI.json';
import opBankcancellationFormCancellationTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationTerms/OpBank/fi-FI.json';
import opBankcancellationFormConfirmSection from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/ConfirmSection/OpBank/fi-FI.json';
import opBankcancellationFormLightUserTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/LightUserTerms/OpBank/fi-FI.json';
import opBankcancellationModalsContainer from '@client/internationalization/localization-messages/cancellation/CancellationModalsContainer/OpBank/fi-FI.json';
import opBankcancellationQuestionsFlow from '@client/internationalization/localization-messages/cancellation/CancellationQuestionFlow/OpBank/fi-FI.json';
import opBankcancellationReasonsDialog from '@client/internationalization/localization-messages/cancellation/CancellationReasonsDialog/OpBank/fi-FI.json';
import opBankcancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/OpBank/fi-FI.json';
import opBankcancellationStepper from '@client/internationalization/localization-messages/cancellation/CancellationStepper/OpBank/fi-FI.json';
import opBankcancellationDoYouWantToSaveDialog from '@client/internationalization/localization-messages/cancellation/DoYouWantToSaveDialog/OpBank/fi-FI.json';
import opBankcancellationIntelligentGuidePage from '@client/internationalization/localization-messages/cancellation/IntelligentGuidePage/OpBank/fi-FI.json';
import opBankcancellationNetflixCancellationInstructionsPage from '@client/internationalization/localization-messages/cancellation/NetflixCancellationInstructionsPage/OpBank/fi-FI.json';
import opBankcancellationSigningNemIdErrorPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdErrorPage/OpBank/fi-FI.json';
import opBankcancellationSigningNemIdSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdSigningPage/OpBank/fi-FI.json';
import opBankcancellationNoSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningPage/OpBank/fi-FI.json';
import opBankcancellationNoSigningServicePage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningServicePage/OpBank/fi-FI.json';
import opBankcancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/OpBank/fi-FI.json';
import opBankcancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/OpBank/fi-FI.json';
import opBankcategories from '@client/internationalization/localization-messages/categories/OpBank/fi-FI.json';
import opBankcategoryNames from '@client/internationalization/localization-messages/CategoryNames/CategoryNames/OpBank/fi-FI.json';
import opBankchangeDataPlan from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplan/OpBank/fi-FI.json';
import opBankchangeDataPlanCompletedPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanCompletedPage/OpBank/fi-FI.json';
import opBankchangeDataPlanOrderFormPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanOrderFormPage/OpBank/fi-FI.json';
import opBankchangeDataPlanPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanPage/OpBank/fi-FI.json';
import opBankchangePaymentChangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-payment/ChangePlanServiceIntroductionDrawer/OpBank/fi-FI.json';
import opBankchangePaymentIntelligentGuidePage from '@client/internationalization/localization-messages/change-payment/IntelligentGuidePage/OpBank/fi-FI.json';
import opBankchangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-plan/ChangePlanServiceIntroductionDrawer/OpBank/fi-FI.json';
import opBankchangePlanIntelligentGuideExperimentPage from '@client/internationalization/localization-messages/change-plan/IntelligentGuideExperimentPage/OpBank/fi-FI.json';
import opBankchangePlanIntelligentGuidePage from '@client/internationalization/localization-messages/change-plan/IntelligentGuidePage/OpBank/fi-FI.json';
import opBankchangePlan from '@client/internationalization/localization-messages/ChangePlan/OpBank/fi-FI.json';
import opBankcharityDonation from '@client/internationalization/localization-messages/charity-donation/OpBank/fi-FI.json';
import opBankcomplexContractGuideIntroPage from '@client/internationalization/localization-messages/complex-contract-guide/IntroPage/OpBank/fi-FI.json';
import opBankcomplexContractGuideLoanServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanServiceQuestionPage/OpBank/fi-FI.json';
import opBankcomplexContractGuideLoanTypeQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanTypeQuestionPage/OpBank/fi-FI.json';
import opBankcomplexContractGuideServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/ServiceQuestionPage/OpBank/fi-FI.json';
import opBankcomplexContractGuideShared from '@client/internationalization/localization-messages/complex-contract-guide/shared/OpBank/fi-FI.json';
import opBankcomplexContractGuideSuccessPage from '@client/internationalization/localization-messages/complex-contract-guide/SuccessPage/OpBank/fi-FI.json';
import opBankconditionsAndPoliciesMenu from '@client/internationalization/localization-messages/conditions-and-policies/ConditionsAndPoliciesMenu/OpBank/fi-FI.json';
import opBankconditionsAndPoliciesCookiesPage from '@client/internationalization/localization-messages/conditions-and-policies/CookiesPage/OpBank/fi-FI.json';
import opBankconditionsAndPoliciesSecurityPage from '@client/internationalization/localization-messages/conditions-and-policies/SecurityPage/OpBank/fi-FI.json';
import opBankconditionsAndPolicies from '@client/internationalization/localization-messages/conditions-and-policies/OpBank/fi-FI.json';
import opBankcontractBindingPeriodDialog from '@client/internationalization/localization-messages/contract/bindingPeriodDialog/OpBank/fi-FI.json';
import opBankcontractCancellationHistory from '@client/internationalization/localization-messages/contract/CancellationHistory/OpBank/fi-FI.json';
import opBankcontractCancelledContract from '@client/internationalization/localization-messages/contract/CancelledContract/OpBank/fi-FI.json';
import opBankcontractCancellationOutcome from '@client/internationalization/localization-messages/contract/ContractCancellationOutcome/OpBank/fi-FI.json';
import opBankcontractCardMessage from '@client/internationalization/localization-messages/contract/ContractCardMessage/OpBank/fi-FI.json';
import opBankcontractDetails from '@client/internationalization/localization-messages/contract/ContractDetails/OpBank/fi-FI.json';
import opBankcontractDetailsPage from '@client/internationalization/localization-messages/contract/ContractDetailsPage/OpBank/fi-FI.json';
import opBankcontractDoMoreComponent from '@client/internationalization/localization-messages/contract/DoMoreComponent/OpBank/fi-FI.json';
import opBankcontractHistoryToolTip from '@client/internationalization/localization-messages/contract/HistoryTooltip/OpBank/fi-FI.json';
import opBankcontractPriceHistory from '@client/internationalization/localization-messages/contract/PriceHistoryLolipop/OpBank/fi-FI.json';
import opBankcontractSelectServicePage from '@client/internationalization/localization-messages/contract/SelectServicePageLolipop/OpBank/fi-FI.json';
import opBankcontractSelectServicesList from '@client/internationalization/localization-messages/contract/SelectServicesListLolipop/OpBank/fi-FI.json';
import opBankcontractSettingsButton from '@client/internationalization/localization-messages/contract/SettingsButtonLolipop/OpBank/fi-FI.json';
import opBankcontractSettingsPage from '@client/internationalization/localization-messages/contract/SettingsPageLolipop/OpBank/fi-FI.json';
import opBankcontractSubscriptionAlarm from '@client/internationalization/localization-messages/contract/SubscriptionAlarmLolipop/OpBank/fi-FI.json';
import opBankcontractSubscriptionDetails from '@client/internationalization/localization-messages/contract/SubscriptionDetailsLolipop/OpBank/fi-FI.json';
import opBankcontractSubscriptionReminder from '@client/internationalization/localization-messages/contract/SubscriptionReminder/OpBank/fi-FI.json';
import opBankcontractSubscriptionReminderDialog from '@client/internationalization/localization-messages/contract/subscriptionReminderDialog/OpBank/fi-FI.json';
import opBankcontractTransactionHistory from '@client/internationalization/localization-messages/contract/TransactionHistoryLolipop/OpBank/fi-FI.json';
import opBankcontractUpdateCost from '@client/internationalization/localization-messages/contract/UpdateCostLolipop/OpBank/fi-FI.json';
import opBankdiscoverCategoryPage from '@client/internationalization/localization-messages/discover/CategoryPage/OpBank/fi-FI.json';
import opBankdiscoverCategoryProviderPage from '@client/internationalization/localization-messages/discover/CategoryProviderPage/OpBank/fi-FI.json';
import opBankdiscoverComponentsProductInfo from '@client/internationalization/localization-messages/discover/components/ProductInformationHeader/OpBank/fi-FI.json';
import opBankdiscoverDiscoverWidget from '@client/internationalization/localization-messages/discover/DiscoverWidget/OpBank/fi-FI.json';
import opBankdiscoverFakeCategoryPage from '@client/internationalization/localization-messages/discover/FakeCategoryPage/OpBank/fi-FI.json';
import opBankdiscoverFakeCategorySubscriptionPage from '@client/internationalization/localization-messages/discover/FakeCategorySubscriptionsPage/OpBank/fi-FI.json';
import opBankdiscoverFitnessCategoryFeedbackPage from '@client/internationalization/localization-messages/discover/FitnessCategoryFeedbackPage/OpBank/fi-FI.json';
import opBankdiscoverMarketplacePage from '@client/internationalization/localization-messages/discover/MarketplacePage/OpBank/fi-FI.json';
import opBankdiscoverMobileOrderSentPage from '@client/internationalization/localization-messages/discover/MobileOrderSentPage/OpBank/fi-FI.json';
import opBankdiscoverOrderProductPage from '@client/internationalization/localization-messages/discover/OrderProductPage/OpBank/fi-FI.json';
import opBankdiscoverProductRecommendations from '@client/internationalization/localization-messages/discover/ProductRecommendations/OpBank/fi-FI.json';
import opBankdiscoverSearchSubscriptionAllCategories from '@client/internationalization/localization-messages/discover/SearchSubscriptionAllCategoriesPage/OpBank/fi-FI.json';
import opBankdiscoverSearchSubscriptionPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionPage/OpBank/fi-FI.json';
import opBankdiscoverSearchSubscriptionResultPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionResultPage/OpBank/fi-FI.json';
import opBankdummy from '@client/internationalization/localization-messages/DUMMY/OpBank/fi-FI.json';
import opBankeSigning from '@client/internationalization/localization-messages/e-signing/nem-id/NemIdSigningPage/OpBank/fi-FI.json';
import opBankeditProduct from '@client/internationalization/localization-messages/EditProduct/OpBank/fi-FI.json';
import opBankenergyGuide from '@client/internationalization/localization-messages/EnergyGuide/OrderForm/OpBank/fi-FI.json';
import opBankerrorHandling from '@client/internationalization/localization-messages/error-handling/LoggedOutPage/OpBank/fi-FI.json';
import opBankerrorPageErrorPage from '@client/internationalization/localization-messages/errorPage/ErrorPage/OpBank/fi-FI.json';
import opBankerrorScreen from '@client/internationalization/localization-messages/ErrorScreen/OpBank/fi-FI.json';
import opBankexplore from '@client/internationalization/localization-messages/Explore/OpBank/fi-FI.json';
import opBankfeedbackModal from '@client/internationalization/localization-messages/feedback-modal/OpBank/fi-FI.json';
import opBankfields from '@client/internationalization/localization-messages/fields/OpBank/fi-FI.json';
import opBankfinancialProductsLoansShow from '@client/internationalization/localization-messages/financial-products/loans/ShowNewFeatureLoanDialog/OpBank/fi-FI.json';
import opBankfinancialProductsSavingsInvestment from '@client/internationalization/localization-messages/financial-products/savings/InvestmentInfoCard/OpBank/fi-FI.json';
import opBankfinancialProductOurPartnersPage from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPage/OpBank/fi-FI.json';
import opBankfinancialProductOurPartnersPageNew from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPageNew/OpBank/fi-FI.json';
import opBankfinancialProductsSavingsOverview from '@client/internationalization/localization-messages/financial-products/savings/Overview/OpBank/fi-FI.json';
import opBankfinancialProductSavingsAfterCancellation from '@client/internationalization/localization-messages/financial-products/savings/SavingsAfterCancellation/OpBank/fi-FI.json';
import opBankfinancialProductsShowNewFeatureLoan from '@client/internationalization/localization-messages/financial-products/ShowNewFeatureLoanDialog/OpBank/fi-FI.json';
import opBankfinancialProductsSwedBankConsolidate from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/HowItWorksPage/OpBank/fi-FI.json';
import opBankfinancialSwedBankConsolidateIntro from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/IntroPage/OpBank/fi-FI.json';
import opBankfinancialProductLoanApplication from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/LoanApplicationStatus/OpBank/fi-FI.json';
import opBankfinancialProductsOfferPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OfferPage/OpBank/fi-FI.json';
import opBankfinancialProductOrderCompleted from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderCompletedPage/OpBank/fi-FI.json';
import opBankfinancialProductOrderPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderPage/OpBank/fi-FI.json';
import opBankfinancialProductSelectContract from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectContractsPage/OpBank/fi-FI.json';
import opBankfinancialProductsSelectPrincipal from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectPrincipalPage/OpBank/fi-FI.json';
import opBankfinancialProductRepaymentPeriod from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/OpBank/fi-FI.json';
import opBankgeneralIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/OpBank/fi-FI.json';
import opBankgeneralSelectServices from '@client/internationalization/localization-messages/general/SelectServicesPage/OpBank/fi-FI.json';
import opBankgenericActionsPage from '@client/internationalization/localization-messages/genericActions/GenericActionsPage/OpBank/fi-FI.json';
import opBankgenericActionsSelectServices from '@client/internationalization/localization-messages/genericActions/SelectServicesPage/OpBank/fi-FI.json';
import opBankgenericActionsSubscriptionCancel from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/Cancel/OpBank/fi-FI.json';
import opBankgenericActionsSubscriptionsChangePayment from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/ChangePayment/OpBank/fi-FI.json';
import opBankguidesContractDetailsAlternative from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/AlternativesSection/OpBank/fi-FI.json';
import opBankguidesDealBanner from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/DealBanner/OpBank/fi-FI.json';
import opBankguidesPaymentMethod from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/PaymentMethodsCard/OpBank/fi-FI.json';
import opBankhack7CancelPage from '@client/internationalization/localization-messages/Hack7CancelPage/OpBank/fi-FI.json';
import opBankheader from '@client/internationalization/localization-messages/Header/OpBank/fi-FI.json';
import opBankhowToCancelListPage from '@client/internationalization/localization-messages/HowToCancelListPage/OpBank/fi-FI.json';
import opBankhowToCancelPage from '@client/internationalization/localization-messages/HowToCancelPage/OpBank/fi-FI.json';
import opBankimproveOrderPage from '@client/internationalization/localization-messages/improve/flowgen/OrderPage/OpBank/fi-FI.json';
import opBankimproveOrderSentPage from '@client/internationalization/localization-messages/improve/flowgen/OrderSentPage/OpBank/fi-FI.json';
import opBankimproveOtherAlternativePage from '@client/internationalization/localization-messages/improve/flowgen/OtherAlternativesPage/OpBank/fi-FI.json';
import opBankimprovePostalCodeInput from '@client/internationalization/localization-messages/improve/flowgen/postalCodeInput/OpBank/fi-FI.json';
import opBankimproveResultPage from '@client/internationalization/localization-messages/improve/flowgen/ResultPage/OpBank/fi-FI.json';
import opBankimproveFlowgen from '@client/internationalization/localization-messages/improve/flowgen/OpBank/fi-FI.json';
import opBankinsightPageInsightsCardsCancelOutcome from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationOutcome/OpBank/fi-FI.json';
import opBankinsightPageInsightsCardsCancelUpdate from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationUpdate/OpBank/fi-FI.json';
import opBankinsightPageOnboardingInsightCard from '@client/internationalization/localization-messages/InsightsPage/OnboardingInsightCard/OpBank/fi-FI.json';
import opBankintervalToString from '@client/internationalization/localization-messages/interval-to-string/OpBank/fi-FI.json';
import opBanklandingPage from '@client/internationalization/localization-messages/LandingPage/OpBank/fi-FI.json';
import opBankloadingScreen from '@client/internationalization/localization-messages/LoadingScreen/OpBank/fi-FI.json';
import opBankloanApplicationsDetailsPage from '@client/internationalization/localization-messages/loan-applications/DetailsPage/OpBank/fi-FI.json';
import opBankloanApplicationsLoadingError from '@client/internationalization/localization-messages/loan-applications/LoadingError/OpBank/fi-FI.json';
import opBankloanApplicationsMenuDrawer from '@client/internationalization/localization-messages/loan-applications/MenuDrawer/OpBank/fi-FI.json';
import opBankloanApplicationsOverview from '@client/internationalization/localization-messages/loan-applications/Overview/OpBank/fi-FI.json';
import opBankloginPage from '@client/internationalization/localization-messages/LoginPage/OpBank/fi-FI.json';
import opBankmanageOrder from '@client/internationalization/localization-messages/ManageOrder/OpBank/fi-FI.json';
import opBankmanualAddContractPage from '@client/internationalization/localization-messages/ManualAddContractPage/OpBank/fi-FI.json';
import opBankmanualAddContractPricingPage from '@client/internationalization/localization-messages/ManualContractPricingPage/OpBank/fi-FI.json';
import opBankmenu from '@client/internationalization/localization-messages/Menu/OpBank/fi-FI.json';
import opBankmenuPage from '@client/internationalization/localization-messages/MenuPage/OpBank/fi-FI.json';
import opBankmovingBundledCancellationForm from '@client/internationalization/localization-messages/moving/BundledCancellationFromPage/OpBank/fi-FI.json';
import opBankmovingBundledCancellationPage from '@client/internationalization/localization-messages/moving/BundledCancellationPage/OpBank/fi-FI.json';
import opBankmovingBundledSwitchOrderForm from '@client/internationalization/localization-messages/moving/BundledSwitchOrderFormPage/OpBank/fi-FI.json';
import opBankmovingBundledSwitchPage from '@client/internationalization/localization-messages/moving/BundledSwitchSwitchPage/OpBank/fi-FI.json';
import opBankthanksSwitchPage from '@client/internationalization/localization-messages/moving/ThanksSwitchPage/OpBank/fi-FI.json';
import opBankmovingGuide from '@client/internationalization/localization-messages/movingGuide/OpBank/fi-FI.json';
import opBankmovingGuideMovingDetails from '@client/internationalization/localization-messages/movingGuide/MovingDetailsPage/OpBank/fi-FI.json';
import opBankmovingGuideOneLastThingPage from '@client/internationalization/localization-messages/movingGuide/OneLastThingPage/OpBank/fi-FI.json';
import opBanknegotiateContractCompletePage from '@client/internationalization/localization-messages/negotiate/NegotiateContractCompletePage/OpBank/fi-FI.json';
import opBanknegotiateGuide from '@client/internationalization/localization-messages/negotiate/NegotiateGuide/OpBank/fi-FI.json';
import opBanknegotiationOngoingCard from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationCard/OpBank/fi-FI.json';
import opBanknegotiationOngoingStepper from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationStepper/OpBank/fi-FI.json';
import opBanknotificationOptInPage from '@client/internationalization/localization-messages/NotificationOptInPage/OpBank/fi-FI.json';
import opBankonboardingPSD2Consent from '@client/internationalization/localization-messages/onboarding/PSD2ConsentPage/OpBank/fi-FI.json';
import opBankonboardingPSD2Primer from '@client/internationalization/localization-messages/onboarding/PSD2PrimerPage/OpBank/fi-FI.json';
import opBankonboardingBDSyncConnecting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryConnecting/OpBank/fi-FI.json';
import opBankonboardingBDSyncError from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryErrorPage/OpBank/fi-FI.json';
import opBankonboardingBDSyncFetch from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryFetchPage/OpBank/fi-FI.json';
import opBankonboardingBDSyncSuccess from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/OpBank/fi-FI.json';
import opBankonboardingBDSyncWaiting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/OpBank/fi-FI.json';
import opBankonboardingCancelProduct from '@client/internationalization/localization-messages/onboarding-cancel-product/OnboardingCancelProductPages/OpBank/fi-FI.json';
import opBankonboardingPsd2WelcomePage from '@client/internationalization/localization-messages/onboarding-psd2/WelcomePage/OpBank/fi-FI.json';
import opBankonboardingPsd2Cards from '@client/internationalization/localization-messages/onboarding-psd2/Cards/OpBank/fi-FI.json';
import opBankonboardingPsd2ConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBank/fi-FI.json';
import opBankonboardingPsd2OpBankConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPage/OpBank/fi-FI.json';
import opBankonboardingPsd2OpBankConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPageV2/OpBank/fi-FI.json';
import opBankonboardingPsd2ConsentPageDanskeBank from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/DanskeBankConsentPage/OpBank/fi-FI.json';
import opBankonboardingPsd2RenewConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPage/OpBank/fi-FI.json';
import opBankonboardingPsd2RenewConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPageV2/OpBank/fi-FI.json';
import opBankorderedSubscriptionOngoing from '@client/internationalization/localization-messages/orderedSubscription/OngoingOrderCard/OpBank/fi-FI.json';
import opBankorderedSubscriptionOrderedEnergy from '@client/internationalization/localization-messages/orderedSubscription/OrderedEnergyProductInfo/OpBank/fi-FI.json';
import opBankorderedSubscriptionOrderedMobile from '@client/internationalization/localization-messages/orderedSubscription/OrderedMobileProductInfo/OpBank/fi-FI.json';
import opBankorderedSubscriptionDetails from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetails/OpBank/fi-FI.json';
import opBankorderedSubscriptionDetailsPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/OpBank/fi-FI.json';
import opBankorderedSubscriptionInfoCard from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/OpBank/fi-FI.json';
import opBankorderedSubscriptionPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionPage/OpBank/fi-FI.json';
import opBankorderedSubscriptionOrderStepper from '@client/internationalization/localization-messages/orderedSubscription/OrderStepper/OpBank/fi-FI.json';
import opBankorderedSubscServiceProviderInfo from '@client/internationalization/localization-messages/orderedSubscription/ServiceProviderInfoCard/OpBank/fi-FI.json';
import opBankorderingSubscriptionTerms from '@client/internationalization/localization-messages/ordering-subscription/common/TermsAndConditionsPage/OpBank/fi-FI.json';
import opBankorderingSubscriptionCompleted from '@client/internationalization/localization-messages/ordering-subscription/common/OrderCompletedPage/OpBank/fi-FI.json';
import opBankorderingBroadbandAddressPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAddressPage/OpBank/fi-FI.json';
import opBankorderingBroadbandAllAlternatives from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/OpBank/fi-FI.json';
import opBankorderingBroadbandContract from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideContractCostPage/OpBank/fi-FI.json';
import opBankorderingBroadbandIntroPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideIntroPage/OpBank/fi-FI.json';
import opBankorderingBroadbandNoResultPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideNoResultPage/OpBank/fi-FI.json';
import opBankorderingBroadbandSpeedPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideSpeedPage/OpBank/fi-FI.json';
import opBankorderingBroadbandOptimizeOrder from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandOrderFormPage/OpBank/fi-FI.json';
import opBankorderingBroadbandOptimizeProduct from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/OpBank/fi-FI.json';
import opBankorderingBroadbandOptimizeProductCosts from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/OpBank/fi-FI.json';
import opBankorderingElectricityOrder from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPage/OpBank/fi-FI.json';
import opBankorderingElectricityOrderVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/OpBank/fi-FI.json';
import opBankorderingElectricityGuidePrice from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/OpBank/fi-FI.json';
import opBankorderingElectricityGuideAlternatives from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideBinding from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideBindingTime from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideEnergy from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideExplanation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideFlow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideHow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideInformation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideIntro from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideLivingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/OpBank/fi-FI.json';
import opBankorderingElectricityGuidePostalCodePage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/OpBank/fi-FI.json';
import opBankorderingElectricityGuidePreferences from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/OpBank/fi-FI.json';
import opBankorderingElectricityGuidePricingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideProductPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideSummaryPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideTrading from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideUsage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideUsageResult from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/OpBank/fi-FI.json';
import opBankorderingElectricityGuideUsageResultVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/OpBank/fi-FI.json';
import opBankorderingElectricityGuidePostalCode from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/OpBank/fi-FI.json';
import opBankorderingSubscriptionIndependentOrder from '@client/internationalization/localization-messages/ordering-subscription/independent/OrderCompletedPage/OpBank/fi-FI.json';
import opBankordersOverview from '@client/internationalization/localization-messages/OrdersOverview/OpBank/fi-FI.json';
import opBankoverviewChangePlanService from '@client/internationalization/localization-messages/overview/ChangePlanServiceIntroductionDrawer/OpBank/fi-FI.json';
import opBankoverviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/OpBank/fi-FI.json';
import opBankoverviewDeprecatedInsights from '@client/internationalization/localization-messages/overview/DeprecatedInsightsPage/OnboardingInsightCard/OpBank/fi-FI.json';
import opBankoverviewDiscoverPage from '@client/internationalization/localization-messages/overview/DiscoverPage/OpBank/fi-FI.json';
import opBankoverviewGenericAction from '@client/internationalization/localization-messages/overview/GenericActions/OpBank/fi-FI.json';
import opBankoverviewImprovementPage from '@client/internationalization/localization-messages/overview/ImprovementPage/OpBank/fi-FI.json';
import opBankoverviewImproveV2 from '@client/internationalization/localization-messages/overview/ImproveV2/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsActionRating from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ActionRating/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsCancellationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/CancellationUpdate/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsChangeData from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ChangeDataplanUpdate/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsIndependent from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/IndependentOrderUpdate/OpBank/fi-FI.json';
import opBankoverviewInsightsCardConfirmCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/OpBank/fi-FI.json';
import opBankoverviewContactUsButton from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ContactUsButton/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsMonthlyCheck from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/MonthlyChecklist/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsNegotiationOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationOutcome/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsNegotiationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationUpdate/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsProvider from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewProviderOffersCard/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsSaveDeskFeedback from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/OpBank/fi-FI.json';
import opBankoverviewInsightCardsSaveDeskRequested from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOrderRequested/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsSaveDeskPredefined from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsSaveDeskResponse from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskResponseCard/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsSwitchOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchOutcome/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsSwitchUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchUpdate/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsThankYou from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ThankYouCard/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsTryCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/TryCancellationAgainButton/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsUnreviewed from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnreviewedAction/OpBank/fi-FI.json';
import opBankoverviewInsightsCardsOnboarding from '@client/internationalization/localization-messages/overview/InsightsPage/OnboardingInsightCard/OpBank/fi-FI.json';
import opBankoverviewInsightsPrimerCardV2 from '@client/internationalization/localization-messages/overview/InsightsPage/PrimerCardV2/OpBank/fi-FI.json';
import opBankoverviewLoadingWithCogs from '@client/internationalization/localization-messages/overview/LoadingWithCogs/OpBank/fi-FI.json';
import opBankoverviewManageContract from '@client/internationalization/localization-messages/overview/ManageContractSection/SwitchProgressWithoutCancelStepper/OpBank/fi-FI.json';
import opBankoverviewMonthlyChecklist from '@client/internationalization/localization-messages/overview/MonthlyChecklist/OpBank/fi-FI.json';
import opBankoverviewMoreInfoNeeded from '@client/internationalization/localization-messages/overview/more-information-needed/OpBank/fi-FI.json';
import opBankoverviewOnboardingCards from '@client/internationalization/localization-messages/overview/OnboardingCards/OpBank/fi-FI.json';
import opBankoverviewQuickActionsCancel from '@client/internationalization/localization-messages/overview/QuickActionsCancel/OpBank/fi-FI.json';
import opBankoverviewQuickActionsCancelV2 from '@client/internationalization/localization-messages/overview/QuickActionsCancelV2/OpBank/fi-FI.json';
import opBankoverviewQuickActionsImprove from '@client/internationalization/localization-messages/overview/QuickActionsImprove/OpBank/fi-FI.json';
import opBankoverviewQuickActionsImproveV2 from '@client/internationalization/localization-messages/overview/QuickActionsImproveV2/OpBank/fi-FI.json';
import opBankoverviewSubscriptionFeedback from '@client/internationalization/localization-messages/overview/SubscriptionFeedback/OpBank/fi-FI.json';
import opBankoverviewSubscriptionSearch from '@client/internationalization/localization-messages/overview/SubscriptionSearch/OpBank/fi-FI.json';
import opBankoverviewSubscriptionSearchComponent from '@client/internationalization/localization-messages/overview/SubscriptionSearchOverviewComponent/OpBank/fi-FI.json';
import opBankwaysToSave from '@client/internationalization/localization-messages/overview/WaysToSave/OpBank/fi-FI.json';
import opBankoverview from '@client/internationalization/localization-messages/overview/OpBank/fi-FI.json';
import opBankoverviewBottomNavigation from '@client/internationalization/localization-messages/overviewBottomNavigation/OpBank/fi-FI.json';
import opBankpauseIntelligentGuide from '@client/internationalization/localization-messages/pause/IntelligentGuidePage/OpBank/fi-FI.json';
import opBankpauseSubscriptionPause from '@client/internationalization/localization-messages/pause/SubscriptionPausePage/OpBank/fi-FI.json';
import opBankprovideFeedbackPage from '@client/internationalization/localization-messages/ProvideFeedbackPage/OpBank/fi-FI.json';
import opBankproviderPortalDatepicker from '@client/internationalization/localization-messages/provider-portal-datepicker/DatePicker/OpBank/fi-FI.json';
import opBankproviderPortalSnackback from '@client/internationalization/localization-messages/provider-portal-snackback/Snackbar/OpBank/fi-FI.json';
import opBankregistrationPage from '@client/internationalization/localization-messages/RegistrationPage/OpBank/fi-FI.json';
import opBankretentionOfferCard from '@client/internationalization/localization-messages/retention-offer/SuccessfulRetentionOfferCard/OpBank/fi-FI.json';
import opBanksaveDesk from '@client/internationalization/localization-messages/save-desk/OpBank/fi-FI.json';
import opBanksaveDeskOngoingCard from '@client/internationalization/localization-messages/save-desk/OngoingSaveDeskCard/OpBank/fi-FI.json';
import opBanksaveDeskOrderSent from '@client/internationalization/localization-messages/save-desk/OrderSentPage/OpBank/fi-FI.json';
import opBanksavingsDoYouWantToSave from '@client/internationalization/localization-messages/savings/DoYouWantToSaveDialog/OpBank/fi-FI.json';
import opBanksavingsAfterCancellation from '@client/internationalization/localization-messages/savings/SavingsAfterCancellation/OpBank/fi-FI.json';
import opBankselectProductPage from '@client/internationalization/localization-messages/SelectProductPage/OpBank/fi-FI.json';
import opBanksettingsNotificationSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/OpBank/fi-FI.json';
import opBanksettingsNotificationPushSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/PushNotificationInstructions/OpBank/fi-FI.json';
import opBanksettingsPersonalDetails from '@client/internationalization/localization-messages/settings/PersonalDetailsPage/OpBank/fi-FI.json';
import opBanksettingsProfile from '@client/internationalization/localization-messages/settings/ProfilingSettingsPage/OpBank/fi-FI.json';
import opBanksettingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/OpBank/fi-FI.json';
import opBanksettingsUser from '@client/internationalization/localization-messages/settings/UserSettingsPage/OpBank/fi-FI.json';
import opBanksettingsViewGdprData from '@client/internationalization/localization-messages/settings/ViewGdprDataPage/OpBank/fi-FI.json';
import opBanksettingsSwedbank from '@client/internationalization/localization-messages/settings-swedbank/SwedbankNotificationSettingsPage/OpBank/fi-FI.json';
import opBanksharedComponentsEmoji from '@client/internationalization/localization-messages/shared-components/EmojiRating/OpBank/fi-FI.json';
import opBanksharedComponentsUpdateBankApp from '@client/internationalization/localization-messages/shared-components/UpdateBankApp/OpBank/fi-FI.json';
import opBanksharedComponentsYouSavedComponent from '@client/internationalization/localization-messages/shared-components/YouSavedComponent/OpBank/fi-FI.json';
import opBankshowOnboardingFeatures from '@client/internationalization/localization-messages/ShowOnboardingFeaturesComponent/OpBank/fi-FI.json';
import opBanksignOutSuccessPage from '@client/internationalization/localization-messages/SignoutSuccessPage/OpBank/fi-FI.json';
import opBankstandardComponentsCurrency from '@client/internationalization/localization-messages/standard-components/CurrencyTextField/OpBank/fi-FI.json';
import opBankstandardComponentsDate from '@client/internationalization/localization-messages/standard-components/DatePicker/OpBank/fi-FI.json';
import opBankstandardComponentsEmail from '@client/internationalization/localization-messages/standard-components/EmailField/OpBank/fi-FI.json';
import opBankstandardComponentsGuide from '@client/internationalization/localization-messages/standard-components/GuidePage/OpBank/fi-FI.json';
import opBankstandardComponentsMobile from '@client/internationalization/localization-messages/standard-components/MobilePhoneNumberField/OpBank/fi-FI.json';
import opBankstandardComponentsPassword from '@client/internationalization/localization-messages/standard-components/PasswordField/OpBank/fi-FI.json';
import opBankstandardComponentsPersonal from '@client/internationalization/localization-messages/standard-components/PersonalNumberField/OpBank/fi-FI.json';
import opBankstandardComponentsPostalCode from '@client/internationalization/localization-messages/standard-components/PostalCodeField/OpBank/fi-FI.json';
import opBankstandardComponentsSnackbar from '@client/internationalization/localization-messages/standard-components/PostalCodeField/OpBank/fi-FI.json';
import opBanksubscriptionCancellationMeasure from '@client/internationalization/localization-messages/subscription/CancellationMeasurement/OpBank/fi-FI.json';
import opBanksubscriptionComponentsSelectable from '@client/internationalization/localization-messages/subscription/components/SelectableServiceList/OpBank/fi-FI.json';
import opBanksubscriptionRemoveSubscription from '@client/internationalization/localization-messages/subscription/RemoveSubscriptionDialog/OpBank/fi-FI.json';
import opBanksubscriptionSelectLoanService from '@client/internationalization/localization-messages/subscription/SelectLoanServicesPage/OpBank/fi-FI.json';
import opBanksubscriptionSelectLoanTransaction from '@client/internationalization/localization-messages/subscription/SelectLoanTransactionTypePage/OpBank/fi-FI.json';
import opBanksubscriptionSelectServices from '@client/internationalization/localization-messages/subscription/SelectServicesPage/OpBank/fi-FI.json';
import opBanksubscriptionPage from '@client/internationalization/localization-messages/subscription/SubscriptionPage/OpBank/fi-FI.json';
import opBanksubscriptionSettingsPage from '@client/internationalization/localization-messages/subscription/SubscriptionSettingsPage/OpBank/fi-FI.json';
import opBanksubscriptionUpdateCostPage from '@client/internationalization/localization-messages/subscription/SubscriptionUpdateCostPage/OpBank/fi-FI.json';
import opBanksubscriptionSwitchMeasurement from '@client/internationalization/localization-messages/subscription/SwitchMeasurement/OpBank/fi-FI.json';
import opBanksubscriptionUnresolvedGuide from '@client/internationalization/localization-messages/subscription/UnresolvedGuide/OpBank/fi-FI.json';
import opBanksubscriptionWhatsWrong from '@client/internationalization/localization-messages/subscription/WhatsWrongDialog/OpBank/fi-FI.json';
import opBanksubscriptionAlarmMore from '@client/internationalization/localization-messages/subscription-alarm/MorePage/OpBank/fi-FI.json';
import opBanksubscriptionAlarmPage from '@client/internationalization/localization-messages/subscription-alarm/SubscriptionAlarmPage/OpBank/fi-FI.json';
import opBanksubscriptionHubActions from '@client/internationalization/localization-messages/subscription-hub/PastActions/OpBank/fi-FI.json';
import opBanksubscriptionHub from '@client/internationalization/localization-messages/subscriptionHub/subscriptionHub/OpBank/fi-FI.json';
import opBanksupplierPortalChange from '@client/internationalization/localization-messages/supplier-portal-change/OpBank/fi-FI.json';
import opBanksupplierPortalSaveDesk from '@client/internationalization/localization-messages/supplier-portal-save-desk/OpBank/fi-FI.json';
import opBankswedbankOverviewSync from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/OpBank/fi-FI.json';
import opBankswedenPhoneCallsLimited from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/OpBank/fi-FI.json';
import opBanksyncSourcesAddSync from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcePage/OpBank/fi-FI.json';
import opBanksyncSourcesAddSyncSpeed from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcesSpeedDial/OpBank/fi-FI.json';
import opBanksyncSourcesDeleteSync from '@client/internationalization/localization-messages/sync-sources/DeleteSyncSourcesPage/OpBank/fi-FI.json';
import opBanksyncSourcesError from '@client/internationalization/localization-messages/sync-sources/Error/OpBank/fi-FI.json';
import opBanksyncSourcesNoSync from '@client/internationalization/localization-messages/sync-sources/NoSyncSources/OpBank/fi-FI.json';
import opBanksyncSourcesPopularSync from '@client/internationalization/localization-messages/sync-sources/PopularSyncSourcesList/OpBank/fi-FI.json';
import opBanksyncSourcesFetching from '@client/internationalization/localization-messages/sync-sources/SyncSourcesFetching/OpBank/fi-FI.json';
import opBanksyncSourcesList from '@client/internationalization/localization-messages/sync-sources/SyncSourcesList/OpBank/fi-FI.json';
import opBanksyncSourcesReminder from '@client/internationalization/localization-messages/sync-sources/SyncSourcesReminder/OpBank/fi-FI.json';
import opBanksyncSourcesTinkSync from '@client/internationalization/localization-messages/sync-sources/TinkSyncPage/OpBank/fi-FI.json';
import opBanksyncSourcesUpdateBank from '@client/internationalization/localization-messages/sync-sources/UpdateBankAuthsPage/OpBank/fi-FI.json';
import opBanksyncSourcesUpdateSync from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSources/OpBank/fi-FI.json';
import opBanksyncSourcesUpdateSyncButton from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSourcesButton/OpBank/fi-FI.json';
import opBankterminationFormPage from '@client/internationalization/localization-messages/termination/TerminationFormPage/OpBank/fi-FI.json';
import opBankterminationFormPCancelReason from '@client/internationalization/localization-messages/termination/TerminationFormPage/CancelReasonSection/OpBank/fi-FI.json';
import opBankterminationInstructions from '@client/internationalization/localization-messages/termination/TerminationInstructionsPage/OpBank/fi-FI.json';
import opBanktermsAndCondition from '@client/internationalization/localization-messages/TermsAndConditionsUpload/OpBank/fi-FI.json';
import opBanktypeFormButton from '@client/internationalization/localization-messages/TypeformButton/OpBank/fi-FI.json';
import opBankuserFormPreview from '@client/internationalization/localization-messages/user-form-preview/OpBank/fi-FI.json';
import opBankuserFeedback from '@client/internationalization/localization-messages/userFeedback/OpBank/fi-FI.json';
import opBankuserDeleteSettings from '@client/internationalization/localization-messages/userSettings/UserDeletePage/OpBank/fi-FI.json';
import opBankuserDeleteVerifiedPage from '@client/internationalization/localization-messages/userSettings/UserDeleteVerifiedPage/OpBank/fi-FI.json';
import opBankzendesk from '@client/internationalization/localization-messages/zendesk/OpBank/fi-FI.json';

export const fiFITranslations = {
  aboutUsPage: aboutUsPage,
  'AboutUsPage/BankAboutUsPage': bankAboutUsPage,
  'addon/mobile/RecommendationsPage': addonMobileRecommendationsPage,
  'addon/mobile/ResultPage': addonMobileResultPage,
  'auth/AuthStatusPage': authAuthStatusPage,
  'auth/BasicLoginPage': authBasicLoginPage,
  'auth/CreateAccountPage': authCreateAccountPage,
  'auth/LoginPage': authCreateAccountPage,
  'auth/OnboardingChooseBankPage': authOnboardingChooseBankPage,
  'auth/OnboardingEmailPage': authOnboardingEmailPage,
  'auth/PreLoginPage': authPreLoginPage,
  'auth/ResetPasswordPage': authResetPasswordPage,
  'auth/SyncSourcesError': authSyncSourcesError,
  'auth/SyncSourcesFetching': authSyncSourcesFetching,
  BadgeABTestCtas: badgeABTestCTAS,
  BadgeCtas: badgeCtas,
  'bank-sync/CancelProductOnboardPages': bankSyncCancelProduct,
  'bank-sync/DrawerPages': bankSyncDrawerPages,
  'bank-sync/LoadingScreen': bankSyncLoadingScreen,
  'bank-sync/OnBoardBankSync': bankSyncOnboardBankSync,
  CampaignIdSelect: campaignIdSelect,
  'cancel/CancellationOutcome': cancelCancellationOutcome,
  'cancellation/cancellationDetailsPage': cancellationDetailsPage,
  'cancellation/CancellationFormPage/CancellationInstructionsSection': cancellationFormCancellationInstruction,
  'cancellation/CancellationFormPage/CancellationTerms': cancellationFormCancellationTerms,
  'cancellation/CancellationFormPage/ConfirmSection': cancellationFormConfirmSection,
  'cancellation/CancellationFormPage/LightUserTerms': cancellationFormLightUserTerms,
  'cancellation/CancellationModalsContainer': cancellationModalsContainer,
  'cancellation/CancellationQuestionFlow': cancellationQuestionsFlow,
  'cancellation/CancellationReasonsDialog': cancellationReasonsDialog,
  'cancellation/CancellationSentPage': cancellationSentPage,
  'cancellation/CancellationStepper': cancellationStepper,
  'cancellation/DoYouWantToSaveDialog': cancellationDoYouWantToSaveDialog,
  'cancellation/IntelligentGuidePage': cancellationIntelligentGuidePage,
  'cancellation/NetflixCancellationInstructionsPage': cancellationNetflixCancellationInstructionsPage,
  'cancellation/signing/NemIdErrorPage': cancellationSigningNemIdErrorPage,
  'cancellation/signing/NemIdSigningPage': cancellationSigningNemIdSigningPage,
  'cancellation/signing/NoSigningPage': cancellationNoSigningPage,
  'cancellation/signing/NoSigningServicePage': cancellationNoSigningServicePage,
  CancellationPortal: cancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement': cancellationsCancellationPortalAgreement,
  categories: categories,
  'CategoryNames/CategoryNames': categoryNames,
  'change-dataplan/ChangeDataplan': changeDataPlan,
  'change-dataplan/ChangeDataplanCompletedPage': changeDataPlanCompletedPage,
  'change-dataplan/ChangeDataplanOrderFormPage': changeDataPlanOrderFormPage,
  'change-dataplan/ChangeDataplanPage': changeDataPlanPage,
  'change-payment/ChangePlanServiceIntroductionDrawer': changePaymentChangePlanServiceIntroductionDrawer,
  'change-payment/IntelligentGuidePage': changePaymentIntelligentGuidePage,
  'change-plan/ChangePlanServiceIntroductionDrawer': changePlanServiceIntroductionDrawer,
  'change-plan/IntelligentGuideExperimentPage': changePlanIntelligentGuideExperimentPage,
  'change-plan/IntelligentGuidePage': changePlanIntelligentGuidePage,
  ChangePlan: changePlan,
  'charity-donation': charityDonation,
  'complex-contract-guide/IntroPage': complexContractGuideIntroPage,
  'complex-contract-guide/LoanServiceQuestionPage': complexContractGuideLoanServiceQuestionPage,
  'complex-contract-guide/LoanTypeQuestionPage': complexContractGuideLoanTypeQuestionPage,
  'complex-contract-guide/ServiceQuestionPage': complexContractGuideServiceQuestionPage,
  'complex-contract-guide/Shared': complexContractGuideShared,
  'complex-contract-guide/SuccessPage': complexContractGuideSuccessPage,
  'conditions-and-policies/ConditionsAndPoliciesMenu': conditionsAndPoliciesMenu,
  'conditions-and-policies/CookiesPage': conditionsAndPoliciesCookiesPage,
  'conditions-and-policies/SecurityPage': conditionsAndPoliciesSecurityPage,
  'conditions-and-policies': conditionsAndPolicies,
  'contract/bindingPeriodDialog': contractBindingPeriodDialog,
  'contract/CancellationHistory': contractCancellationHistory,
  'contract/CancelledContract': contractCancelledContract,
  'contract/ContractCancellationOutcome': contractCancellationOutcome,
  'contract/ContractCardMessage': contractCardMessage,
  'contract/ContractDetails': contractDetails,
  'contract/ContractDetailsPage': contractDetailsPage,
  'contract/DoMoreComponent': contractDoMoreComponent,
  'contract/HistoryToolTip': contractHistoryToolTip,
  'contract/PriceHistoryLolipop': contractPriceHistory,
  'contract/SelectServicePageLolipop': contractSelectServicePage,
  'contract/SelectServicesListLolipop': contractSelectServicesList,
  'contract/SettingsButtonLolipop': contractSettingsButton,
  'contract/SettingsPageLolipop': contractSettingsPage,
  'contract/SubscriptionAlarmLolipop': contractSubscriptionAlarm,
  'contract/SubscriptionDetailsLolipop': contractSubscriptionDetails,
  'contract/SubscriptionReminder': contractSubscriptionReminder,
  'contract/subscriptionReminderDialog': contractSubscriptionReminderDialog,
  'contract/TransactionHistoryLolipop': contractTransactionHistory,
  'contract/UpdateCostLolipop': contractUpdateCost,
  'discover/CategoryPage': discoverCategoryPage,
  'discover/CategoryProviderPage': discoverCategoryProviderPage,
  'discover/components/ProductInformationHeader': discoverComponentsProductInfo,
  'discover/DiscoverWidget': discoverDiscoverWidget,
  'discover/FakeCategoryPage': discoverFakeCategoryPage,
  'discover/FakeCategorySubscriptionsPage': discoverFakeCategorySubscriptionPage,
  'discover/FitnessCategoryFeedbackPage': discoverFitnessCategoryFeedbackPage,
  'discover/MarketplacePage': discoverMarketplacePage,
  'discover/MobileOrderSentPage': discoverMobileOrderSentPage,
  'discover/OrderProductPage': discoverOrderProductPage,
  'discover/ProductRecommendations': discoverProductRecommendations,
  'discover/SearchSubscriptionAllCategoriesPage': discoverSearchSubscriptionAllCategories,
  'discover/SearchSubscriptionPage': discoverSearchSubscriptionPage,
  'discover/SearchSubscriptionResultPage': discoverSearchSubscriptionResultPage,
  DUMMY: dummy,
  'e-signing/nem-id/NemIdSigningPage': eSigning,
  EditProduct: editProduct,
  'EnergyGuide/OrderForm': energyGuide,
  'error-handling/LoggedOutPage': errorHandling,
  'errorPage/ErrorPage': errorPageErrorPage,
  ErrorScreen: errorScreen,
  Explore: explore,
  'feedback-modal': feedbackModal,
  fields: fields,
  'financial-products/loans/ShowNewFeatureLoanDialog': financialProductsLoansShow,
  'financial-products/savings/InvestmentInfoCard': financialProductsSavingsInvestment,
  'financial-products/savings/OurPartnersPage': financialProductOurPartnersPage,
  'financial-products/savings/OurPartnersPageNew': financialProductOurPartnersPageNew,
  'financial-products/savings/Overview': financialProductsSavingsOverview,
  'financial-products/savings/SavingsAfterCancellation': financialProductSavingsAfterCancellation,
  'financial-products/ShowNewFeatureLoanDialog': financialProductsShowNewFeatureLoan,
  'financial-products/swedbank-consolidate-loans/HowItWorksPage': financialProductsSwedBankConsolidate,
  'financial-products/swedbank-consolidate-loans/IntroPage': financialSwedBankConsolidateIntro,
  'financial-products/swedbank-consolidate-loans/LoanApplicationStatus': financialProductLoanApplication,
  'financial-products/swedbank-consolidate-loans/OfferPage': financialProductsOfferPage,
  'financial-products/swedbank-consolidate-loans/OrderCompletedPage': financialProductOrderCompleted,
  'financial-products/swedbank-consolidate-loans/OrderPage': financialProductOrderPage,
  'financial-products/swedbank-consolidate-loans/SelectContractsPage': financialProductSelectContract,
  'financial-products/swedbank-consolidate-loans/SelectPrincipalPage': financialProductsSelectPrincipal,
  'financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage': financialProductRepaymentPeriod,
  'general/IntelligentGuide': generalIntelligentGuide,
  'general/SelectServicesPage': generalSelectServices,
  'genericActions/GenericActionsPage': genericActionsPage,
  'genericActions/SelectServicesPage': genericActionsSelectServices,
  'genericActions/SubscriptionSearchPage/Cancel': genericActionsSubscriptionCancel,
  'genericActions/SubscriptionSearchPage/ChangePayment': genericActionsSubscriptionsChangePayment,
  'guides/GuideContractDetailsPage/AlternativesSection': guidesContractDetailsAlternative,
  'guides/GuideContractDetailsPage/DealBanner': guidesDealBanner,
  'guides/GuideContractDetailsPage/PaymentMethodsCard': guidesPaymentMethod,
  Hack7CancelPage: hack7CancelPage,
  Header: header,
  HowToCancelListPage: howToCancelListPage,
  HowToCancelPage: howToCancelPage,
  'improve/flowgen': improveFlowgen,
  'improve/flowgen/OrderPage': improveOrderPage,
  'improve/flowgen/OrderSentPage': improveOrderSentPage,
  'improve/flowgen/OtherAlternativesPage': improveOtherAlternativePage,
  'improve/flowgen/PostalCodeInput': improvePostalCodeInput,
  'improve/flowgen/ResultPage': improveResultPage,
  'InsightsPage/InsightCards/CancellationOutcome': insightPageInsightsCardsCancelOutcome,
  'InsightsPage/InsightCards/CancellationUpdate': insightPageInsightsCardsCancelUpdate,
  'InsightsPage/OnboardingInsightCard': insightPageOnboardingInsightCard,
  'interval-to-string': intervalToString,
  LandingPage: landingPage,
  LoadingScreen: loadingScreen,
  'loan-applications/DetailsPage': loanApplicationsDetailsPage,
  'loan-applications/LoadingError': loanApplicationsLoadingError,
  'loan-applications/MenuDrawer': loanApplicationsMenuDrawer,
  'loan-applications/Overview': loanApplicationsOverview,
  LoginPage: loginPage,
  ManageOrder: manageOrder,
  ManualAddContractPage: manualAddContractPage,
  ManualContractPricingPage: manualAddContractPricingPage,
  Menu: menu,
  MenuPage: menuPage,
  'moving/BundledCancellationFromPage': movingBundledCancellationForm,
  'moving/BundledCancellationPage': movingBundledCancellationPage,
  'moving/BundledSwitchOrderFormPage': movingBundledSwitchOrderForm,
  'moving/BundledSwitchSwitchPage': movingBundledSwitchPage,
  'moving/ThanksSwitchPage': thanksSwitchPage,
  movingGuide: movingGuide,
  'movingGuide/MovingDetailsPage': movingGuideMovingDetails,
  'movingGuide/OneLastThingPage': movingGuideOneLastThingPage,
  'negotiate/NegotiateContractCompletePage': negotiateContractCompletePage,
  'negotiate/NegotiateGuide': negotiateGuide,
  'negotiation/OngoingNegotiationCard': negotiationOngoingCard,
  'negotiation/OngoingNegotiationStepper': negotiationOngoingStepper,
  NotificationOptInPage: notificationOptInPage,
  'onboarding/PSD2ConsentPage': onboardingPSD2Consent,
  'onboarding/PSD2PrimerPage': onboardingPSD2Primer,
  'onboarding-bank-discovery-sync/BankDiscoveryConnecting': onboardingBDSyncConnecting,
  'onboarding-bank-discovery-sync/BankDiscoveryErrorPage': onboardingBDSyncError,
  'onboarding-bank-discovery-sync/BankDiscoveryFetchPage': onboardingBDSyncFetch,
  'onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage': onboardingBDSyncSuccess,
  'onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage': onboardingBDSyncWaiting,
  'onboarding-cancel-product/OnboardingCancelProductPages': onboardingCancelProduct,
  'onboarding-psd2/WelcomePage': onboardingPsd2WelcomePage,
  'onboarding-psd2/ConsentPage': onboardingPsd2ConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPage': onboardingPsd2OpBankConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPageV2': onboardingPsd2OpBankConsentPageV2,
  'onboarding-psd2/ConsentPage/DanskeBankConsentPage': onboardingPsd2ConsentPageDanskeBank,
  'onboarding-psd2/RenewConsentPage': onboardingPsd2RenewConsentPage,
  'onboarding-psd2/RenewConsentPageV2': onboardingPsd2RenewConsentPageV2,
  'onboarding/Cards': onboardingPsd2Cards,
  'orderedSubscription/OngoingOrderCard': orderedSubscriptionOngoing,
  'orderedSubscription/OrderedEnergyProductInfo': orderedSubscriptionOrderedEnergy,
  'orderedSubscription/OrderedMobileProductInfo': orderedSubscriptionOrderedMobile,
  'orderedSubscription/OrderedSubscriptionDetails': orderedSubscriptionDetails,
  'orderedSubscription/OrderedSubscriptionDetailsPage': orderedSubscriptionDetailsPage,
  'orderedSubscription/OrderedSubscriptionInfoCard': orderedSubscriptionInfoCard,
  'orderedSubscription/OrderedSubscriptionPage': orderedSubscriptionPage,
  'orderedSubscription/OrderStepper': orderedSubscriptionOrderStepper,
  'orderedSubscription/ServiceProviderInfoCard': orderedSubscServiceProviderInfo,
  'ordering-subscription/common/TermsAndConditionsPage': orderingSubscriptionTerms,
  'ordering-subscription/common/OrderCompletedPage': orderingSubscriptionCompleted,
  'ordering-subscription/broadband/common/BroadbandGuideAddressPage': orderingBroadbandAddressPage,
  'ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage': orderingBroadbandAllAlternatives,
  'ordering-subscription/broadband/common/BroadbandGuideContractCostPage': orderingBroadbandContract,
  'ordering-subscription/broadband/common/BroadbandGuideIntroPage': orderingBroadbandIntroPage,
  'ordering-subscription/broadband/common/BroadbandGuideNoResultPage': orderingBroadbandNoResultPage,
  'ordering-subscription/broadband/common/BroadbandGuideSpeedPage': orderingBroadbandSpeedPage,
  'ordering-subscription/broadband/optimize/BroadbandOrderFormPage': orderingBroadbandOptimizeOrder,
  'ordering-subscription/broadband/optimize/BroadbandProductPage': orderingBroadbandOptimizeProduct,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog':
    orderingBroadbandOptimizeProductCosts,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPage': orderingElectricityOrder,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant': orderingElectricityOrderVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog': orderingElectricityGuidePrice,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage':
    orderingElectricityGuideAlternatives,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage':
    orderingElectricityGuideBinding,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage':
    orderingElectricityGuideBindingTime,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage': orderingElectricityGuideEnergy,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage':
    orderingElectricityGuideExplanation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage': orderingElectricityGuideFlow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage': orderingElectricityGuideHow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage':
    orderingElectricityGuideInformation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage': orderingElectricityGuideIntro,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage': orderingElectricityGuideLivingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage':
    orderingElectricityGuidePostalCodePage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences': orderingElectricityGuidePreferences,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage':
    orderingElectricityGuidePricingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage': orderingElectricityGuideProductPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage': orderingElectricityGuideSummaryPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage': orderingElectricityGuideTrading,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage': orderingElectricityGuideUsage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage':
    orderingElectricityGuideUsageResult,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant':
    orderingElectricityGuideUsageResultVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityPostalCode': orderingElectricityGuidePostalCode,
  'ordering-subscription/independent/OrderCompletedPage': orderingSubscriptionIndependentOrder,
  OrdersOverview: ordersOverview,
  'overview/addManualSubscription': overviewAddManualSubscription,
  'overview/BindingTimeBar': overviewBindingTimeBar,
  'overview/BindingTimeNotice': bindingTimeNotice,
  'overview/ChangePlanServiceIntroductionDrawer': overviewChangePlanService,
  'overview/ContractAction': overviewContractAction,
  'overview/DeprecatedInsightsPage/OnboardingInsightCard': overviewDeprecatedInsights,
  'overview/DiscoverPage': overviewDiscoverPage,
  'overview/GenericActions': overviewGenericAction,
  'overview/ImprovementPage': overviewImprovementPage,
  'overview/ImproveV2': overviewImproveV2,
  'overview/InsightsPage': insightPage,
  'overview/InsightsPage/InsightCards/ActionRating': overviewInsightsCardsActionRating,
  'overview/InsightsPage/InsightCards/CancellationUpdate': overviewInsightsCardsCancellationUpdate,
  'overview/InsightsPage/InsightCards/ChangeDataplanUpdate': overviewInsightsCardsChangeData,
  'overview/InsightsPage/InsightCards/IndependentOrderUpdate': overviewInsightsCardsIndependent,
  'overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton': overviewInsightsCardConfirmCancellation,
  'overview/InsightsPage/InsightCards/ContactUsButton': overviewContactUsButton,
  'overview/InsightsPage/InsightCards/MonthlyChecklist': overviewInsightsCardsMonthlyCheck,
  'overview/InsightsPage/InsightCards/NegotiationOutcome': overviewInsightsCardsNegotiationOutcome,
  'overview/InsightsPage/InsightCards/NegotiationUpdate': overviewInsightsCardsNegotiationUpdate,
  'overview/InsightsPage/InsightCards/NewProviderOffersCard': overviewInsightsCardsProvider,
  'overview/InsightsPage/InsightCards/NewSubscriptionFound': insightPage_insightsCards_newSubscription,
  'overview/InsightsPage/InsightCards/SaveDeskFeedbackCard': overviewInsightsCardsSaveDeskFeedback,
  'overview/InsightsPage/InsightCards/SaveDeskOrderRequested': overviewInsightCardsSaveDeskRequested,
  'overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested': overviewInsightsCardsSaveDeskPredefined,
  'overview/InsightsPage/InsightCards/SaveDeskResponseCard': overviewInsightsCardsSaveDeskResponse,
  'overview/InsightsPage/InsightCards/SwitchOutcome': overviewInsightsCardsSwitchOutcome,
  'overview/InsightsPage/InsightCards/SwitchUpdate': overviewInsightsCardsSwitchUpdate,
  'overview/InsightsPage/InsightCards/ThankYouCard': overviewInsightsCardsThankYou,
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton': overviewInsightsCardsTryCancellation,
  'overview/InsightsPage/InsightCards/UnreviewedAction': overviewInsightsCardsUnreviewed,
  'overview/InsightsPage/InsightCards/AddonRecommendation': insightPage_insightsCards_addon,
  'overview/InsightsPage/BindingTimeEnding': insightPage_bindingTimeEnding,
  'overview/InsightsPage/InsightCards/DiscoverOngoing': insightPage_insightsCards_discoverOngoing,
  'overview/InsightsPage/InsightCards/DiscoverOutcome': insightPage_insightsCards_discoverOutcomeInsight,
  'overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard':
    insightPage_insightsCards_personalRecommend,
  'overview/InsightsPage/InsightCards/SaveDeskOngoingCard': insightPage_insightsCards_saveDeskOngoing,
  'overview/InsightsPage/InsightCards/SaveDeskOutcome': insightPage_insightsCards_saveDeskOutcome,
  'overview/InsightsPage/SubscriptionCostChanged': insightPage_subscriptionCostChanged,
  'overview/InsightsPage/InsightCards/UnresolvedSubscription': insightPage_unresolvedSubscription,
  'overview/InsightsPage/OnboardingInsightCard': overviewInsightsCardsOnboarding,
  'overview/InsightsPage/PrimerCardV2': overviewInsightsPrimerCardV2,
  'overview/LoadingWithCogs': overviewLoadingWithCogs,
  'overview/ManageContractSection/SwitchProgressWithoutCancelStepper': overviewManageContract,
  'overview/MonthlyChecklist': overviewMonthlyChecklist,
  'overview/MoreInfoNeeded': overviewMoreInfoNeeded,
  'overview/OnboardingCards': overviewOnboardingCards,
  'overview/QuickActionsCancel': overviewQuickActionsCancel,
  'overview/QuickActionsCancelV2': overviewQuickActionsCancelV2,
  'overview/QuickActionsImprove': overviewQuickActionsImprove,
  'overview/QuickActionsImproveV2': overviewQuickActionsImproveV2,
  'overview/SubscriptionFeedback': overviewSubscriptionFeedback,
  'overview/SubscriptionSearch': overviewSubscriptionSearch,
  'overview/SubscriptionSearchOverviewComponent': overviewSubscriptionSearchComponent,
  'overview/WaysToSave': waysToSave,
  'overview/OverviewPage': overviewPage,
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage':
    overviewPage_didYouCancel,
  'overview/OnboardingDrawer': onboardingDrawer,
  'overview/QuickActionsV2': overviewPage_quickActionsV2,
  overview: overview,
  overviewBottomNavigation: overviewBottomNavigation,
  'pause/IntelligentGuidePage': pauseIntelligentGuide,
  'pause/SubscriptionPausePage': pauseSubscriptionPause,
  provideFeedbackPage: provideFeedbackPage,
  'provider-portal-datepicker/DatePicker': providerPortalDatepicker,
  'provider-portal-snackback/Snackbar': providerPortalSnackback,
  RegistrationPage: registrationPage,
  'retention-offer/SuccessfulRetentionOfferCard': retentionOfferCard,
  'save-desk': saveDesk,
  'save-desk/OngoingSaveDeskCard': saveDeskOngoingCard,
  'save-desk/OrderSentPage': saveDeskOrderSent,
  'savings/DoYouWantToSaveDialog': savingsDoYouWantToSave,
  'savings/SavingsAfterCancellation': savingsAfterCancellation,
  SelectProductPage: selectProductPage,
  'settings/NotificationSettingsPage': settingsNotificationSettings,
  'settings/NotificationSettingsPage/PushNotificationInstructions': settingsNotificationPushSettings,
  'settings/PersonalDetailsPage': settingsPersonalDetails,
  'settings/ProfilingSettingsPage': settingsProfile,
  'settings/SettingsPage': settingsPage,
  'settings/UserSettingsPage': settingsUser,
  'settings/ViewGdprDataPage': settingsViewGdprData,
  'settings-swedbank/SwedbankNotificationSettingsPage': settingsSwedbank,
  'shared-components/EmojiRating': sharedComponentsEmoji,
  'shared-components/UpdateBankApp': sharedComponentsUpdateBankApp,
  'shared-components/YouSavedComponent': sharedComponentsYouSavedComponent,
  ShowOnboardingFeaturesComponent: showOnboardingFeatures,
  SignoutSuccessPage: signOutSuccessPage,
  'standard-components/CurrencyTextField': standardComponentsCurrency,
  'standard-components/EmailField': standardComponentsEmail,
  'standard-components/DatePicker': standardComponentsDate,
  'standard-components/GuidePage': standardComponentsGuide,
  'standard-components/MobilePhoneNumberField': standardComponentsMobile,
  'standard-components/PasswordField': standardComponentsPassword,
  'standard-components/PersonalNumberField': standardComponentsPersonal,
  'standard-components/PostalCodeField': standardComponentsPostalCode,
  'standard-components/Snackbar': standardComponentsSnackbar,
  'subscription/CancellationMeasurement': subscriptionCancellationMeasure,
  'subscription/components/SelectableServiceList': subscriptionComponentsSelectable,
  'subscription/RemoveSubscriptionDialog': subscriptionRemoveSubscription,
  'subscription/SelectLoanServicesPage': subscriptionSelectLoanService,
  'subscription/SelectLoanTransactionTypePage': subscriptionSelectLoanTransaction,
  'subscription/SelectServicesPage': subscriptionSelectServices,
  'subscription/SubscriptionPage': subscriptionPage,
  'subscription/SubscriptionSettingsPage': subscriptionSettingsPage,
  'subscription/SubscriptionUpdateCostPage': subscriptionUpdateCostPage,
  'subscription/SwitchMeasurement': subscriptionSwitchMeasurement,
  'subscription/UnresolvedGuide': subscriptionUnresolvedGuide,
  'subscription/WhatsWrongDialog': subscriptionWhatsWrong,
  'subscription-alarm/MorePage': subscriptionAlarmMore,
  'subscription-alarm/SubscriptionAlarmPage': subscriptionAlarmPage,
  'subscription-hub/PastActions': subscriptionHubActions,
  'subscriptionHub/subscriptionHub': subscriptionHub,
  'supplier-portal-change': supplierPortalChange,
  'supplier-portal-save-desk': supplierPortalSaveDesk,
  SwedbankOverviewSyncFetch: swedbankOverviewSync,
  SwedenPhoneCallsLimitationInput: swedenPhoneCallsLimited,
  'sync-sources/AddSyncSourcePage': syncSourcesAddSync,
  'sync-sources/AddSyncSourcesSpeedDial': syncSourcesAddSyncSpeed,
  'sync-sources/DeleteSyncSourcesPage': syncSourcesDeleteSync,
  'sync-sources/Error': syncSourcesError,
  'sync-sources/NoSyncSources': syncSourcesNoSync,
  'sync-sources/PopularSyncSourcesList': syncSourcesPopularSync,
  'sync-sources/SyncSourcesFetching': syncSourcesFetching,
  'sync-sources/SyncSourcesList': syncSourcesList,
  'sync-sources/TinkSyncPage': syncSourcesTinkSync,
  'sync-sources/UpdateBankAuthsPage': syncSourcesUpdateBank,
  'sync-sources/UpdateSyncSources': syncSourcesUpdateSync,
  'sync-sources/UpdateSyncSourcesButton': syncSourcesUpdateSyncButton,
  'sync-sources/SyncSourcesReminder': syncSourcesReminder,
  'termination/TerminationFormPage': terminationFormPage,
  'termination/TerminationFormPage/CancelReasonSection': terminationFormPCancelReason,
  'termination/TerminationInstructionsPage': terminationInstructions,
  TermsAndConditionsUpload: termsAndCondition,
  TypeformButton: typeFormButton,
  'user-form-preview': userFormPreview,
  userFeedback: userFeedback,
  'userSettings/UserDeletePage': userDeleteSettings,
  'userSettings/UserDeleteVerifiedPage': userDeleteVerifiedPage,
  zendesk: zendesk,
  'aboutUsPage/OpBank': opBankaboutUsPage,
  'AboutUsPage/BankAboutUsPage/OpBank': opBankbankAboutUsPage,
  'addon/mobile/RecommendationsPage/OpBank': opBankaddonMobileRecommendationsPage,
  'addon/mobile/ResultPage/OpBank': opBankaddonMobileResultPage,
  'auth/AuthStatusPage/OpBank': opBankauthAuthStatusPage,
  'auth/BasicLoginPage/OpBank': opBankauthBasicLoginPage,
  'auth/CreateAccountPage/OpBank': opBankauthCreateAccountPage,
  'auth/LoginPage/OpBank': opBankauthCreateAccountPage,
  'auth/OnboardingChooseBankPage/OpBank': opBankauthOnboardingChooseBankPage,
  'auth/OnboardingEmailPage/OpBank': opBankauthOnboardingEmailPage,
  'auth/PreLoginPage/OpBank': opBankauthPreLoginPage,
  'auth/ResetPasswordPage/OpBank': opBankauthResetPasswordPage,
  'auth/SyncSourcesError/OpBank': opBankauthSyncSourcesError,
  'auth/SyncSourcesFetching/OpBank': opBankauthSyncSourcesFetching,
  'BadgeABTestCtas/OpBank': opBankbadgeABTestCTAS,
  'BadgeCtas/OpBank': opBankbadgeCtas,
  'bank-sync/CancelProductOnboardPages/OpBank': opBankbankSyncCancelProduct,
  'bank-sync/DrawerPages/OpBank': opBankbankSyncDrawerPages,
  'bank-sync/LoadingScreen/OpBank': opBankbankSyncLoadingScreen,
  'bank-sync/OnBoardBankSync/OpBank': opBankbankSyncOnboardBankSync,
  'CampaignIdSelect/OpBank': opBankcampaignIdSelect,
  'cancel/CancellationOutcome/OpBank': opBankcancelCancellationOutcome,
  'cancellation/cancellationDetailsPage/OpBank': opBankcancellationDetailsPage,
  'cancellation/CancellationFormPage/CancellationInstructionsSection/OpBank':
    opBankcancellationFormCancellationInstruction,
  'cancellation/CancellationFormPage/CancellationTerms/OpBank': opBankcancellationFormCancellationTerms,
  'cancellation/CancellationFormPage/ConfirmSection/OpBank': opBankcancellationFormConfirmSection,
  'cancellation/CancellationFormPage/LightUserTerms/OpBank': opBankcancellationFormLightUserTerms,
  'cancellation/CancellationModalsContainer/OpBank': opBankcancellationModalsContainer,
  'cancellation/CancellationQuestionFlow/OpBank': opBankcancellationQuestionsFlow,
  'cancellation/CancellationReasonsDialog/OpBank': opBankcancellationReasonsDialog,
  'cancellation/CancellationSentPage/OpBank': opBankcancellationSentPage,
  'cancellation/CancellationStepper/OpBank': opBankcancellationStepper,
  'cancellation/DoYouWantToSaveDialog/OpBank': opBankcancellationDoYouWantToSaveDialog,
  'cancellation/IntelligentGuidePage/OpBank': opBankcancellationIntelligentGuidePage,
  'cancellation/NetflixCancellationInstructionsPage/OpBank': opBankcancellationNetflixCancellationInstructionsPage,
  'cancellation/signing/NemIdErrorPage/OpBank': opBankcancellationSigningNemIdErrorPage,
  'cancellation/signing/NemIdSigningPage/OpBank': opBankcancellationSigningNemIdSigningPage,
  'cancellation/signing/NoSigningPage/OpBank': opBankcancellationNoSigningPage,
  'cancellation/signing/NoSigningServicePage/OpBank': opBankcancellationNoSigningServicePage,
  'CancellationPortal/OpBank': opBankcancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement/OpBank':
    opBankcancellationsCancellationPortalAgreement,
  'categories/OpBank': opBankcategories,
  'CategoryNames/CategoryNames/OpBank': opBankcategoryNames,
  'change-dataplan/ChangeDataplan/OpBank': opBankchangeDataPlan,
  'change-dataplan/ChangeDataplanCompletedPage/OpBank': opBankchangeDataPlanCompletedPage,
  'change-dataplan/ChangeDataplanOrderFormPage/OpBank': opBankchangeDataPlanOrderFormPage,
  'change-dataplan/ChangeDataplanPage/OpBank': opBankchangeDataPlanPage,
  'change-payment/ChangePlanServiceIntroductionDrawer/OpBank': opBankchangePaymentChangePlanServiceIntroductionDrawer,
  'change-payment/IntelligentGuidePage/OpBank': opBankchangePaymentIntelligentGuidePage,
  'change-plan/ChangePlanServiceIntroductionDrawer/OpBank': opBankchangePlanServiceIntroductionDrawer,
  'change-plan/IntelligentGuideExperimentPage/OpBank': opBankchangePlanIntelligentGuideExperimentPage,
  'change-plan/IntelligentGuidePage/OpBank': opBankchangePlanIntelligentGuidePage,
  'ChangePlan/OpBank': opBankchangePlan,
  'charity-donation/OpBank': opBankcharityDonation,
  'complex-contract-guide/IntroPage/OpBank': opBankcomplexContractGuideIntroPage,
  'complex-contract-guide/LoanServiceQuestionPage/OpBank': opBankcomplexContractGuideLoanServiceQuestionPage,
  'complex-contract-guide/LoanTypeQuestionPage/OpBank': opBankcomplexContractGuideLoanTypeQuestionPage,
  'complex-contract-guide/ServiceQuestionPage/OpBank': opBankcomplexContractGuideServiceQuestionPage,
  'complex-contract-guide/Shared/OpBank': opBankcomplexContractGuideShared,
  'complex-contract-guide/SuccessPage/OpBank': opBankcomplexContractGuideSuccessPage,
  'conditions-and-policies/ConditionsAndPoliciesMenu/OpBank': opBankconditionsAndPoliciesMenu,
  'conditions-and-policies/CookiesPage/OpBank': opBankconditionsAndPoliciesCookiesPage,
  'conditions-and-policies/SecurityPage/OpBank': opBankconditionsAndPoliciesSecurityPage,
  'conditions-and-policies/OpBank': opBankconditionsAndPolicies,
  'contract/bindingPeriodDialog/OpBank': opBankcontractBindingPeriodDialog,
  'contract/CancellationHistory/OpBank': opBankcontractCancellationHistory,
  'contract/CancelledContract/OpBank': opBankcontractCancelledContract,
  'contract/ContractCancellationOutcome/OpBank': opBankcontractCancellationOutcome,
  'contract/ContractCardMessage/OpBank': opBankcontractCardMessage,
  'contract/ContractDetails/OpBank': opBankcontractDetails,
  'contract/ContractDetailsPage/OpBank': opBankcontractDetailsPage,
  'contract/DoMoreComponent/OpBank': opBankcontractDoMoreComponent,
  'contract/HistoryToolTip/OpBank': opBankcontractHistoryToolTip,
  'contract/PriceHistoryLolipop/OpBank': opBankcontractPriceHistory,
  'contract/SelectServicePageLolipop/OpBank': opBankcontractSelectServicePage,
  'contract/SelectServicesListLolipop/OpBank': opBankcontractSelectServicesList,
  'contract/SettingsButtonLolipop/OpBank': opBankcontractSettingsButton,
  'contract/SettingsPageLolipop/OpBank': opBankcontractSettingsPage,
  'contract/SubscriptionAlarmLolipop/OpBank': opBankcontractSubscriptionAlarm,
  'contract/SubscriptionDetailsLolipop/OpBank': opBankcontractSubscriptionDetails,
  'contract/SubscriptionReminder/OpBank': opBankcontractSubscriptionReminder,
  'contract/subscriptionReminderDialog/OpBank': opBankcontractSubscriptionReminderDialog,
  'contract/TransactionHistoryLolipop/OpBank': opBankcontractTransactionHistory,
  'contract/UpdateCostLolipop/OpBank': opBankcontractUpdateCost,
  'discover/CategoryPage/OpBank': opBankdiscoverCategoryPage,
  'discover/CategoryProviderPage/OpBank': opBankdiscoverCategoryProviderPage,
  'discover/components/ProductInformationHeader/OpBank': opBankdiscoverComponentsProductInfo,
  'discover/DiscoverWidget/OpBank': opBankdiscoverDiscoverWidget,
  'discover/FakeCategoryPage/OpBank': opBankdiscoverFakeCategoryPage,
  'discover/FakeCategorySubscriptionsPage/OpBank': opBankdiscoverFakeCategorySubscriptionPage,
  'discover/FitnessCategoryFeedbackPage/OpBank': opBankdiscoverFitnessCategoryFeedbackPage,
  'discover/MarketplacePage/OpBank': opBankdiscoverMarketplacePage,
  'discover/MobileOrderSentPage/OpBank': opBankdiscoverMobileOrderSentPage,
  'discover/OrderProductPage/OpBank': opBankdiscoverOrderProductPage,
  'discover/ProductRecommendations/OpBank': opBankdiscoverProductRecommendations,
  'discover/SearchSubscriptionAllCategoriesPage/OpBank': opBankdiscoverSearchSubscriptionAllCategories,
  'discover/SearchSubscriptionPage/OpBank': opBankdiscoverSearchSubscriptionPage,
  'discover/SearchSubscriptionResultPage/OpBank': opBankdiscoverSearchSubscriptionResultPage,
  'DUMMY/OpBank': opBankdummy,
  'e-signing/nem-id/NemIdSigningPage/OpBank': opBankeSigning,
  'EditProduct/OpBank': opBankeditProduct,
  'EnergyGuide/OrderForm/OpBank': opBankenergyGuide,
  'error-handling/LoggedOutPage/OpBank': opBankerrorHandling,
  'errorPage/ErrorPage/OpBank': opBankerrorPageErrorPage,
  'ErrorScreen/OpBank': opBankerrorScreen,
  'Explore/OpBank': opBankexplore,
  'feedback-modal/OpBank': opBankfeedbackModal,
  'fields/OpBank': opBankfields,
  'financial-products/loans/ShowNewFeatureLoanDialog/OpBank': opBankfinancialProductsLoansShow,
  'financial-products/savings/InvestmentInfoCard/OpBank': opBankfinancialProductsSavingsInvestment,
  'financial-products/savings/OurPartnersPage/OpBank': opBankfinancialProductOurPartnersPage,
  'financial-products/savings/OurPartnersPageNew/OpBank': opBankfinancialProductOurPartnersPageNew,
  'financial-products/savings/Overview/OpBank': opBankfinancialProductsSavingsOverview,
  'financial-products/savings/SavingsAfterCancellation/OpBank': opBankfinancialProductSavingsAfterCancellation,
  'financial-products/ShowNewFeatureLoanDialog/OpBank': opBankfinancialProductsShowNewFeatureLoan,
  'financial-products/swedbank-consolidate-loans/HowItWorksPage/OpBank': opBankfinancialProductsSwedBankConsolidate,
  'financial-products/swedbank-consolidate-loans/IntroPage/OpBank': opBankfinancialSwedBankConsolidateIntro,
  'financial-products/swedbank-consolidate-loans/LoanApplicationStatus/OpBank': opBankfinancialProductLoanApplication,
  'financial-products/swedbank-consolidate-loans/OfferPage/OpBank': opBankfinancialProductsOfferPage,
  'financial-products/swedbank-consolidate-loans/OrderCompletedPage/OpBank': opBankfinancialProductOrderCompleted,
  'financial-products/swedbank-consolidate-loans/OrderPage/OpBank': opBankfinancialProductOrderPage,
  'financial-products/swedbank-consolidate-loans/SelectContractsPage/OpBank': opBankfinancialProductSelectContract,
  'financial-products/swedbank-consolidate-loans/SelectPrincipalPage/OpBank': opBankfinancialProductsSelectPrincipal,
  'financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/OpBank':
    opBankfinancialProductRepaymentPeriod,
  'general/IntelligentGuide/OpBank': opBankgeneralIntelligentGuide,
  'general/SelectServicesPage/OpBank': opBankgeneralSelectServices,
  'genericActions/GenericActionsPage/OpBank': opBankgenericActionsPage,
  'genericActions/SelectServicesPage/OpBank': opBankgenericActionsSelectServices,
  'genericActions/SubscriptionSearchPage/Cancel/OpBank': opBankgenericActionsSubscriptionCancel,
  'genericActions/SubscriptionSearchPage/ChangePayment/OpBank': opBankgenericActionsSubscriptionsChangePayment,
  'guides/GuideContractDetailsPage/AlternativesSection/OpBank': opBankguidesContractDetailsAlternative,
  'guides/GuideContractDetailsPage/DealBanner/OpBank': opBankguidesDealBanner,
  'guides/GuideContractDetailsPage/PaymentMethodsCard/OpBank': opBankguidesPaymentMethod,
  'Hack7CancelPage/OpBank': opBankhack7CancelPage,
  'Header/OpBank': opBankheader,
  'HowToCancelListPage/OpBank': opBankhowToCancelListPage,
  'HowToCancelPage/OpBank': opBankhowToCancelPage,
  'improve/flowgen/OpBank': opBankimproveFlowgen,
  'improve/flowgen/OrderPage/OpBank': opBankimproveOrderPage,
  'improve/flowgen/OrderSentPage/OpBank': opBankimproveOrderSentPage,
  'improve/flowgen/OtherAlternativesPage/OpBank': opBankimproveOtherAlternativePage,
  'improve/flowgen/PostalCodeInput/OpBank': opBankimprovePostalCodeInput,
  'improve/flowgen/ResultPage/OpBank': opBankimproveResultPage,
  'InsightsPage/InsightCards/CancellationOutcome/OpBank': opBankinsightPageInsightsCardsCancelOutcome,
  'InsightsPage/InsightCards/CancellationUpdate/OpBank': opBankinsightPageInsightsCardsCancelUpdate,
  'InsightsPage/OnboardingInsightCard/OpBank': opBankinsightPageOnboardingInsightCard,
  'interval-to-string/OpBank': opBankintervalToString,
  'LandingPage/OpBank': opBanklandingPage,
  'LoadingScreen/OpBank': opBankloadingScreen,
  'loan-applications/DetailsPage/OpBank': opBankloanApplicationsDetailsPage,
  'loan-applications/LoadingError/OpBank': opBankloanApplicationsLoadingError,
  'loan-applications/MenuDrawer/OpBank': opBankloanApplicationsMenuDrawer,
  'loan-applications/Overview/OpBank': opBankloanApplicationsOverview,
  'LoginPage/OpBank': opBankloginPage,
  'ManageOrder/OpBank': opBankmanageOrder,
  'ManualAddContractPage/OpBank': opBankmanualAddContractPage,
  'ManualContractPricingPage/OpBank': opBankmanualAddContractPricingPage,
  'Menu/OpBank': opBankmenu,
  'MenuPage/OpBank': opBankmenuPage,
  'moving/BundledCancellationFromPage/OpBank': opBankmovingBundledCancellationForm,
  'moving/BundledCancellationPage/OpBank': opBankmovingBundledCancellationPage,
  'moving/BundledSwitchOrderFormPage/OpBank': opBankmovingBundledSwitchOrderForm,
  'moving/BundledSwitchSwitchPage/OpBank': opBankmovingBundledSwitchPage,
  'moving/ThanksSwitchPage/OpBank': opBankthanksSwitchPage,
  'movingGuide/OpBank': opBankmovingGuide,
  'movingGuide/MovingDetailsPage/OpBank': opBankmovingGuideMovingDetails,
  'movingGuide/OneLastThingPage/OpBank': opBankmovingGuideOneLastThingPage,
  'negotiate/NegotiateContractCompletePage/OpBank': opBanknegotiateContractCompletePage,
  'negotiate/NegotiateGuide/OpBank': opBanknegotiateGuide,
  'negotiation/OngoingNegotiationCard/OpBank': opBanknegotiationOngoingCard,
  'negotiation/OngoingNegotiationStepper/OpBank': opBanknegotiationOngoingStepper,
  'NotificationOptInPage/OpBank': opBanknotificationOptInPage,
  'onboarding/PSD2ConsentPage/OpBank': opBankonboardingPSD2Consent,
  'onboarding/PSD2PrimerPage/OpBank': opBankonboardingPSD2Primer,
  'onboarding-bank-discovery-sync/BankDiscoveryConnecting/OpBank': opBankonboardingBDSyncConnecting,
  'onboarding-bank-discovery-sync/BankDiscoveryErrorPage/OpBank': opBankonboardingBDSyncError,
  'onboarding-bank-discovery-sync/BankDiscoveryFetchPage/OpBank': opBankonboardingBDSyncFetch,
  'onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/OpBank': opBankonboardingBDSyncSuccess,
  'onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/OpBank': opBankonboardingBDSyncWaiting,
  'onboarding-cancel-product/OnboardingCancelProductPages/OpBank': opBankonboardingCancelProduct,
  'onboarding-psd2/WelcomePage/OpBank': opBankonboardingPsd2WelcomePage,
  'onboarding-psd2/ConsentPage/OpBank': opBankonboardingPsd2ConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPage/OpBank': opBankonboardingPsd2OpBankConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPageV2/OpBank': opBankonboardingPsd2OpBankConsentPageV2,
  'onboarding-psd2/ConsentPage/DanskeBankConsentPage/OpBank': opBankonboardingPsd2ConsentPageDanskeBank,
  'onboarding-psd2/RenewConsentPage/OpBank': opBankonboardingPsd2RenewConsentPage,
  'onboarding-psd2/RenewConsentPageV2/OpBank': opBankonboardingPsd2RenewConsentPageV2,
  'onboarding/Cards/OpBank': opBankonboardingPsd2Cards,
  'orderedSubscription/OngoingOrderCard/OpBank': opBankorderedSubscriptionOngoing,
  'orderedSubscription/OrderedEnergyProductInfo/OpBank': opBankorderedSubscriptionOrderedEnergy,
  'orderedSubscription/OrderedMobileProductInfo/OpBank': opBankorderedSubscriptionOrderedMobile,
  'orderedSubscription/OrderedSubscriptionDetails/OpBank': opBankorderedSubscriptionDetails,
  'orderedSubscription/OrderedSubscriptionDetailsPage/OpBank': opBankorderedSubscriptionDetailsPage,
  'orderedSubscription/OrderedSubscriptionInfoCard/OpBank': opBankorderedSubscriptionInfoCard,
  'orderedSubscription/OrderedSubscriptionPage/OpBank': opBankorderedSubscriptionPage,
  'orderedSubscription/OrderStepper/OpBank': opBankorderedSubscriptionOrderStepper,
  'orderedSubscription/ServiceProviderInfoCard/OpBank': opBankorderedSubscServiceProviderInfo,
  'ordering-subscription/common/TermsAndConditionsPage/OpBank': opBankorderingSubscriptionTerms,
  'ordering-subscription/common/OrderCompletedPage/OpBank': opBankorderingSubscriptionCompleted,
  'ordering-subscription/broadband/common/BroadbandGuideAddressPage/OpBank': opBankorderingBroadbandAddressPage,
  'ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/OpBank':
    opBankorderingBroadbandAllAlternatives,
  'ordering-subscription/broadband/common/BroadbandGuideContractCostPage/OpBank': opBankorderingBroadbandContract,
  'ordering-subscription/broadband/common/BroadbandGuideIntroPage/OpBank': opBankorderingBroadbandIntroPage,
  'ordering-subscription/broadband/common/BroadbandGuideNoResultPage/OpBank': opBankorderingBroadbandNoResultPage,
  'ordering-subscription/broadband/common/BroadbandGuideSpeedPage/OpBank': opBankorderingBroadbandSpeedPage,
  'ordering-subscription/broadband/optimize/BroadbandOrderFormPage/OpBank': opBankorderingBroadbandOptimizeOrder,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/OpBank': opBankorderingBroadbandOptimizeProduct,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/OpBank':
    opBankorderingBroadbandOptimizeProductCosts,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPage/OpBank': opBankorderingElectricityOrder,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/OpBank':
    opBankorderingElectricityOrderVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/OpBank':
    opBankorderingElectricityGuidePrice,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/OpBank':
    opBankorderingElectricityGuideAlternatives,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/OpBank':
    opBankorderingElectricityGuideBinding,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/OpBank':
    opBankorderingElectricityGuideBindingTime,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/OpBank':
    opBankorderingElectricityGuideEnergy,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/OpBank':
    opBankorderingElectricityGuideExplanation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/OpBank':
    opBankorderingElectricityGuideFlow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/OpBank':
    opBankorderingElectricityGuideHow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/OpBank':
    opBankorderingElectricityGuideInformation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/OpBank':
    opBankorderingElectricityGuideIntro,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/OpBank':
    opBankorderingElectricityGuideLivingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/OpBank':
    opBankorderingElectricityGuidePostalCodePage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/OpBank':
    opBankorderingElectricityGuidePreferences,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/OpBank':
    opBankorderingElectricityGuidePricingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/OpBank':
    opBankorderingElectricityGuideProductPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/OpBank':
    opBankorderingElectricityGuideSummaryPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/OpBank':
    opBankorderingElectricityGuideTrading,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/OpBank': opBankorderingElectricityGuideUsage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/OpBank':
    opBankorderingElectricityGuideUsageResult,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/OpBank':
    opBankorderingElectricityGuideUsageResultVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/OpBank':
    opBankorderingElectricityGuidePostalCode,
  'ordering-subscription/independent/OrderCompletedPage/OpBank': opBankorderingSubscriptionIndependentOrder,
  'OrdersOverview/OpBank': opBankordersOverview,
  'overview/addManualSubscription/OpBank': opBankoverviewAddManualSubscription,
  'overview/BindingTimeBar/OpBank': opBankoverviewBindingTimeBar,
  'overview/BindingTimeNotice/OpBank': opBankbindingTimeNotice,
  'overview/ChangePlanServiceIntroductionDrawer/OpBank': opBankoverviewChangePlanService,
  'overview/ContractAction/OpBank': opBankoverviewContractAction,
  'overview/DeprecatedInsightsPage/OnboardingInsightCard/OpBank': opBankoverviewDeprecatedInsights,
  'overview/DiscoverPage/OpBank': opBankoverviewDiscoverPage,
  'overview/GenericActions/OpBank': opBankoverviewGenericAction,
  'overview/ImprovementPage/OpBank': opBankoverviewImprovementPage,
  'overview/ImproveV2/OpBank': opBankoverviewImproveV2,
  'overview/InsightsPage/OpBank': opBankinsightPage,
  'overview/InsightsPage/InsightCards/ActionRating/OpBank': opBankoverviewInsightsCardsActionRating,
  'overview/InsightsPage/InsightCards/CancellationUpdate/OpBank': opBankoverviewInsightsCardsCancellationUpdate,
  'overview/InsightsPage/InsightCards/ChangeDataplanUpdate/OpBank': opBankoverviewInsightsCardsChangeData,
  'overview/InsightsPage/InsightCards/IndependentOrderUpdate/OpBank': opBankoverviewInsightsCardsIndependent,
  'overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/OpBank':
    opBankoverviewInsightsCardConfirmCancellation,
  'overview/InsightsPage/InsightCards/ContactUsButton/OpBank': opBankoverviewContactUsButton,
  'overview/InsightsPage/InsightCards/MonthlyChecklist/OpBank': opBankoverviewInsightsCardsMonthlyCheck,
  'overview/InsightsPage/InsightCards/NegotiationOutcome/OpBank': opBankoverviewInsightsCardsNegotiationOutcome,
  'overview/InsightsPage/InsightCards/NegotiationUpdate/OpBank': opBankoverviewInsightsCardsNegotiationUpdate,
  'overview/InsightsPage/InsightCards/NewProviderOffersCard/OpBank': opBankoverviewInsightsCardsProvider,
  'overview/InsightsPage/InsightCards/NewSubscriptionFound/OpBank': opBankinsightPage_insightsCards_newSubscription,
  'overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/OpBank': opBankoverviewInsightsCardsSaveDeskFeedback,
  'overview/InsightsPage/InsightCards/SaveDeskOrderRequested/OpBank': opBankoverviewInsightCardsSaveDeskRequested,
  'overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/OpBank':
    opBankoverviewInsightsCardsSaveDeskPredefined,
  'overview/InsightsPage/InsightCards/SaveDeskResponseCard/OpBank': opBankoverviewInsightsCardsSaveDeskResponse,
  'overview/InsightsPage/InsightCards/SwitchOutcome/OpBank': opBankoverviewInsightsCardsSwitchOutcome,
  'overview/InsightsPage/InsightCards/SwitchUpdate/OpBank': opBankoverviewInsightsCardsSwitchUpdate,
  'overview/InsightsPage/InsightCards/ThankYouCard/OpBank': opBankoverviewInsightsCardsThankYou,
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton/OpBank': opBankoverviewInsightsCardsTryCancellation,
  'overview/InsightsPage/InsightCards/UnreviewedAction/OpBank': opBankoverviewInsightsCardsUnreviewed,
  'overview/InsightsPage/InsightCards/AddonRecommendation/OpBank': opBankinsightPage_insightsCards_addon,
  'overview/InsightsPage/BindingTimeEnding/OpBank': opBankinsightPage_bindingTimeEnding,
  'overview/InsightsPage/InsightCards/DiscoverOngoing/OpBank': opBankinsightPage_insightsCards_discoverOngoing,
  'overview/InsightsPage/InsightCards/DiscoverOutcome/OpBank': opBankinsightPage_insightsCards_discoverOutcomeInsight,
  'overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/OpBank':
    opBankinsightPage_insightsCards_personalRecommend,
  'overview/InsightsPage/InsightCards/SaveDeskOngoingCard/OpBank': opBankinsightPage_insightsCards_saveDeskOngoing,
  'overview/InsightsPage/InsightCards/SaveDeskOutcome/OpBank': opBankinsightPage_insightsCards_saveDeskOutcome,
  'overview/InsightsPage/SubscriptionCostChanged/OpBank': opBankinsightPage_subscriptionCostChanged,
  'overview/InsightsPage/InsightCards/UnresolvedSubscription/OpBank': opBankinsightPage_unresolvedSubscription,
  'overview/InsightsPage/OnboardingInsightCard/OpBank': opBankoverviewInsightsCardsOnboarding,
  'overview/InsightsPage/PrimerCardV2/OpBank': opBankoverviewInsightsPrimerCardV2,
  'overview/LoadingWithCogs/OpBank': opBankoverviewLoadingWithCogs,
  'overview/ManageContractSection/SwitchProgressWithoutCancelStepper/OpBank': opBankoverviewManageContract,
  'overview/MonthlyChecklist/OpBank': opBankoverviewMonthlyChecklist,
  'overview/MoreInfoNeeded/OpBank': opBankoverviewMoreInfoNeeded,
  'overview/OnboardingCards/OpBank': opBankoverviewOnboardingCards,
  'overview/QuickActionsCancel/OpBank': opBankoverviewQuickActionsCancel,
  'overview/QuickActionsCancelV2/OpBank': opBankoverviewQuickActionsCancelV2,
  'overview/QuickActionsImprove/OpBank': opBankoverviewQuickActionsImprove,
  'overview/QuickActionsImproveV2/OpBank': opBankoverviewQuickActionsImproveV2,
  'overview/SubscriptionFeedback/OpBank': opBankoverviewSubscriptionFeedback,
  'overview/SubscriptionSearch/OpBank': opBankoverviewSubscriptionSearch,
  'overview/SubscriptionSearchOverviewComponent/OpBank': opBankoverviewSubscriptionSearchComponent,
  'overview/WaysToSave/OpBank': opBankwaysToSave,
  'overview/OverviewPage/OpBank': opBankoverviewPage,
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/OpBank':
    opBankoverviewPage_didYouCancel,
  'overview/OnboardingDrawer/OpBank': opBankonboardingDrawer,
  'overview/QuickActionsV2/OpBank': opBankoverviewPage_quickActionsV2,
  'overview/OpBank': opBankoverview,
  'overviewBottomNavigation/OpBank': opBankoverviewBottomNavigation,
  'pause/IntelligentGuidePage/OpBank': opBankpauseIntelligentGuide,
  'pause/SubscriptionPausePage/OpBank': opBankpauseSubscriptionPause,
  'provideFeedbackPage/OpBank': opBankprovideFeedbackPage,
  'provider-portal-datepicker/DatePicker/OpBank': opBankproviderPortalDatepicker,
  'provider-portal-snackback/Snackbar/OpBank': opBankproviderPortalSnackback,
  'RegistrationPage/OpBank': opBankregistrationPage,
  'retention-offer/SuccessfulRetentionOfferCard/OpBank': opBankretentionOfferCard,
  'save-desk/OpBank': opBanksaveDesk,
  'save-desk/OngoingSaveDeskCard/OpBank': opBanksaveDeskOngoingCard,
  'save-desk/OrderSentPage/OpBank': opBanksaveDeskOrderSent,
  'savings/DoYouWantToSaveDialog/OpBank': opBanksavingsDoYouWantToSave,
  'savings/SavingsAfterCancellation/OpBank': opBanksavingsAfterCancellation,
  'SelectProductPage/OpBank': opBankselectProductPage,
  'settings/NotificationSettingsPage/OpBank': opBanksettingsNotificationSettings,
  'settings/NotificationSettingsPage/PushNotificationInstructions/OpBank': opBanksettingsNotificationPushSettings,
  'settings/PersonalDetailsPage/OpBank': opBanksettingsPersonalDetails,
  'settings/ProfilingSettingsPage/OpBank': opBanksettingsProfile,
  'settings/SettingsPage/OpBank': opBanksettingsPage,
  'settings/UserSettingsPage/OpBank': opBanksettingsUser,
  'settings/ViewGdprDataPage/OpBank': opBanksettingsViewGdprData,
  'settings-swedbank/SwedbankNotificationSettingsPage/OpBank': opBanksettingsSwedbank,
  'shared-components/EmojiRating/OpBank': opBanksharedComponentsEmoji,
  'shared-components/UpdateBankApp/OpBank': opBanksharedComponentsUpdateBankApp,
  'shared-components/YouSavedComponent/OpBank': opBanksharedComponentsYouSavedComponent,
  'ShowOnboardingFeaturesComponent/OpBank': opBankshowOnboardingFeatures,
  'SignoutSuccessPage/OpBank': opBanksignOutSuccessPage,
  'standard-components/CurrencyTextField/OpBank': opBankstandardComponentsCurrency,
  'standard-components/EmailField/OpBank': opBankstandardComponentsEmail,
  'standard-components/DatePicker/OpBank': opBankstandardComponentsDate,
  'standard-components/GuidePage/OpBank': opBankstandardComponentsGuide,
  'standard-components/MobilePhoneNumberField/OpBank': opBankstandardComponentsMobile,
  'standard-components/PasswordField/OpBank': opBankstandardComponentsPassword,
  'standard-components/PersonalNumberField/OpBank': opBankstandardComponentsPersonal,
  'standard-components/PostalCodeField/OpBank': opBankstandardComponentsPostalCode,
  'standard-components/Snackbar/OpBank': opBankstandardComponentsSnackbar,
  'subscription/CancellationMeasurement/OpBank': opBanksubscriptionCancellationMeasure,
  'subscription/components/SelectableServiceList/OpBank': opBanksubscriptionComponentsSelectable,
  'subscription/RemoveSubscriptionDialog/OpBank': opBanksubscriptionRemoveSubscription,
  'subscription/SelectLoanServicesPage/OpBank': opBanksubscriptionSelectLoanService,
  'subscription/SelectLoanTransactionTypePage/OpBank': opBanksubscriptionSelectLoanTransaction,
  'subscription/SelectServicesPage/OpBank': opBanksubscriptionSelectServices,
  'subscription/SubscriptionPage/OpBank': opBanksubscriptionPage,
  'subscription/SubscriptionSettingsPage/OpBank': opBanksubscriptionSettingsPage,
  'subscription/SubscriptionUpdateCostPage/OpBank': opBanksubscriptionUpdateCostPage,
  'subscription/SwitchMeasurement/OpBank': opBanksubscriptionSwitchMeasurement,
  'subscription/UnresolvedGuide/OpBank': opBanksubscriptionUnresolvedGuide,
  'subscription/WhatsWrongDialog/OpBank': opBanksubscriptionWhatsWrong,
  'subscription-alarm/MorePage/OpBank': opBanksubscriptionAlarmMore,
  'subscription-alarm/SubscriptionAlarmPage/OpBank': opBanksubscriptionAlarmPage,
  'subscription-hub/PastActions/OpBank': opBanksubscriptionHubActions,
  'subscriptionHub/subscriptionHub/OpBank': opBanksubscriptionHub,
  'supplier-portal-change/OpBank': opBanksupplierPortalChange,
  'supplier-portal-save-desk/OpBank': opBanksupplierPortalSaveDesk,
  'SwedbankOverviewSyncFetch/OpBank': opBankswedbankOverviewSync,
  'SwedenPhoneCallsLimitationInput/OpBank': opBankswedenPhoneCallsLimited,
  'sync-sources/AddSyncSourcePage/OpBank': opBanksyncSourcesAddSync,
  'sync-sources/AddSyncSourcesSpeedDial/OpBank': opBanksyncSourcesAddSyncSpeed,
  'sync-sources/DeleteSyncSourcesPage/OpBank': opBanksyncSourcesDeleteSync,
  'sync-sources/Error/OpBank': opBanksyncSourcesError,
  'sync-sources/NoSyncSources/OpBank': opBanksyncSourcesNoSync,
  'sync-sources/PopularSyncSourcesList/OpBank': opBanksyncSourcesPopularSync,
  'sync-sources/SyncSourcesFetching/OpBank': opBanksyncSourcesFetching,
  'sync-sources/SyncSourcesList/OpBank': opBanksyncSourcesList,
  'sync-sources/TinkSyncPage/OpBank': opBanksyncSourcesTinkSync,
  'sync-sources/UpdateBankAuthsPage/OpBank': opBanksyncSourcesUpdateBank,
  'sync-sources/UpdateSyncSources/OpBank': opBanksyncSourcesUpdateSync,
  'sync-sources/UpdateSyncSourcesButton/OpBank': opBanksyncSourcesUpdateSyncButton,
  'sync-sources/SyncSourcesReminder/OpBank': opBanksyncSourcesReminder,
  'termination/TerminationFormPage/OpBank': opBankterminationFormPage,
  'termination/TerminationFormPage/CancelReasonSection/OpBank': opBankterminationFormPCancelReason,
  'termination/TerminationInstructionsPage/OpBank': opBankterminationInstructions,
  'TermsAndConditionsUpload/OpBank': opBanktermsAndCondition,
  'TypeformButton/OpBank': opBanktypeFormButton,
  'user-form-preview/OpBank': opBankuserFormPreview,
  'userFeedback/OpBank': opBankuserFeedback,
  'userSettings/UserDeletePage/OpBank': opBankuserDeleteSettings,
  'userSettings/UserDeleteVerifiedPage/OpBank': opBankuserDeleteVerifiedPage,
  'zendesk/OpBank': opBankzendesk,
};
