// When updating this model also update the copy in '/integration-tests/cypress/models/user.ts'

import type { Locale } from '../modules/app-context/constants';
import type { SubscriptionId } from '../modules/subscription/types';

export type UserId = string;

export interface UserInformation {
  emailAddress?: string;
  name?: PersonName;
  address?: Address;
  phoneNumber?: string;
  visitedPages?: VisitedPages;
  locale?: Locale;
}

export interface User {
  id: UserId;
  visitedPages: VisitedPages;
  role: UserRole;
  name?: PersonName;
  address?: Address;
  phoneNumber?: string;
  locale: Locale;
  personalNumber?: string;
  emailAddress?: string;
  notificationSettings: NotificationSettings;
  shouldAcceptUserTerms: boolean;
  shouldAcceptSavingTerms?: boolean;
  latestBankDiscoverySyncAt?: string;
  createdAt: string;
  isStandaloneAndSwedbankUser: boolean;
  featureOverrides?: UserFeatureOverrides;
}

export interface PersonName {
  first: string;
  last: string;
}

export type Title = 'Mr' | 'Ms';

export function printPersonName(personName: PersonName) {
  return `${personName.first} ${personName.last}`;
}

export type UserRole = 'RegularUser' | 'ManualTestUser';

export interface VisitedPages {
  viewedNewFeaturesPageAt: Record<string, unknown>;
  viewedSubscriptions: ViewedSubscription[];
  viewedPushNotificationOnboardingAt?: string;
  viewedOnboardDrawerAt?: string;
  viewedCancelProductOnboardingAt?: string;
  viewedBankSyncWorkInProgressAt?: string;
  viewedChangePlanServiceIntroductionAt?: string;
  viewedQuickCancelServiceIntroductionAt?: string;
}

export interface ViewedSubscription {
  subscriptionId: SubscriptionId;
  exploredAt: string;
}

export interface Address {
  careOf?: string;
  city: string;
  postalCode: string;
  street: string;
  streetNumber?: string;
  floor?: string;
  box?: string;
}

export type PhoneNumber = string;

export enum ProfilingSettingProfiles {
  Student = 'Student',
}

export interface ProfilingSetting {
  setting: ProfilingSettingProfiles;
  enabled: boolean;
}

export enum UpdatingStates {
  UPDATING = 'updating',
  UPDATED = 'updated',
  UPDATE_FAILED = 'updateFailed',
}

export interface NotificationSettings {
  monthlyReportEmail?: boolean;
  newFeaturesEmail?: boolean;
  personalFinanceAdviceEmail?: boolean;
  contractChangeEmail?: boolean;
  contractOptimizationEmail?: boolean;
  pushNotificationsEnabled?: boolean;
}

export enum UserTermsAgreementType {
  NotAcceptedTerms = 'NotAcceptedTerms',
  AcceptedTerms = 'AcceptedTerms',
}

export interface UserTermsAgreement {
  type: UserTermsAgreementType | string;
}

export interface UserFeatureOverrides {
  identify?: boolean;
}
