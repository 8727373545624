import type { CategoryName } from '@client/constants/categories';
import type { Supplier, SupplierWithServices } from '@client/models/supplier';
import { paymentIntervalsPer } from '../models/payment-intervals';
import type { PaymentInterval } from '../modules/subscription/types';

export interface PartialSupplierWithServices {
  name: string;
}

export interface ManualAddSupplierState {
  pageIsLoading: boolean;
  pageLoadingFailed: boolean;
  suppliers: SupplierWithServices[];
  cost?: number;
  paymentInterval: PaymentInterval;
  selectedSupplier?: SupplierWithServices | PartialSupplierWithServices;
  goToOptimize?: boolean;
  goToOptimizeCategory?: CategoryName;
}

const initialState: ManualAddSupplierState = {
  pageIsLoading: false,
  pageLoadingFailed: false,
  suppliers: [],
  cost: undefined,
  paymentInterval: paymentIntervalsPer[3].interval,
  selectedSupplier: undefined,
};

const NAMESPACE = 'ADD_SUBSCRIPTION';
const SET_GO_TO_OPTIMIZE_OPTIONS = `${NAMESPACE}/SET_GO_TO_OPTIMIZE`;
const SUPPLIER_SELECTED = `${NAMESPACE}/SUPPLIER_SELECTED`;
const SET_COST = `${NAMESPACE}/SET_COST`;
const SET_PAYMENT_INTERVAL = `${NAMESPACE}/SET_PAYMENT_INTERVAL`;
const PAGE_LOADING = `${NAMESPACE}/PAGE_LOADING`;
const PAGE_LOADING_FAILURE = `${NAMESPACE}/PAGE_LOADING_FAILURE`;
const PAGE_LOADING_SUCCESS = `${NAMESPACE}/PAGE_LOADING_SUCCESS`;

export function reducer(state: ManualAddSupplierState = initialState, action: any) {
  switch (action.type) {
    case SET_GO_TO_OPTIMIZE_OPTIONS: {
      return {
        ...state,
        goToOptimize: action.goToOptimize,
        goToOptimizeCategory: action.category,
      };
    }
    case SUPPLIER_SELECTED: {
      return {
        ...state,
        selectedSupplier: action.selectedSupplier,
      };
    }
    case SET_COST: {
      return {
        ...state,
        cost: action.cost,
      };
    }
    case SET_PAYMENT_INTERVAL: {
      return {
        ...state,
        paymentInterval: action.paymentInterval,
      };
    }
    case PAGE_LOADING:
      return {
        ...state,
        pageIsLoading: true,
      };
    case PAGE_LOADING_FAILURE:
      return {
        ...state,
        pageIsLoading: false,
        pageLoadingFailed: true,
      };
    case PAGE_LOADING_SUCCESS: {
      return {
        ...state,
        pageIsLoading: false,
        pageLoadingFailed: false,
        suppliers: action.suppliers,
      };
    }
    default: {
      return state;
    }
  }
}

export const setGoToOptimizeOptions = (goToOptimize: boolean, category: CategoryName) => ({
  type: SET_GO_TO_OPTIMIZE_OPTIONS,
  goToOptimize,
  category,
});

export const setSelectedSupplier = (selectedSupplier: Supplier | PartialSupplierWithServices) => ({
  type: SUPPLIER_SELECTED,
  selectedSupplier,
});

export const setCost = (cost: number) => ({
  type: SET_COST,
  cost,
});

export const setPaymentInterval = (paymentInterval: PaymentInterval) => ({
  type: SET_PAYMENT_INTERVAL,
  paymentInterval,
});

export const pageLoading = () => ({ type: PAGE_LOADING });
export const pageLoadingFailure = () => ({ type: PAGE_LOADING_FAILURE });
export const pageLoadingSuccess = (suppliers: SupplierWithServices[]) => ({
  type: PAGE_LOADING_SUCCESS,
  suppliers,
});

export const selectorSuppliers = (state: any): SupplierWithServices[] => state.manualAddSupplier.suppliers;
export const selectorIsLoading = (state: any): boolean => state.manualAddSupplier.pageIsLoading;
export const selectorLoadingFailed = (state: any): boolean => state.manualAddSupplier.pageLoadingFailed;
export const selectorSelectedSupplier = (state: any): SupplierWithServices => state.manualAddSupplier.selectedSupplier;
export const selectorCost = (state: any): number => state.manualAddSupplier.cost;
export const selectorPaymentInterval = (state: any): PaymentInterval => state.manualAddSupplier.paymentInterval;
export const selectorGoToOptimizeCategory = (state: any): CategoryName => state.manualAddSupplier.goToOptimizeCategory;
