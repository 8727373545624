import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { CancellationMethodType } from '@client/models/service';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { isCancellationEnabled } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { ContractAction } from '@client/modules/overview/ContractAction';
import { selectorSubscriptions } from '@client/modules/overview/duck';
import { PerInterval } from '@client/modules/shared-components/interval-to-string';
import type { BindingTimeContract, Subscription } from '@client/modules/subscription/types';
import { ContractState, FrontendSubscriptionType, isWithinBindingPeriod } from '@client/modules/subscription/types';
import { CategoryIcon } from '@client/shared-components/CategoryIcon/CategoryIcon';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';
import { sendViewedPageEvent } from '@client/tracking/mixpanel-tracking-events';
import { useTheme } from '@material-ui/core/styles';
import { Badge } from '@minna-technologies/minna-ui/components/Badge';
import { SubscriptionCard } from '@minna-technologies/minna-ui/components/SubscriptionCard';
import { SubscriptionInfo } from '@minna-technologies/minna-ui/components/SubscriptionCard/SubscriptionInfo';
import { SubscriptionRow } from '@minna-technologies/minna-ui/components/SubscriptionCard/SubscriptionRow';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import type { Contract } from '../../../../../subscription/types';
import { ContractType } from './ContractInformation/ContractInformation';
import { DidYouCancelMessage } from './DidYouCancelMessage';
import { getPercentBindingTimeOver, isBindingPeriodEndingSoon } from './utils';

interface Props {
  subscription: Subscription;
  subscriptionType: FrontendSubscriptionType;
  contractType?: ContractType;

  onContractClick?(contract: Contract): void;

  onSubscriptionClick?(subscription: Subscription, allSubscriptions: Subscription[], features: AppFeatureToggles): void;

  navigateToContractDetailsPage?(subscription: Subscription, contract: Contract): void;
}

const SubscriptionListItemWithActionsInner: FC<Props> = (
  { subscription, subscriptionType, onSubscriptionClick, navigateToContractDetailsPage, onContractClick, contractType },
  { localizeCostWithCurrency }: any
) => {
  const { colors } = useTheme();
  const features = useSelector(selectorFeatures);
  const allSubscriptions = useSelector(selectorSubscriptions);

  const unresolvedSubscription = subscription.userMessage.message.type === 'MoreInformationNeededMessage';
  const nonImprovableContracts: Contract[] = [];
  const bindingTimeContracts: BindingTimeContract[] = [];
  const cancelAvailable = isCancellationEnabled(features.actionFeatures);
  const unactionableContractTypes = [ContractType.Terminated];

  const isCancellationMethodBlock = (contract: Contract) =>
    contract.cancellationMethod.type === CancellationMethodType.BlockPayment;

  const isTerminatedSubscription = subscriptionType === FrontendSubscriptionType.TerminatedSubscription;
  const isContractManageable = (contract: Contract) =>
    !unactionableContractTypes.includes(getContractType(contract)) && !isCancellationMethodBlock(contract);

  const onClickHandler = onSubscriptionClick
    ? () => onSubscriptionClick?.(subscription, allSubscriptions, features)
    : noop;

  const onContractClickHandler = (contract: Contract) => {
    if (isTerminatedSubscription) {
      if (onClickHandler) {
        onClickHandler();
      } else {
        noop();
      }
    } else {
      onContractListItemClick(contract);
    }
  };

  function overviewMessage() {
    if (unresolvedSubscription) {
      return (
        <div data-test="subscription-info">
          <SubscriptionInfo label={<LocalizedMessage id="maybeWeCanHelpYouLabel" />} onClick={onClickHandler} />
        </div>
      );
    } else if (subscription.userMessage.message.type === 'DidYouCancelMessage' && !isTerminatedSubscription) {
      return <DidYouCancelMessage onClick={onClickHandler} />;
    }

    return null;
  }

  subscription.contracts.forEach((contract: Contract) => {
    if (isWithinBindingPeriod(contract) && !isBindingPeriodEndingSoon(contract)) {
      bindingTimeContracts.push(contract);
    } else nonImprovableContracts.push(contract);
  });

  const allContracts = [...nonImprovableContracts, ...bindingTimeContracts];
  const [openContract, setOpenContract] = useState<Contract | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const contractHasOrderOngoing = (contract: Contract) => {
    return contract.state !== ContractState.Active;
  };

  const sendClickedButtonMixpanelEvent = (contract: Contract) => {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_OVERVIEW,
      Type: 'Main',
      'Supplier name': subscription.supplier.name,
      'Supplier ID': subscription.supplier.id,
      Category: contract.service.category.name,
      Location: 'Bottom',
      Button: 'Continue',
    });
  };

  const sendViewedDrawerMixpanelEvent = (contract: Contract) => {
    sendViewedPageEvent(FEATURE_OVERVIEW, 'Action Drawer', {
      'Supplier name': subscription.supplier.name,
      'Supplier ID': subscription.supplier.id,
      Category: contract.service.category.name,
      'Binding time set': isWithinBindingPeriod(contract),
      'Monthly Cost': subscription.cost.amount,
    });
  };

  const onContractDrawerClick = (contract: Contract) => {
    setIsDrawerOpen(true);
    setOpenContract(contract);
  };

  const showDrawerOrDetails = (contract: Contract) => {
    sendClickedButtonMixpanelEvent(contract);
    if (contractHasOrderOngoing(contract) && navigateToContractDetailsPage) {
      navigateToContractDetailsPage(subscription, contract);
    } else {
      sendViewedDrawerMixpanelEvent(contract);
      onContractDrawerClick(contract);
    }
  };

  const onContractListItemClick = (contract: Contract) => {
    if (navigateToContractDetailsPage && !cancelAvailable) {
      navigateToContractDetailsPage(subscription, contract);
    }
    if (onContractClick) {
      onContractClick(contract);
    } else {
      showDrawerOrDetails(contract);
    }
  };

  const iconStrokeColor = (contract: Contract) =>
    isContractManageable(contract) ? colors.action.positive : colors.textOn.surfaceSubdued;

  function getContractType(contract: Contract) {
    if (contractType) {
      return contractType;
    } else if (!isBindingPeriodEndingSoon(contract)) {
      return ContractType.BindingTime;
    } else if (contract.state === ContractState.Terminated) {
      return ContractType.Terminated;
    } else return ContractType.Other;
  }

  const getContractBadge = (contract: Contract) => {
    return isContractManageable(contract) ? (
      <Badge
        label={<LocalizedMessage id={'manageable'} />}
        color={colors.base.surface}
        backgroundColor={colors.action.secondary}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      <SubscriptionCard
        onClick={onClickHandler}
        logo={{
          url: subscription.supplier.logoUrl,
          alt: subscription.supplier.name,
        }}
        heading={unresolvedSubscription ? '' : localizeCostWithCurrency(subscription.cost, 2)}
        body={unresolvedSubscription ? '' : <PerInterval interval={subscription.paymentInterval} />}
        info={overviewMessage()}
        data-test="subscription-list-item"
      >
        {allContracts.map((contract) => (
          <SubscriptionRow
            key={contract.id}
            data-test={'subscription-service-row'}
            icon={
              <CategoryIcon
                categoryName={contract.service.category.name}
                isHighlighted={false}
                nativeColor={iconStrokeColor(contract)}
              />
            }
            label={contract.service.category.text}
            onClick={() => onContractClickHandler(contract)}
            badge={getContractBadge(contract)}
            progress={
              contract.contractBindingPeriod
                ? getPercentBindingTimeOver((contract as BindingTimeContract).contractBindingPeriod)
                : undefined
            }
            chevron={'down'}
            disabled={!isContractManageable(contract)}
          />
        ))}
      </SubscriptionCard>
      <ContractAction
        subscription={subscription}
        open={isDrawerOpen}
        // @ts-ignore TODO: Needs proper Nil handling! This issues was not detected by type checker before ContractAction was refactored
        contract={openContract}
        supplier={subscription.supplier}
        subscriptionId={subscription.id}
        close={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

SubscriptionListItemWithActionsInner.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
  localizeDate: PropTypes.func.isRequired,
};

export const SubscriptionListItemWithActions = withLocalization('overview/OverviewPage')(
  SubscriptionListItemWithActionsInner
);
