import type { CategoryName } from '@client/constants/categories';
import { withFeatures } from '@client/feature-toggling';
import { LocalizedMessage } from '@client/internationalization';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { isCancellationEnabled } from '@client/modules/app-context';
import { CategoryIcon } from '@client/shared-components/CategoryIcon/CategoryIcon';
import type { Theme } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Badge } from '@minna-technologies/minna-ui/components/Badge';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { ChevronDownIcon } from '@minna-technologies/minna-ui/icons/ChevronDown';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import React from 'react';
import styles from './styles.scss';

interface Props {
  contractType: ContractType;
  features: AppFeatureToggles;
  serviceName: CategoryName;
  serviceText: string;
}

const chipTypes = ({ colors }: Theme) => ({
  bindingTime: {
    backgroundColor: colors.action.info,
    labelColor: colors.base.surface,
    label: 'bindingPeriod',
  },
  cancelable: {
    backgroundColor: colors.action.secondary,
    labelColor: colors.base.surface,
    label: 'cancel',
  },
});

export enum ContractType {
  Unreviewed = 'unreviewed',
  BindingTime = 'bindingTime',
  Cancelable = 'cancelable',
  Terminated = 'Terminated',
  Other = 'other',
}

const actionableContractTypes = [ContractType.Unreviewed, ContractType.Other];

const unactionableContractTypes = [ContractType.Terminated];

export const ContractInformation = withFeatures(
  // eslint-disable-next-line
  ({ contractType = ContractType.Other, features, serviceName, serviceText }: Props) => {
    const theme = useTheme();
    const cancelAvailable = isCancellationEnabled(features.actionFeatures);
    function getChipType() {
      if (!cancelAvailable) {
        return null;
      }
      switch (contractType) {
        case ContractType.BindingTime:
          return chipTypes(theme).bindingTime;
        case ContractType.Cancelable:
          return cancelAvailable ? chipTypes(theme).cancelable : null;
        default:
          return null;
      }
    }
    function getChipOrLogo() {
      if (chipType) {
        return (
          <Badge
            label={<LocalizedMessage id={chipType.label} />}
            color={chipType.labelColor}
            backgroundColor={chipType.backgroundColor}
          />
        );
      } else {
        return null;
      }
    }
    function getChevron() {
      if (!cancelAvailable) {
        return <ChevronRightIcon style={{ marginLeft: '8px' }} />;
      }
      if (isContractActionable) {
        return <ChevronDownIcon style={{ marginLeft: '8px' }} />;
      }

      return null;
    }
    const { colors } = useTheme();
    const chipType = getChipType();
    const isContractActionable = cancelAvailable && actionableContractTypes.includes(contractType);
    const isUnactionableContract = unactionableContractTypes.includes(contractType);
    const iconStrokeColor = () => (isUnactionableContract ? theme.colors.textOn.surfaceSubdued : undefined);

    return (
      <div
        className={styles.firstRow}
        style={{
          cursor: contractType === ContractType.Terminated ? 'not-allowed' : 'pointer',
        }}
      >
        <div className={styles.logoAndLabel}>
          <CategoryIcon categoryName={serviceName} isHighlighted={false} nativeColor={iconStrokeColor()} />
          <span className={contractType !== ContractType.Other ? styles.truncatedLabel : styles.label}>
            <Body
              style={{
                color: isUnactionableContract ? colors.textOn.surfaceSubdued : colors.textOn.surface,
                textTransform: 'capitalize',
              }}
            >
              {serviceText}
            </Body>
          </span>
        </div>
        <div className={styles.chipContainer}>
          {getChipOrLogo()}
          {getChevron()}
        </div>
      </div>
    );
  }
);
