import type { Contract, Subscription } from '../modules/subscription/types';
import * as mixpanel from './mixpanel-events';
import * as swedbankAnalytics from './swedbank-analytics-events';

export function viewDashboardTrackingEvent(noOfActiveSubscriptions: number) {
  mixpanel.viewDashboardMixpanelEvent(noOfActiveSubscriptions);
  swedbankAnalytics.viewDashboardAnalyticsEvent();
}

export function sentCancellationOfContractFinishTrackingEvent(
  subscription: Subscription,
  amountSavedPerYear: number,
  contract?: Contract
) {
  mixpanel.sentCancellationOfContractFinishMixpanelEvent(subscription, amountSavedPerYear, contract);
  swedbankAnalytics.userRequestedCancellationAnalyticsEvent(subscription, amountSavedPerYear, contract);
}
