import { GenericErrorPageContainer } from '@client/components/error/GenericErrorPage';
import { Psd2ErrorPageContainer } from '@client/components/error/Psd2ErrorPage';
// @ts-ignore js import, remove this when the import is typed
import { errorPageOnFailure, loadingPageWhileLoading, ScrollToTop } from '@client/containers/container-helpers';
import { OnboardingPSD2Route } from '@client/containers/OnboardingPsd2Route';
import {
  selectorIsLoading,
  selectorLoadingFailed,
  selectorLoggedIn,
  selectorUser,
  userNotLoggedIn,
} from '@client/ducks/user';
import { SubscriptionActionType } from '@client/models/subscription';
import type { AppContext } from '@client/modules/app-context';
import {
  isCancellationEnabled,
  isChangePaymentEnabled,
  NavigationType,
  OnboardingType,
  SyncFlowType,
} from '@client/modules/app-context';
import { AppType, Market } from '@client/modules/app-context/constants';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { OnboardingCancelProduct } from '@client/modules/auth/OnboardingCancelProduct';
import { SignoutSuccessPageContainer } from '@client/modules/auth/SignoutSuccessPage';
import { CancellationDetailsPage } from '@client/modules/cancellation/CancellationDetailsPage';
import { CancellationModalsContainer } from '@client/modules/cancellation/CancellationModalsContainer';
import { CancelServiceRoute } from '@client/modules/cancellation/cancelServiceRoute';
import { LazyLoadedChangePaymentRoute } from '@client/modules/changePayment/LazyLoadedChangePaymentRoute';
import {
  conditionsAndPoliciesPath,
  LazyLoadedConditionsAndPoliciesRoute,
} from '@client/modules/conditions-and-policies';
import { ESigning } from '@client/modules/e-signing';
import { LoggedOutPage } from '@client/modules/error-handling/LoggedOutPage';
import PageNotFoundContainer from '@client/modules/fetching/ErrorPage';
import { LoadingWithCogs } from '@client/modules/loading-with-cogs/component';
import { ManualSubscriptionPricingContainer } from '@client/modules/ManualContractPricingPage';
import { MenuPageContainer } from '@client/modules/MenuPage';
import { FeedbackPage } from '@client/modules/nps-csat-module/component';
import { overviewPageUrl, overviewPath, OverviewRoute } from '@client/modules/overview';
import { ManualAddSubscriptionContainer } from '@client/modules/overview/ManualAddContractPage';
import { QuickActionRoute } from '@client/modules/QuickActions/QuickActionRoute';
import { LazyLoadedSettingsRoute, settingsPath } from '@client/modules/settings';
// @ts-ignore js import, remove this when the import is typed
import SwedbankNotificationSettingsContainer from '@client/modules/settings/SwedbankNotificationSettingsPage';
import { IsShutdown } from '@client/modules/standalone/isshutdown';
import { MorePage } from '@client/modules/subscription-alarm/MorePage';
import { SubscriptionHubContainer } from '@client/modules/subscription-hub';
import { SubscriptionSearchPageContainer } from '@client/modules/subscription-search/SubscriptionSearchPage';
import { SubscriptionRoute } from '@client/modules/subscription/routes/route';
import { UnresolvedSubscriptionRoute } from '@client/modules/subscription/unresolved-guide/route';
import { UserDeletePageContainer } from '@client/modules/userDeleted/UserDeletePage';
import { UserDeleteVerifiedPage } from '@client/modules/userDeleted/UserDeleteVerifiedPage';
import { swedishToEnglishRedirections } from '@client/route-redirections/swedish-to-english-redirections';
import * as urls from '@client/routes';
import { swedbankRoutes } from '@client/routes';
import { Header } from '@client/shared-components/Header';
import Visible from '@client/shared-components/layout/Visible';
import { SelectServicesPage } from '@client/shared-components/SelectServicesPage';
import { markUserActive } from '@client/utils/hooks/use-session-keep-alive';
import type { History } from 'history';
import type { FC, ReactElement } from 'react';
import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { allLazyModules } from '../experimental-lazy-modules/allLazyModules';
import { MerchantGuideErrorPage } from '@client/modules/intelligent-guides/components/MerchantGuideErrorPage';

interface EndUserRouterInner {
  appContext: AppContext;
  history: History;
  userIsLoggedIn: boolean;
}

const EndUserRouterInner: FC<EndUserRouterInner> = ({ appContext, history, userIsLoggedIn }) => {
  const features = appContext.features;
  // TODO: These ones need to be replaced by proper feature toggles
  const isSwedenStandalone = appContext.market === Market.Sweden && appContext.appType == AppType.Standalone;
  const isSwedbank = appContext.appType === AppType.Swedbank;
  const isOP = appContext.appType === AppType.OP;
  const dispatch = useDispatch();

  const isSyncFlowOnboardingEnabled =
    features.syncFlow?.syncFlowType === SyncFlowType.PSD2 && features.syncFlow?.onboarding;
  const isSyncFlowEnabled = features.syncFlow?.syncFlowType === SyncFlowType.PSD2;
  const isIdentifyEnabled = features.identify?.identify;

  const lazyModuleRoutes = useMemo(
    () =>
      allLazyModules
        .map((lazyModule) => {
          return lazyModule.evaluate(appContext).routes;
        })
        .concat(),
    [appContext]
  );

  useEffect(
    () =>
      history.listen(() => {
        markUserActive(() => dispatch(userNotLoggedIn()));
      }),
    [history, dispatch]
  );

  const redirectToOverviewOrLogin = (props: RouteComponentProps<any>) => {
    return (
      <ConditionalIsUserLoggedIn
        loggedIn={<Redirect to={urls.overviewPage} {...props} />}
        loggedOut={<Redirect to={urls.loginPage} {...props} />}
      />
    );
  };

  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route path={'/shutdown-standalone'} render={() => <IsShutdown />} />
          {isSwedenStandalone && <Route path={urls.landingPage} render={() => <IsShutdown />} />}
          {lazyModuleRoutes}
          <Route path={urls.syncLoadingPage} render={() => <LoadingWithCogs />} />
          <Route exact path={overviewPath}>
            {isIdentifyEnabled ? (
              <OverviewRoute />
            ) : isOP ? (
              <Redirect to={urls.subscriptionHub} />
            ) : (
              // TODO: not sure if this route is used, maybe it's better to send everyone to Subscription Hub
              <Redirect to={urls.subscriptionSearchWithAction(SubscriptionActionType.Cancel)} />
            )}
          </Route>
          {userIsLoggedIn && isSyncFlowOnboardingEnabled && (
            <Route path={urls.onboardingPsd2} render={() => <OnboardingPSD2Route />} />
          )}
          {userIsLoggedIn && isSyncFlowEnabled && (
            <Route
              path={urls.onboardingPsd2Callback}
              render={({ location }) => <LoadingWithCogs location={location} isFirstTimePsd2User />}
            />
          )}
          {userIsLoggedIn && isSyncFlowEnabled && (
            <Route
              path={urls.onboardingPsd2CallbackWithoutCode}
              render={() => <LoadingWithCogs isFirstTimePsd2UserWithoutCode />}
            />
          )}
          <Route
            path={`${urls.subscriptionRoutePrefix}/:subscriptionId`}
            render={({
              match: {
                params: { subscriptionId },
              },
              location,
            }) => (
              <div>
                {userIsLoggedIn && features.onboarding === OnboardingType.CANCELPRODUCT && <OnboardingCancelProduct />}
                <Visible on="min-sm">
                  <Header />
                </Visible>
                <SubscriptionRoute subscriptionId={subscriptionId} location={location} />
              </div>
            )}
          />
          <Route
            path={urls.quickActions}
            render={() => (
              <>
                <Visible on="min-sm">
                  <Header />
                </Visible>
                <QuickActionRoute />
              </>
            )}
          />
          <Route path={urls.subscriptionSearch}>
            <SubscriptionSearchPageContainer />
          </Route>
          <Route path={urls.subscriptionHub}>
            <SubscriptionHubContainer />
          </Route>
          <Route path={urls.cancellationDetailsPage(':cancellationId')}>
            <CancellationDetailsPage />
          </Route>
          <Route path={urls.selectService}>
            <SelectServicesPage />
          </Route>
          <Route
            path={urls.unresolvedSubscriptionGuideBasePath}
            render={() => (
              <>
                <Visible on="min-sm">
                  <Header />
                </Visible>
                <UnresolvedSubscriptionRoute />
              </>
            )}
          />
          {features.identify?.overview?.manuallyAddedSubscriptions && (
            <Route
              path={urls.manualAddSubscriptionPage}
              render={() => (
                <div>
                  <Visible on="min-sm">
                    <Header />
                  </Visible>
                  <ManualAddSubscriptionContainer />
                </div>
              )}
              exact
            />
          )}
          {features.identify?.overview?.manuallyAddedSubscriptions && (
            <Route
              path={urls.manualSubscriptionPricingPage}
              render={() => (
                <div>
                  <Visible on="min-sm">
                    <Header />
                  </Visible>
                  <ManualSubscriptionPricingContainer />
                </div>
              )}
              exact
            />
          )}
          <Route exact path={urls.csatNps} render={() => <FeedbackPage />} />
          <Route path={urls.morePage} component={MorePage} />
          <Route path={urls.howToCancelListPage} exact render={redirectToOverviewOrLogin} />
          <Route
            path={urls.howToCancelServiceProviderPage(':serviceProviderNameSlug')}
            render={redirectToOverviewOrLogin}
          />
          {isCancellationEnabled(features.actionFeatures) && (
            <Route path={urls.cancelServiceRoute}>
              <CancelServiceRoute />
            </Route>
          )}
          {isChangePaymentEnabled(features.actionFeatures) && (
            <Route path={urls.changePaymentIntelligentGuide}>
              <LazyLoadedChangePaymentRoute />
            </Route>
          )}
          {features.conditionsAndPolicies && (
            <Route path={conditionsAndPoliciesPath}>
              <LazyLoadedConditionsAndPoliciesRoute />
            </Route>
          )}
          {features.navigation && (
            <Route
              path={urls.menuPage}
              render={({ location }) => (
                <>
                  {features.navigation?.navigationType === NavigationType.FULL && (
                    <Visible on="min-sm">
                      <Header />
                    </Visible>
                  )}
                  <MenuPageContainer currentLocation={location.pathname} />
                </>
              )}
            />
          )}
          {features.settings && (
            <Route
              path={settingsPath}
              render={() => (
                <div>
                  <Visible on="min-sm">
                    <Header />
                  </Visible>
                  <LazyLoadedSettingsRoute />
                </div>
              )}
            />
          )}
          {features.settings && features.settings.deleteAccount && (
            <Route
              path={urls.userDeletePage}
              render={() => (
                <div>
                  <Visible on="min-sm">
                    <Header />
                  </Visible>
                  <UserDeletePageContainer />
                </div>
              )}
            />
          )}
          {features.settings && features.settings.deleteAccount && (
            <Route path={urls.userDeleteSuccessPage} render={() => <UserDeleteVerifiedPage />} />
          )}
          {isCancellationEnabled(features.actionFeatures) && (
            <Route
              path={urls.signResourcePage(':contractId', ':signingId', ':resourceId', ':navigateToOnSuccess')}
              render={({
                match: {
                  params: { contractId, signingId, resourceId, navigateToOnSuccess },
                },
              }) => (
                <>
                  <Visible on="min-sm">
                    <Header />
                  </Visible>
                  <ESigning
                    contractId={contractId}
                    signingId={signingId}
                    resourceToSignId={resourceId}
                    navigateToOnSuccess={navigateToOnSuccess}
                  />
                </>
              )}
            />
          )}
          <Route path={urls.merchantGuideError} component={MerchantGuideErrorPage} />
          <Route path={urls.signoutSuccessPage} render={() => <SignoutSuccessPageContainer />} />
          <Route path={urls.loggedOutPage} component={LoggedOutPage} />
          {userIsLoggedIn && (
            <Redirect
              exact
              from={urls.landingPage}
              to={
                features.identify?.overview
                  ? overviewPageUrl
                  : features.subscriptionHub.search
                  ? urls.subscriptionSearchWithAction(SubscriptionActionType.Cancel)
                  : urls.errorPage
              }
            />
          )}
          {!userIsLoggedIn && <Route path={urls.errorPage} component={GenericErrorPageContainer} />}
          {userIsLoggedIn && <Route path={urls.psd2ErrorPage} component={Psd2ErrorPageContainer} />}
          {isSwedbank && (
            <Route path={swedbankRoutes.notificationPage} component={SwedbankNotificationSettingsContainer} />
          )}
          {swedishToEnglishRedirections()}
          {!userIsLoggedIn && <Route component={GenericErrorPageContainer} />}
          <Route component={PageNotFoundContainer} />
        </Switch>
      </ScrollToTop>
      <CancellationModalsContainer />
    </Router>
  );
};

function mapStateToProps(state: any) {
  return {
    appContext: selectorAppContext(state),
    user: selectorUser(state),
    userIsLoading: selectorIsLoading(state),
    userIsLoadingFailed: selectorLoadingFailed(state),
    userIsLoggedIn: selectorLoggedIn(state),
  };
}

interface EndUserRouterProps {
  history: History;
}

// @ts-ignore
export const EndUserRouter: FC<EndUserRouterProps> = compose(
  connect(mapStateToProps),
  loadingPageWhileLoading((p: any) => p.userIsLoading),
  errorPageOnFailure((p: any) => p.userIsLoadingFailed)
  // @ts-ignore
)(EndUserRouterInner);

export interface ConditionalIsUserLoggedInProps {
  loggedIn: ReactElement;
  loggedOut: ReactElement;
}

export const ConditionalIsUserLoggedIn: FC<ConditionalIsUserLoggedInProps> = ({ loggedIn, loggedOut }) => {
  const isLoggedIn = useSelector(selectorLoggedIn);

  if (isLoggedIn) {
    return loggedIn;
  } else {
    return loggedOut;
  }
};
