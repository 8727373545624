import aboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/fr-BE.json';
import bankAboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/BankAboutUsPage/fr-BE.json';
import insightPage from '@client/internationalization/localization-messages/overview/InsightsPage/fr-BE.json';
import insightPage_insightsCards_addon from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/AddonRecommendation/fr-BE.json';
import insightPage_bindingTimeEnding from '@client/internationalization/localization-messages/overview/InsightsPage/BindingTimeEnding/fr-BE.json';
import insightPage_insightsCards_discoverOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOngoing/fr-BE.json';
import insightPage_insightsCards_discoverOutcomeInsight from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOutcome/fr-BE.json';
import insightPage_insightsCards_newSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewSubscriptionFound/fr-BE.json';
import insightPage_insightsCards_personalRecommend from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/fr-BE.json';
import insightPage_insightsCards_saveDeskOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOngoingCard/fr-BE.json';
import insightPage_insightsCards_saveDeskOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOutcome/fr-BE.json';
import insightPage_subscriptionCostChanged from '@client/internationalization/localization-messages/overview/InsightsPage/SubscriptionCostChanged/fr-BE.json';
import insightPage_unresolvedSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnresolvedSubscription/fr-BE.json';
import overviewPage from '@client/internationalization/localization-messages/overview/OverviewPage/fr-BE.json';
import overviewAddManualSubscription from '@client/internationalization/localization-messages/overview/addManualSubscription/fr-BE.json';
import overviewBindingTimeBar from '@client/internationalization/localization-messages/overview/BindingTimeBar/fr-BE.json';
import overviewPage_didYouCancel from '@client/internationalization/localization-messages/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/fr-BE.json';
import badgeCtas from '@client/internationalization/localization-messages/BadgeCtas/fr-BE.json';
import bindingTimeNotice from '@client/internationalization/localization-messages/overview/BindingTimeNotice/fr-BE.json';
import onboardingDrawer from '@client/internationalization/localization-messages/overview/OnboardingDrawer/fr-BE.json';
import overviewPage_quickActionsV2 from '@client/internationalization/localization-messages/overview/QuickActionsV2/fr-BE.json';
import addonMobileRecommendationsPage from '@client/internationalization/localization-messages/addon/mobile/RecommendationsPage/fr-BE.json';
import addonMobileResultPage from '@client/internationalization/localization-messages/addon/mobile/ResultPage/fr-BE.json';
import authAuthStatusPage from '@client/internationalization/localization-messages/auth/AuthStatusPage/fr-BE.json';
import authBasicLoginPage from '@client/internationalization/localization-messages/auth/BasicLoginPage/fr-BE.json';
import authCreateAccountPage from '@client/internationalization/localization-messages/auth/CreateAccountPage/fr-BE.json';
import authOnboardingChooseBankPage from '@client/internationalization/localization-messages/auth/OnboardingChooseBankPage/fr-BE.json';
import authOnboardingEmailPage from '@client/internationalization/localization-messages/auth/OnboardingEmailPage/fr-BE.json';
import authPreLoginPage from '@client/internationalization/localization-messages/auth/PreLoginPage/fr-BE.json';
import authResetPasswordPage from '@client/internationalization/localization-messages/auth/ResetPasswordPage/fr-BE.json';
import authSyncSourcesError from '@client/internationalization/localization-messages/auth/SyncSourcesError/fr-BE.json';
import authSyncSourcesFetching from '@client/internationalization/localization-messages/auth/SyncSourcesFetching/fr-BE.json';
import badgeABTestCTAS from '@client/internationalization/localization-messages/BadgeABTestCtas/fr-BE.json';
import bankSyncCancelProduct from '@client/internationalization/localization-messages/bank-sync/CancelProductOnboardPages/fr-BE.json';
import bankSyncDrawerPages from '@client/internationalization/localization-messages/bank-sync/DrawerPages/fr-BE.json';
import bankSyncLoadingScreen from '@client/internationalization/localization-messages/bank-sync/LoadingScreen/fr-BE.json';
import bankSyncOnboardBankSync from '@client/internationalization/localization-messages/bank-sync/OnBoardBankSync/fr-BE.json';
import campaignIdSelect from '@client/internationalization/localization-messages/CampaignIdSelect/fr-BE.json';
import cancelCancellationOutcome from '@client/internationalization/localization-messages/cancel/CancellationOutcome/fr-BE.json';
import cancellationDetailsPage from '@client/internationalization/localization-messages/cancellation/cancellationDetailsPage/fr-BE.json';
import cancellationFormCancellationInstruction from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationInstructionsSection/fr-BE.json';
import cancellationFormCancellationTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationTerms/fr-BE.json';
import cancellationFormConfirmSection from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/ConfirmSection/fr-BE.json';
import cancellationFormLightUserTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/LightUserTerms/fr-BE.json';
import cancellationModalsContainer from '@client/internationalization/localization-messages/cancellation/CancellationModalsContainer/fr-BE.json';
import cancellationQuestionsFlow from '@client/internationalization/localization-messages/cancellation/CancellationQuestionFlow/fr-BE.json';
import cancellationReasonsDialog from '@client/internationalization/localization-messages/cancellation/CancellationReasonsDialog/fr-BE.json';
import cancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/fr-BE.json';
import cancellationStepper from '@client/internationalization/localization-messages/cancellation/CancellationStepper/fr-BE.json';
import cancellationDoYouWantToSaveDialog from '@client/internationalization/localization-messages/cancellation/DoYouWantToSaveDialog/fr-BE.json';
import cancellationIntelligentGuidePage from '@client/internationalization/localization-messages/cancellation/IntelligentGuidePage/fr-BE.json';
import cancellationNetflixCancellationInstructionsPage from '@client/internationalization/localization-messages/cancellation/NetflixCancellationInstructionsPage/fr-BE.json';
import cancellationSigningNemIdErrorPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdErrorPage/fr-BE.json';
import cancellationSigningNemIdSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdSigningPage/fr-BE.json';
import cancellationNoSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningPage/fr-BE.json';
import cancellationNoSigningServicePage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningServicePage/fr-BE.json';
import cancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/fr-BE.json';
import cancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/fr-BE.json';
import categories from '@client/internationalization/localization-messages/categories/fr-BE.json';
import categoryNames from '@client/internationalization/localization-messages/CategoryNames/CategoryNames/fr-BE.json';
import changeDataPlan from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplan/fr-BE.json';
import changeDataPlanCompletedPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanCompletedPage/fr-BE.json';
import changeDataPlanOrderFormPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanOrderFormPage/fr-BE.json';
import changeDataPlanPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanPage/fr-BE.json';
import changePaymentChangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-payment/ChangePlanServiceIntroductionDrawer/fr-BE.json';
import changePaymentIntelligentGuidePage from '@client/internationalization/localization-messages/change-payment/IntelligentGuidePage/fr-BE.json';
import changePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-plan/ChangePlanServiceIntroductionDrawer/fr-BE.json';
import changePlanIntelligentGuideExperimentPage from '@client/internationalization/localization-messages/change-plan/IntelligentGuideExperimentPage/fr-BE.json';
import changePlanIntelligentGuidePage from '@client/internationalization/localization-messages/change-plan/IntelligentGuidePage/fr-BE.json';
import changePlan from '@client/internationalization/localization-messages/ChangePlan/fr-BE.json';
import charityDonation from '@client/internationalization/localization-messages/charity-donation/fr-BE.json';
import complexContractGuideIntroPage from '@client/internationalization/localization-messages/complex-contract-guide/IntroPage/fr-BE.json';
import complexContractGuideLoanServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanServiceQuestionPage/fr-BE.json';
import complexContractGuideLoanTypeQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanTypeQuestionPage/fr-BE.json';
import complexContractGuideServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/ServiceQuestionPage/fr-BE.json';
import complexContractGuideShared from '@client/internationalization/localization-messages/complex-contract-guide/shared/fr-BE.json';
import complexContractGuideSuccessPage from '@client/internationalization/localization-messages/complex-contract-guide/SuccessPage/fr-BE.json';
import conditionsAndPoliciesMenu from '@client/internationalization/localization-messages/conditions-and-policies/ConditionsAndPoliciesMenu/fr-BE.json';
import conditionsAndPoliciesCookiesPage from '@client/internationalization/localization-messages/conditions-and-policies/CookiesPage/fr-BE.json';
import conditionsAndPoliciesSecurityPage from '@client/internationalization/localization-messages/conditions-and-policies/SecurityPage/fr-BE.json';
import conditionsAndPolicies from '@client/internationalization/localization-messages/conditions-and-policies/fr-BE.json';
import contractBindingPeriodDialog from '@client/internationalization/localization-messages/contract/bindingPeriodDialog/fr-BE.json';
import contractCancellationHistory from '@client/internationalization/localization-messages/contract/CancellationHistory/fr-BE.json';
import contractCancelledContract from '@client/internationalization/localization-messages/contract/CancelledContract/fr-BE.json';
import contractCancellationOutcome from '@client/internationalization/localization-messages/contract/ContractCancellationOutcome/fr-BE.json';
import contractCardMessage from '@client/internationalization/localization-messages/contract/ContractCardMessage/fr-BE.json';
import contractDetails from '@client/internationalization/localization-messages/contract/ContractDetails/fr-BE.json';
import contractDetailsPage from '@client/internationalization/localization-messages/contract/ContractDetailsPage/fr-BE.json';
import contractDoMoreComponent from '@client/internationalization/localization-messages/contract/DoMoreComponent/fr-BE.json';
import contractHistoryToolTip from '@client/internationalization/localization-messages/contract/HistoryTooltip/fr-BE.json';
import contractPriceHistory from '@client/internationalization/localization-messages/contract/PriceHistoryLolipop/fr-BE.json';
import contractSelectServicePage from '@client/internationalization/localization-messages/contract/SelectServicePageLolipop/fr-BE.json';
import contractSelectServicesList from '@client/internationalization/localization-messages/contract/SelectServicesListLolipop/fr-BE.json';
import contractSettingsButton from '@client/internationalization/localization-messages/contract/SettingsButtonLolipop/fr-BE.json';
import contractSettingsPage from '@client/internationalization/localization-messages/contract/SettingsPageLolipop/fr-BE.json';
import contractSubscriptionAlarm from '@client/internationalization/localization-messages/contract/SubscriptionAlarmLolipop/fr-BE.json';
import contractSubscriptionDetails from '@client/internationalization/localization-messages/contract/SubscriptionDetailsLolipop/fr-BE.json';
import contractSubscriptionReminder from '@client/internationalization/localization-messages/contract/SubscriptionReminder/fr-BE.json';
import contractSubscriptionReminderDialog from '@client/internationalization/localization-messages/contract/subscriptionReminderDialog/fr-BE.json';
import contractTransactionHistory from '@client/internationalization/localization-messages/contract/TransactionHistoryLolipop/fr-BE.json';
import contractUpdateCost from '@client/internationalization/localization-messages/contract/UpdateCostLolipop/fr-BE.json';
import discoverCategoryPage from '@client/internationalization/localization-messages/discover/CategoryPage/fr-BE.json';
import discoverCategoryProviderPage from '@client/internationalization/localization-messages/discover/CategoryProviderPage/fr-BE.json';
import discoverComponentsProductInfo from '@client/internationalization/localization-messages/discover/components/ProductInformationHeader/fr-BE.json';
import discoverDiscoverWidget from '@client/internationalization/localization-messages/discover/DiscoverWidget/fr-BE.json';
import discoverFakeCategoryPage from '@client/internationalization/localization-messages/discover/FakeCategoryPage/fr-BE.json';
import discoverFakeCategorySubscriptionPage from '@client/internationalization/localization-messages/discover/FakeCategorySubscriptionsPage/fr-BE.json';
import discoverFitnessCategoryFeedbackPage from '@client/internationalization/localization-messages/discover/FitnessCategoryFeedbackPage/fr-BE.json';
import discoverMarketplacePage from '@client/internationalization/localization-messages/discover/MarketplacePage/fr-BE.json';
import discoverMobileOrderSentPage from '@client/internationalization/localization-messages/discover/MobileOrderSentPage/fr-BE.json';
import discoverOrderProductPage from '@client/internationalization/localization-messages/discover/OrderProductPage/fr-BE.json';
import discoverProductRecommendations from '@client/internationalization/localization-messages/discover/ProductRecommendations/fr-BE.json';
import discoverSearchSubscriptionAllCategories from '@client/internationalization/localization-messages/discover/SearchSubscriptionAllCategoriesPage/fr-BE.json';
import discoverSearchSubscriptionPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionPage/fr-BE.json';
import discoverSearchSubscriptionResultPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionResultPage/fr-BE.json';
import dummy from '@client/internationalization/localization-messages/DUMMY/fr-BE.json';
import eSigning from '@client/internationalization/localization-messages/e-signing/nem-id/NemIdSigningPage/fr-BE.json';
import editProduct from '@client/internationalization/localization-messages/EditProduct/fr-BE.json';
import energyGuide from '@client/internationalization/localization-messages/EnergyGuide/OrderForm/fr-BE.json';
import errorHandling from '@client/internationalization/localization-messages/error-handling/LoggedOutPage/fr-BE.json';
import errorPageErrorPage from '@client/internationalization/localization-messages/errorPage/ErrorPage/fr-BE.json';
import errorScreen from '@client/internationalization/localization-messages/ErrorScreen/fr-BE.json';
import explore from '@client/internationalization/localization-messages/Explore/fr-BE.json';
import feedbackModal from '@client/internationalization/localization-messages/feedback-modal/fr-BE.json';
import fields from '@client/internationalization/localization-messages/fields/fr-BE.json';
import financialProductsLoansShow from '@client/internationalization/localization-messages/financial-products/loans/ShowNewFeatureLoanDialog/fr-BE.json';
import financialProductsSavingsInvestment from '@client/internationalization/localization-messages/financial-products/savings/InvestmentInfoCard/fr-BE.json';
import financialProductOurPartnersPage from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPage/fr-BE.json';
import financialProductOurPartnersPageNew from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPageNew/fr-BE.json';
import financialProductsSavingsOverview from '@client/internationalization/localization-messages/financial-products/savings/Overview/fr-BE.json';
import financialProductSavingsAfterCancellation from '@client/internationalization/localization-messages/financial-products/savings/SavingsAfterCancellation/fr-BE.json';
import financialProductsShowNewFeatureLoan from '@client/internationalization/localization-messages/financial-products/ShowNewFeatureLoanDialog/fr-BE.json';
import financialProductsSwedBankConsolidate from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/HowItWorksPage/fr-BE.json';
import financialSwedBankConsolidateIntro from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/IntroPage/fr-BE.json';
import financialProductLoanApplication from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/LoanApplicationStatus/fr-BE.json';
import financialProductsOfferPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OfferPage/fr-BE.json';
import financialProductOrderCompleted from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderCompletedPage/fr-BE.json';
import financialProductOrderPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderPage/fr-BE.json';
import financialProductSelectContract from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectContractsPage/fr-BE.json';
import financialProductsSelectPrincipal from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectPrincipalPage/fr-BE.json';
import financialProductRepaymentPeriod from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/fr-BE.json';
import generalIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/fr-BE.json';
import generalSelectServices from '@client/internationalization/localization-messages/general/SelectServicesPage/fr-BE.json';
import genericActionsPage from '@client/internationalization/localization-messages/genericActions/GenericActionsPage/fr-BE.json';
import genericActionsSelectServices from '@client/internationalization/localization-messages/genericActions/SelectServicesPage/fr-BE.json';
import genericActionsSubscriptionCancel from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/Cancel/fr-BE.json';
import genericActionsSubscriptionsChangePayment from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/ChangePayment/fr-BE.json';
import guidesContractDetailsAlternative from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/AlternativesSection/fr-BE.json';
import guidesDealBanner from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/DealBanner/fr-BE.json';
import guidesPaymentMethod from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/PaymentMethodsCard/fr-BE.json';
import hack7CancelPage from '@client/internationalization/localization-messages/Hack7CancelPage/fr-BE.json';
import header from '@client/internationalization/localization-messages/Header/fr-BE.json';
import howToCancelListPage from '@client/internationalization/localization-messages/HowToCancelListPage/fr-BE.json';
import howToCancelPage from '@client/internationalization/localization-messages/HowToCancelPage/fr-BE.json';
import improveOrderPage from '@client/internationalization/localization-messages/improve/flowgen/OrderPage/fr-BE.json';
import improveOrderSentPage from '@client/internationalization/localization-messages/improve/flowgen/OrderSentPage/fr-BE.json';
import improveOtherAlternativePage from '@client/internationalization/localization-messages/improve/flowgen/OtherAlternativesPage/fr-BE.json';
import improvePostalCodeInput from '@client/internationalization/localization-messages/improve/flowgen/postalCodeInput/fr-BE.json';
import improveResultPage from '@client/internationalization/localization-messages/improve/flowgen/ResultPage/fr-BE.json';
import improveFlowgen from '@client/internationalization/localization-messages/improve/flowgen/fr-BE.json';
import insightPageInsightsCardsCancelOutcome from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationOutcome/fr-BE.json';
import insightPageInsightsCardsCancelUpdate from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationUpdate/fr-BE.json';
import insightPageOnboardingInsightCard from '@client/internationalization/localization-messages/InsightsPage/OnboardingInsightCard/fr-BE.json';
import intervalToString from '@client/internationalization/localization-messages/interval-to-string/fr-BE.json';
import landingPage from '@client/internationalization/localization-messages/LandingPage/fr-BE.json';
import loadingScreen from '@client/internationalization/localization-messages/LoadingScreen/fr-BE.json';
import loanApplicationsDetailsPage from '@client/internationalization/localization-messages/loan-applications/DetailsPage/fr-BE.json';
import loanApplicationsLoadingError from '@client/internationalization/localization-messages/loan-applications/LoadingError/fr-BE.json';
import loanApplicationsMenuDrawer from '@client/internationalization/localization-messages/loan-applications/MenuDrawer/fr-BE.json';
import loanApplicationsOverview from '@client/internationalization/localization-messages/loan-applications/Overview/fr-BE.json';
import loginPage from '@client/internationalization/localization-messages/LoginPage/fr-BE.json';
import manageOrder from '@client/internationalization/localization-messages/ManageOrder/fr-BE.json';
import manualAddContractPage from '@client/internationalization/localization-messages/ManualAddContractPage/fr-BE.json';
import manualAddContractPricingPage from '@client/internationalization/localization-messages/ManualContractPricingPage/fr-BE.json';
import menu from '@client/internationalization/localization-messages/Menu/fr-BE.json';
import menuPage from '@client/internationalization/localization-messages/MenuPage/fr-BE.json';
import movingBundledCancellationForm from '@client/internationalization/localization-messages/moving/BundledCancellationFromPage/fr-BE.json';
import movingBundledCancellationPage from '@client/internationalization/localization-messages/moving/BundledCancellationPage/fr-BE.json';
import movingBundledSwitchOrderForm from '@client/internationalization/localization-messages/moving/BundledSwitchOrderFormPage/fr-BE.json';
import movingBundledSwitchPage from '@client/internationalization/localization-messages/moving/BundledSwitchSwitchPage/fr-BE.json';
import thanksSwitchPage from '@client/internationalization/localization-messages/moving/ThanksSwitchPage/fr-BE.json';
import movingGuide from '@client/internationalization/localization-messages/movingGuide/fr-BE.json';
import movingGuideMovingDetails from '@client/internationalization/localization-messages/movingGuide/MovingDetailsPage/fr-BE.json';
import movingGuideOneLastThingPage from '@client/internationalization/localization-messages/movingGuide/OneLastThingPage/fr-BE.json';
import negotiateContractCompletePage from '@client/internationalization/localization-messages/negotiate/NegotiateContractCompletePage/fr-BE.json';
import negotiateGuide from '@client/internationalization/localization-messages/negotiate/NegotiateGuide/fr-BE.json';
import negotiationOngoingCard from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationCard/fr-BE.json';
import negotiationOngoingStepper from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationStepper/fr-BE.json';
import notificationOptInPage from '@client/internationalization/localization-messages/NotificationOptInPage/fr-BE.json';
import onboardingPSD2Consent from '@client/internationalization/localization-messages/onboarding/PSD2ConsentPage/fr-BE.json';
import onboardingPSD2Primer from '@client/internationalization/localization-messages/onboarding/PSD2PrimerPage/fr-BE.json';
import onboardingBDSyncConnecting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryConnecting/fr-BE.json';
import onboardingBDSyncError from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryErrorPage/fr-BE.json';
import onboardingBDSyncFetch from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryFetchPage/fr-BE.json';
import onboardingBDSyncSuccess from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/fr-BE.json';
import onboardingBDSyncWaiting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/fr-BE.json';
import onboardingCancelProduct from '@client/internationalization/localization-messages/onboarding-cancel-product/OnboardingCancelProductPages/fr-BE.json';
import onboardingPsd2WelcomePage from '@client/internationalization/localization-messages/onboarding-psd2/WelcomePage/fr-BE.json';
import onboardingPsd2Cards from '@client/internationalization/localization-messages/onboarding-psd2/Cards/fr-BE.json';
import onboardingPsd2ConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/fr-BE.json';
import onboardingPsd2OpBankConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPage/fr-BE.json';
import onboardingPsd2OpBankConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPageV2/fr-BE.json';
import onboardingPsd2ConsentPageDanskeBank from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/DanskeBankConsentPage/fr-BE.json';
import onboardingPsd2RenewConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPage/fr-BE.json';
import onboardingPsd2RenewConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPageV2/fr-BE.json';
import orderedSubscriptionOngoing from '@client/internationalization/localization-messages/orderedSubscription/OngoingOrderCard/fr-BE.json';
import orderedSubscriptionOrderedEnergy from '@client/internationalization/localization-messages/orderedSubscription/OrderedEnergyProductInfo/fr-BE.json';
import orderedSubscriptionOrderedMobile from '@client/internationalization/localization-messages/orderedSubscription/OrderedMobileProductInfo/fr-BE.json';
import orderedSubscriptionDetails from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetails/fr-BE.json';
import orderedSubscriptionDetailsPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/fr-BE.json';
import orderedSubscriptionInfoCard from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/fr-BE.json';
import orderedSubscriptionPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionPage/fr-BE.json';
import orderedSubscriptionOrderStepper from '@client/internationalization/localization-messages/orderedSubscription/OrderStepper/fr-BE.json';
import orderedSubscServiceProviderInfo from '@client/internationalization/localization-messages/orderedSubscription/ServiceProviderInfoCard/fr-BE.json';
import orderingSubscriptionTerms from '@client/internationalization/localization-messages/ordering-subscription/common/TermsAndConditionsPage/fr-BE.json';
import orderingSubscriptionCompleted from '@client/internationalization/localization-messages/ordering-subscription/common/OrderCompletedPage/fr-BE.json';
import orderingBroadbandAddressPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAddressPage/fr-BE.json';
import orderingBroadbandAllAlternatives from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/fr-BE.json';
import orderingBroadbandContract from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideContractCostPage/fr-BE.json';
import orderingBroadbandIntroPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideIntroPage/fr-BE.json';
import orderingBroadbandNoResultPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideNoResultPage/fr-BE.json';
import orderingBroadbandSpeedPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideSpeedPage/fr-BE.json';
import orderingBroadbandOptimizeOrder from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandOrderFormPage/fr-BE.json';
import orderingBroadbandOptimizeProduct from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/fr-BE.json';
import orderingBroadbandOptimizeProductCosts from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/fr-BE.json';
import orderingElectricityOrder from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPage/fr-BE.json';
import orderingElectricityOrderVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/fr-BE.json';
import orderingElectricityGuidePrice from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/fr-BE.json';
import orderingElectricityGuideAlternatives from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/fr-BE.json';
import orderingElectricityGuideBinding from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/fr-BE.json';
import orderingElectricityGuideBindingTime from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/fr-BE.json';
import orderingElectricityGuideEnergy from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/fr-BE.json';
import orderingElectricityGuideExplanation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/fr-BE.json';
import orderingElectricityGuideFlow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/fr-BE.json';
import orderingElectricityGuideHow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/fr-BE.json';
import orderingElectricityGuideInformation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/fr-BE.json';
import orderingElectricityGuideIntro from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/fr-BE.json';
import orderingElectricityGuideLivingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/fr-BE.json';
import orderingElectricityGuidePostalCodePage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/fr-BE.json';
import orderingElectricityGuidePreferences from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/fr-BE.json';
import orderingElectricityGuidePricingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/fr-BE.json';
import orderingElectricityGuideProductPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/fr-BE.json';
import orderingElectricityGuideSummaryPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/fr-BE.json';
import orderingElectricityGuideTrading from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/fr-BE.json';
import orderingElectricityGuideUsage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/fr-BE.json';
import orderingElectricityGuideUsageResult from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/fr-BE.json';
import orderingElectricityGuideUsageResultVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/fr-BE.json';
import orderingElectricityGuidePostalCode from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/fr-BE.json';
import orderingSubscriptionIndependentOrder from '@client/internationalization/localization-messages/ordering-subscription/independent/OrderCompletedPage/fr-BE.json';
import ordersOverview from '@client/internationalization/localization-messages/OrdersOverview/fr-BE.json';
import overviewChangePlanService from '@client/internationalization/localization-messages/overview/ChangePlanServiceIntroductionDrawer/fr-BE.json';
import overviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/fr-BE.json';
import overviewDeprecatedInsights from '@client/internationalization/localization-messages/overview/DeprecatedInsightsPage/OnboardingInsightCard/fr-BE.json';
import overviewDiscoverPage from '@client/internationalization/localization-messages/overview/DiscoverPage/fr-BE.json';
import overviewGenericAction from '@client/internationalization/localization-messages/overview/GenericActions/fr-BE.json';
import overviewImprovementPage from '@client/internationalization/localization-messages/overview/ImprovementPage/fr-BE.json';
import overviewImproveV2 from '@client/internationalization/localization-messages/overview/ImproveV2/fr-BE.json';
import overviewInsightsCardsActionRating from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ActionRating/fr-BE.json';
import overviewInsightsCardsCancellationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/CancellationUpdate/fr-BE.json';
import overviewInsightsCardsChangeData from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ChangeDataplanUpdate/fr-BE.json';
import overviewInsightsCardsIndependent from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/IndependentOrderUpdate/fr-BE.json';
import overviewInsightsCardConfirmCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/fr-BE.json';
import overviewContactUsButton from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ContactUsButton/fr-BE.json';
import overviewInsightsCardsMonthlyCheck from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/MonthlyChecklist/fr-BE.json';
import overviewInsightsCardsNegotiationOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationOutcome/fr-BE.json';
import overviewInsightsCardsNegotiationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationUpdate/fr-BE.json';
import overviewInsightsCardsProvider from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewProviderOffersCard/fr-BE.json';
import overviewInsightsCardsSaveDeskFeedback from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/fr-BE.json';
import overviewInsightCardsSaveDeskRequested from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOrderRequested/fr-BE.json';
import overviewInsightsCardsSaveDeskPredefined from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/fr-BE.json';
import overviewInsightsCardsSaveDeskResponse from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskResponseCard/fr-BE.json';
import overviewInsightsCardsSwitchOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchOutcome/fr-BE.json';
import overviewInsightsCardsSwitchUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchUpdate/fr-BE.json';
import overviewInsightsCardsThankYou from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ThankYouCard/fr-BE.json';
import overviewInsightsCardsTryCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/TryCancellationAgainButton/fr-BE.json';
import overviewInsightsCardsUnreviewed from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnreviewedAction/fr-BE.json';
import overviewInsightsCardsOnboarding from '@client/internationalization/localization-messages/overview/InsightsPage/OnboardingInsightCard/fr-BE.json';
import overviewInsightsPrimerCardV2 from '@client/internationalization/localization-messages/overview/InsightsPage/PrimerCardV2/fr-BE.json';
import overviewLoadingWithCogs from '@client/internationalization/localization-messages/overview/LoadingWithCogs/fr-BE.json';
import overviewManageContract from '@client/internationalization/localization-messages/overview/ManageContractSection/SwitchProgressWithoutCancelStepper/fr-BE.json';
import overviewMonthlyChecklist from '@client/internationalization/localization-messages/overview/MonthlyChecklist/fr-BE.json';
import overviewMoreInfoNeeded from '@client/internationalization/localization-messages/overview/more-information-needed/fr-BE.json';
import overviewOnboardingCards from '@client/internationalization/localization-messages/overview/OnboardingCards/fr-BE.json';
import overviewQuickActionsCancel from '@client/internationalization/localization-messages/overview/QuickActionsCancel/fr-BE.json';
import overviewQuickActionsCancelV2 from '@client/internationalization/localization-messages/overview/QuickActionsCancelV2/fr-BE.json';
import overviewQuickActionsImprove from '@client/internationalization/localization-messages/overview/QuickActionsImprove/fr-BE.json';
import overviewQuickActionsImproveV2 from '@client/internationalization/localization-messages/overview/QuickActionsImproveV2/fr-BE.json';
import overviewSubscriptionFeedback from '@client/internationalization/localization-messages/overview/SubscriptionFeedback/fr-BE.json';
import overviewSubscriptionSearch from '@client/internationalization/localization-messages/overview/SubscriptionSearch/fr-BE.json';
import overviewSubscriptionSearchComponent from '@client/internationalization/localization-messages/overview/SubscriptionSearchOverviewComponent/fr-BE.json';
import waysToSave from '@client/internationalization/localization-messages/overview/WaysToSave/fr-BE.json';
import overview from '@client/internationalization/localization-messages/overview/fr-BE.json';
import overviewBottomNavigation from '@client/internationalization/localization-messages/overviewBottomNavigation/fr-BE.json';
import pauseIntelligentGuide from '@client/internationalization/localization-messages/pause/IntelligentGuidePage/fr-BE.json';
import pauseSubscriptionPause from '@client/internationalization/localization-messages/pause/SubscriptionPausePage/fr-BE.json';
import provideFeedbackPage from '@client/internationalization/localization-messages/ProvideFeedbackPage/fr-BE.json';
import providerPortalDatepicker from '@client/internationalization/localization-messages/provider-portal-datepicker/DatePicker/fr-BE.json';
import providerPortalSnackback from '@client/internationalization/localization-messages/provider-portal-snackback/Snackbar/fr-BE.json';
import registrationPage from '@client/internationalization/localization-messages/RegistrationPage/fr-BE.json';
import retentionOfferCard from '@client/internationalization/localization-messages/retention-offer/SuccessfulRetentionOfferCard/fr-BE.json';
import saveDesk from '@client/internationalization/localization-messages/save-desk/fr-BE.json';
import saveDeskOngoingCard from '@client/internationalization/localization-messages/save-desk/OngoingSaveDeskCard/fr-BE.json';
import saveDeskOrderSent from '@client/internationalization/localization-messages/save-desk/OrderSentPage/fr-BE.json';
import savingsDoYouWantToSave from '@client/internationalization/localization-messages/savings/DoYouWantToSaveDialog/fr-BE.json';
import savingsAfterCancellation from '@client/internationalization/localization-messages/savings/SavingsAfterCancellation/fr-BE.json';
import selectProductPage from '@client/internationalization/localization-messages/SelectProductPage/fr-BE.json';
import settingsNotificationSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/fr-BE.json';
import settingsNotificationPushSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/PushNotificationInstructions/fr-BE.json';
import settingsPersonalDetails from '@client/internationalization/localization-messages/settings/PersonalDetailsPage/fr-BE.json';
import settingsProfile from '@client/internationalization/localization-messages/settings/ProfilingSettingsPage/fr-BE.json';
import settingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/fr-BE.json';
import settingsUser from '@client/internationalization/localization-messages/settings/UserSettingsPage/fr-BE.json';
import settingsViewGdprData from '@client/internationalization/localization-messages/settings/ViewGdprDataPage/fr-BE.json';
import settingsSwedbank from '@client/internationalization/localization-messages/settings-swedbank/SwedbankNotificationSettingsPage/fr-BE.json';
import sharedComponentsEmoji from '@client/internationalization/localization-messages/shared-components/EmojiRating/fr-BE.json';
import sharedComponentsUpdateBankApp from '@client/internationalization/localization-messages/shared-components/UpdateBankApp/fr-BE.json';
import sharedComponentsYouSavedComponent from '@client/internationalization/localization-messages/shared-components/YouSavedComponent/fr-BE.json';
import showOnboardingFeatures from '@client/internationalization/localization-messages/ShowOnboardingFeaturesComponent/fr-BE.json';
import signOutSuccessPage from '@client/internationalization/localization-messages/SignoutSuccessPage/fr-BE.json';
import standardComponentsCurrency from '@client/internationalization/localization-messages/standard-components/CurrencyTextField/fr-BE.json';
import standardComponentsDate from '@client/internationalization/localization-messages/standard-components/DatePicker/fr-BE.json';
import standardComponentsEmail from '@client/internationalization/localization-messages/standard-components/EmailField/fr-BE.json';
import standardComponentsGuide from '@client/internationalization/localization-messages/standard-components/GuidePage/fr-BE.json';
import standardComponentsMobile from '@client/internationalization/localization-messages/standard-components/MobilePhoneNumberField/fr-BE.json';
import standardComponentsPassword from '@client/internationalization/localization-messages/standard-components/PasswordField/fr-BE.json';
import standardComponentsPersonal from '@client/internationalization/localization-messages/standard-components/PersonalNumberField/fr-BE.json';
import standardComponentsPostalCode from '@client/internationalization/localization-messages/standard-components/PostalCodeField/fr-BE.json';
import standardComponentsSnackbar from '@client/internationalization/localization-messages/standard-components/PostalCodeField/fr-BE.json';
import subscriptionCancellationMeasure from '@client/internationalization/localization-messages/subscription/CancellationMeasurement/fr-BE.json';
import subscriptionComponentsSelectable from '@client/internationalization/localization-messages/subscription/components/SelectableServiceList/fr-BE.json';
import subscriptionRemoveSubscription from '@client/internationalization/localization-messages/subscription/RemoveSubscriptionDialog/fr-BE.json';
import subscriptionSelectLoanService from '@client/internationalization/localization-messages/subscription/SelectLoanServicesPage/fr-BE.json';
import subscriptionSelectLoanTransaction from '@client/internationalization/localization-messages/subscription/SelectLoanTransactionTypePage/fr-BE.json';
import subscriptionSelectServices from '@client/internationalization/localization-messages/subscription/SelectServicesPage/fr-BE.json';
import subscriptionPage from '@client/internationalization/localization-messages/subscription/SubscriptionPage/fr-BE.json';
import subscriptionSettingsPage from '@client/internationalization/localization-messages/subscription/SubscriptionSettingsPage/fr-BE.json';
import subscriptionUpdateCostPage from '@client/internationalization/localization-messages/subscription/SubscriptionUpdateCostPage/fr-BE.json';
import subscriptionSwitchMeasurement from '@client/internationalization/localization-messages/subscription/SwitchMeasurement/fr-BE.json';
import subscriptionUnresolvedGuide from '@client/internationalization/localization-messages/subscription/UnresolvedGuide/fr-BE.json';
import subscriptionWhatsWrong from '@client/internationalization/localization-messages/subscription/WhatsWrongDialog/fr-BE.json';
import subscriptionAlarmMore from '@client/internationalization/localization-messages/subscription-alarm/MorePage/fr-BE.json';
import subscriptionAlarmPage from '@client/internationalization/localization-messages/subscription-alarm/SubscriptionAlarmPage/fr-BE.json';
import subscriptionHubActions from '@client/internationalization/localization-messages/subscription-hub/PastActions/fr-BE.json';
import subscriptionHub from '@client/internationalization/localization-messages/subscriptionHub/subscriptionHub/fr-BE.json';
import supplierPortalChange from '@client/internationalization/localization-messages/supplier-portal-change/fr-BE.json';
import supplierPortalSaveDesk from '@client/internationalization/localization-messages/supplier-portal-save-desk/fr-BE.json';
import swedbankOverviewSync from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/fr-BE.json';
import swedenPhoneCallsLimited from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/fr-BE.json';
import syncSourcesAddSync from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcePage/fr-BE.json';
import syncSourcesAddSyncSpeed from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcesSpeedDial/fr-BE.json';
import syncSourcesDeleteSync from '@client/internationalization/localization-messages/sync-sources/DeleteSyncSourcesPage/fr-BE.json';
import syncSourcesError from '@client/internationalization/localization-messages/sync-sources/Error/fr-BE.json';
import syncSourcesNoSync from '@client/internationalization/localization-messages/sync-sources/NoSyncSources/fr-BE.json';
import syncSourcesPopularSync from '@client/internationalization/localization-messages/sync-sources/PopularSyncSourcesList/fr-BE.json';
import syncSourcesFetching from '@client/internationalization/localization-messages/sync-sources/SyncSourcesFetching/fr-BE.json';
import syncSourcesList from '@client/internationalization/localization-messages/sync-sources/SyncSourcesList/fr-BE.json';
import syncSourcesReminder from '@client/internationalization/localization-messages/sync-sources/SyncSourcesReminder/fr-BE.json';
import syncSourcesTinkSync from '@client/internationalization/localization-messages/sync-sources/TinkSyncPage/fr-BE.json';
import syncSourcesUpdateBank from '@client/internationalization/localization-messages/sync-sources/UpdateBankAuthsPage/fr-BE.json';
import syncSourcesUpdateSync from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSources/fr-BE.json';
import syncSourcesUpdateSyncButton from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSourcesButton/fr-BE.json';
import terminationFormPage from '@client/internationalization/localization-messages/termination/TerminationFormPage/fr-BE.json';
import terminationFormPCancelReason from '@client/internationalization/localization-messages/termination/TerminationFormPage/CancelReasonSection/fr-BE.json';
import terminationInstructions from '@client/internationalization/localization-messages/termination/TerminationInstructionsPage/fr-BE.json';
import termsAndCondition from '@client/internationalization/localization-messages/TermsAndConditionsUpload/fr-BE.json';
import typeFormButton from '@client/internationalization/localization-messages/TypeformButton/fr-BE.json';
import userFormPreview from '@client/internationalization/localization-messages/user-form-preview/fr-BE.json';
import userFeedback from '@client/internationalization/localization-messages/userFeedback/fr-BE.json';
import userDeleteSettings from '@client/internationalization/localization-messages/userSettings/UserDeletePage/fr-BE.json';
import userDeleteVerifiedPage from '@client/internationalization/localization-messages/userSettings/UserDeleteVerifiedPage/fr-BE.json';
import zendesk from '@client/internationalization/localization-messages/zendesk/fr-BE.json';
import ingaboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/Ing/fr-BE.json';
import ingbankAboutUsPage from '@client/internationalization/localization-messages/AboutUsPage/BankAboutUsPage/Ing/fr-BE.json';
import inginsightPage from '@client/internationalization/localization-messages/overview/InsightsPage/Ing/fr-BE.json';
import inginsightPage_insightsCards_addon from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/AddonRecommendation/Ing/fr-BE.json';
import inginsightPage_bindingTimeEnding from '@client/internationalization/localization-messages/overview/InsightsPage/BindingTimeEnding/Ing/fr-BE.json';
import inginsightPage_insightsCards_discoverOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOngoing/Ing/fr-BE.json';
import inginsightPage_insightsCards_discoverOutcomeInsight from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/DiscoverOutcome/Ing/fr-BE.json';
import inginsightPage_insightsCards_newSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewSubscriptionFound/Ing/fr-BE.json';
import inginsightPage_insightsCards_personalRecommend from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/Ing/fr-BE.json';
import inginsightPage_insightsCards_saveDeskOngoing from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOngoingCard/Ing/fr-BE.json';
import inginsightPage_insightsCards_saveDeskOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOutcome/Ing/fr-BE.json';
import inginsightPage_subscriptionCostChanged from '@client/internationalization/localization-messages/overview/InsightsPage/SubscriptionCostChanged/Ing/fr-BE.json';
import inginsightPage_unresolvedSubscription from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnresolvedSubscription/Ing/fr-BE.json';
import ingoverviewPage from '@client/internationalization/localization-messages/overview/OverviewPage/Ing/fr-BE.json';
import ingoverviewAddManualSubscription from '@client/internationalization/localization-messages/overview/addManualSubscription/Ing/fr-BE.json';
import ingoverviewBindingTimeBar from '@client/internationalization/localization-messages/overview/BindingTimeBar/Ing/fr-BE.json';
import ingoverviewPage_didYouCancel from '@client/internationalization/localization-messages/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/Ing/fr-BE.json';
import ingbadgeCtas from '@client/internationalization/localization-messages/BadgeCtas/Ing/fr-BE.json';
import ingbindingTimeNotice from '@client/internationalization/localization-messages/overview/BindingTimeNotice/Ing/fr-BE.json';
import ingonboardingDrawer from '@client/internationalization/localization-messages/overview/OnboardingDrawer/Ing/fr-BE.json';
import ingoverviewPage_quickActionsV2 from '@client/internationalization/localization-messages/overview/QuickActionsV2/Ing/fr-BE.json';
import ingaddonMobileRecommendationsPage from '@client/internationalization/localization-messages/addon/mobile/RecommendationsPage/Ing/fr-BE.json';
import ingaddonMobileResultPage from '@client/internationalization/localization-messages/addon/mobile/ResultPage/Ing/fr-BE.json';
import ingauthAuthStatusPage from '@client/internationalization/localization-messages/auth/AuthStatusPage/Ing/fr-BE.json';
import ingauthBasicLoginPage from '@client/internationalization/localization-messages/auth/BasicLoginPage/Ing/fr-BE.json';
import ingauthCreateAccountPage from '@client/internationalization/localization-messages/auth/CreateAccountPage/Ing/fr-BE.json';
import ingauthOnboardingChooseBankPage from '@client/internationalization/localization-messages/auth/OnboardingChooseBankPage/Ing/fr-BE.json';
import ingauthOnboardingEmailPage from '@client/internationalization/localization-messages/auth/OnboardingEmailPage/Ing/fr-BE.json';
import ingauthPreLoginPage from '@client/internationalization/localization-messages/auth/PreLoginPage/Ing/fr-BE.json';
import ingauthResetPasswordPage from '@client/internationalization/localization-messages/auth/ResetPasswordPage/Ing/fr-BE.json';
import ingauthSyncSourcesError from '@client/internationalization/localization-messages/auth/SyncSourcesError/Ing/fr-BE.json';
import ingauthSyncSourcesFetching from '@client/internationalization/localization-messages/auth/SyncSourcesFetching/Ing/fr-BE.json';
import ingbadgeABTestCTAS from '@client/internationalization/localization-messages/BadgeABTestCtas/Ing/fr-BE.json';
import ingbankSyncCancelProduct from '@client/internationalization/localization-messages/bank-sync/CancelProductOnboardPages/Ing/fr-BE.json';
import ingbankSyncDrawerPages from '@client/internationalization/localization-messages/bank-sync/DrawerPages/Ing/fr-BE.json';
import ingbankSyncLoadingScreen from '@client/internationalization/localization-messages/bank-sync/LoadingScreen/Ing/fr-BE.json';
import ingbankSyncOnboardBankSync from '@client/internationalization/localization-messages/bank-sync/OnBoardBankSync/Ing/fr-BE.json';
import ingcampaignIdSelect from '@client/internationalization/localization-messages/CampaignIdSelect/Ing/fr-BE.json';
import ingcancelCancellationOutcome from '@client/internationalization/localization-messages/cancel/CancellationOutcome/Ing/fr-BE.json';
import ingcancellationDetailsPage from '@client/internationalization/localization-messages/cancellation/cancellationDetailsPage/Ing/fr-BE.json';
import ingcancellationFormCancellationInstruction from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationInstructionsSection/Ing/fr-BE.json';
import ingcancellationFormCancellationTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/CancellationTerms/Ing/fr-BE.json';
import ingcancellationFormConfirmSection from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/ConfirmSection/Ing/fr-BE.json';
import ingcancellationFormLightUserTerms from '@client/internationalization/localization-messages/cancellation/CancellationFormPage/LightUserTerms/Ing/fr-BE.json';
import ingcancellationModalsContainer from '@client/internationalization/localization-messages/cancellation/CancellationModalsContainer/Ing/fr-BE.json';
import ingcancellationQuestionsFlow from '@client/internationalization/localization-messages/cancellation/CancellationQuestionFlow/Ing/fr-BE.json';
import ingcancellationReasonsDialog from '@client/internationalization/localization-messages/cancellation/CancellationReasonsDialog/Ing/fr-BE.json';
import ingcancellationSentPage from '@client/internationalization/localization-messages/cancellation/CancellationSentPage/Ing/fr-BE.json';
import ingcancellationStepper from '@client/internationalization/localization-messages/cancellation/CancellationStepper/Ing/fr-BE.json';
import ingcancellationDoYouWantToSaveDialog from '@client/internationalization/localization-messages/cancellation/DoYouWantToSaveDialog/Ing/fr-BE.json';
import ingcancellationIntelligentGuidePage from '@client/internationalization/localization-messages/cancellation/IntelligentGuidePage/Ing/fr-BE.json';
import ingcancellationNetflixCancellationInstructionsPage from '@client/internationalization/localization-messages/cancellation/NetflixCancellationInstructionsPage/Ing/fr-BE.json';
import ingcancellationSigningNemIdErrorPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdErrorPage/Ing/fr-BE.json';
import ingcancellationSigningNemIdSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NemIdSigningPage/Ing/fr-BE.json';
import ingcancellationNoSigningPage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningPage/Ing/fr-BE.json';
import ingcancellationNoSigningServicePage from '@client/internationalization/localization-messages/cancellation/signing/NoSigningServicePage/Ing/fr-BE.json';
import ingcancellationPortal from '@client/internationalization/localization-messages/CancellationPortal/Ing/fr-BE.json';
import ingcancellationsCancellationPortalAgreement from '@client/internationalization/localization-messages/cancellations/cancellation-portal/cancellation-portal-agency-agreement/Ing/fr-BE.json';
import ingcategories from '@client/internationalization/localization-messages/categories/Ing/fr-BE.json';
import ingcategoryNames from '@client/internationalization/localization-messages/CategoryNames/CategoryNames/Ing/fr-BE.json';
import ingchangeDataPlan from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplan/Ing/fr-BE.json';
import ingchangeDataPlanCompletedPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanCompletedPage/Ing/fr-BE.json';
import ingchangeDataPlanOrderFormPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanOrderFormPage/Ing/fr-BE.json';
import ingchangeDataPlanPage from '@client/internationalization/localization-messages/change-dataplan/ChangeDataplanPage/Ing/fr-BE.json';
import ingchangePaymentChangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-payment/ChangePlanServiceIntroductionDrawer/Ing/fr-BE.json';
import ingchangePaymentIntelligentGuidePage from '@client/internationalization/localization-messages/change-payment/IntelligentGuidePage/Ing/fr-BE.json';
import ingchangePlanServiceIntroductionDrawer from '@client/internationalization/localization-messages/change-plan/ChangePlanServiceIntroductionDrawer/Ing/fr-BE.json';
import ingchangePlanIntelligentGuideExperimentPage from '@client/internationalization/localization-messages/change-plan/IntelligentGuideExperimentPage/Ing/fr-BE.json';
import ingchangePlanIntelligentGuidePage from '@client/internationalization/localization-messages/change-plan/IntelligentGuidePage/Ing/fr-BE.json';
import ingchangePlan from '@client/internationalization/localization-messages/ChangePlan/Ing/fr-BE.json';
import ingcharityDonation from '@client/internationalization/localization-messages/charity-donation/Ing/fr-BE.json';
import ingcomplexContractGuideIntroPage from '@client/internationalization/localization-messages/complex-contract-guide/IntroPage/Ing/fr-BE.json';
import ingcomplexContractGuideLoanServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanServiceQuestionPage/Ing/fr-BE.json';
import ingcomplexContractGuideLoanTypeQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/LoanTypeQuestionPage/Ing/fr-BE.json';
import ingcomplexContractGuideServiceQuestionPage from '@client/internationalization/localization-messages/complex-contract-guide/ServiceQuestionPage/Ing/fr-BE.json';
import ingcomplexContractGuideShared from '@client/internationalization/localization-messages/complex-contract-guide/shared/Ing/fr-BE.json';
import ingcomplexContractGuideSuccessPage from '@client/internationalization/localization-messages/complex-contract-guide/SuccessPage/Ing/fr-BE.json';
import ingconditionsAndPoliciesMenu from '@client/internationalization/localization-messages/conditions-and-policies/ConditionsAndPoliciesMenu/Ing/fr-BE.json';
import ingconditionsAndPoliciesCookiesPage from '@client/internationalization/localization-messages/conditions-and-policies/CookiesPage/Ing/fr-BE.json';
import ingconditionsAndPoliciesSecurityPage from '@client/internationalization/localization-messages/conditions-and-policies/SecurityPage/Ing/fr-BE.json';
import ingconditionsAndPolicies from '@client/internationalization/localization-messages/conditions-and-policies/Ing/fr-BE.json';
import ingcontractBindingPeriodDialog from '@client/internationalization/localization-messages/contract/bindingPeriodDialog/Ing/fr-BE.json';
import ingcontractCancellationHistory from '@client/internationalization/localization-messages/contract/CancellationHistory/Ing/fr-BE.json';
import ingcontractCancelledContract from '@client/internationalization/localization-messages/contract/CancelledContract/Ing/fr-BE.json';
import ingcontractCancellationOutcome from '@client/internationalization/localization-messages/contract/ContractCancellationOutcome/Ing/fr-BE.json';
import ingcontractCardMessage from '@client/internationalization/localization-messages/contract/ContractCardMessage/Ing/fr-BE.json';
import ingcontractDetails from '@client/internationalization/localization-messages/contract/ContractDetails/Ing/fr-BE.json';
import ingcontractDetailsPage from '@client/internationalization/localization-messages/contract/ContractDetailsPage/Ing/fr-BE.json';
import ingcontractDoMoreComponent from '@client/internationalization/localization-messages/contract/DoMoreComponent/Ing/fr-BE.json';
import ingcontractHistoryToolTip from '@client/internationalization/localization-messages/contract/HistoryTooltip/Ing/fr-BE.json';
import ingcontractPriceHistory from '@client/internationalization/localization-messages/contract/PriceHistoryLolipop/Ing/fr-BE.json';
import ingcontractSelectServicePage from '@client/internationalization/localization-messages/contract/SelectServicePageLolipop/Ing/fr-BE.json';
import ingcontractSelectServicesList from '@client/internationalization/localization-messages/contract/SelectServicesListLolipop/Ing/fr-BE.json';
import ingcontractSettingsButton from '@client/internationalization/localization-messages/contract/SettingsButtonLolipop/Ing/fr-BE.json';
import ingcontractSettingsPage from '@client/internationalization/localization-messages/contract/SettingsPageLolipop/Ing/fr-BE.json';
import ingcontractSubscriptionAlarm from '@client/internationalization/localization-messages/contract/SubscriptionAlarmLolipop/Ing/fr-BE.json';
import ingcontractSubscriptionDetails from '@client/internationalization/localization-messages/contract/SubscriptionDetailsLolipop/Ing/fr-BE.json';
import ingcontractSubscriptionReminder from '@client/internationalization/localization-messages/contract/SubscriptionReminder/Ing/fr-BE.json';
import ingcontractSubscriptionReminderDialog from '@client/internationalization/localization-messages/contract/subscriptionReminderDialog/Ing/fr-BE.json';
import ingcontractTransactionHistory from '@client/internationalization/localization-messages/contract/TransactionHistoryLolipop/Ing/fr-BE.json';
import ingcontractUpdateCost from '@client/internationalization/localization-messages/contract/UpdateCostLolipop/Ing/fr-BE.json';
import ingdiscoverCategoryPage from '@client/internationalization/localization-messages/discover/CategoryPage/Ing/fr-BE.json';
import ingdiscoverCategoryProviderPage from '@client/internationalization/localization-messages/discover/CategoryProviderPage/Ing/fr-BE.json';
import ingdiscoverComponentsProductInfo from '@client/internationalization/localization-messages/discover/components/ProductInformationHeader/Ing/fr-BE.json';
import ingdiscoverDiscoverWidget from '@client/internationalization/localization-messages/discover/DiscoverWidget/Ing/fr-BE.json';
import ingdiscoverFakeCategoryPage from '@client/internationalization/localization-messages/discover/FakeCategoryPage/Ing/fr-BE.json';
import ingdiscoverFakeCategorySubscriptionPage from '@client/internationalization/localization-messages/discover/FakeCategorySubscriptionsPage/Ing/fr-BE.json';
import ingdiscoverFitnessCategoryFeedbackPage from '@client/internationalization/localization-messages/discover/FitnessCategoryFeedbackPage/Ing/fr-BE.json';
import ingdiscoverMarketplacePage from '@client/internationalization/localization-messages/discover/MarketplacePage/Ing/fr-BE.json';
import ingdiscoverMobileOrderSentPage from '@client/internationalization/localization-messages/discover/MobileOrderSentPage/Ing/fr-BE.json';
import ingdiscoverOrderProductPage from '@client/internationalization/localization-messages/discover/OrderProductPage/Ing/fr-BE.json';
import ingdiscoverProductRecommendations from '@client/internationalization/localization-messages/discover/ProductRecommendations/Ing/fr-BE.json';
import ingdiscoverSearchSubscriptionAllCategories from '@client/internationalization/localization-messages/discover/SearchSubscriptionAllCategoriesPage/Ing/fr-BE.json';
import ingdiscoverSearchSubscriptionPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionPage/Ing/fr-BE.json';
import ingdiscoverSearchSubscriptionResultPage from '@client/internationalization/localization-messages/discover/SearchSubscriptionResultPage/Ing/fr-BE.json';
import ingdummy from '@client/internationalization/localization-messages/DUMMY/Ing/fr-BE.json';
import ingeSigning from '@client/internationalization/localization-messages/e-signing/nem-id/NemIdSigningPage/Ing/fr-BE.json';
import ingeditProduct from '@client/internationalization/localization-messages/EditProduct/Ing/fr-BE.json';
import ingenergyGuide from '@client/internationalization/localization-messages/EnergyGuide/OrderForm/Ing/fr-BE.json';
import ingerrorHandling from '@client/internationalization/localization-messages/error-handling/LoggedOutPage/Ing/fr-BE.json';
import ingerrorPageErrorPage from '@client/internationalization/localization-messages/errorPage/ErrorPage/Ing/fr-BE.json';
import ingerrorScreen from '@client/internationalization/localization-messages/ErrorScreen/Ing/fr-BE.json';
import ingexplore from '@client/internationalization/localization-messages/Explore/Ing/fr-BE.json';
import ingfeedbackModal from '@client/internationalization/localization-messages/feedback-modal/Ing/fr-BE.json';
import ingfields from '@client/internationalization/localization-messages/fields/Ing/fr-BE.json';
import ingfinancialProductsLoansShow from '@client/internationalization/localization-messages/financial-products/loans/ShowNewFeatureLoanDialog/Ing/fr-BE.json';
import ingfinancialProductsSavingsInvestment from '@client/internationalization/localization-messages/financial-products/savings/InvestmentInfoCard/Ing/fr-BE.json';
import ingfinancialProductOurPartnersPage from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPage/Ing/fr-BE.json';
import ingfinancialProductOurPartnersPageNew from '@client/internationalization/localization-messages/financial-products/savings/OurPartnersPageNew/Ing/fr-BE.json';
import ingfinancialProductsSavingsOverview from '@client/internationalization/localization-messages/financial-products/savings/Overview/Ing/fr-BE.json';
import ingfinancialProductSavingsAfterCancellation from '@client/internationalization/localization-messages/financial-products/savings/SavingsAfterCancellation/Ing/fr-BE.json';
import ingfinancialProductsShowNewFeatureLoan from '@client/internationalization/localization-messages/financial-products/ShowNewFeatureLoanDialog/Ing/fr-BE.json';
import ingfinancialProductsSwedBankConsolidate from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/HowItWorksPage/Ing/fr-BE.json';
import ingfinancialSwedBankConsolidateIntro from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/IntroPage/Ing/fr-BE.json';
import ingfinancialProductLoanApplication from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/LoanApplicationStatus/Ing/fr-BE.json';
import ingfinancialProductsOfferPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OfferPage/Ing/fr-BE.json';
import ingfinancialProductOrderCompleted from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderCompletedPage/Ing/fr-BE.json';
import ingfinancialProductOrderPage from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/OrderPage/Ing/fr-BE.json';
import ingfinancialProductSelectContract from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectContractsPage/Ing/fr-BE.json';
import ingfinancialProductsSelectPrincipal from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectPrincipalPage/Ing/fr-BE.json';
import ingfinancialProductRepaymentPeriod from '@client/internationalization/localization-messages/financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/Ing/fr-BE.json';
import inggeneralIntelligentGuide from '@client/internationalization/localization-messages/general/IntelligentGuide/Ing/fr-BE.json';
import inggeneralSelectServices from '@client/internationalization/localization-messages/general/SelectServicesPage/Ing/fr-BE.json';
import inggenericActionsPage from '@client/internationalization/localization-messages/genericActions/GenericActionsPage/Ing/fr-BE.json';
import inggenericActionsSelectServices from '@client/internationalization/localization-messages/genericActions/SelectServicesPage/Ing/fr-BE.json';
import inggenericActionsSubscriptionCancel from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/Cancel/Ing/fr-BE.json';
import inggenericActionsSubscriptionsChangePayment from '@client/internationalization/localization-messages/genericActions/SubscriptionSearchPage/ChangePayment/Ing/fr-BE.json';
import ingguidesContractDetailsAlternative from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/AlternativesSection/Ing/fr-BE.json';
import ingguidesDealBanner from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/DealBanner/Ing/fr-BE.json';
import ingguidesPaymentMethod from '@client/internationalization/localization-messages/guides/GuideContractDetailsPage/PaymentMethodsCard/Ing/fr-BE.json';
import inghack7CancelPage from '@client/internationalization/localization-messages/Hack7CancelPage/Ing/fr-BE.json';
import ingheader from '@client/internationalization/localization-messages/Header/Ing/fr-BE.json';
import inghowToCancelListPage from '@client/internationalization/localization-messages/HowToCancelListPage/Ing/fr-BE.json';
import inghowToCancelPage from '@client/internationalization/localization-messages/HowToCancelPage/Ing/fr-BE.json';
import ingimproveOrderPage from '@client/internationalization/localization-messages/improve/flowgen/OrderPage/Ing/fr-BE.json';
import ingimproveOrderSentPage from '@client/internationalization/localization-messages/improve/flowgen/OrderSentPage/Ing/fr-BE.json';
import ingimproveOtherAlternativePage from '@client/internationalization/localization-messages/improve/flowgen/OtherAlternativesPage/Ing/fr-BE.json';
import ingimprovePostalCodeInput from '@client/internationalization/localization-messages/improve/flowgen/postalCodeInput/Ing/fr-BE.json';
import ingimproveResultPage from '@client/internationalization/localization-messages/improve/flowgen/ResultPage/Ing/fr-BE.json';
import ingimproveFlowgen from '@client/internationalization/localization-messages/improve/flowgen/Ing/fr-BE.json';
import inginsightPageInsightsCardsCancelOutcome from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationOutcome/Ing/fr-BE.json';
import inginsightPageInsightsCardsCancelUpdate from '@client/internationalization/localization-messages/InsightsPage/InsightCards/CancellationUpdate/Ing/fr-BE.json';
import inginsightPageOnboardingInsightCard from '@client/internationalization/localization-messages/InsightsPage/OnboardingInsightCard/Ing/fr-BE.json';
import ingintervalToString from '@client/internationalization/localization-messages/interval-to-string/Ing/fr-BE.json';
import inglandingPage from '@client/internationalization/localization-messages/LandingPage/Ing/fr-BE.json';
import ingloadingScreen from '@client/internationalization/localization-messages/LoadingScreen/Ing/fr-BE.json';
import ingloanApplicationsDetailsPage from '@client/internationalization/localization-messages/loan-applications/DetailsPage/Ing/fr-BE.json';
import ingloanApplicationsLoadingError from '@client/internationalization/localization-messages/loan-applications/LoadingError/Ing/fr-BE.json';
import ingloanApplicationsMenuDrawer from '@client/internationalization/localization-messages/loan-applications/MenuDrawer/Ing/fr-BE.json';
import ingloanApplicationsOverview from '@client/internationalization/localization-messages/loan-applications/Overview/Ing/fr-BE.json';
import ingloginPage from '@client/internationalization/localization-messages/LoginPage/Ing/fr-BE.json';
import ingmanageOrder from '@client/internationalization/localization-messages/ManageOrder/Ing/fr-BE.json';
import ingmanualAddContractPage from '@client/internationalization/localization-messages/ManualAddContractPage/Ing/fr-BE.json';
import ingmanualAddContractPricingPage from '@client/internationalization/localization-messages/ManualContractPricingPage/Ing/fr-BE.json';
import ingmenu from '@client/internationalization/localization-messages/Menu/Ing/fr-BE.json';
import ingmenuPage from '@client/internationalization/localization-messages/MenuPage/Ing/fr-BE.json';
import ingmovingBundledCancellationForm from '@client/internationalization/localization-messages/moving/BundledCancellationFromPage/Ing/fr-BE.json';
import ingmovingBundledCancellationPage from '@client/internationalization/localization-messages/moving/BundledCancellationPage/Ing/fr-BE.json';
import ingmovingBundledSwitchOrderForm from '@client/internationalization/localization-messages/moving/BundledSwitchOrderFormPage/Ing/fr-BE.json';
import ingmovingBundledSwitchPage from '@client/internationalization/localization-messages/moving/BundledSwitchSwitchPage/Ing/fr-BE.json';
import ingthanksSwitchPage from '@client/internationalization/localization-messages/moving/ThanksSwitchPage/Ing/fr-BE.json';
import ingmovingGuide from '@client/internationalization/localization-messages/movingGuide/Ing/fr-BE.json';
import ingmovingGuideMovingDetails from '@client/internationalization/localization-messages/movingGuide/MovingDetailsPage/Ing/fr-BE.json';
import ingmovingGuideOneLastThingPage from '@client/internationalization/localization-messages/movingGuide/OneLastThingPage/Ing/fr-BE.json';
import ingnegotiateContractCompletePage from '@client/internationalization/localization-messages/negotiate/NegotiateContractCompletePage/Ing/fr-BE.json';
import ingnegotiateGuide from '@client/internationalization/localization-messages/negotiate/NegotiateGuide/Ing/fr-BE.json';
import ingnegotiationOngoingCard from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationCard/Ing/fr-BE.json';
import ingnegotiationOngoingStepper from '@client/internationalization/localization-messages/negotiation/OngoingNegotiationStepper/Ing/fr-BE.json';
import ingnotificationOptInPage from '@client/internationalization/localization-messages/NotificationOptInPage/Ing/fr-BE.json';
import ingonboardingPSD2Consent from '@client/internationalization/localization-messages/onboarding/PSD2ConsentPage/Ing/fr-BE.json';
import ingonboardingPSD2Primer from '@client/internationalization/localization-messages/onboarding/PSD2PrimerPage/Ing/fr-BE.json';
import ingonboardingBDSyncConnecting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryConnecting/Ing/fr-BE.json';
import ingonboardingBDSyncError from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryErrorPage/Ing/fr-BE.json';
import ingonboardingBDSyncFetch from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryFetchPage/Ing/fr-BE.json';
import ingonboardingBDSyncSuccess from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/Ing/fr-BE.json';
import ingonboardingBDSyncWaiting from '@client/internationalization/localization-messages/onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/Ing/fr-BE.json';
import ingonboardingCancelProduct from '@client/internationalization/localization-messages/onboarding-cancel-product/OnboardingCancelProductPages/Ing/fr-BE.json';
import ingonboardingPsd2WelcomePage from '@client/internationalization/localization-messages/onboarding-psd2/WelcomePage/Ing/fr-BE.json';
import ingonboardingPsd2Cards from '@client/internationalization/localization-messages/onboarding-psd2/Cards/Ing/fr-BE.json';
import ingonboardingPsd2ConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/Ing/fr-BE.json';
import ingonboardingPsd2OpBankConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPage/Ing/fr-BE.json';
import ingonboardingPsd2OpBankConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/OpBankConsentPageV2/Ing/fr-BE.json';
import ingonboardingPsd2ConsentPageDanskeBank from '@client/internationalization/localization-messages/onboarding-psd2/ConsentPage/DanskeBankConsentPage/Ing/fr-BE.json';
import ingonboardingPsd2RenewConsentPage from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPage/Ing/fr-BE.json';
import ingonboardingPsd2RenewConsentPageV2 from '@client/internationalization/localization-messages/onboarding-psd2/RenewConsentPageV2/Ing/fr-BE.json';
import ingorderedSubscriptionOngoing from '@client/internationalization/localization-messages/orderedSubscription/OngoingOrderCard/Ing/fr-BE.json';
import ingorderedSubscriptionOrderedEnergy from '@client/internationalization/localization-messages/orderedSubscription/OrderedEnergyProductInfo/Ing/fr-BE.json';
import ingorderedSubscriptionOrderedMobile from '@client/internationalization/localization-messages/orderedSubscription/OrderedMobileProductInfo/Ing/fr-BE.json';
import ingorderedSubscriptionDetails from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetails/Ing/fr-BE.json';
import ingorderedSubscriptionDetailsPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/Ing/fr-BE.json';
import ingorderedSubscriptionInfoCard from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionDetailsPage/Ing/fr-BE.json';
import ingorderedSubscriptionPage from '@client/internationalization/localization-messages/orderedSubscription/OrderedSubscriptionPage/Ing/fr-BE.json';
import ingorderedSubscriptionOrderStepper from '@client/internationalization/localization-messages/orderedSubscription/OrderStepper/Ing/fr-BE.json';
import ingorderedSubscServiceProviderInfo from '@client/internationalization/localization-messages/orderedSubscription/ServiceProviderInfoCard/Ing/fr-BE.json';
import ingorderingSubscriptionTerms from '@client/internationalization/localization-messages/ordering-subscription/common/TermsAndConditionsPage/Ing/fr-BE.json';
import ingorderingSubscriptionCompleted from '@client/internationalization/localization-messages/ordering-subscription/common/OrderCompletedPage/Ing/fr-BE.json';
import ingorderingBroadbandAddressPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAddressPage/Ing/fr-BE.json';
import ingorderingBroadbandAllAlternatives from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/Ing/fr-BE.json';
import ingorderingBroadbandContract from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideContractCostPage/Ing/fr-BE.json';
import ingorderingBroadbandIntroPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideIntroPage/Ing/fr-BE.json';
import ingorderingBroadbandNoResultPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideNoResultPage/Ing/fr-BE.json';
import ingorderingBroadbandSpeedPage from '@client/internationalization/localization-messages/ordering-subscription/broadband/common/BroadbandGuideSpeedPage/Ing/fr-BE.json';
import ingorderingBroadbandOptimizeOrder from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandOrderFormPage/Ing/fr-BE.json';
import ingorderingBroadbandOptimizeProduct from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/Ing/fr-BE.json';
import ingorderingBroadbandOptimizeProductCosts from '@client/internationalization/localization-messages/ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/Ing/fr-BE.json';
import ingorderingElectricityOrder from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPage/Ing/fr-BE.json';
import ingorderingElectricityOrderVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/Ing/fr-BE.json';
import ingorderingElectricityGuidePrice from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/Ing/fr-BE.json';
import ingorderingElectricityGuideAlternatives from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/Ing/fr-BE.json';
import ingorderingElectricityGuideBinding from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/Ing/fr-BE.json';
import ingorderingElectricityGuideBindingTime from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/Ing/fr-BE.json';
import ingorderingElectricityGuideEnergy from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/Ing/fr-BE.json';
import ingorderingElectricityGuideExplanation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/Ing/fr-BE.json';
import ingorderingElectricityGuideFlow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/Ing/fr-BE.json';
import ingorderingElectricityGuideHow from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/Ing/fr-BE.json';
import ingorderingElectricityGuideInformation from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/Ing/fr-BE.json';
import ingorderingElectricityGuideIntro from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/Ing/fr-BE.json';
import ingorderingElectricityGuideLivingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/Ing/fr-BE.json';
import ingorderingElectricityGuidePostalCodePage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/Ing/fr-BE.json';
import ingorderingElectricityGuidePreferences from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/Ing/fr-BE.json';
import ingorderingElectricityGuidePricingType from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/Ing/fr-BE.json';
import ingorderingElectricityGuideProductPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/Ing/fr-BE.json';
import ingorderingElectricityGuideSummaryPage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/Ing/fr-BE.json';
import ingorderingElectricityGuideTrading from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/Ing/fr-BE.json';
import ingorderingElectricityGuideUsage from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/Ing/fr-BE.json';
import ingorderingElectricityGuideUsageResult from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/Ing/fr-BE.json';
import ingorderingElectricityGuideUsageResultVariant from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/Ing/fr-BE.json';
import ingorderingElectricityGuidePostalCode from '@client/internationalization/localization-messages/ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/Ing/fr-BE.json';
import ingorderingSubscriptionIndependentOrder from '@client/internationalization/localization-messages/ordering-subscription/independent/OrderCompletedPage/Ing/fr-BE.json';
import ingordersOverview from '@client/internationalization/localization-messages/OrdersOverview/Ing/fr-BE.json';
import ingoverviewChangePlanService from '@client/internationalization/localization-messages/overview/ChangePlanServiceIntroductionDrawer/Ing/fr-BE.json';
import ingoverviewContractAction from '@client/internationalization/localization-messages/overview/ContractAction/Ing/fr-BE.json';
import ingoverviewDeprecatedInsights from '@client/internationalization/localization-messages/overview/DeprecatedInsightsPage/OnboardingInsightCard/Ing/fr-BE.json';
import ingoverviewDiscoverPage from '@client/internationalization/localization-messages/overview/DiscoverPage/Ing/fr-BE.json';
import ingoverviewGenericAction from '@client/internationalization/localization-messages/overview/GenericActions/Ing/fr-BE.json';
import ingoverviewImprovementPage from '@client/internationalization/localization-messages/overview/ImprovementPage/Ing/fr-BE.json';
import ingoverviewImproveV2 from '@client/internationalization/localization-messages/overview/ImproveV2/Ing/fr-BE.json';
import ingoverviewInsightsCardsActionRating from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ActionRating/Ing/fr-BE.json';
import ingoverviewInsightsCardsCancellationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/CancellationUpdate/Ing/fr-BE.json';
import ingoverviewInsightsCardsChangeData from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ChangeDataplanUpdate/Ing/fr-BE.json';
import ingoverviewInsightsCardsIndependent from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/IndependentOrderUpdate/Ing/fr-BE.json';
import ingoverviewInsightsCardConfirmCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/Ing/fr-BE.json';
import ingoverviewContactUsButton from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ContactUsButton/Ing/fr-BE.json';
import ingoverviewInsightsCardsMonthlyCheck from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/MonthlyChecklist/Ing/fr-BE.json';
import ingoverviewInsightsCardsNegotiationOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationOutcome/Ing/fr-BE.json';
import ingoverviewInsightsCardsNegotiationUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NegotiationUpdate/Ing/fr-BE.json';
import ingoverviewInsightsCardsProvider from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/NewProviderOffersCard/Ing/fr-BE.json';
import ingoverviewInsightsCardsSaveDeskFeedback from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/Ing/fr-BE.json';
import ingoverviewInsightCardsSaveDeskRequested from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskOrderRequested/Ing/fr-BE.json';
import ingoverviewInsightsCardsSaveDeskPredefined from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/Ing/fr-BE.json';
import ingoverviewInsightsCardsSaveDeskResponse from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SaveDeskResponseCard/Ing/fr-BE.json';
import ingoverviewInsightsCardsSwitchOutcome from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchOutcome/Ing/fr-BE.json';
import ingoverviewInsightsCardsSwitchUpdate from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/SwitchUpdate/Ing/fr-BE.json';
import ingoverviewInsightsCardsThankYou from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/ThankYouCard/Ing/fr-BE.json';
import ingoverviewInsightsCardsTryCancellation from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/TryCancellationAgainButton/Ing/fr-BE.json';
import ingoverviewInsightsCardsUnreviewed from '@client/internationalization/localization-messages/overview/InsightsPage/InsightCards/UnreviewedAction/Ing/fr-BE.json';
import ingoverviewInsightsCardsOnboarding from '@client/internationalization/localization-messages/overview/InsightsPage/OnboardingInsightCard/Ing/fr-BE.json';
import ingoverviewInsightsPrimerCardV2 from '@client/internationalization/localization-messages/overview/InsightsPage/PrimerCardV2/Ing/fr-BE.json';
import ingoverviewLoadingWithCogs from '@client/internationalization/localization-messages/overview/LoadingWithCogs/Ing/fr-BE.json';
import ingoverviewManageContract from '@client/internationalization/localization-messages/overview/ManageContractSection/SwitchProgressWithoutCancelStepper/Ing/fr-BE.json';
import ingoverviewMonthlyChecklist from '@client/internationalization/localization-messages/overview/MonthlyChecklist/Ing/fr-BE.json';
import ingoverviewMoreInfoNeeded from '@client/internationalization/localization-messages/overview/more-information-needed/Ing/fr-BE.json';
import ingoverviewOnboardingCards from '@client/internationalization/localization-messages/overview/OnboardingCards/Ing/fr-BE.json';
import ingoverviewQuickActionsCancel from '@client/internationalization/localization-messages/overview/QuickActionsCancel/Ing/fr-BE.json';
import ingoverviewQuickActionsCancelV2 from '@client/internationalization/localization-messages/overview/QuickActionsCancelV2/Ing/fr-BE.json';
import ingoverviewQuickActionsImprove from '@client/internationalization/localization-messages/overview/QuickActionsImprove/Ing/fr-BE.json';
import ingoverviewQuickActionsImproveV2 from '@client/internationalization/localization-messages/overview/QuickActionsImproveV2/Ing/fr-BE.json';
import ingoverviewSubscriptionFeedback from '@client/internationalization/localization-messages/overview/SubscriptionFeedback/Ing/fr-BE.json';
import ingoverviewSubscriptionSearch from '@client/internationalization/localization-messages/overview/SubscriptionSearch/Ing/fr-BE.json';
import ingoverviewSubscriptionSearchComponent from '@client/internationalization/localization-messages/overview/SubscriptionSearchOverviewComponent/Ing/fr-BE.json';
import ingwaysToSave from '@client/internationalization/localization-messages/overview/WaysToSave/Ing/fr-BE.json';
import ingoverview from '@client/internationalization/localization-messages/overview/Ing/fr-BE.json';
import ingoverviewBottomNavigation from '@client/internationalization/localization-messages/overviewBottomNavigation/Ing/fr-BE.json';
import ingpauseIntelligentGuide from '@client/internationalization/localization-messages/pause/IntelligentGuidePage/Ing/fr-BE.json';
import ingpauseSubscriptionPause from '@client/internationalization/localization-messages/pause/SubscriptionPausePage/Ing/fr-BE.json';
import ingprovideFeedbackPage from '@client/internationalization/localization-messages/ProvideFeedbackPage/Ing/fr-BE.json';
import ingproviderPortalDatepicker from '@client/internationalization/localization-messages/provider-portal-datepicker/DatePicker/Ing/fr-BE.json';
import ingproviderPortalSnackback from '@client/internationalization/localization-messages/provider-portal-snackback/Snackbar/Ing/fr-BE.json';
import ingregistrationPage from '@client/internationalization/localization-messages/RegistrationPage/Ing/fr-BE.json';
import ingretentionOfferCard from '@client/internationalization/localization-messages/retention-offer/SuccessfulRetentionOfferCard/Ing/fr-BE.json';
import ingsaveDesk from '@client/internationalization/localization-messages/save-desk/Ing/fr-BE.json';
import ingsaveDeskOngoingCard from '@client/internationalization/localization-messages/save-desk/OngoingSaveDeskCard/Ing/fr-BE.json';
import ingsaveDeskOrderSent from '@client/internationalization/localization-messages/save-desk/OrderSentPage/Ing/fr-BE.json';
import ingsavingsDoYouWantToSave from '@client/internationalization/localization-messages/savings/DoYouWantToSaveDialog/Ing/fr-BE.json';
import ingsavingsAfterCancellation from '@client/internationalization/localization-messages/savings/SavingsAfterCancellation/Ing/fr-BE.json';
import ingselectProductPage from '@client/internationalization/localization-messages/SelectProductPage/Ing/fr-BE.json';
import ingsettingsNotificationSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/Ing/fr-BE.json';
import ingsettingsNotificationPushSettings from '@client/internationalization/localization-messages/settings/NotificationSettingsPage/PushNotificationInstructions/Ing/fr-BE.json';
import ingsettingsPersonalDetails from '@client/internationalization/localization-messages/settings/PersonalDetailsPage/Ing/fr-BE.json';
import ingsettingsProfile from '@client/internationalization/localization-messages/settings/ProfilingSettingsPage/Ing/fr-BE.json';
import ingsettingsPage from '@client/internationalization/localization-messages/settings/SettingsPage/Ing/fr-BE.json';
import ingsettingsUser from '@client/internationalization/localization-messages/settings/UserSettingsPage/Ing/fr-BE.json';
import ingsettingsViewGdprData from '@client/internationalization/localization-messages/settings/ViewGdprDataPage/Ing/fr-BE.json';
import ingsettingsSwedbank from '@client/internationalization/localization-messages/settings-swedbank/SwedbankNotificationSettingsPage/Ing/fr-BE.json';
import ingsharedComponentsEmoji from '@client/internationalization/localization-messages/shared-components/EmojiRating/Ing/fr-BE.json';
import ingsharedComponentsUpdateBankApp from '@client/internationalization/localization-messages/shared-components/UpdateBankApp/Ing/fr-BE.json';
import ingsharedComponentsYouSavedComponent from '@client/internationalization/localization-messages/shared-components/YouSavedComponent/Ing/fr-BE.json';
import ingshowOnboardingFeatures from '@client/internationalization/localization-messages/ShowOnboardingFeaturesComponent/Ing/fr-BE.json';
import ingsignOutSuccessPage from '@client/internationalization/localization-messages/SignoutSuccessPage/Ing/fr-BE.json';
import ingstandardComponentsCurrency from '@client/internationalization/localization-messages/standard-components/CurrencyTextField/Ing/fr-BE.json';
import ingstandardComponentsDate from '@client/internationalization/localization-messages/standard-components/DatePicker/Ing/fr-BE.json';
import ingstandardComponentsEmail from '@client/internationalization/localization-messages/standard-components/EmailField/Ing/fr-BE.json';
import ingstandardComponentsGuide from '@client/internationalization/localization-messages/standard-components/GuidePage/Ing/fr-BE.json';
import ingstandardComponentsMobile from '@client/internationalization/localization-messages/standard-components/MobilePhoneNumberField/Ing/fr-BE.json';
import ingstandardComponentsPassword from '@client/internationalization/localization-messages/standard-components/PasswordField/Ing/fr-BE.json';
import ingstandardComponentsPersonal from '@client/internationalization/localization-messages/standard-components/PersonalNumberField/Ing/fr-BE.json';
import ingstandardComponentsPostalCode from '@client/internationalization/localization-messages/standard-components/PostalCodeField/Ing/fr-BE.json';
import ingstandardComponentsSnackbar from '@client/internationalization/localization-messages/standard-components/PostalCodeField/Ing/fr-BE.json';
import ingsubscriptionCancellationMeasure from '@client/internationalization/localization-messages/subscription/CancellationMeasurement/Ing/fr-BE.json';
import ingsubscriptionComponentsSelectable from '@client/internationalization/localization-messages/subscription/components/SelectableServiceList/Ing/fr-BE.json';
import ingsubscriptionRemoveSubscription from '@client/internationalization/localization-messages/subscription/RemoveSubscriptionDialog/Ing/fr-BE.json';
import ingsubscriptionSelectLoanService from '@client/internationalization/localization-messages/subscription/SelectLoanServicesPage/Ing/fr-BE.json';
import ingsubscriptionSelectLoanTransaction from '@client/internationalization/localization-messages/subscription/SelectLoanTransactionTypePage/Ing/fr-BE.json';
import ingsubscriptionSelectServices from '@client/internationalization/localization-messages/subscription/SelectServicesPage/Ing/fr-BE.json';
import ingsubscriptionPage from '@client/internationalization/localization-messages/subscription/SubscriptionPage/Ing/fr-BE.json';
import ingsubscriptionSettingsPage from '@client/internationalization/localization-messages/subscription/SubscriptionSettingsPage/Ing/fr-BE.json';
import ingsubscriptionUpdateCostPage from '@client/internationalization/localization-messages/subscription/SubscriptionUpdateCostPage/Ing/fr-BE.json';
import ingsubscriptionSwitchMeasurement from '@client/internationalization/localization-messages/subscription/SwitchMeasurement/Ing/fr-BE.json';
import ingsubscriptionUnresolvedGuide from '@client/internationalization/localization-messages/subscription/UnresolvedGuide/Ing/fr-BE.json';
import ingsubscriptionWhatsWrong from '@client/internationalization/localization-messages/subscription/WhatsWrongDialog/Ing/fr-BE.json';
import ingsubscriptionAlarmMore from '@client/internationalization/localization-messages/subscription-alarm/MorePage/Ing/fr-BE.json';
import ingsubscriptionAlarmPage from '@client/internationalization/localization-messages/subscription-alarm/SubscriptionAlarmPage/Ing/fr-BE.json';
import ingsubscriptionHubActions from '@client/internationalization/localization-messages/subscription-hub/PastActions/Ing/fr-BE.json';
import ingsubscriptionHub from '@client/internationalization/localization-messages/subscriptionHub/subscriptionHub/Ing/fr-BE.json';
import ingsupplierPortalChange from '@client/internationalization/localization-messages/supplier-portal-change/Ing/fr-BE.json';
import ingsupplierPortalSaveDesk from '@client/internationalization/localization-messages/supplier-portal-save-desk/Ing/fr-BE.json';
import ingswedbankOverviewSync from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/Ing/fr-BE.json';
import ingswedenPhoneCallsLimited from '@client/internationalization/localization-messages/SwedbankOverviewSyncFetch/Ing/fr-BE.json';
import ingsyncSourcesAddSync from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcePage/Ing/fr-BE.json';
import ingsyncSourcesAddSyncSpeed from '@client/internationalization/localization-messages/sync-sources/AddSyncSourcesSpeedDial/Ing/fr-BE.json';
import ingsyncSourcesDeleteSync from '@client/internationalization/localization-messages/sync-sources/DeleteSyncSourcesPage/Ing/fr-BE.json';
import ingsyncSourcesError from '@client/internationalization/localization-messages/sync-sources/Error/Ing/fr-BE.json';
import ingsyncSourcesNoSync from '@client/internationalization/localization-messages/sync-sources/NoSyncSources/Ing/fr-BE.json';
import ingsyncSourcesPopularSync from '@client/internationalization/localization-messages/sync-sources/PopularSyncSourcesList/Ing/fr-BE.json';
import ingsyncSourcesFetching from '@client/internationalization/localization-messages/sync-sources/SyncSourcesFetching/Ing/fr-BE.json';
import ingsyncSourcesList from '@client/internationalization/localization-messages/sync-sources/SyncSourcesList/Ing/fr-BE.json';
import ingsyncSourcesReminder from '@client/internationalization/localization-messages/sync-sources/SyncSourcesReminder/Ing/fr-BE.json';
import ingsyncSourcesTinkSync from '@client/internationalization/localization-messages/sync-sources/TinkSyncPage/Ing/fr-BE.json';
import ingsyncSourcesUpdateBank from '@client/internationalization/localization-messages/sync-sources/UpdateBankAuthsPage/Ing/fr-BE.json';
import ingsyncSourcesUpdateSync from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSources/Ing/fr-BE.json';
import ingsyncSourcesUpdateSyncButton from '@client/internationalization/localization-messages/sync-sources/UpdateSyncSourcesButton/Ing/fr-BE.json';
import ingterminationFormPage from '@client/internationalization/localization-messages/termination/TerminationFormPage/Ing/fr-BE.json';
import ingterminationFormPCancelReason from '@client/internationalization/localization-messages/termination/TerminationFormPage/CancelReasonSection/Ing/fr-BE.json';
import ingterminationInstructions from '@client/internationalization/localization-messages/termination/TerminationInstructionsPage/Ing/fr-BE.json';
import ingtermsAndCondition from '@client/internationalization/localization-messages/TermsAndConditionsUpload/Ing/fr-BE.json';
import ingtypeFormButton from '@client/internationalization/localization-messages/TypeformButton/Ing/fr-BE.json';
import inguserFormPreview from '@client/internationalization/localization-messages/user-form-preview/Ing/fr-BE.json';
import inguserFeedback from '@client/internationalization/localization-messages/userFeedback/Ing/fr-BE.json';
import inguserDeleteSettings from '@client/internationalization/localization-messages/userSettings/UserDeletePage/Ing/fr-BE.json';
import inguserDeleteVerifiedPage from '@client/internationalization/localization-messages/userSettings/UserDeleteVerifiedPage/Ing/fr-BE.json';
import ingzendesk from '@client/internationalization/localization-messages/zendesk/Ing/fr-BE.json';

export const frBETranslations = {
  aboutUsPage: aboutUsPage,
  'AboutUsPage/BankAboutUsPage': bankAboutUsPage,
  'addon/mobile/RecommendationsPage': addonMobileRecommendationsPage,
  'addon/mobile/ResultPage': addonMobileResultPage,
  'auth/AuthStatusPage': authAuthStatusPage,
  'auth/BasicLoginPage': authBasicLoginPage,
  'auth/CreateAccountPage': authCreateAccountPage,
  'auth/LoginPage': authCreateAccountPage,
  'auth/OnboardingChooseBankPage': authOnboardingChooseBankPage,
  'auth/OnboardingEmailPage': authOnboardingEmailPage,
  'auth/PreLoginPage': authPreLoginPage,
  'auth/ResetPasswordPage': authResetPasswordPage,
  'auth/SyncSourcesError': authSyncSourcesError,
  'auth/SyncSourcesFetching': authSyncSourcesFetching,
  BadgeABTestCtas: badgeABTestCTAS,
  BadgeCtas: badgeCtas,
  'bank-sync/CancelProductOnboardPages': bankSyncCancelProduct,
  'bank-sync/DrawerPages': bankSyncDrawerPages,
  'bank-sync/LoadingScreen': bankSyncLoadingScreen,
  'bank-sync/OnBoardBankSync': bankSyncOnboardBankSync,
  CampaignIdSelect: campaignIdSelect,
  'cancel/CancellationOutcome': cancelCancellationOutcome,
  'cancellation/cancellationDetailsPage': cancellationDetailsPage,
  'cancellation/CancellationFormPage/CancellationInstructionsSection': cancellationFormCancellationInstruction,
  'cancellation/CancellationFormPage/CancellationTerms': cancellationFormCancellationTerms,
  'cancellation/CancellationFormPage/ConfirmSection': cancellationFormConfirmSection,
  'cancellation/CancellationFormPage/LightUserTerms': cancellationFormLightUserTerms,
  'cancellation/CancellationModalsContainer': cancellationModalsContainer,
  'cancellation/CancellationQuestionFlow': cancellationQuestionsFlow,
  'cancellation/CancellationReasonsDialog': cancellationReasonsDialog,
  'cancellation/CancellationSentPage': cancellationSentPage,
  'cancellation/CancellationStepper': cancellationStepper,
  'cancellation/DoYouWantToSaveDialog': cancellationDoYouWantToSaveDialog,
  'cancellation/IntelligentGuidePage': cancellationIntelligentGuidePage,
  'cancellation/NetflixCancellationInstructionsPage': cancellationNetflixCancellationInstructionsPage,
  'cancellation/signing/NemIdErrorPage': cancellationSigningNemIdErrorPage,
  'cancellation/signing/NemIdSigningPage': cancellationSigningNemIdSigningPage,
  'cancellation/signing/NoSigningPage': cancellationNoSigningPage,
  'cancellation/signing/NoSigningServicePage': cancellationNoSigningServicePage,
  CancellationPortal: cancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement': cancellationsCancellationPortalAgreement,
  categories: categories,
  'CategoryNames/CategoryNames': categoryNames,
  'change-dataplan/ChangeDataplan': changeDataPlan,
  'change-dataplan/ChangeDataplanCompletedPage': changeDataPlanCompletedPage,
  'change-dataplan/ChangeDataplanOrderFormPage': changeDataPlanOrderFormPage,
  'change-dataplan/ChangeDataplanPage': changeDataPlanPage,
  'change-payment/ChangePlanServiceIntroductionDrawer': changePaymentChangePlanServiceIntroductionDrawer,
  'change-payment/IntelligentGuidePage': changePaymentIntelligentGuidePage,
  'change-plan/ChangePlanServiceIntroductionDrawer': changePlanServiceIntroductionDrawer,
  'change-plan/IntelligentGuideExperimentPage': changePlanIntelligentGuideExperimentPage,
  'change-plan/IntelligentGuidePage': changePlanIntelligentGuidePage,
  ChangePlan: changePlan,
  'charity-donation': charityDonation,
  'complex-contract-guide/IntroPage': complexContractGuideIntroPage,
  'complex-contract-guide/LoanServiceQuestionPage': complexContractGuideLoanServiceQuestionPage,
  'complex-contract-guide/LoanTypeQuestionPage': complexContractGuideLoanTypeQuestionPage,
  'complex-contract-guide/ServiceQuestionPage': complexContractGuideServiceQuestionPage,
  'complex-contract-guide/Shared': complexContractGuideShared,
  'complex-contract-guide/SuccessPage': complexContractGuideSuccessPage,
  'conditions-and-policies/ConditionsAndPoliciesMenu': conditionsAndPoliciesMenu,
  'conditions-and-policies/CookiesPage': conditionsAndPoliciesCookiesPage,
  'conditions-and-policies/SecurityPage': conditionsAndPoliciesSecurityPage,
  'conditions-and-policies': conditionsAndPolicies,
  'contract/bindingPeriodDialog': contractBindingPeriodDialog,
  'contract/CancellationHistory': contractCancellationHistory,
  'contract/CancelledContract': contractCancelledContract,
  'contract/ContractCancellationOutcome': contractCancellationOutcome,
  'contract/ContractCardMessage': contractCardMessage,
  'contract/ContractDetails': contractDetails,
  'contract/ContractDetailsPage': contractDetailsPage,
  'contract/DoMoreComponent': contractDoMoreComponent,
  'contract/HistoryToolTip': contractHistoryToolTip,
  'contract/PriceHistoryLolipop': contractPriceHistory,
  'contract/SelectServicePageLolipop': contractSelectServicePage,
  'contract/SelectServicesListLolipop': contractSelectServicesList,
  'contract/SettingsButtonLolipop': contractSettingsButton,
  'contract/SettingsPageLolipop': contractSettingsPage,
  'contract/SubscriptionAlarmLolipop': contractSubscriptionAlarm,
  'contract/SubscriptionDetailsLolipop': contractSubscriptionDetails,
  'contract/SubscriptionReminder': contractSubscriptionReminder,
  'contract/subscriptionReminderDialog': contractSubscriptionReminderDialog,
  'contract/TransactionHistoryLolipop': contractTransactionHistory,
  'contract/UpdateCostLolipop': contractUpdateCost,
  'discover/CategoryPage': discoverCategoryPage,
  'discover/CategoryProviderPage': discoverCategoryProviderPage,
  'discover/components/ProductInformationHeader': discoverComponentsProductInfo,
  'discover/DiscoverWidget': discoverDiscoverWidget,
  'discover/FakeCategoryPage': discoverFakeCategoryPage,
  'discover/FakeCategorySubscriptionsPage': discoverFakeCategorySubscriptionPage,
  'discover/FitnessCategoryFeedbackPage': discoverFitnessCategoryFeedbackPage,
  'discover/MarketplacePage': discoverMarketplacePage,
  'discover/MobileOrderSentPage': discoverMobileOrderSentPage,
  'discover/OrderProductPage': discoverOrderProductPage,
  'discover/ProductRecommendations': discoverProductRecommendations,
  'discover/SearchSubscriptionAllCategoriesPage': discoverSearchSubscriptionAllCategories,
  'discover/SearchSubscriptionPage': discoverSearchSubscriptionPage,
  'discover/SearchSubscriptionResultPage': discoverSearchSubscriptionResultPage,
  DUMMY: dummy,
  'e-signing/nem-id/NemIdSigningPage': eSigning,
  EditProduct: editProduct,
  'EnergyGuide/OrderForm': energyGuide,
  'error-handling/LoggedOutPage': errorHandling,
  'errorPage/ErrorPage': errorPageErrorPage,
  ErrorScreen: errorScreen,
  Explore: explore,
  'feedback-modal': feedbackModal,
  fields: fields,
  'financial-products/loans/ShowNewFeatureLoanDialog': financialProductsLoansShow,
  'financial-products/savings/InvestmentInfoCard': financialProductsSavingsInvestment,
  'financial-products/savings/OurPartnersPage': financialProductOurPartnersPage,
  'financial-products/savings/OurPartnersPageNew': financialProductOurPartnersPageNew,
  'financial-products/savings/Overview': financialProductsSavingsOverview,
  'financial-products/savings/SavingsAfterCancellation': financialProductSavingsAfterCancellation,
  'financial-products/ShowNewFeatureLoanDialog': financialProductsShowNewFeatureLoan,
  'financial-products/swedbank-consolidate-loans/HowItWorksPage': financialProductsSwedBankConsolidate,
  'financial-products/swedbank-consolidate-loans/IntroPage': financialSwedBankConsolidateIntro,
  'financial-products/swedbank-consolidate-loans/LoanApplicationStatus': financialProductLoanApplication,
  'financial-products/swedbank-consolidate-loans/OfferPage': financialProductsOfferPage,
  'financial-products/swedbank-consolidate-loans/OrderCompletedPage': financialProductOrderCompleted,
  'financial-products/swedbank-consolidate-loans/OrderPage': financialProductOrderPage,
  'financial-products/swedbank-consolidate-loans/SelectContractsPage': financialProductSelectContract,
  'financial-products/swedbank-consolidate-loans/SelectPrincipalPage': financialProductsSelectPrincipal,
  'financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage': financialProductRepaymentPeriod,
  'general/IntelligentGuide': generalIntelligentGuide,
  'general/SelectServicesPage': generalSelectServices,
  'genericActions/GenericActionsPage': genericActionsPage,
  'genericActions/SelectServicesPage': genericActionsSelectServices,
  'genericActions/SubscriptionSearchPage/Cancel': genericActionsSubscriptionCancel,
  'genericActions/SubscriptionSearchPage/ChangePayment': genericActionsSubscriptionsChangePayment,
  'guides/GuideContractDetailsPage/AlternativesSection': guidesContractDetailsAlternative,
  'guides/GuideContractDetailsPage/DealBanner': guidesDealBanner,
  'guides/GuideContractDetailsPage/PaymentMethodsCard': guidesPaymentMethod,
  Hack7CancelPage: hack7CancelPage,
  Header: header,
  HowToCancelListPage: howToCancelListPage,
  HowToCancelPage: howToCancelPage,
  'improve/flowgen': improveFlowgen,
  'improve/flowgen/OrderPage': improveOrderPage,
  'improve/flowgen/OrderSentPage': improveOrderSentPage,
  'improve/flowgen/OtherAlternativesPage': improveOtherAlternativePage,
  'improve/flowgen/PostalCodeInput': improvePostalCodeInput,
  'improve/flowgen/ResultPage': improveResultPage,
  'InsightsPage/InsightCards/CancellationOutcome': insightPageInsightsCardsCancelOutcome,
  'InsightsPage/InsightCards/CancellationUpdate': insightPageInsightsCardsCancelUpdate,
  'InsightsPage/OnboardingInsightCard': insightPageOnboardingInsightCard,
  'interval-to-string': intervalToString,
  LandingPage: landingPage,
  LoadingScreen: loadingScreen,
  'loan-applications/DetailsPage': loanApplicationsDetailsPage,
  'loan-applications/LoadingError': loanApplicationsLoadingError,
  'loan-applications/MenuDrawer': loanApplicationsMenuDrawer,
  'loan-applications/Overview': loanApplicationsOverview,
  LoginPage: loginPage,
  ManageOrder: manageOrder,
  ManualAddContractPage: manualAddContractPage,
  ManualContractPricingPage: manualAddContractPricingPage,
  Menu: menu,
  MenuPage: menuPage,
  'moving/BundledCancellationFromPage': movingBundledCancellationForm,
  'moving/BundledCancellationPage': movingBundledCancellationPage,
  'moving/BundledSwitchOrderFormPage': movingBundledSwitchOrderForm,
  'moving/BundledSwitchSwitchPage': movingBundledSwitchPage,
  'moving/ThanksSwitchPage': thanksSwitchPage,
  movingGuide: movingGuide,
  'movingGuide/MovingDetailsPage': movingGuideMovingDetails,
  'movingGuide/OneLastThingPage': movingGuideOneLastThingPage,
  'negotiate/NegotiateContractCompletePage': negotiateContractCompletePage,
  'negotiate/NegotiateGuide': negotiateGuide,
  'negotiation/OngoingNegotiationCard': negotiationOngoingCard,
  'negotiation/OngoingNegotiationStepper': negotiationOngoingStepper,
  NotificationOptInPage: notificationOptInPage,
  'onboarding/PSD2ConsentPage': onboardingPSD2Consent,
  'onboarding/PSD2PrimerPage': onboardingPSD2Primer,
  'onboarding-bank-discovery-sync/BankDiscoveryConnecting': onboardingBDSyncConnecting,
  'onboarding-bank-discovery-sync/BankDiscoveryErrorPage': onboardingBDSyncError,
  'onboarding-bank-discovery-sync/BankDiscoveryFetchPage': onboardingBDSyncFetch,
  'onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage': onboardingBDSyncSuccess,
  'onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage': onboardingBDSyncWaiting,
  'onboarding-cancel-product/OnboardingCancelProductPages': onboardingCancelProduct,
  'onboarding-psd2/WelcomePage': onboardingPsd2WelcomePage,
  'onboarding-psd2/ConsentPage': onboardingPsd2ConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPage': onboardingPsd2OpBankConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPageV2': onboardingPsd2OpBankConsentPageV2,
  'onboarding-psd2/ConsentPage/DanskeBankConsentPage': onboardingPsd2ConsentPageDanskeBank,
  'onboarding-psd2/RenewConsentPage': onboardingPsd2RenewConsentPage,
  'onboarding-psd2/RenewConsentPageV2': onboardingPsd2RenewConsentPageV2,
  'onboarding/Cards': onboardingPsd2Cards,
  'orderedSubscription/OngoingOrderCard': orderedSubscriptionOngoing,
  'orderedSubscription/OrderedEnergyProductInfo': orderedSubscriptionOrderedEnergy,
  'orderedSubscription/OrderedMobileProductInfo': orderedSubscriptionOrderedMobile,
  'orderedSubscription/OrderedSubscriptionDetails': orderedSubscriptionDetails,
  'orderedSubscription/OrderedSubscriptionDetailsPage': orderedSubscriptionDetailsPage,
  'orderedSubscription/OrderedSubscriptionInfoCard': orderedSubscriptionInfoCard,
  'orderedSubscription/OrderedSubscriptionPage': orderedSubscriptionPage,
  'orderedSubscription/OrderStepper': orderedSubscriptionOrderStepper,
  'orderedSubscription/ServiceProviderInfoCard': orderedSubscServiceProviderInfo,
  'ordering-subscription/common/TermsAndConditionsPage': orderingSubscriptionTerms,
  'ordering-subscription/common/OrderCompletedPage': orderingSubscriptionCompleted,
  'ordering-subscription/broadband/common/BroadbandGuideAddressPage': orderingBroadbandAddressPage,
  'ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage': orderingBroadbandAllAlternatives,
  'ordering-subscription/broadband/common/BroadbandGuideContractCostPage': orderingBroadbandContract,
  'ordering-subscription/broadband/common/BroadbandGuideIntroPage': orderingBroadbandIntroPage,
  'ordering-subscription/broadband/common/BroadbandGuideNoResultPage': orderingBroadbandNoResultPage,
  'ordering-subscription/broadband/common/BroadbandGuideSpeedPage': orderingBroadbandSpeedPage,
  'ordering-subscription/broadband/optimize/BroadbandOrderFormPage': orderingBroadbandOptimizeOrder,
  'ordering-subscription/broadband/optimize/BroadbandProductPage': orderingBroadbandOptimizeProduct,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog':
    orderingBroadbandOptimizeProductCosts,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPage': orderingElectricityOrder,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant': orderingElectricityOrderVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog': orderingElectricityGuidePrice,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage':
    orderingElectricityGuideAlternatives,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage':
    orderingElectricityGuideBinding,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage':
    orderingElectricityGuideBindingTime,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage': orderingElectricityGuideEnergy,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage':
    orderingElectricityGuideExplanation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage': orderingElectricityGuideFlow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage': orderingElectricityGuideHow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage':
    orderingElectricityGuideInformation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage': orderingElectricityGuideIntro,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage': orderingElectricityGuideLivingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage':
    orderingElectricityGuidePostalCodePage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences': orderingElectricityGuidePreferences,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage':
    orderingElectricityGuidePricingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage': orderingElectricityGuideProductPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage': orderingElectricityGuideSummaryPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage': orderingElectricityGuideTrading,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage': orderingElectricityGuideUsage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage':
    orderingElectricityGuideUsageResult,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant':
    orderingElectricityGuideUsageResultVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityPostalCode': orderingElectricityGuidePostalCode,
  'ordering-subscription/independent/OrderCompletedPage': orderingSubscriptionIndependentOrder,
  OrdersOverview: ordersOverview,
  'overview/addManualSubscription': overviewAddManualSubscription,
  'overview/BindingTimeBar': overviewBindingTimeBar,
  'overview/BindingTimeNotice': bindingTimeNotice,
  'overview/ChangePlanServiceIntroductionDrawer': overviewChangePlanService,
  'overview/ContractAction': overviewContractAction,
  'overview/DeprecatedInsightsPage/OnboardingInsightCard': overviewDeprecatedInsights,
  'overview/DiscoverPage': overviewDiscoverPage,
  'overview/GenericActions': overviewGenericAction,
  'overview/ImprovementPage': overviewImprovementPage,
  'overview/ImproveV2': overviewImproveV2,
  'overview/InsightsPage': insightPage,
  'overview/InsightsPage/InsightCards/ActionRating': overviewInsightsCardsActionRating,
  'overview/InsightsPage/InsightCards/CancellationUpdate': overviewInsightsCardsCancellationUpdate,
  'overview/InsightsPage/InsightCards/ChangeDataplanUpdate': overviewInsightsCardsChangeData,
  'overview/InsightsPage/InsightCards/IndependentOrderUpdate': overviewInsightsCardsIndependent,
  'overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton': overviewInsightsCardConfirmCancellation,
  'overview/InsightsPage/InsightCards/ContactUsButton': overviewContactUsButton,
  'overview/InsightsPage/InsightCards/MonthlyChecklist': overviewInsightsCardsMonthlyCheck,
  'overview/InsightsPage/InsightCards/NegotiationOutcome': overviewInsightsCardsNegotiationOutcome,
  'overview/InsightsPage/InsightCards/NegotiationUpdate': overviewInsightsCardsNegotiationUpdate,
  'overview/InsightsPage/InsightCards/NewProviderOffersCard': overviewInsightsCardsProvider,
  'overview/InsightsPage/InsightCards/NewSubscriptionFound': insightPage_insightsCards_newSubscription,
  'overview/InsightsPage/InsightCards/SaveDeskFeedbackCard': overviewInsightsCardsSaveDeskFeedback,
  'overview/InsightsPage/InsightCards/SaveDeskOrderRequested': overviewInsightCardsSaveDeskRequested,
  'overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested': overviewInsightsCardsSaveDeskPredefined,
  'overview/InsightsPage/InsightCards/SaveDeskResponseCard': overviewInsightsCardsSaveDeskResponse,
  'overview/InsightsPage/InsightCards/SwitchOutcome': overviewInsightsCardsSwitchOutcome,
  'overview/InsightsPage/InsightCards/SwitchUpdate': overviewInsightsCardsSwitchUpdate,
  'overview/InsightsPage/InsightCards/ThankYouCard': overviewInsightsCardsThankYou,
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton': overviewInsightsCardsTryCancellation,
  'overview/InsightsPage/InsightCards/UnreviewedAction': overviewInsightsCardsUnreviewed,
  'overview/InsightsPage/InsightCards/AddonRecommendation': insightPage_insightsCards_addon,
  'overview/InsightsPage/BindingTimeEnding': insightPage_bindingTimeEnding,
  'overview/InsightsPage/InsightCards/DiscoverOngoing': insightPage_insightsCards_discoverOngoing,
  'overview/InsightsPage/InsightCards/DiscoverOutcome': insightPage_insightsCards_discoverOutcomeInsight,
  'overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard':
    insightPage_insightsCards_personalRecommend,
  'overview/InsightsPage/InsightCards/SaveDeskOngoingCard': insightPage_insightsCards_saveDeskOngoing,
  'overview/InsightsPage/InsightCards/SaveDeskOutcome': insightPage_insightsCards_saveDeskOutcome,
  'overview/InsightsPage/SubscriptionCostChanged': insightPage_subscriptionCostChanged,
  'overview/InsightsPage/InsightCards/UnresolvedSubscription': insightPage_unresolvedSubscription,
  'overview/InsightsPage/OnboardingInsightCard': overviewInsightsCardsOnboarding,
  'overview/InsightsPage/PrimerCardV2': overviewInsightsPrimerCardV2,
  'overview/LoadingWithCogs': overviewLoadingWithCogs,
  'overview/ManageContractSection/SwitchProgressWithoutCancelStepper': overviewManageContract,
  'overview/MonthlyChecklist': overviewMonthlyChecklist,
  'overview/MoreInfoNeeded': overviewMoreInfoNeeded,
  'overview/OnboardingCards': overviewOnboardingCards,
  'overview/QuickActionsCancel': overviewQuickActionsCancel,
  'overview/QuickActionsCancelV2': overviewQuickActionsCancelV2,
  'overview/QuickActionsImprove': overviewQuickActionsImprove,
  'overview/QuickActionsImproveV2': overviewQuickActionsImproveV2,
  'overview/SubscriptionFeedback': overviewSubscriptionFeedback,
  'overview/SubscriptionSearch': overviewSubscriptionSearch,
  'overview/SubscriptionSearchOverviewComponent': overviewSubscriptionSearchComponent,
  'overview/WaysToSave': waysToSave,
  'overview/OverviewPage': overviewPage,
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage':
    overviewPage_didYouCancel,
  'overview/OnboardingDrawer': onboardingDrawer,
  'overview/QuickActionsV2': overviewPage_quickActionsV2,
  overview: overview,
  overviewBottomNavigation: overviewBottomNavigation,
  'pause/IntelligentGuidePage': pauseIntelligentGuide,
  'pause/SubscriptionPausePage': pauseSubscriptionPause,
  provideFeedbackPage: provideFeedbackPage,
  'provider-portal-datepicker/DatePicker': providerPortalDatepicker,
  'provider-portal-snackback/Snackbar': providerPortalSnackback,
  RegistrationPage: registrationPage,
  'retention-offer/SuccessfulRetentionOfferCard': retentionOfferCard,
  'save-desk': saveDesk,
  'save-desk/OngoingSaveDeskCard': saveDeskOngoingCard,
  'save-desk/OrderSentPage': saveDeskOrderSent,
  'savings/DoYouWantToSaveDialog': savingsDoYouWantToSave,
  'savings/SavingsAfterCancellation': savingsAfterCancellation,
  SelectProductPage: selectProductPage,
  'settings/NotificationSettingsPage': settingsNotificationSettings,
  'settings/NotificationSettingsPage/PushNotificationInstructions': settingsNotificationPushSettings,
  'settings/PersonalDetailsPage': settingsPersonalDetails,
  'settings/ProfilingSettingsPage': settingsProfile,
  'settings/SettingsPage': settingsPage,
  'settings/UserSettingsPage': settingsUser,
  'settings/ViewGdprDataPage': settingsViewGdprData,
  'settings-swedbank/SwedbankNotificationSettingsPage': settingsSwedbank,
  'shared-components/EmojiRating': sharedComponentsEmoji,
  'shared-components/UpdateBankApp': sharedComponentsUpdateBankApp,
  'shared-components/YouSavedComponent': sharedComponentsYouSavedComponent,
  ShowOnboardingFeaturesComponent: showOnboardingFeatures,
  SignoutSuccessPage: signOutSuccessPage,
  'standard-components/CurrencyTextField': standardComponentsCurrency,
  'standard-components/EmailField': standardComponentsEmail,
  'standard-components/DatePicker': standardComponentsDate,
  'standard-components/GuidePage': standardComponentsGuide,
  'standard-components/MobilePhoneNumberField': standardComponentsMobile,
  'standard-components/PasswordField': standardComponentsPassword,
  'standard-components/PersonalNumberField': standardComponentsPersonal,
  'standard-components/PostalCodeField': standardComponentsPostalCode,
  'standard-components/Snackbar': standardComponentsSnackbar,
  'subscription/CancellationMeasurement': subscriptionCancellationMeasure,
  'subscription/components/SelectableServiceList': subscriptionComponentsSelectable,
  'subscription/RemoveSubscriptionDialog': subscriptionRemoveSubscription,
  'subscription/SelectLoanServicesPage': subscriptionSelectLoanService,
  'subscription/SelectLoanTransactionTypePage': subscriptionSelectLoanTransaction,
  'subscription/SelectServicesPage': subscriptionSelectServices,
  'subscription/SubscriptionPage': subscriptionPage,
  'subscription/SubscriptionSettingsPage': subscriptionSettingsPage,
  'subscription/SubscriptionUpdateCostPage': subscriptionUpdateCostPage,
  'subscription/SwitchMeasurement': subscriptionSwitchMeasurement,
  'subscription/UnresolvedGuide': subscriptionUnresolvedGuide,
  'subscription/WhatsWrongDialog': subscriptionWhatsWrong,
  'subscription-alarm/MorePage': subscriptionAlarmMore,
  'subscription-alarm/SubscriptionAlarmPage': subscriptionAlarmPage,
  'subscription-hub/PastActions': subscriptionHubActions,
  'subscriptionHub/subscriptionHub': subscriptionHub,
  'supplier-portal-change': supplierPortalChange,
  'supplier-portal-save-desk': supplierPortalSaveDesk,
  SwedbankOverviewSyncFetch: swedbankOverviewSync,
  SwedenPhoneCallsLimitationInput: swedenPhoneCallsLimited,
  'sync-sources/AddSyncSourcePage': syncSourcesAddSync,
  'sync-sources/AddSyncSourcesSpeedDial': syncSourcesAddSyncSpeed,
  'sync-sources/DeleteSyncSourcesPage': syncSourcesDeleteSync,
  'sync-sources/Error': syncSourcesError,
  'sync-sources/NoSyncSources': syncSourcesNoSync,
  'sync-sources/PopularSyncSourcesList': syncSourcesPopularSync,
  'sync-sources/SyncSourcesFetching': syncSourcesFetching,
  'sync-sources/SyncSourcesList': syncSourcesList,
  'sync-sources/TinkSyncPage': syncSourcesTinkSync,
  'sync-sources/UpdateBankAuthsPage': syncSourcesUpdateBank,
  'sync-sources/UpdateSyncSources': syncSourcesUpdateSync,
  'sync-sources/UpdateSyncSourcesButton': syncSourcesUpdateSyncButton,
  'sync-sources/SyncSourcesReminder': syncSourcesReminder,
  'termination/TerminationFormPage': terminationFormPage,
  'termination/TerminationFormPage/CancelReasonSection': terminationFormPCancelReason,
  'termination/TerminationInstructionsPage': terminationInstructions,
  TermsAndConditionsUpload: termsAndCondition,
  TypeformButton: typeFormButton,
  'user-form-preview': userFormPreview,
  userFeedback: userFeedback,
  'userSettings/UserDeletePage': userDeleteSettings,
  'userSettings/UserDeleteVerifiedPage': userDeleteVerifiedPage,
  zendesk: zendesk,
  'aboutUsPage/Ing': ingaboutUsPage,
  'AboutUsPage/BankAboutUsPage/Ing': ingbankAboutUsPage,
  'addon/mobile/RecommendationsPage/Ing': ingaddonMobileRecommendationsPage,
  'addon/mobile/ResultPage/Ing': ingaddonMobileResultPage,
  'auth/AuthStatusPage/Ing': ingauthAuthStatusPage,
  'auth/BasicLoginPage/Ing': ingauthBasicLoginPage,
  'auth/CreateAccountPage/Ing': ingauthCreateAccountPage,
  'auth/LoginPage/Ing': ingauthCreateAccountPage,
  'auth/OnboardingChooseBankPage/Ing': ingauthOnboardingChooseBankPage,
  'auth/OnboardingEmailPage/Ing': ingauthOnboardingEmailPage,
  'auth/PreLoginPage/Ing': ingauthPreLoginPage,
  'auth/ResetPasswordPage/Ing': ingauthResetPasswordPage,
  'auth/SyncSourcesError/Ing': ingauthSyncSourcesError,
  'auth/SyncSourcesFetching/Ing': ingauthSyncSourcesFetching,
  'BadgeABTestCtas/Ing': ingbadgeABTestCTAS,
  'BadgeCtas/Ing': ingbadgeCtas,
  'bank-sync/CancelProductOnboardPages/Ing': ingbankSyncCancelProduct,
  'bank-sync/DrawerPages/Ing': ingbankSyncDrawerPages,
  'bank-sync/LoadingScreen/Ing': ingbankSyncLoadingScreen,
  'bank-sync/OnBoardBankSync/Ing': ingbankSyncOnboardBankSync,
  'CampaignIdSelect/Ing': ingcampaignIdSelect,
  'cancel/CancellationOutcome/Ing': ingcancelCancellationOutcome,
  'cancellation/cancellationDetailsPage/Ing': ingcancellationDetailsPage,
  'cancellation/CancellationFormPage/CancellationInstructionsSection/Ing': ingcancellationFormCancellationInstruction,
  'cancellation/CancellationFormPage/CancellationTerms/Ing': ingcancellationFormCancellationTerms,
  'cancellation/CancellationFormPage/ConfirmSection/Ing': ingcancellationFormConfirmSection,
  'cancellation/CancellationFormPage/LightUserTerms/Ing': ingcancellationFormLightUserTerms,
  'cancellation/CancellationModalsContainer/Ing': ingcancellationModalsContainer,
  'cancellation/CancellationQuestionFlow/Ing': ingcancellationQuestionsFlow,
  'cancellation/CancellationReasonsDialog/Ing': ingcancellationReasonsDialog,
  'cancellation/CancellationSentPage/Ing': ingcancellationSentPage,
  'cancellation/CancellationStepper/Ing': ingcancellationStepper,
  'cancellation/DoYouWantToSaveDialog/Ing': ingcancellationDoYouWantToSaveDialog,
  'cancellation/IntelligentGuidePage/Ing': ingcancellationIntelligentGuidePage,
  'cancellation/NetflixCancellationInstructionsPage/Ing': ingcancellationNetflixCancellationInstructionsPage,
  'cancellation/signing/NemIdErrorPage/Ing': ingcancellationSigningNemIdErrorPage,
  'cancellation/signing/NemIdSigningPage/Ing': ingcancellationSigningNemIdSigningPage,
  'cancellation/signing/NoSigningPage/Ing': ingcancellationNoSigningPage,
  'cancellation/signing/NoSigningServicePage/Ing': ingcancellationNoSigningServicePage,
  'CancellationPortal/Ing': ingcancellationPortal,
  'cancellations/cancellation-portal/cancellation-portal-agency-agreement/Ing':
    ingcancellationsCancellationPortalAgreement,
  'categories/Ing': ingcategories,
  'CategoryNames/CategoryNames/Ing': ingcategoryNames,
  'change-dataplan/ChangeDataplan/Ing': ingchangeDataPlan,
  'change-dataplan/ChangeDataplanCompletedPage/Ing': ingchangeDataPlanCompletedPage,
  'change-dataplan/ChangeDataplanOrderFormPage/Ing': ingchangeDataPlanOrderFormPage,
  'change-dataplan/ChangeDataplanPage/Ing': ingchangeDataPlanPage,
  'change-payment/ChangePlanServiceIntroductionDrawer/Ing': ingchangePaymentChangePlanServiceIntroductionDrawer,
  'change-payment/IntelligentGuidePage/Ing': ingchangePaymentIntelligentGuidePage,
  'change-plan/ChangePlanServiceIntroductionDrawer/Ing': ingchangePlanServiceIntroductionDrawer,
  'change-plan/IntelligentGuideExperimentPage/Ing': ingchangePlanIntelligentGuideExperimentPage,
  'change-plan/IntelligentGuidePage/Ing': ingchangePlanIntelligentGuidePage,
  'ChangePlan/Ing': ingchangePlan,
  'charity-donation/Ing': ingcharityDonation,
  'complex-contract-guide/IntroPage/Ing': ingcomplexContractGuideIntroPage,
  'complex-contract-guide/LoanServiceQuestionPage/Ing': ingcomplexContractGuideLoanServiceQuestionPage,
  'complex-contract-guide/LoanTypeQuestionPage/Ing': ingcomplexContractGuideLoanTypeQuestionPage,
  'complex-contract-guide/ServiceQuestionPage/Ing': ingcomplexContractGuideServiceQuestionPage,
  'complex-contract-guide/Shared/Ing': ingcomplexContractGuideShared,
  'complex-contract-guide/SuccessPage/Ing': ingcomplexContractGuideSuccessPage,
  'conditions-and-policies/ConditionsAndPoliciesMenu/Ing': ingconditionsAndPoliciesMenu,
  'conditions-and-policies/CookiesPage/Ing': ingconditionsAndPoliciesCookiesPage,
  'conditions-and-policies/SecurityPage/Ing': ingconditionsAndPoliciesSecurityPage,
  'conditions-and-policies/Ing': ingconditionsAndPolicies,
  'contract/bindingPeriodDialog/Ing': ingcontractBindingPeriodDialog,
  'contract/CancellationHistory/Ing': ingcontractCancellationHistory,
  'contract/CancelledContract/Ing': ingcontractCancelledContract,
  'contract/ContractCancellationOutcome/Ing': ingcontractCancellationOutcome,
  'contract/ContractCardMessage/Ing': ingcontractCardMessage,
  'contract/ContractDetails/Ing': ingcontractDetails,
  'contract/ContractDetailsPage/Ing': ingcontractDetailsPage,
  'contract/DoMoreComponent/Ing': ingcontractDoMoreComponent,
  'contract/HistoryToolTip/Ing': ingcontractHistoryToolTip,
  'contract/PriceHistoryLolipop/Ing': ingcontractPriceHistory,
  'contract/SelectServicePageLolipop/Ing': ingcontractSelectServicePage,
  'contract/SelectServicesListLolipop/Ing': ingcontractSelectServicesList,
  'contract/SettingsButtonLolipop/Ing': ingcontractSettingsButton,
  'contract/SettingsPageLolipop/Ing': ingcontractSettingsPage,
  'contract/SubscriptionAlarmLolipop/Ing': ingcontractSubscriptionAlarm,
  'contract/SubscriptionDetailsLolipop/Ing': ingcontractSubscriptionDetails,
  'contract/SubscriptionReminder/Ing': ingcontractSubscriptionReminder,
  'contract/subscriptionReminderDialog/Ing': ingcontractSubscriptionReminderDialog,
  'contract/TransactionHistoryLolipop/Ing': ingcontractTransactionHistory,
  'contract/UpdateCostLolipop/Ing': ingcontractUpdateCost,
  'discover/CategoryPage/Ing': ingdiscoverCategoryPage,
  'discover/CategoryProviderPage/Ing': ingdiscoverCategoryProviderPage,
  'discover/components/ProductInformationHeader/Ing': ingdiscoverComponentsProductInfo,
  'discover/DiscoverWidget/Ing': ingdiscoverDiscoverWidget,
  'discover/FakeCategoryPage/Ing': ingdiscoverFakeCategoryPage,
  'discover/FakeCategorySubscriptionsPage/Ing': ingdiscoverFakeCategorySubscriptionPage,
  'discover/FitnessCategoryFeedbackPage/Ing': ingdiscoverFitnessCategoryFeedbackPage,
  'discover/MarketplacePage/Ing': ingdiscoverMarketplacePage,
  'discover/MobileOrderSentPage/Ing': ingdiscoverMobileOrderSentPage,
  'discover/OrderProductPage/Ing': ingdiscoverOrderProductPage,
  'discover/ProductRecommendations/Ing': ingdiscoverProductRecommendations,
  'discover/SearchSubscriptionAllCategoriesPage/Ing': ingdiscoverSearchSubscriptionAllCategories,
  'discover/SearchSubscriptionPage/Ing': ingdiscoverSearchSubscriptionPage,
  'discover/SearchSubscriptionResultPage/Ing': ingdiscoverSearchSubscriptionResultPage,
  'DUMMY/Ing': ingdummy,
  'e-signing/nem-id/NemIdSigningPage/Ing': ingeSigning,
  'EditProduct/Ing': ingeditProduct,
  'EnergyGuide/OrderForm/Ing': ingenergyGuide,
  'error-handling/LoggedOutPage/Ing': ingerrorHandling,
  'errorPage/ErrorPage/Ing': ingerrorPageErrorPage,
  'ErrorScreen/Ing': ingerrorScreen,
  'Explore/Ing': ingexplore,
  'feedback-modal/Ing': ingfeedbackModal,
  'fields/Ing': ingfields,
  'financial-products/loans/ShowNewFeatureLoanDialog/Ing': ingfinancialProductsLoansShow,
  'financial-products/savings/InvestmentInfoCard/Ing': ingfinancialProductsSavingsInvestment,
  'financial-products/savings/OurPartnersPage/Ing': ingfinancialProductOurPartnersPage,
  'financial-products/savings/OurPartnersPageNew/Ing': ingfinancialProductOurPartnersPageNew,
  'financial-products/savings/Overview/Ing': ingfinancialProductsSavingsOverview,
  'financial-products/savings/SavingsAfterCancellation/Ing': ingfinancialProductSavingsAfterCancellation,
  'financial-products/ShowNewFeatureLoanDialog/Ing': ingfinancialProductsShowNewFeatureLoan,
  'financial-products/swedbank-consolidate-loans/HowItWorksPage/Ing': ingfinancialProductsSwedBankConsolidate,
  'financial-products/swedbank-consolidate-loans/IntroPage/Ing': ingfinancialSwedBankConsolidateIntro,
  'financial-products/swedbank-consolidate-loans/LoanApplicationStatus/Ing': ingfinancialProductLoanApplication,
  'financial-products/swedbank-consolidate-loans/OfferPage/Ing': ingfinancialProductsOfferPage,
  'financial-products/swedbank-consolidate-loans/OrderCompletedPage/Ing': ingfinancialProductOrderCompleted,
  'financial-products/swedbank-consolidate-loans/OrderPage/Ing': ingfinancialProductOrderPage,
  'financial-products/swedbank-consolidate-loans/SelectContractsPage/Ing': ingfinancialProductSelectContract,
  'financial-products/swedbank-consolidate-loans/SelectPrincipalPage/Ing': ingfinancialProductsSelectPrincipal,
  'financial-products/swedbank-consolidate-loans/SelectRepaymentPeriodPage/Ing': ingfinancialProductRepaymentPeriod,
  'general/IntelligentGuide/Ing': inggeneralIntelligentGuide,
  'general/SelectServicesPage/Ing': inggeneralSelectServices,
  'genericActions/GenericActionsPage/Ing': inggenericActionsPage,
  'genericActions/SelectServicesPage/Ing': inggenericActionsSelectServices,
  'genericActions/SubscriptionSearchPage/Cancel/Ing': inggenericActionsSubscriptionCancel,
  'genericActions/SubscriptionSearchPage/ChangePayment/Ing': inggenericActionsSubscriptionsChangePayment,
  'guides/GuideContractDetailsPage/AlternativesSection/Ing': ingguidesContractDetailsAlternative,
  'guides/GuideContractDetailsPage/DealBanner/Ing': ingguidesDealBanner,
  'guides/GuideContractDetailsPage/PaymentMethodsCard/Ing': ingguidesPaymentMethod,
  'Hack7CancelPage/Ing': inghack7CancelPage,
  'Header/Ing': ingheader,
  'HowToCancelListPage/Ing': inghowToCancelListPage,
  'HowToCancelPage/Ing': inghowToCancelPage,
  'improve/flowgen/Ing': ingimproveFlowgen,
  'improve/flowgen/OrderPage/Ing': ingimproveOrderPage,
  'improve/flowgen/OrderSentPage/Ing': ingimproveOrderSentPage,
  'improve/flowgen/OtherAlternativesPage/Ing': ingimproveOtherAlternativePage,
  'improve/flowgen/PostalCodeInput/Ing': ingimprovePostalCodeInput,
  'improve/flowgen/ResultPage/Ing': ingimproveResultPage,
  'InsightsPage/InsightCards/CancellationOutcome/Ing': inginsightPageInsightsCardsCancelOutcome,
  'InsightsPage/InsightCards/CancellationUpdate/Ing': inginsightPageInsightsCardsCancelUpdate,
  'InsightsPage/OnboardingInsightCard/Ing': inginsightPageOnboardingInsightCard,
  'interval-to-string/Ing': ingintervalToString,
  'LandingPage/Ing': inglandingPage,
  'LoadingScreen/Ing': ingloadingScreen,
  'loan-applications/DetailsPage/Ing': ingloanApplicationsDetailsPage,
  'loan-applications/LoadingError/Ing': ingloanApplicationsLoadingError,
  'loan-applications/MenuDrawer/Ing': ingloanApplicationsMenuDrawer,
  'loan-applications/Overview/Ing': ingloanApplicationsOverview,
  'LoginPag/Ing': ingloginPage,
  'ManageOrder/Ing': ingmanageOrder,
  'ManualAddContractPage/Ing': ingmanualAddContractPage,
  'ManualContractPricingPage/Ing': ingmanualAddContractPricingPage,
  'Menu/Ing': ingmenu,
  'MenuPage/Ing': ingmenuPage,
  'moving/BundledCancellationFromPage/Ing': ingmovingBundledCancellationForm,
  'moving/BundledCancellationPage/Ing': ingmovingBundledCancellationPage,
  'moving/BundledSwitchOrderFormPage/Ing': ingmovingBundledSwitchOrderForm,
  'moving/BundledSwitchSwitchPage/Ing': ingmovingBundledSwitchPage,
  'moving/ThanksSwitchPage/Ing': ingthanksSwitchPage,
  'movingGuide/Ing': ingmovingGuide,
  'movingGuide/MovingDetailsPage/Ing': ingmovingGuideMovingDetails,
  'movingGuide/OneLastThingPage/Ing': ingmovingGuideOneLastThingPage,
  'negotiate/NegotiateContractCompletePage/Ing': ingnegotiateContractCompletePage,
  'negotiate/NegotiateGuide/Ing': ingnegotiateGuide,
  'negotiation/OngoingNegotiationCard/Ing': ingnegotiationOngoingCard,
  'negotiation/OngoingNegotiationStepper/Ing': ingnegotiationOngoingStepper,
  'NotificationOptInPage/Ing': ingnotificationOptInPage,
  'onboarding/PSD2ConsentPage/Ing': ingonboardingPSD2Consent,
  'onboarding/PSD2PrimerPage/Ing': ingonboardingPSD2Primer,
  'onboarding-bank-discovery-sync/BankDiscoveryConnecting/Ing': ingonboardingBDSyncConnecting,
  'onboarding-bank-discovery-sync/BankDiscoveryErrorPage/Ing': ingonboardingBDSyncError,
  'onboarding-bank-discovery-sync/BankDiscoveryFetchPage/Ing': ingonboardingBDSyncFetch,
  'onboarding-bank-discovery-sync/BankDiscoverySuccessOnboardingPage/Ing': ingonboardingBDSyncSuccess,
  'onboarding-bank-discovery-sync/BankDiscoveryWaitingForBankIdPage/Ing': ingonboardingBDSyncWaiting,
  'onboarding-cancel-product/OnboardingCancelProductPages/Ing': ingonboardingCancelProduct,
  'onboarding-psd2/WelcomePage/Ing': ingonboardingPsd2WelcomePage,
  'onboarding-psd2/ConsentPage/Ing': ingonboardingPsd2ConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPage/Ing': ingonboardingPsd2OpBankConsentPage,
  'onboarding-psd2/ConsentPage/OpBankConsentPageV2/Ing': ingonboardingPsd2OpBankConsentPageV2,
  'onboarding-psd2/ConsentPage/DanskeBankConsentPage/Ing': ingonboardingPsd2ConsentPageDanskeBank,
  'onboarding-psd2/RenewConsentPage/Ing': ingonboardingPsd2RenewConsentPage,
  'onboarding-psd2/RenewConsentPageV2/Ing': ingonboardingPsd2RenewConsentPageV2,
  'onboarding/Cards/Ing': ingonboardingPsd2Cards,
  'orderedSubscription/OngoingOrderCard/Ing': ingorderedSubscriptionOngoing,
  'orderedSubscription/OrderedEnergyProductInfo/Ing': ingorderedSubscriptionOrderedEnergy,
  'orderedSubscription/OrderedMobileProductInfo/Ing': ingorderedSubscriptionOrderedMobile,
  'orderedSubscription/OrderedSubscriptionDetails/Ing': ingorderedSubscriptionDetails,
  'orderedSubscription/OrderedSubscriptionDetailsPage/Ing': ingorderedSubscriptionDetailsPage,
  'orderedSubscription/OrderedSubscriptionInfoCard/Ing': ingorderedSubscriptionInfoCard,
  'orderedSubscription/OrderedSubscriptionPage/Ing': ingorderedSubscriptionPage,
  'orderedSubscription/OrderStepper/Ing': ingorderedSubscriptionOrderStepper,
  'orderedSubscription/ServiceProviderInfoCard/Ing': ingorderedSubscServiceProviderInfo,
  'ordering-subscription/common/TermsAndConditionsPage/Ing': ingorderingSubscriptionTerms,
  'ordering-subscription/common/OrderCompletedPage/Ing': ingorderingSubscriptionCompleted,
  'ordering-subscription/broadband/common/BroadbandGuideAddressPage/Ing': ingorderingBroadbandAddressPage,
  'ordering-subscription/broadband/common/BroadbandGuideAllAlternativesPage/Ing': ingorderingBroadbandAllAlternatives,
  'ordering-subscription/broadband/common/BroadbandGuideContractCostPage/Ing': ingorderingBroadbandContract,
  'ordering-subscription/broadband/common/BroadbandGuideIntroPage/Ing': ingorderingBroadbandIntroPage,
  'ordering-subscription/broadband/common/BroadbandGuideNoResultPage/Ing': ingorderingBroadbandNoResultPage,
  'ordering-subscription/broadband/common/BroadbandGuideSpeedPage/Ing': ingorderingBroadbandSpeedPage,
  'ordering-subscription/broadband/optimize/BroadbandOrderFormPage/Ing': ingorderingBroadbandOptimizeOrder,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/Ing': ingorderingBroadbandOptimizeProduct,
  'ordering-subscription/broadband/optimize/BroadbandProductPage/BroadbandCostsDialog/Ing':
    ingorderingBroadbandOptimizeProductCosts,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPage/Ing': ingorderingElectricityOrder,
  'ordering-subscription/electricity/optimize/ElectricityOrderFormPageVariant/Ing': ingorderingElectricityOrderVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityComparisonPriceDialog/Ing':
    ingorderingElectricityGuidePrice,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideAllAlternativesPage/Ing':
    ingorderingElectricityGuideAlternatives,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingPage/Ing':
    ingorderingElectricityGuideBinding,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideContractBindingTimePage/Ing':
    ingorderingElectricityGuideBindingTime,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideEnergySourcesPage/Ing':
    ingorderingElectricityGuideEnergy,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideExplanationPage/Ing':
    ingorderingElectricityGuideExplanation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideFlowPreferencePage/Ing':
    ingorderingElectricityGuideFlow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideHowItWorksPage/Ing': ingorderingElectricityGuideHow,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideInformationPage/Ing':
    ingorderingElectricityGuideInformation,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideIntroPage/Ing': ingorderingElectricityGuideIntro,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideLivingTypePage/Ing':
    ingorderingElectricityGuideLivingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePostalCodePage/Ing':
    ingorderingElectricityGuidePostalCodePage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePreferences/Ing':
    ingorderingElectricityGuidePreferences,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuidePricingTypePage/Ing':
    ingorderingElectricityGuidePricingType,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideProductPage/Ing':
    ingorderingElectricityGuideProductPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideSummaryPage/Ing':
    ingorderingElectricityGuideSummaryPage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideTradingCostPage/Ing':
    ingorderingElectricityGuideTrading,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsage/Ing': ingorderingElectricityGuideUsage,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPage/Ing':
    ingorderingElectricityGuideUsageResult,
  'ordering-subscription/electricity/optimize/guide/ElectricityGuideUsageResultPageVariant/Ing':
    ingorderingElectricityGuideUsageResultVariant,
  'ordering-subscription/electricity/optimize/guide/ElectricityPostalCode/Ing': ingorderingElectricityGuidePostalCode,
  'ordering-subscription/independent/OrderCompletedPage/Ing': ingorderingSubscriptionIndependentOrder,
  'OrdersOverview/Ing': ingordersOverview,
  'overview/addManualSubscription/Ing': ingoverviewAddManualSubscription,
  'overview/BindingTimeBar/Ing': ingoverviewBindingTimeBar,
  'overview/BindingTimeNotice/Ing': ingbindingTimeNotice,
  'overview/ChangePlanServiceIntroductionDrawer/Ing': ingoverviewChangePlanService,
  'overview/ContractAction/Ing': ingoverviewContractAction,
  'overview/DeprecatedInsightsPage/OnboardingInsightCard/Ing': ingoverviewDeprecatedInsights,
  'overview/DiscoverPage/Ing': ingoverviewDiscoverPage,
  'overview/GenericActions/Ing': ingoverviewGenericAction,
  'overview/ImprovementPage/Ing': ingoverviewImprovementPage,
  'overview/ImproveV2/Ing': ingoverviewImproveV2,
  'overview/InsightsPage/Ing': inginsightPage,
  'overview/InsightsPage/InsightCards/ActionRating/Ing': ingoverviewInsightsCardsActionRating,
  'overview/InsightsPage/InsightCards/CancellationUpdate/Ing': ingoverviewInsightsCardsCancellationUpdate,
  'overview/InsightsPage/InsightCards/ChangeDataplanUpdate/Ing': ingoverviewInsightsCardsChangeData,
  'overview/InsightsPage/InsightCards/IndependentOrderUpdate/Ing': ingoverviewInsightsCardsIndependent,
  'overview/InsightsPage/InsightCards/ConfirmCancellationByUserButton/Ing': ingoverviewInsightsCardConfirmCancellation,
  'overview/InsightsPage/InsightCards/ContactUsButton/Ing': ingoverviewContactUsButton,
  'overview/InsightsPage/InsightCards/MonthlyChecklist/Ing': ingoverviewInsightsCardsMonthlyCheck,
  'overview/InsightsPage/InsightCards/NegotiationOutcome/Ing': ingoverviewInsightsCardsNegotiationOutcome,
  'overview/InsightsPage/InsightCards/NegotiationUpdate/Ing': ingoverviewInsightsCardsNegotiationUpdate,
  'overview/InsightsPage/InsightCards/NewProviderOffersCard/Ing': ingoverviewInsightsCardsProvider,
  'overview/InsightsPage/InsightCards/NewSubscriptionFound/Ing': inginsightPage_insightsCards_newSubscription,
  'overview/InsightsPage/InsightCards/SaveDeskFeedbackCard/Ing': ingoverviewInsightsCardsSaveDeskFeedback,
  'overview/InsightsPage/InsightCards/SaveDeskOrderRequested/Ing': ingoverviewInsightCardsSaveDeskRequested,
  'overview/InsightsPage/InsightCards/SaveDeskPredefinedOfferRequested/Ing': ingoverviewInsightsCardsSaveDeskPredefined,
  'overview/InsightsPage/InsightCards/SaveDeskResponseCard/Ing': ingoverviewInsightsCardsSaveDeskResponse,
  'overview/InsightsPage/InsightCards/SwitchOutcome/Ing': ingoverviewInsightsCardsSwitchOutcome,
  'overview/InsightsPage/InsightCards/SwitchUpdate/Ing': ingoverviewInsightsCardsSwitchUpdate,
  'overview/InsightsPage/InsightCards/ThankYouCard/Ing': ingoverviewInsightsCardsThankYou,
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton/Ing': ingoverviewInsightsCardsTryCancellation,
  'overview/InsightsPage/InsightCards/UnreviewedAction/Ing': ingoverviewInsightsCardsUnreviewed,
  'overview/InsightsPage/InsightCards/AddonRecommendation/Ing': inginsightPage_insightsCards_addon,
  'overview/InsightsPage/BindingTimeEnding/Ing': inginsightPage_bindingTimeEnding,
  'overview/InsightsPage/InsightCards/DiscoverOngoing/Ing': inginsightPage_insightsCards_discoverOngoing,
  'overview/InsightsPage/InsightCards/DiscoverOutcome/Ing': inginsightPage_insightsCards_discoverOutcomeInsight,
  'overview/InsightsPage/InsightCards/PersonalRecommendationFromPreferenceCard/Ing':
    inginsightPage_insightsCards_personalRecommend,
  'overview/InsightsPage/InsightCards/SaveDeskOngoingCard/Ing': inginsightPage_insightsCards_saveDeskOngoing,
  'overview/InsightsPage/InsightCards/SaveDeskOutcome/Ing': inginsightPage_insightsCards_saveDeskOutcome,
  'overview/InsightsPage/SubscriptionCostChanged/Ing': inginsightPage_subscriptionCostChanged,
  'overview/InsightsPage/InsightCards/UnresolvedSubscription/Ing': inginsightPage_unresolvedSubscription,
  'overview/InsightsPage/OnboardingInsightCard/Ing': ingoverviewInsightsCardsOnboarding,
  'overview/InsightsPage/PrimerCardV2/Ing': ingoverviewInsightsPrimerCardV2,
  'overview/LoadingWithCogs/Ing': ingoverviewLoadingWithCogs,
  'overview/ManageContractSection/SwitchProgressWithoutCancelStepper/Ing': ingoverviewManageContract,
  'overview/MonthlyChecklist/Ing': ingoverviewMonthlyChecklist,
  'overview/MoreInfoNeeded/Ing': ingoverviewMoreInfoNeeded,
  'overview/OnboardingCards/Ing': ingoverviewOnboardingCards,
  'overview/QuickActionsCancel/Ing': ingoverviewQuickActionsCancel,
  'overview/QuickActionsCancelV2/Ing': ingoverviewQuickActionsCancelV2,
  'overview/QuickActionsImprove/Ing': ingoverviewQuickActionsImprove,
  'overview/QuickActionsImproveV2/Ing': ingoverviewQuickActionsImproveV2,
  'overview/SubscriptionFeedback/Ing': ingoverviewSubscriptionFeedback,
  'overview/SubscriptionSearch/Ing': ingoverviewSubscriptionSearch,
  'overview/SubscriptionSearchOverviewComponent/Ing': ingoverviewSubscriptionSearchComponent,
  'overview/WaysToSave/Ing': ingwaysToSave,
  'overview/OverviewPage/Ing': ingoverviewPage,
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage/Ing':
    ingoverviewPage_didYouCancel,
  'overview/OnboardingDrawer/Ing': ingonboardingDrawer,
  'overview/QuickActionsV2/Ing': ingoverviewPage_quickActionsV2,
  'overview/Ing': ingoverview,
  'overviewBottomNavigation/Ing': ingoverviewBottomNavigation,
  'pause/IntelligentGuidePage/Ing': ingpauseIntelligentGuide,
  'pause/SubscriptionPausePage/Ing': ingpauseSubscriptionPause,
  'provideFeedbackPage/Ing': ingprovideFeedbackPage,
  'provider-portal-datepicker/DatePicker/Ing': ingproviderPortalDatepicker,
  'provider-portal-snackback/Snackbar/Ing': ingproviderPortalSnackback,
  'RegistrationPage/Ing': ingregistrationPage,
  'retention-offer/SuccessfulRetentionOfferCard/Ing': ingretentionOfferCard,
  'save-desk/Ing': ingsaveDesk,
  'save-desk/OngoingSaveDeskCard/Ing': ingsaveDeskOngoingCard,
  'save-desk/OrderSentPage/Ing': ingsaveDeskOrderSent,
  'savings/DoYouWantToSaveDialog/Ing': ingsavingsDoYouWantToSave,
  'savings/SavingsAfterCancellation/Ing': ingsavingsAfterCancellation,
  'SelectProductPage/Ing': ingselectProductPage,
  'settings/NotificationSettingsPage/Ing': ingsettingsNotificationSettings,
  'settings/NotificationSettingsPage/PushNotificationInstructions/Ing': ingsettingsNotificationPushSettings,
  'settings/PersonalDetailsPage/Ing': ingsettingsPersonalDetails,
  'settings/ProfilingSettingsPage/Ing': ingsettingsProfile,
  'settings/SettingsPage/Ing': ingsettingsPage,
  'settings/UserSettingsPage/Ing': ingsettingsUser,
  'settings/ViewGdprDataPage/Ing': ingsettingsViewGdprData,
  'settings-swedbank/SwedbankNotificationSettingsPage/Ing': ingsettingsSwedbank,
  'shared-components/EmojiRating/Ing': ingsharedComponentsEmoji,
  'shared-components/UpdateBankApp/Ing': ingsharedComponentsUpdateBankApp,
  'shared-components/YouSavedComponent/Ing': ingsharedComponentsYouSavedComponent,
  'ShowOnboardingFeaturesComponene/Ing': ingshowOnboardingFeatures,
  'SignoutSuccessPage/Ing': ingsignOutSuccessPage,
  'standard-components/CurrencyTextField/Ing': ingstandardComponentsCurrency,
  'standard-components/EmailField/Ing': ingstandardComponentsEmail,
  'standard-components/DatePicker/Ing': ingstandardComponentsDate,
  'standard-components/GuidePage/Ing': ingstandardComponentsGuide,
  'standard-components/MobilePhoneNumberField/Ing': ingstandardComponentsMobile,
  'standard-components/PasswordField/Ing': ingstandardComponentsPassword,
  'standard-components/PersonalNumberField/Ing': ingstandardComponentsPersonal,
  'standard-components/PostalCodeField/Ing': ingstandardComponentsPostalCode,
  'standard-components/Snackbar/Ing': ingstandardComponentsSnackbar,
  'subscription/CancellationMeasurement/Ing': ingsubscriptionCancellationMeasure,
  'subscription/components/SelectableServiceList/Ing': ingsubscriptionComponentsSelectable,
  'subscription/RemoveSubscriptionDialog/Ing': ingsubscriptionRemoveSubscription,
  'subscription/SelectLoanServicesPage/Ing': ingsubscriptionSelectLoanService,
  'subscription/SelectLoanTransactionTypePage/Ing': ingsubscriptionSelectLoanTransaction,
  'subscription/SelectServicesPage/Ing': ingsubscriptionSelectServices,
  'subscription/SubscriptionPage/Ing': ingsubscriptionPage,
  'subscription/SubscriptionSettingsPage/Ing': ingsubscriptionSettingsPage,
  'subscription/SubscriptionUpdateCostPage/Ing': ingsubscriptionUpdateCostPage,
  'subscription/SwitchMeasurement/Ing': ingsubscriptionSwitchMeasurement,
  'subscription/UnresolvedGuide/Ing': ingsubscriptionUnresolvedGuide,
  'subscription/WhatsWrongDialog/Ing': ingsubscriptionWhatsWrong,
  'subscription-alarm/MorePage/Ing': ingsubscriptionAlarmMore,
  'subscription-alarm/SubscriptionAlarmPage/Ing': ingsubscriptionAlarmPage,
  'subscription-hub/PastActions/Ing': ingsubscriptionHubActions,
  'subscriptionHub/subscriptionHub/Ing': ingsubscriptionHub,
  'supplier-portal-change/Ing': ingsupplierPortalChange,
  'supplier-portal-save-desk/Ing': ingsupplierPortalSaveDesk,
  'SwedbankOverviewSyncFetch/Ing': ingswedbankOverviewSync,
  'SwedenPhoneCallsLimitationInput/Ing': ingswedenPhoneCallsLimited,
  'sync-sources/AddSyncSourcePage/Ing': ingsyncSourcesAddSync,
  'sync-sources/AddSyncSourcesSpeedDial/Ing': ingsyncSourcesAddSyncSpeed,
  'sync-sources/DeleteSyncSourcesPage/Ing': ingsyncSourcesDeleteSync,
  'sync-sources/Error/Ing': ingsyncSourcesError,
  'sync-sources/NoSyncSources/Ing': ingsyncSourcesNoSync,
  'sync-sources/PopularSyncSourcesList/Ing': ingsyncSourcesPopularSync,
  'sync-sources/SyncSourcesFetching/Ing': ingsyncSourcesFetching,
  'sync-sources/SyncSourcesList/Ing': ingsyncSourcesList,
  'sync-sources/TinkSyncPage/Ing': ingsyncSourcesTinkSync,
  'sync-sources/UpdateBankAuthsPage/Ing': ingsyncSourcesUpdateBank,
  'sync-sources/UpdateSyncSources/Ing': ingsyncSourcesUpdateSync,
  'sync-sources/UpdateSyncSourcesButton/Ing': ingsyncSourcesUpdateSyncButton,
  'sync-sources/SyncSourcesReminder/Ing': ingsyncSourcesReminder,
  'termination/TerminationFormPage/Ing': ingterminationFormPage,
  'termination/TerminationFormPage/CancelReasonSection/Ing': ingterminationFormPCancelReason,
  'termination/TerminationInstructionsPage/Ing': ingterminationInstructions,
  'TermsAndConditionsUpload/Ing': ingtermsAndCondition,
  'TypeformButton/Ing': ingtypeFormButton,
  'user-form-preview/Ing': inguserFormPreview,
  'userFeedback/Ing': inguserFeedback,
  'userSettings/UserDeletePage/Ing': inguserDeleteSettings,
  'userSettings/UserDeleteVerifiedPage/Ing': inguserDeleteVerifiedPage,
  'zendesk/Ing': ingzendesk,
};
