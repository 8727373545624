import Sentry from '@client/assets/js/sentry';
import { GenericSyncErrorPageContainer } from '@client/components/error/GenericSyncErrorPage';
import { Psd2ErrorPageContainer } from '@client/components/error/Psd2ErrorPage';
import { setUser, setUserVisitedPage } from '@client/ducks/user';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { getUser, saveUserViewedBankSyncWorkInProgress } from '@client/models/user';
import { selectorAppType, selectorFeatures } from '@client/modules/app-context/duck';
import { cogsColor } from '@client/modules/loading-with-cogs/utils';
import { updateOverview } from '@client/modules/overview/duck';
import {
  fetchSessionWebUiPath,
  fetchSyncedOverview,
  ReconsentReason,
  triggerSyncedOverviewPsd2,
  triggerSyncedOverviewWithoutPsd2AuthCode,
} from '@client/modules/overview/model';
import * as urls from '@client/routes';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_ONBOARDING_ACTION_DRAWER } from '@client/tracking/mixpanel-constants';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import useTheme from '@material-ui/core/styles/useTheme';
import { CogsAnimation } from '@minna-technologies/minna-ui/components/CogsAnimation';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import type { AxiosError } from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SyncFlowType } from '../app-context';
import styles from './styles.scss';
import { AppType } from '@client/modules/app-context/constants';
import { psd2AuthenticationWithClient } from '@client/modules/onboarding/OnboardingPsd2/consent/psd2AuthenticationWithClient';

interface LoadingWithCogsInnerProps {
  isFirstTimePsd2User?: boolean;
  isFirstTimePsd2UserWithoutCode?: boolean;
  location?: any;
}

export const triggerFetchSyncedOverviewPsd2 = async (location: any) => {
  let params;
  if (location.search) {
    params = new URLSearchParams(location.search);
  } else {
    // Some banks send all params after a # instead of a ? as per https://openid.net/specs/openid-connect-core-1_0.html#FragmentNotes
    const fragmentString = location.hash.slice(1, location.hash.length);
    params = new URLSearchParams(fragmentString);
  }

  const code = params.get('code');
  const idToken = params.get('id_token');
  const error = params.get('error');
  const errorDescription = params.get('error_description');

  if (error === 'access_denied') {
    if (errorDescription !== 'PSU Cancelled') {
      // OP will end up here if user clicks cancel in PSD2 flow. This results in sending the user back to the beginning of the Bank's PSD2 flow. Do we want this?
      const url = await psd2AuthenticationWithClient();
      window.location = url;
    } else {
      Sentry.captureMessage(
        `Got error ${error}, errorDescription ${errorDescription} when user returned from PSD2 auth flow`
      );
      throw new Error(
        `Got error when user returned from bank psd2 auth flow. Error: ${error}, errorDescription: ${errorDescription}`
      );
    }
  } else if (error) {
    Sentry.captureMessage(`Got error ${error} when user returned from PSD2 auth flow`);
    throw new Error(`Got error when user returned from bank psd2 auth flow: ${error}`);
  }

  const requestData = {
    code: code,
    idToken: idToken,
  };

  return triggerSyncedOverviewPsd2(requestData);
};

// Sabadell specific psd2 implementation: Psd2 auth code is received and exchanged before user is redirected to loading screen
const triggerFetchSyncedOverviewWithoutPsd2AuthCode = async () => triggerSyncedOverviewWithoutPsd2AuthCode();

export const LoadingWithCogsInner: React.FunctionComponent<LoadingWithCogsInnerProps> = ({
  isFirstTimePsd2User,
  isFirstTimePsd2UserWithoutCode,
  location,
}: LoadingWithCogsInnerProps) => {
  enum FetchStatus {
    IS_LOADING = 'IS_LOADING',
    FAILED = 'FAILED',
    SUCCEEDED = 'SUCCEEDED',
  }
  const [isFakeWaiting, setIsFakeWaiting] = useState(false);
  const [redirectPending, setRedirectPending] = useState(false);
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.IS_LOADING);
  const [redirectUrl, setRedirectUrl] = useState(urls.overviewPage);
  const features = useSelector(selectorFeatures);
  const { colors } = useTheme();
  const cogColor = cogsColor(features.theme, colors.action.primary as string);
  const history = useHistory();
  const dispatch = useDispatch();
  const isFromPsd2Flow = features.syncFlow?.syncFlowType === SyncFlowType.PSD2;
  const errorPage = isFromPsd2Flow ? <Psd2ErrorPageContainer /> : <GenericSyncErrorPageContainer />;
  const platform = useSelector(selectorAppType);

  useMountEffect(() => {
    getMixpanel().track(EventNames.VIEWED_GUIDE, {
      Feature: FEATURE_ONBOARDING_ACTION_DRAWER,
      Page: 'Loading Screen',
    });
    if (isFromPsd2Flow) {
      fetchSessionWebUiPath()
        .then((maybeWebUiRedirectUrl) => {
          if (maybeWebUiRedirectUrl) {
            setRedirectUrl(maybeWebUiRedirectUrl);
          }
        })
        .catch((error: AxiosError) => {
          Sentry.captureExceptionWithMessage(error, 'Failed to fetch session DeepLink');
        });
    }
    // start a timeout of 3 seconds, minimum waiting time for the initial screen.
    // this is a workaround for AB tests not getting set properly when one of the
    // initial pages is being tested.
    setIsFakeWaiting(true);
    setTimeout(() => {
      setIsFakeWaiting(false);
    }, 3000);
  });

  const saveUserViewedBankSyncPages = useCallback(() => {
    dispatch(setUserVisitedPage('viewedBankSyncWorkInProgressAt', moment().toString()));
    saveUserViewedBankSyncWorkInProgress().catch((error: Error) => {
      Sentry.captureExceptionWithMessage(error, 'Failed to update visited bank loading screen on user model');
    });
  }, [dispatch]);

  const navigateToFinalUrl = useCallback(
    (url) => {
      if (features.identify?.identifyOptIn) {
        // Redirect with a hard refresh, since feature toggles might have changed due to user being upgraded from a light to a full user
        window.location = url;
      } else {
        history.replace(url);
      }
    },
    [history, features]
  );

  useEffect(() => {
    if (fetchStatus === FetchStatus.IS_LOADING) {
      const asyncWaitForOverviewSync = async () => {
        try {
          if (isFromPsd2Flow && isFirstTimePsd2User) {
            await triggerFetchSyncedOverviewPsd2(location);
          } else if (isFromPsd2Flow && isFirstTimePsd2UserWithoutCode) {
            const res = await triggerFetchSyncedOverviewWithoutPsd2AuthCode();
            if (res.requiresReconsent) {
              if (
                res.reconsentReason == ReconsentReason.TokenInvalid ||
                res.reconsentReason == ReconsentReason.ConsentInvalid
              ) {
                //redirect to renew consent page
                history.push(urls.onboardingPsd2ConsentExpired);
              }
            }
          }
          const overviewResponse = await fetchSyncedOverview();
          if (overviewResponse.requiresReconsent) {
            //redirect to renew consent page
            history.push(urls.onboardingPsd2ConsentExpired);
          }
          if (overviewResponse.overview) {
            dispatch(updateOverview(overviewResponse.overview));
            setFetchStatus(FetchStatus.SUCCEEDED);
            saveUserViewedBankSyncPages();
          } else {
            Sentry.captureMessage('Overview was not returned from backend', { level: 'info' });
          }
        } catch (error) {
          setFetchStatus(FetchStatus.FAILED);
          Sentry.captureExceptionWithMessage(error, 'Failed to fetch overview for sync');
        }
      };

      getUser()
        .then((user) => {
          dispatch(setUser(user));
          if (user.role === 'ManualTestUser' && platform !== AppType.OP) {
            // Do not trigger sync for test users, send directly to destination.
            // For OP it's OK to trigger a sync since the bank simulator is always on there.
            history.replace(redirectUrl);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            asyncWaitForOverviewSync();
          }
        })
        .catch((error) => {
          Sentry.captureExceptionWithMessage(error, 'Failed to load user');
        });
    }
    if (fetchStatus === FetchStatus.SUCCEEDED) {
      saveUserViewedBankSyncPages();
      if (!isFakeWaiting) {
        navigateToFinalUrl(redirectUrl);
      } else {
        setRedirectPending(true);
      }
    }
    // disabled this linting rule since this has a lot of unclear dependencies. fetchStatus
    // should be the only relevant one, so we keep it at that and disable the linting rule.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus]);
  useEffect(() => {
    if (redirectPending && !isFakeWaiting) {
      navigateToFinalUrl(redirectUrl);
    }
  }, [redirectPending, isFakeWaiting, navigateToFinalUrl, redirectUrl]);

  return fetchStatus !== FetchStatus.FAILED ? (
    <div className={styles.container}>
      <Headline2 style={{ marginTop: '72px', textAlign: 'center' }} data-test="loading-title">
        <LocalizedMessage id={'loadingTitle'} />
      </Headline2>
      <Body style={{ marginTop: '32px', textAlign: 'center' }}>
        <LocalizedMessage id={'inAMoment'} />
      </Body>
      <Body style={{ marginTop: '24px' }}>
        <LocalizedMessage id={'30Seconds'} />
      </Body>
      <div style={{ marginTop: '64px' }}>
        <CogsAnimation color={cogColor} />
      </div>
    </div>
  ) : (
    errorPage
  );
};

export const LoadingWithCogs = withLocalization('overview/LoadingWithCogs')(LoadingWithCogsInner);
